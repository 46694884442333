module rps.data {

    export class Report {
        public reportName: string;
        public inputParams: rps.data.parameters.QueryParams;

        constructor(params: {
            reportName: string;
            inputParams?: rps.data.parameters.QueryParams;
        }) {
            this.reportName = params.reportName;
            if (params.inputParams)
                this.inputParams = params.inputParams;
        }
    }

    export class UserReport {
        public codReport: string;

        constructor(params: {
            codReport: string;
        }) {
            this.codReport = params.codReport;
        }
    }
} 