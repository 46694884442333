/// <reference path="typeExtensions.ts" />

module rps.extensions {
    export class Extension {
        constructor(public extensionFn: Function, public extendsFn: Function) {
        }
    }

    export function register(...exportedMemebers: any[]) {
        var exportedObject = {};
        exportedMemebers.forEach((member) => {
            if (rps.object.isFunction(member))
                exportedObject[functionName(member)] = member;
            else if (member instanceof Extension)
                exportedObject[functionName((<Extension>member).extendsFn)] = member;
        });
        return exportedObject;
    }

    export function functionName(fun) {
        var ret: string;
        if (fun instanceof Function)
            ret = fun.toString();
        else if (fun.constructor instanceof Function)
            ret = fun.constructor.toString();

        ret = ret.substr('function '.length);
        ret = ret.substr(0, ret.indexOf('('));
        return ret;
    }
}
 