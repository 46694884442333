/// <reference path="parameters.ts" />
/// <reference path="../viewmodels/modelVM.ts" />

module rps.data.sources {
    export class ViewSource implements IActivable,IOnBeforeUnload,IDestroyable,IExecutableQuery {        
        public isIActivable: boolean = true;
        public isIDestroyable: boolean = true;
        public isIOnBeforeUnload: boolean = true;

        public propertyChanged = rps.app.eventManager.createEmitter<rps.viewmodels.properties.VMPropertyChange>();
        private _parentVM: rps.viewmodels.BaseVM;
        private defaultView: string;        
        private relatedVMFunction: Function;
        private optionsBindings: Array<Function> = [];        
        private saveState: boolean;
        private filtersManager: rps.data.filters.FiltersManager;

        public entityName: string;
        public allowedViews: Array<rps.data.sources.ViewSource.ViewDefinitionVM>;
        private _selectedView: rps.data.sources.ViewSource.ViewDefinitionVM;
        get selectedView(): rps.data.sources.ViewSource.ViewDefinitionVM {
            return this._selectedView;
        }
        set selectedView(newValue: rps.data.sources.ViewSource.ViewDefinitionVM) {
            this.setSelectedView(newValue);
        }

        private _selectedViewType: string;
        get selectedViewType(): string {
            return this._selectedViewType;
        }
        set selectedViewType(newValue: string) {
            this._selectedViewType = (newValue);

            this.propertyChanged.emit({ propertyName:"selectedViewType",newValue:newValue});
        }

        private _options: rps.data.sources.ViewSource.IViewSourceOptions;
        get options(): rps.data.sources.ViewSource.IViewSourceOptions {
            return this._options;
        }
        set options(newValue: rps.data.sources.ViewSource.IViewSourceOptions) {
            this._options = newValue;

            this.propertyChanged.emit({ propertyName: "options", newValue: newValue });
        }

        public allowedViewTypes: Array<rps.data.sources.IView>;
        public items: { data: Array<any>; count: number };
        public hasItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.items) && this.items.count > 0)
                return true;
            else
                return false;
        }
        public getItems(): Array<any> {
            if (this.hasItems())
                return this.items.data;
            else
                return new Array<any>();
        }
        public selectedItems: Array<any>;
        public hasSelectedItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.selectedItems) && this.selectedItems.length > 0)
                return true;
            else
                return false;
        }
        public getSelectedItems(): Array<any> {
            if (this.hasSelectedItems())
                return this.selectedItems;
            else
                return new Array<any>();
        }

        public autoRefresh: boolean = false;

        constructor(params: {
            entityName: string;
            parentVM?: rps.viewmodels.BaseVM;
            defaultView?: string;            
            relatedVMFunction?: Function;
            saveState?: boolean;            
        }) {
            this.filtersManager = new rps.data.filters.FiltersManager({
                entityName: params.entityName
            });
            this._parentVM = params.parentVM;
            this.defaultView = params.defaultView;
            this.entityName = params.entityName;
            if (params.relatedVMFunction)
                this.relatedVMFunction = (<any>(params.relatedVMFunction)).getResolvedType(params.relatedVMFunction);
            else
                this.relatedVMFunction = rps.data.sources.QuerySourceItemVM;

            var newAllowedViewTypes: Array<rps.data.sources.IView> = new Array<rps.data.sources.IView>();
            newAllowedViewTypes.push({ value: 'Card', type: rps.data.sources.ViewType.Card, name: 'Card view',allowEditItem:false });
            newAllowedViewTypes.push({ value: 'Grid', type: rps.data.sources.ViewType.Grid, name: 'Grid view', allowEditItem: false });
            this.allowedViewTypes = newAllowedViewTypes;
            this.saveState = params.saveState;
        }

        initialize(): Promise<any> {
            return this.configViews();
        }

        private configViews(): Promise<any> {
            return rps.app.apiRef.get<Array<rps.data.QueryHeader>>({
                url: rps.string.format("{0}/entities/{1}/queries/views", this.entityName.split("/")[0],this.entityName.split("/")[1])
            }).then((result: Array<rps.data.QueryHeader>) => {
                //Generar los VM-s a partir de la lista de vistas devuelta por el servidor
                this.allowedViews = new Array<rps.data.sources.ViewSource.ViewDefinitionVM>();
                result.forEach((ri) => {
                    this.allowedViews.push(new rps.data.sources.ViewSource.ViewDefinitionVM(ri));
                });           

                //Algoritmo para decidir, cual es la vista por defecto
                if (Enumerable.From<rps.data.sources.ViewSource.ViewDefinitionVM>(this.allowedViews).Any()) {                        
                    var view: rps.data.sources.ViewSource.ViewDefinitionVM;

                    //Se busca en la cache, para mirar si tenemos guardados datos de la última visita a la página
                    var key: string = string.format("ViewSource/{0}/{1}", rps.app.session.user, this.entityName);
                    var localStorageData: {
                        filterDefinition: rps.data.FilterDefinition;
                        selectedViewName: string;
                        selectedViewType: string;
                    } = rps.app.localStorage.get(key);

                    //Se intenta obtener la vista de los datos de la cache
                    if (localStorageData && localStorageData.selectedViewName)
                        view = Enumerable.From<rps.data.sources.ViewSource.ViewDefinitionVM>(this.allowedViews).FirstOrDefault(null, (v) => v.Name == localStorageData.selectedViewName);
                    //Se intenta obtener la vista de los datos por defecto
                    if (!view &&this.defaultView)
                        view = Enumerable.From<rps.data.sources.ViewSource.ViewDefinitionVM>(this.allowedViews).FirstOrDefault(null, (v) => v.Name == this.defaultView);
                    //Si aun no tenemos la vista a seleccionar, se coge la primera
                    if (!view)
                        view = Enumerable.From<rps.data.sources.ViewSource.ViewDefinitionVM>(this.allowedViews).First();

                    //En el caso de que haya filtros cacheados, se aplican
                    if (localStorageData && localStorageData.filterDefinition)
                        return this.filtersManager.setFilterDefinition(localStorageData.filterDefinition).then(() => {
                            //En caso de que haya un tipo de vista seleccionada, se selecciona
                            if (localStorageData.selectedViewType &&
                                Enumerable.From<rps.data.sources.IView>(this.allowedViewTypes).Any(vt => vt.value == localStorageData.selectedViewType)) {
                                this.selectedViewType = localStorageData.selectedViewType;
                            }
                            return this.setSelectedView(view);
                        });
                    else
                        return this.setSelectedView(view);
                }
                else {
                    return this.setSelectedView(null);
                }
            });
        }

        setSelectedView(newValue: rps.data.sources.ViewSource.ViewDefinitionVM): Promise<any>{
            if (this._selectedView != newValue) {
                this._selectedView = newValue;
                return this.createOptions();
            }
            else
                return Promise.resolve<any>(this);
        }

        createOptions(): Promise<ViewSource>{
            return new Promise<ViewSource>((resolve) => {
                if (this.selectedView) {                    
                    rps.app.api.get({
                        url: this.selectedView.ReferenceUrl,
                        urlType: rps.services.UrlType.Absolute
                    }).then((result: rps.data.QueryReference) => {
                        if (result.IsPaginated) {
                            this.createViewSourceOptions(result,resolve);
                        }
                        else if (result.IsChronological) {
                            this.createChronologicalViewSourceOptions(result, resolve);
                        }
                        else {
                            this.createEmptyViewSourceOptions(resolve);
                        }
                    });
                }
                else {
                    this.setOptions(null);
                    resolve(this);
                }
            });
        }

        private createViewSourceOptions(result: rps.data.QueryReference, resolve: async.IResolveReject<ViewSource>) {
            var newOptions: rps.data.sources.ViewSource.ViewSourceOptions = new rps.data.sources.ViewSource.ViewSourceOptions();
            newOptions.isSearchable = result.IsSearchable;
            newOptions.editState = result.EditState;
            var queryParams: parameters.QueryParams = new parameters.QueryParams();
            result.Parameters.forEach((p) => {
                switch (p.Type) {
                    case "session.company":
                        queryParams.push(new parameters.QueryParam(p.Name, rps.app.session.company));
                        break;
                    case "session.user":
                        queryParams.push(new parameters.QueryParam(p.Name, rps.app.session.user));
                        break;
                    case "session.role":
                        queryParams.push(new parameters.QueryParam(p.Name, rps.app.session.role));
                        break;
                    case "session.language":
                        queryParams.push(new parameters.QueryParam(p.Name, rps.app.session.language));
                        break;
                    case "session.site":
                        queryParams.push(new parameters.QueryParam(p.Name, rps.app.session.idSite));
                        break;
                    case "session.acctype":
                        queryParams.push(new parameters.QueryParam(p.Name, rps.app.session.codAccType));
                        break;
                }
            });
                  
            var newQuerySource: rps.data.sources.QuerySource = new rps.data.sources.QuerySource({
                parentVM: this._parentVM,
                service: this.entityName.split("/")[0],
                queryName: this.selectedView.Name,
                relatedVMFunction: this.relatedVMFunction,
                queryParams: queryParams,
                saveState: this.saveState,
                executionPolicy: rps.queryExecutionPolicy.Automatic,
                filtersManager: this.filtersManager,
                isSearchable: (result.IsSearchable) ? true : false
            });

            newOptions.querySource = newQuerySource;            
            newOptions.columnsDefinition = JSON.stringify(rps.utils.createColumnsDefinition(result));
            newOptions.supportedExportFormats = result.SupportedExportFormats ? result.SupportedExportFormats.join(" ") : "";
            this.setOptions(newOptions);
            resolve(this);
        }

        private createChronologicalViewSourceOptions(result: rps.data.QueryReference, resolve: async.IResolveReject<ViewSource>) {
            var newOptions: rps.data.sources.ViewSource.ChronologicalViewSourceOptions = new rps.data.sources.ViewSource.ChronologicalViewSourceOptions();            
            newOptions.editState = result.EditState;
            var queryParams: parameters.QueryParams = new parameters.QueryParams();
            result.Parameters.forEach((p) => {
                switch (p.Type) {
                    case "session.company":
                        queryParams.push(new parameters.QueryParam(p.Name, rps.app.session.company));
                        break;
                    case "session.user":
                        queryParams.push(new parameters.QueryParam(p.Name, rps.app.session.user));
                        break;
                    case "session.role":
                        queryParams.push(new parameters.QueryParam(p.Name, rps.app.session.role));
                        break;
                    case "session.language":
                        queryParams.push(new parameters.QueryParam(p.Name, rps.app.session.language));
                        break;
                    case "session.site":
                        queryParams.push(new parameters.QueryParam(p.Name, rps.app.session.idSite));
                        break;
                    case "session.acctype":
                        queryParams.push(new parameters.QueryParam(p.Name, rps.app.session.codAccType));
                        break;
                }
            });

            var newQuerySource: rps.data.sources.ChronologicalQuerySource = new rps.data.sources.ChronologicalQuerySource({
                parentVM: this._parentVM,
                service: this.entityName.split("/")[0],
                queryName: this.selectedView.Name,
                executionPolicy: rps.queryExecutionPolicy.Automatic,
                relatedVMFunction: this.relatedVMFunction,
                queryParams: queryParams                
            });

            newOptions.querySource = newQuerySource;            
            this.setOptions(newOptions);
            resolve(this);
        }

        private createEmptyViewSourceOptions(resolve: async.IResolveReject<ViewSource>) {
            this.setOptions(null);
            resolve(this);
        }

        setOptions = (newOptions: rps.data.sources.ViewSource.IViewSourceOptions) => {
            this.options = newOptions;
            if (this.options && this.options.querySource) {
                this.options.querySource.initialize();
                this.options.querySource.propertyChanged.subscribe((value: viewmodels.properties.VMPropertyChange) => {
                    if (value.propertyName == "items")
                        this.itemsChanged(this.options.querySource.items);
                    else if (value.propertyName == "selectedItems")
                        this.selectedItemsChanged(this.options.querySource.getSelectedItems());
                });
            }
        }

        itemsChanged = (newValue: { data: Array<any>; count: number }) => {
            this.items = newValue;
        }

        selectedItemsChanged = (newValue: Array<any>) => {
            this.selectedItems = newValue;
        }

        onActivate(isFirstActivation:boolean): void {
            if (this.options && this.options.querySource)
                this.options.querySource.onActivate(isFirstActivation);
        }

        public executionPolicy: rps.queryExecutionPolicy;

        public execute(): Promise<any> {
            if (this.options && this.options.querySource)
                return this.options.querySource.execute();
            else
                return Promise.reject("No selected view");
        }

        onDestroy(): void {
            this.propertyChanged.onDestroy();
            if (this.options)
                this.options.onDestroy();
            if (this.filtersManager)
                this.filtersManager.onDestroy();
        }

        onBeforeUnload(): Promise<any> {
            //Crear una clave única para los filtros y para la cache ([usuario]/[nombre entidad])
            var key: string = string.format("ViewSource/{0}/{1}", rps.app.session.user, this.entityName);

            //Si tiene una vista seleccionada, se guarda en la cache junto a los filtros, sino se elimina la entrada
            if (this.selectedView) {
                //Crear el objeto que se va a guardar
                var data: {
                    filterDefinition: rps.data.FilterDefinition;
                    selectedViewName: string;
                    selectedViewType: string;
                } = {
                        filterDefinition: this.filtersManager.getFilterDefinition(key),
                        selectedViewName: this.selectedView.Name,
                        selectedViewType: this.selectedViewType
                    };

                //Se guardan los datos en la cache local
                rps.app.localStorage.add(key, data);
            }
            else
                rps.app.localStorage.remove(key);

            return Promise.resolve<any>(this);
        }
    }
}

module rps.data.sources.ViewSource {

    /**
     * VM de las diferentes vistas que contiene una entidad
     */
    export class ViewDefinitionVM {
        Name: string;
        Label: string;
        ReferenceUrl: string;
        Scope: string;
        ScopeDescription: string;

        constructor(queryHeader: rps.data.QueryHeader) {
            this.Name = queryHeader.Name;
            this.Label = queryHeader.Label;
            this.ReferenceUrl = queryHeader.ReferenceUrl;
            this.Scope = queryHeader.Scope;
            //Las vistas se catogorizan por “Mis vistas” y las otras
            if (queryHeader.Scope == "user")
                this.ScopeDescription = rps.app.resources.directives.VIEW_DEFINITION_MY_VIEWS;
            else
                this.ScopeDescription = "";
        }
    }

    export interface IViewSourceOptions extends IDestroyable{
        querySource: rps.data.sources.ChronologicalQuerySource | rps.data.sources.QuerySource;
        isChronological: boolean;
        isPaginated: boolean;
        editState: rps.data.IUILinkDefinition;
    }

    export class ViewSourceOptions implements IViewSourceOptions {
        public isIDestroyable: boolean = true;
        public isPaginated: boolean = true;
        public isChronological: boolean = false;
        public isSearchable: boolean;
        public querySource: rps.data.sources.QuerySource;
        public columnsDefinition: string;
        public editState: rps.data.IUILinkDefinition;
        public supportedExportFormats: string;
        public onDestroy(): void {
            if (this.querySource)
                this.querySource.onDestroy();
        }
    }

    export class ChronologicalViewSourceOptions implements IViewSourceOptions {
        public isIDestroyable: boolean = true;
        public isPaginated: boolean = false;
        public isChronological: boolean = true;
        public querySource: rps.data.sources.ChronologicalQuerySource; 
        public editState: rps.data.IUILinkDefinition;       
        public onDestroy(): void {
            if (this.querySource)
                this.querySource.onDestroy();
        }
    }

}