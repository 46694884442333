(function () {

    function _pad(number,width) {
        var r = String(number);
        return r.length >= width ? r : new Array(width - r.length + 1).join('0') + r;
    }

/**
 * Polyfill para añadir un métoto toISOString al objeto Date, para que no haga la conversión de la zona horaria automáticamente
 */
    (<any>Date.prototype).toISOString = function () {
        return this.getFullYear()
            + '-' + _pad(this.getMonth() + 1,2)
            + '-' + _pad(this.getDate(), 2)
            + 'T' + _pad(this.getHours(), 2)
            + ':' + _pad(this.getMinutes(), 2)
            + ':' + _pad(this.getSeconds(), 2)
            + '.' + _pad(this.getMilliseconds(),3);
    };

    (<any>Date.prototype).toShortDate = function () {
        return kendo.toString(this, "d");
    };

    /** Compatibilidad c# -> devuelve la parte de fecha de un Date, sin hora */
    (<any>Date.prototype).Date = function () {
        return new Date(this.getFullYear(), this.getMonth() + 1, this.getDate(), 0, 0, 0, 0);
    };

    //Se añaden dos propiedades Value a los Date y Number para que las validaciones de CL de tipos nullables
    //en C# sean válidas
    if (!Date.prototype.Value) {
        Object.defineProperty(Date.prototype, "Value", {
            get: function () {
                return this;
            },
            enumerable: true,
            configurable: true
        });
    }

    if (!Number.prototype.Value) {
        Object.defineProperty(Number.prototype, "Value", {
            get: function () {
                return this;
            },
            enumerable: true,
            configurable: true
        });
    }

    if (!Number.prototype.Round) {
        Number.prototype.Round = function (decimalPlaces:number) {
            return rps.utils.roundToDecimals(this, decimalPlaces);
        };
    }

    if (!Number.prototype.Equals) {
        Number.prototype.Equals = function (value2: Number | number) {
            return this.round(10) == value2.Round(10);           
        };
    }

    if (!Number.prototype.NotEquals) {
        Number.prototype.NotEquals = function (value2: Number | number) {
            return this.round(10) != value2.Round(10);
        };
    }

    if (!Number.prototype.GreaterThan) {
        Number.prototype.GreaterThan = function (value2: Number | number) {
            return this.round(10) > value2.Round(10);
        };
    }

    if (!Number.prototype.GreaterThanOrEquals) {
        Number.prototype.GreaterThanOrEquals = function (value2: Number | number) {
            return this.round(10) >= value2.Round(10);
        };
    }

    if (!Number.prototype.LessThan) {
        Number.prototype.LessThan = function (value2: Number | number) {
            return this.round(10) < value2.Round(10);
        };
    }

    if (!Number.prototype.LessThanOrEquals) {
        Number.prototype.LessThanOrEquals = function (value2: Number | number) {
            return this.round(10) <= value2.Round(10);
        };
    }

    /** Devuelve la parte de fecha de un Date, sin hora */
    //Compatibilidad c# en CL ->
    if (!Date.prototype.Date) {
        Object.defineProperty(Date.prototype, "Date", {
            get: function () {
                return new Date(this.getFullYear(), this.getMonth() + 1, this.getDate(), 0, 0, 0, 0);
            },
            enumerable: true,
            configurable: true
        });
    }

    /** Devuelve día del mes de una fecha */
    //Compatibilidad c# en CL ->
    if (!Date.prototype.Day) {
        Object.defineProperty(Date.prototype, "Day", {
            get: function () {
                return this.getDate();
            },
            enumerable: true,
            configurable: true
        });
    }

    //Equivalente al string.format usado en c# para reemplazar {1} en strings.
    if (!String.prototype.format) {
        String.prototype.format = function () {
            var args = arguments;
            return this.replace(/{(\d+)}/g, function (match, number) {
                return typeof args[number] != 'undefined'
                    ? args[number]
                    : match
                    ;
            });
        };
    }

    //Devuelve si un string se puede convertir a número
    if (!String.prototype.isNumeric) {
        String.prototype.isNumeric = function () {
            return !isNaN(this)
        }
    }

    //Devuelve si un string se puede convertir a fecha
    if (!String.prototype.isDate) {
        String.prototype.isDate = function isDate() {
            var d = new Date(this);
            return !isNaN(d.valueOf());
        }
    }

} ());

interface Date {
    toISOString(): string;
    /** Devuelve el mismo valor; usado para los DateTimes nullables de c#, al traducir CL*/
    Value: Date;
    /** Devuelve la parte de fecha de un Date, sin hora */
    Date: Date;
    /** Devuelve el día del mes */
    Day: number;
    /** Devuelve la fecha corta, teniendo en cuenta la cultura de los datos de sesión */
    toShortDate(): string;
}

interface Number {
    /** Devuelve el mismo valor; usado para los numéricos nullables de c#, al traducir CL*/
    Value: Number;
    Equals(value2: Number | number): boolean;
    NotEquals(value2: Number | number): boolean;
    GreaterThan(value2: Number | number): boolean;
    GreaterThanOrEquals(value2: Number | number): boolean;
    LessThan(value2: Number): boolean;
    LessThanOrEquals(value2: Number | number): boolean;
    Round(decimalPlaces: number): number;
}

interface String {
    startsWith(searchString: String, position?: number): boolean;
    endsWith(searchString: String, position?: number): boolean;
    includes(searchString: String, position?: number): boolean;
    format(...values: string[]): string;
    /** Devuelve si un string se puede convertir a número */
    isNumeric(): boolean;
    /** Devuelve si un string se puede convertir a fecha */
    isDate(): boolean;
    hashCode(): number;
}

interface Array<T>{
    move(oldIndex: number, newIndex: number);
}

interface Promise<T> {
    finally<TResult>(finallyCallback: () => any): Promise<TResult>;
    //finally(finallyCallback: () => any): void;
}

/**
* Polyfill para añadir un métoto startsWith al objeto string (se supone que en ECMA6 estará ya)
*/
if (!(<any>String.prototype).startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function (searchString, position) {
            position = position || 0;
            return this.lastIndexOf(searchString, position) === position;
        }
    });
}

if (!(<any>String.prototype).endsWith) {
    Object.defineProperty(String.prototype, 'endsWith', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function (searchString, position) {
            var subjectString = this.toString();
            if (position === undefined || position > subjectString.length) {
                position = subjectString.length;
            }
            position -= searchString.length;
            var lastIndex = subjectString.indexOf(searchString, position);
            return lastIndex !== -1 && lastIndex === position;
        }
    });
}

if (!(<any>String.prototype).format) {
    Object.defineProperty(String.prototype, 'format', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function (searchString, position) {
            var args = arguments;
            return this.replace(/{(\d+)}/g, function (match, number) {
                return typeof args[number] != 'undefined'
                    ? args[number]
                    : match
                    ;
            });
        }
    });
}

if (!(<any>String.prototype).includes) {
    (<any>String.prototype).includes = function (searchString: String, position?: number) {
        'use strict';
        return String.prototype.indexOf.apply(this, arguments) !== -1;
    };
}

if (!(<any>String.prototype).hashCode) {
    (<any>String.prototype).hashCode = function () {
        var hash = 0, i, chr, len;
        if (this.length === 0) return hash;
        for (i = 0, len = this.length; i < len; i++) {
            chr = this.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    };
}

module linq {
    export interface Enumerable<T> {
        forEach(action);
    }
}

if (!(<any>Enumerable).prototype.forEach) {
    (<any>Enumerable).prototype.forEach = function (action) {
        return (<any>Enumerable).prototype.ForEach.call(this, action);
    }
}

if (!(<any>Promise).prototype.finally) {
    (<any>Promise).prototype.finally = function (callback) {
        let p = this.constructor;
        // We don’t invoke the callback in here,
        // because we want then() to handle its exceptions
        return this.then(
            // Callback fulfills: pass on predecessor settlement
            // Callback rejects: pass on rejection (=omit 2nd arg.)
            value => p.resolve(callback()).then(() => value),
            reason => p.resolve(callback()).then(() => { throw reason })
        );
    };
}

if (!(<any>Array.prototype.move)){
    Array.prototype.move = function (old_index, new_index) {
        if (new_index >= this.length) {
            var k = new_index - this.length;
            while ((k--) + 1) {
                this.push(undefined);
            }
        }
        this.splice(new_index, 0, this.splice(old_index, 1)[0]);
        return this; // for testing purposes
    };
}

