module rps.viewmodels {
    /**
     * @hidden
     */
    export class ObservableArray<T extends ObservableObject> extends kendo.data.ObservableArray {
        constructor(array?: Array<T>) {
            if (array)
                super(array);
            else
                super(new Array<T>());
        }

        asEnumerable(): linq.Enumerable<T> {
            return Enumerable.From<T>(<any>this);
        }

        toArray(): Array<T> {
            return this.slice(0);
        }

        sort(compareFn?: (a: T, b: T) => number): void {
            Array.prototype.sort.call(this, compareFn);
        }
    }

    /**
     * @hidden
     */
    export class ObservableObject extends kendo.data.ObservableObject {
    }
}