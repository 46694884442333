/// <reference path="./propertyPatch.ts" />

module rps.entities {

    /** InheritableCollection
*  http://www.bennadel.com/blog/2292-Extending-JavaScript-Arrays-While-Keeping-Native-Bracket-Notation-Functionality.htm
*  https://gist.github.com/fatso83/3773d4cb5f39128b3732
* 
* Clase base que se p uede usar para crear colecciones especiales; como la herencia directa de Array no está soportada,
* se ha creado esta clase intermedia de la que sí se puede heredar 
*/
    export interface ICollection<T> {
    }

    export class InheritableCollection<T> implements rps.entities.ICollection<T>{

        /** @internal **/
        private collection: any;

        constructor(...initialItems: any[]) {
            this.collection = Object.create(Array.prototype);

            InheritableCollection.init(this.collection, initialItems, InheritableCollection.prototype);

            return this.collection;
        }

        static init(collection, initialItems: any[], prototype) {
            Object.getOwnPropertyNames(prototype)
                .forEach((prop) => {
                    if (prop === 'constructor') return;

                    Object.defineProperty(collection, prop, { value: prototype[prop] })
                });

            // If we don't redefine the property, the length property is suddenly enumerable!
            // Failing to do this, this would fail: Object.keys([]) === Object.keys(new Collection() )
            Object.defineProperty(collection, 'length', {
                value: collection.length,
                writable: true,
                enumerable: false
            });

            if (initialItems) {
                var itemsToPush = initialItems;
                if (Array.isArray(initialItems[0]) && initialItems.length === 1) {
                    itemsToPush = initialItems[0];
                }
                Array.prototype.push.apply(collection, itemsToPush);
            }

            return collection;
        }

        //public query(): linq.Enumerable<T> {
        //    return Enumerable.From<T>(<any>this);
        //}
        public asEnumerable(): linq.Enumerable<T> {
            return Enumerable.From<T>(this);
        }

        // dummy declarations
        // "massaged" the Array interface definitions in lib.d.ts to fit here
        toString: () => string;
        toLocaleString: () => string;
        concat: <U extends T[]>(...items: U[]) => T[];
        join: (separator?: string) => string;
        public pop: () => T;
        public push: (...items: T[]) => number;
        reverse: () => T[];
        shift: () => T;
        slice: (start?: number, end?: number) => T[];
        sort: (compareFn?: (a: T, b: T) => number) => T[];
        splice: (start?: number, deleteCount?: number, ...items: T[]) => T[];
        unshift: (...items: T[]) => number;
        indexOf: (searchElement: T, fromIndex?: number) => number;
        lastIndexOf: (searchElement: T, fromIndex?: number) => number;
        every: (callbackfn: (value: T, index: number, array: T[]) => boolean, thisArg?: any) => boolean;
        some: (callbackfn: (value: T, index: number, array: T[]) => boolean, thisArg?: any) => boolean;
        forEach: (callbackfn: (value: T, index: number, array: T[]) => void, thisArg?: any) => void;
        map: <U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any) => U[];
        filter: (callbackfn: (value: T, index: number, array: T[]) => boolean, thisArg?: any) => T[];
        reduce: <U>(callbackfn: (previousValue: U, currentValue: T, currentIndex: number, array: T[]) => U, initialValue: U) => U;
        reduceRight: <U>(callbackfn: (previousValue: U, currentValue: T, currentIndex: number, array: T[]) => U, initialValue: U) => U;
        public length: number;
        [n: number]: T;
    }

    export interface IMainEntity extends IBaseEntity {
    }

    export interface IBaseEntity extends rps.errors.INotifyDataErrorInfo, IDestroyable {
        /** @internal **/
        $id: string;
        /** @internal **/
        IID: string;
        __isNew: boolean;
        __isModified: boolean;
        /** @internal **/
        __hasModifiedRelations: boolean;
        /** @internal **/
        __entityUrl: string;
        /** @internal **/
        __parent: IBaseEntity;

        invokeSet(propertyName: string): Promise<IBaseEntity>;
        invokeSetPartial(propertyName: string): Promise<IBaseEntity>;
        merge(data: any, conditional: boolean, uid?: string);
        IDPropertyName(): string;
        getEntityID(): string;
        getParentRelations(): { ParentRelation: string; ParentId: string }[];
        getChildRelations(): string[];
        getWIfIsNewProps(): string[];
        onPropertyChanged(propertyName: string): void;
        applyPatch(patchSet: IPropertyPatch[]): void;
        propertyChanged: rps.services.IEventEmitter<{ PropertyName: string }>;
        clearAllErrors();
        resetEntity();
        setAsModified();
        replaceId(newId: string);

        update(): Promise<IBaseEntity>;
        patch(customQuery?: rps.data.sources.GetEntitySource): Promise<IBaseEntity>;
        delete(): Promise<IBaseEntity>;
    }

    export interface IHierarchicalEntity {
        IDParent: string;
    }

    /**
     * Base class from which inherit all the entity models in the application.
     */
    export class BaseEntity implements IBaseEntity {
        /** @internal **/
        public isIDestroyable: boolean = true;

        //Eventos:
        public propertyChanged: rps.services.IEventEmitter<{ PropertyName: string }>;

        //This property is done to avoid circular references wrapping the View Models
        //public getTime: boolean = true;

        public constructor() {
            this.initializeChildCollections();

            this.propertyChanged = rps.app.eventManager.createEmitter<{ PropertyName: string }>(false);
        }

        /** @internal **/
        private initializeChildCollections(): void {

        }

        /** @internal **/
        public $id: string;
        /** @rpsInternal **/
        public IID: string;
        /** @internal **/
        public __hasErrors: boolean = false;
        public __isNew: boolean = false;
        public __isModified: boolean = false;
        /** @internal **/
        public __hasModifiedRelations: boolean = false;
        /** @internal **/
        public __entityUrl: string;
        /** @internal **/
        public __parent: IBaseEntity

        /** @internal **/
        public __originalEntity: IBaseEntity;

        /** @internal **/
        private __errors: rps.errors.ErrorDetails = new rps.errors.ErrorDetails();

        public addErrors(serverErrors: Array<rps.data.ValidationError>) {
            if (rps.object.isArray(serverErrors)) {
                serverErrors.forEach((m) => {
                    if (m.ErrorDescription) { //Puede venir sin ErrorCode pero no sin description

                        //Si tiene entityPath, ver si se puede repartir entre los hijos, mirando la primera parte
                        if (m.EntityPath) { //El path viene en la forma States[1].Countys[3].... Hay que separar los paths e ir repartiendo el error
                            var pathStart: string = m.EntityPath;
                            var firstDot = (<string>m.EntityPath).indexOf('.');
                            if (firstDot > -1)
                                pathStart = (<string>m.EntityPath).substr(0, firstDot);
                            var relationName = pathStart.substr(0, pathStart.indexOf('['));
                            var childPosition = parseInt(pathStart.substr(pathStart.indexOf('[') + 1).replace(']', ''));
                            if (this[relationName] && this[relationName][childPosition]) {
                                m.EntityPath = firstDot > -1 ? m.EntityPath.substr(firstDot + 1) : "";
                                (<BaseEntity>this[relationName][childPosition]).addErrors([m]);
                            }
                        }
                        else {
                            this.__errors.addError(new rps.errors.ErrorDetail(m.ErrorCode, m.ErrorDescription, m.Property));
                        }
                    }
                });
            }

            this.updateErrors();
        }

        /** @internal **/
        private updateErrors(): void {
            this.__hasErrors = this.__errors.length > 0;
        }

        public clearAllErrors() {
            this.__errors.clear();
            this.__hasErrors = false;
            this.getChildRelations().forEach((childRelation) => {
                if (this[childRelation]) {
                    (<ChildCollection<BaseEntity>>this[childRelation]).forEach((child) => {
                        child.clearAllErrors();
                    });
                }
            });
        }

        public getErrors(property?: string): rps.errors.ErrorDetail[] {
            if (property && this.constructor.prototype.hasOwnProperty(property)) {
                var errors: rps.errors.ErrorDetail[] = new Array<rps.errors.ErrorDetail>();
                for (var i = 0; i < this.__errors.length; i++) {
                    if (this.__errors[i].property === property)
                        errors.push(this.__errors[i]);
                }
                this.updateErrors();
                return errors;
            }
            else if (!property) {
                //Devolver las suyas y las de sus hijos
                var errors: rps.errors.ErrorDetail[] = this.__errors.slice(0);
                this.getChildRelations().forEach((childRelation) => {
                    if (this[childRelation]) {
                        (<ChildCollection<IBaseEntity>>this[childRelation]).forEach((child) => {
                            errors = errors.concat(child.getErrors());
                        });
                    }
                });
                this.updateErrors();
                return errors;
            }

            this.updateErrors();
            return [];
        }

        public getEntityID(): string {
            return null;
        }

        public IDPropertyName(): string {
            return null;
        }

        public getParentRelations(): { ParentRelation: string; ParentId: string }[] {
            return null;
        }

        public getChildRelations(): string[] {
            return [];
        }

        public getWIfIsNewProps(): string[] {
            return [];
        }

        public resetEntity() {
            this.__isModified = false;
            this.__isNew = false;
            this.__hasModifiedRelations = false;
            this.clearAllErrors();

            //Borrar todas las originales
            for (var property in this) {
                if (property.startsWith("___"))
                    delete this[property];
            }

            this.getChildRelations().forEach((childColName) => {
                if (this[childColName])
                    //Borrar lista de eliminados
                    this[childColName].__deletedIDs.length = 0;
                (<ChildCollection<BaseEntity>>this[childColName]).forEach((child) => {
                    child.resetEntity();
                });
            });

        }

        protected setProperty<T>(propertyName: string, newValue: T, setAsModified = true) {
            if (propertyName in this) {
                if (rps.object.isDate(newValue)) {
                    if (!rps.date.areEqual(this["_" + propertyName], <any>newValue)) {
                        //Si es la primera vez que modifica la propiedad, se guarda el valor original, para resolver el caso de registro modificado
                        if (!rps.app.entityFactory.isWorking && rps.object.isUndefined(this["___" + propertyName]))
                            this["___" + propertyName] = this["_" + propertyName];

                        this["_" + propertyName] = newValue;
                        this.reportPropertyChanged(propertyName, setAsModified);
                    }
                }
                else {
                    if (this["_" + propertyName] !== newValue) {
                        //Si es la primera vez que modifica la propiedad, se guarda el valor original, para resolver el caso de registro modificado
                        if (!rps.app.entityFactory.isWorking && rps.object.isUndefined(this["___" + propertyName]))
                            this["___" + propertyName] = this["_" + propertyName];

                        this["_" + propertyName] = newValue;
                        this.reportPropertyChanged(propertyName, setAsModified);
                    }
                }
            }
        }

        protected reportPropertyChanged(propertyName: string, setAsModified = true): void {
            // isWorking: Al hacer el merge, por ejemplo, que no deje la entidad modificada
            // setAsModified: Las propiedades no mapeades vendrán con setAsModified a falso
            if (!rps.app.entityFactory.isWorking && setAsModified) {
                this.setAsModified();
                if (!rps.app.entityFactory.isApplyingPatch) //Para que al aplicar patch no lance código de CL (ya habrá saltado en el servicio)
                    this.onPropertyChanged(propertyName);
            }
            //Lanzar el evento de propertyChanged
            this.propertyChanged.emit({ PropertyName: propertyName });
        }

        public setAsModified(): void {
            this.__isModified = true;
            var p = this.__parent;
            while (p) {
                p.__hasModifiedRelations = true;
                p = p.__parent;
            }
        }

        /**
         * Reemplaza el id de la entidad por el nuevo id pasado; también cambia los id-s de los hijos que referencian al padre
         * @param newId Nuevo id que se va a establecer
         */
        public replaceId(newId: string) {
            const idPropertyName = this.IDPropertyName();
            this[idPropertyName] = newId;
            //Cambiar el id del padre en los hijos
            this.getChildRelations().forEach((relation) => {
                //Se da por hecho que la propiedad del ID del padre coincide (en nombre) con la propia de ID del padre físico
                //p.e., DeliveryNoteSL.IDDeliveryNote == DeliveryNoteLineSL.IDDeliveryNote
                let children = <rps.entities.ChildCollection<BaseEntity>>this[relation];
                children.forEach((child) => {
                    child[idPropertyName] = newId;
                });
            });
        }

        public onPropertyChanged(propertyName: string): void {
        }

        protected validateExtended(errors: any[]): void {
        }

        protected validateDelete(errors: any[]): boolean {
            return true;
        }

        public update(): Promise<IBaseEntity> {
            return new Promise((resolve, reject) => {
                this._update(resolve, reject);
            });
        }

        /** @internal **/
        private _update(resolve: async.IResolveReject<any>, reject: async.IResolveReject<any>): void {
            this.clearAllErrors();
            this.validateExtended(null);
            if (this.__hasErrors)
                reject(this.getErrors());
            else {
                rps.app.api.update(this).then((data) => {
                    //Resetear la entidad si todo ha ido bien
                    rps.app.entityFactory.isWorking = true;
                    this.merge(data, false, "or0");
                    this.resetEntity();

                    if (this.__originalEntity) {
                        this.__originalEntity.merge(data, false, "cp0");
                    }

                    resolve(this);
                }).catch((errors) => {
                    if (errors == 409) {
                        //Registro modificado en el servidor: intentar resolver el conflicto automáticamente para volver a grabar
                        this.resolveModifiedRowException(resolve, reject);
                    }
                    else {
                        this.addErrors(errors);
                        reject(errors);
                    }
                }).finally(() => {
                    rps.app.entityFactory.isWorking = false;
                });
            }
        }

        /** @internal **/
        private resolveModifiedRowException(resolve: async.IResolveReject<any>, reject: async.IResolveReject<any>) {
            //Obtener el registro del servidor
            rps.app.api.get({
                url: this.__entityUrl,
                queryParams: { id: this.getEntityID() },
                urlType: rps.services.UrlType.Relative
            }).then((getData) => {
                //Hacer un merge comparando valores; si el valor del servidor es el mismo que el que hay en el valor origina
                rps.app.entityFactory.isWorking = true;
                try {
                    this.merge(getData, true, "orc");

                    //Volver a lanzar la update si todo ha ido bien al hacer el merge condicional
                    this._update(resolve, reject);
                    resolve(this);
                }
                catch (ex) {
                    reject(ex);
                }
                finally {
                    rps.app.entityFactory.isWorking = false;
                }

            }).catch((errors) => {
                this.addErrors(errors);
                reject(errors);
            }).finally(() => {
                rps.app.entityFactory.isWorking = false;
            });;
        }

        public delete(): Promise<IBaseEntity> {
            return new Promise((resolve, reject) => {
                this.clearAllErrors();
                //Si es nueva, borrarla sin más
                if (this.__isNew) {
                    resolve(null);
                    return;
                }

                this.validateDelete(null);
                if (this.__hasErrors)
                    reject(this.getErrors());
                else {
                    rps.app.api.delete(this).then((data) => {
                        resolve(null);
                    }).catch((errors) => {
                        this.addErrors(errors);
                        reject(errors);
                    });
                }
            });
        }

        public patch(customQuery?: rps.data.sources.GetEntitySource): Promise<IBaseEntity> {
            return new Promise((resolve, reject) => {
                this.clearAllErrors();
                this.validateExtended(null);
                if (this.__hasErrors)
                    reject(this.getErrors());
                else {
                    rps.app.api.patch(this).then((data) => {
                        //En el patch, no se devuelven datos de la entidad, hay que volver a cargarla                        
                        rps.app.entityFactory.get(
                            this.getEntityID(),
                            this.__entityUrl,
                            false,
                            customQuery
                        ).then((getData) => {
                            //Resetear la entidad si todo ha ido bien
                            rps.app.entityFactory.isWorking = true;
                            this.merge(getData, false, "or1");
                            this.resetEntity();

                            if (this.__originalEntity) {
                                this.__originalEntity.merge(getData, false, "cp1");
                            }

                            resolve(this);
                        }).catch((errors) => {
                            this.addErrors(errors);
                            reject(errors);
                        }).finally(() => {
                            rps.app.entityFactory.isWorking = false;
                        });;
                    }).catch((errors) => {
                        this.addErrors(errors);
                        reject(errors);
                    });
                }
            });
        }

        /** @internal **/
        private invokeSetQueue = new Array<{
            propertyName: string,
            contractName?:string,
            resolve: async.IResolveReject<BaseEntity>,
            reject: (reason?: any) => void
        }>();

        public invokeSet(propertyName: string): Promise<IBaseEntity>;
        public invokeSet(propertyName: string, contractName: string): Promise<IBaseEntity>;
        public invokeSet(propertyName: string, contractName?:string): Promise<IBaseEntity> {
            if (rps.app.entityFactory.isApplyingPatch)
                return Promise.resolve(this);

            var setPromise = new Promise<BaseEntity>((resolve, reject) => {
                this.invokeSetQueue.push({ propertyName: propertyName, contractName:contractName, resolve: resolve, reject: reject });
                //Si es la primera, se ejecuta directamente
                if (this.invokeSetQueue.length == 1)
                    this._invokeSet();
            });
            setPromise.then(() => {
                if (this.invokeSetQueue.length > 0) {
                    //Eliminar el primero (que se acaba de ejecutar)
                    let next = this.invokeSetQueue.splice(0, 1);
                    //Y ejecutar otra vez por si quedan más
                    this._invokeSet();
                }
            });
            setPromise.catch(() => {
                //Quita de la lista
                if (this.invokeSetQueue.length > 0)
                    this.invokeSetQueue.splice(0, 1);
            });

            return setPromise;
        }

        /** @internal **/
        private _invokeSet() {
            if (this.invokeSetQueue.length > 0) {
                let next = this.invokeSetQueue[0];
                rps.app.api.setProperty(this, next.propertyName, next.contractName).then((data) => {
                    this.applyPatch(data);
                    next.resolve(this);
                }).catch((errors) => {
                    next.reject(errors);
                });
            }
        }

        public invokeSetPartial(propertyName: string): Promise<IBaseEntity>;
        public invokeSetPartial(propertyName: string, contractName: string): Promise<IBaseEntity>;
        public invokeSetPartial(propertyName: string, contractName?: string): Promise<IBaseEntity> {
            //TODO: por ahora, el invokeSetPartial llama al invokeSet entero, a la espera de ver cómo va en rendimiento
            return this.invokeSet(propertyName, contractName);
        }

        public merge(args: any, conditional: boolean, uid?: string) {
            this.doMerge(this, conditional, [args], uid || "0");
            this.mergeDetailedEntity(this, args);
        }

        protected doMerge(target: BaseEntity, conditional: boolean, args: any[], uid: string) {
            if (args) {
                Enumerable.From(args).forEach((obj) => {
                    if (obj && obj !== target) {
                        //La primera que se mergea es la IID, para que no falle en los merges de colección
                        //if (rps.object.hasValue(obj["IID"]))
                        //    target["IID"] = obj["IID"];

                        if (target.IDPropertyName() && rps.object.hasValue(obj[target.IDPropertyName()]))
                            target[target.IDPropertyName()] = obj[target.IDPropertyName()];

                        let fooEntity = new BaseEntity();
                        //Y luego el resto de propiedades
                        for (var key in obj) {

                            //No mergear las que empiecen por _ o las que sean propias de BaseENtity
                            if (key.startsWith("_"))
                                continue;
                            if (key in fooEntity)
                                continue;

                            var value = obj[key];
                            if (key == "MOD" || key == "PP" || key == "$detail")
                                continue;

                            if (key == "NEW") {
                                target.__isNew = obj["NEW"];
                                continue;
                            }

                            //Si tiene el metodo merge, se le llama (el instanceof ChildCollection no funciona)
                            if (target[key] &&
                                (Enumerable.From(target.getParentRelations()).FirstOrDefault(null, (pr) => pr.ParentRelation == key) == null) &&
                                target[key].merge)
                                (<any>target[key]).merge(value, conditional, uid);
                            else if (target[key] instanceof rps.entities.BaseEntity) { //También estos casos, hace falta para el caso de DeliveryNoteLine -> OrderLine -> Order
                                if (!value["__" + uid]) {
                                    //HACK: se establece el valor __entityUrl para que no se cicle al hacer el merge
                                    value["__" + uid] = (<any>target[key]).__entityUrl;
                                    (<any>target[key]).merge(value, conditional, uid);
                                }
                                else {
                                    //Se marca la entidad para tratarla en casos raros tipo deliveryNoteLine -> orderLine (se mete el mismo IID)
                                    //debugger;
                                    target[key]["IID"] = value.IID;
                                    //target[key][target[key].IDPropertyName()] = value[target[key].IDPropertyName()];
                                }
                            }
                            else {
                                if (conditional) {
                                    //Mirar si la propiedad ha cambiado en cliente
                                    if (rps.object.isUndefined(target["___" + key])) //No cambia -> merge normal
                                        target[key] = value;
                                    else {
                                        //Ha cambiado: mirar si el valor original es el mismo que el que se va a establecer; si
                                        //no ha cambiado, no se hace el merge, se deja el valor que había
                                        //Se mira si ha cambiado y si además el valor nuevo es distinto al que se ha puesto en el servidor
                                        let areDistinct: boolean;
                                        if (rps.object.isDate(value) && rps.object.isDate(target["___" + key]))
                                            areDistinct = !rps.date.areEqual(value, target["___" + key]) && !rps.date.areEqual(value, target[key]);
                                        else
                                            areDistinct = (value !== target["___" + key]) && (value !== target[key]);

                                        //Si ha cambiado y los valores a poner son diferentes, error por conflicto irresoluble
                                        if (areDistinct) {
                                            throw (rps.app.resources.errors.ERR_CANNOT_MERGE);
                                        }
                                    }
                                }
                                else
                                    target[key] = value;
                            }
                        };
                    }
                });
            }
        }

        /** @internal **/
        private mergeDetailedEntity(target: BaseEntity, obj: BaseEntity) {

            //Tratar el caso especial de $detail (de estar, siempre está colgando de la entidad principal)
            if (obj && rps.object.hasValue(obj["$detail"])) {
                //Buscar entre todos los hijos la referencia al detail
                var childRelations = target.getChildRelations();
                for (var r = 0; r < childRelations.length; r++) {
                    let childRelation: ChildCollection<IBaseEntity> = target[childRelations[r]];
                    for (var c = 0; c < childRelation.length; c++) {
                        let child = childRelation[c];
                        if (child.getEntityID() == obj["$detail"][child.IDPropertyName()]) {
                            target["$detail"] = child;
                            break;
                        }
                    }
                    if (rps.object.isDefined(target["$detail"]))
                        break;
                }
            }
        }

        /** @internal **/
        private setHashKey(obj, h) {
            if (h) {
                obj.$$hashKey = h;
            }
            else {
                delete obj.$$hashKey;
            }
        }

        public applyPatch(patchSet: IPropertyPatch[]): void {
            this.doApplyPatch(this, patchSet);
        }

        protected doApplyPatch(targetEntity: BaseEntity, patchSet: IPropertyPatch[]): void {
            try {
                rps.app.entityFactory.isApplyingPatch = true;

                patchSet.forEach((p) => {
                    if (p.Operation === PatchOperationType.Replace) {
                        var pathParts = p.Path.split('.');
                        var target: any = targetEntity;
                        for (var i = 0; i < pathParts.length - 1; i++) {
                            var indexedPathPart = /(\w+)\[(.+)\]/g.exec(pathParts[i]);
                            if (indexedPathPart) {
                                target = Enumerable.From<BaseEntity>(target[indexedPathPart[1]]).FirstOrDefault(null, ent => ent.getEntityID() == indexedPathPart[2]);
                            }
                            else
                                target = target[pathParts[i]];

                            if (rps.object.isNullOrUndefined(target))
                                break;
                        }
                        var lastPart = pathParts[pathParts.length - 1];
                        if (!rps.object.isNullOrUndefined(target) && lastPart in target) {
                            if (rps.object.isNullOrUndefined(target[lastPart]) || target[lastPart] === p.OldValue)
                                target[lastPart] = p.NewValue;
                            else if (target[lastPart] instanceof rps.entities.BaseEntity && target[lastPart].getEntityID && rps.object.hasValue(p.NewValue))
                                //Para el caso concreto de entidades, se mergea siempre
                                (<rps.entities.BaseEntity>target[lastPart]).merge(p.NewValue, false, "m0");
                            else if (target[lastPart].getTime && p.OldValue.getTime && target[lastPart].getTime() === p.OldValue.getTime())
                                //Para el caso concreto de las fechas, la comparación hay que hacerla con el método gettime, si no, aunque sean la misma fecha, al ser instancias distintas, siempre sale que son distintas
                                target[lastPart] = p.NewValue;
                        }
                    }
                    else if (p.Operation === PatchOperationType.Add) {
                        var pathParts = p.Path.split('.');
                        var target: any = targetEntity;
                        for (var i = 0; i < pathParts.length; i++) {
                            var indexedPathPart = /(\w+)\[(.+)\]/g.exec(pathParts[i]);
                            if (indexedPathPart) {
                                if (i < pathParts.length - 1)
                                    target = Enumerable.From<BaseEntity>(target[indexedPathPart[1]]).FirstOrDefault(null, ent => ent.getEntityID() == indexedPathPart[2]);
                                else
                                    target = target[indexedPathPart[1]];
                            }
                            else
                                target = target[pathParts[i]];

                            if (rps.object.isNullOrUndefined(target))
                                break;
                        }
                        if (!rps.object.isNullOrUndefined(target)) {
                            let mergeId: string = "c" + rps.guid.newGuid().replace("-", "");
                            
                            if (rps.entities.isChildCollection(target)) {
                                //Si es un ChildCollection
                                var newElement: IBaseEntity = rps.object.instantiate(target.__entityType);
                                newElement.__entityUrl = (<any>target.__entityType).entityUrl;
                                newElement.merge(p.NewValue, false, mergeId);
                                target.add(newElement);
                            }
                            else
                                (<any>target).merge([p.NewValue], mergeId);
                        }
                    }
                    else if (p.Operation === PatchOperationType.Delete) {
                        var pathParts = p.Path.split('.');
                        var target: any = targetEntity;
                        var id:string | null = null;
                        for (var i = 0; i < pathParts.length; i++) {
                            var indexedPathPart = /(\w+)\[(.+)\]/g.exec(pathParts[i]);
                            if (indexedPathPart) {
                                if (i < pathParts.length - 1)
                                    target = Enumerable.From<BaseEntity>(target[indexedPathPart[1]]).FirstOrDefault(null, ent => ent.getEntityID() == indexedPathPart[2]);
                                else {
                                    target = target[indexedPathPart[1]];
                                    id = indexedPathPart[2];
                                }
                            }
                            else
                                target = target[pathParts[i]];

                            if (rps.object.isNullOrUndefined(target))
                                break;
                        }
                        if (!rps.object.isNullOrUndefined(target) && !string.isNullOrEmpty(id)) {
                            var entity = Enumerable.From<BaseEntity>(target).FirstOrDefault(null, ent => ent.getEntityID() == id);;
                            if (entity)
                                (<any>target).remove(entity)
                        }
                    }
                });
            }
            catch (e) {
            }
            finally {
                rps.app.entityFactory.isApplyingPatch = false;
            }
        }

        /** @internal **/
        public validateVariableValue(value: string, errors: any[]) {
            rps.utils.validateVariableValue(this, value, errors);
        }

        /** @internal **/
        private isDestroyed = false;
        public onDestroy() {
            if (!this.isDestroyed) {
                this.isDestroyed = true;

                this.propertyChanged.onDestroy();
                for (var p in this) {
                    var propertyValue = this[p];
                    if (isIDestroyable(propertyValue))
                        propertyValue.onDestroy();
                }
            }
        }
    }

    export function isChildCollection<T extends IBaseEntity>(val): val is ChildCollection<T> {
        return (rps.object.isArray(val) && (<any>val).__entityType);
    }

    export class ChildCollection<TEntity extends IBaseEntity> extends InheritableCollection<TEntity> implements IDestroyable {
        /** @internal **/
        public isIDestroyable: boolean;

        //Eventos:
        //public collectionChanged: IChildCollectionChanged<{
        //    Type: ChildCollectionChangedType;
        //    Element: TEntity
        //}>;
        public collectionChanged: rps.services.IEventEmitter<{
            Type: ChildCollectionChangedType;
            Element: TEntity
        }>;

        /** @internal **/
        public __entityType: Function;
        /** @internal **/
        private __parent: IBaseEntity;
        /** @internal **/
        private __deletedIDs: Array<string>;
        /** @internal **/
        private __trackChanges: boolean;
        /** @internal **/
        private isDestroyed: boolean;

        /** @internal **/
        public getTrackChanges(): boolean {
            return this.__trackChanges;
        }

        constructor(parent: IBaseEntity, entityType: Function, ...initialItems: IBaseEntity[]) {
            var s = <any>super();
            InheritableCollection.init(s, initialItems, ChildCollection.prototype);
            s.__parent = parent;
            s.__entityType = rps.app.entityFactory.resolveEntityType(entityType);
            s.__deletedIDs = new Array<string>();

            s.collectionChanged = rps.app.eventManager.createEmitter<{
                Type: ChildCollectionChangedType;
                Element: TEntity
            }>(false);

            s.__trackChanges = true;
            s.isDestroyed = false;

            s.isIDestroyable = true;

            return s;
        }

        merge(items: ChildCollection<any> | Array<any>, conditional: boolean, uid: string) {
            if (items) {
                this.__trackChanges = false;

                //Borrar los que no estén en la lista que venía
                for (var i = this.length - 1; i >= 0; i--) {
                    var entityId = this[i].getEntityID();
                    if (!(Enumerable.From<TEntity>(items).FirstOrDefault(undefined, q => q[this[i].IDPropertyName()] == entityId))) {
                        //Si es condicional se mira si es nuevo; si es nuevo, no se elimina porque lo ha metido ahora
                        if (!conditional || !this[i].__isNew)
                            this.remove(this[i]);

                    }
                }

                //Recorrer los elementos que vienen para ver si merge o add con los restantes
                items.forEach((item) => {
                    if (!item["__" + uid]) {
                        var existingElement = this.asEnumerable().FirstOrDefault(undefined, q => q.getEntityID() === item[q.IDPropertyName()]);
                        if (existingElement) {
                            //HACK: se establece el valor __entityUrl para que no se cicle al hacer el merge
                            item["__" + uid] = (<any>this.__entityType).entityUrl;

                            existingElement.merge(item, conditional, uid);
                        }
                        else {
                            var newElement: IBaseEntity = rps.object.instantiate(this.__entityType);
                            newElement.__entityUrl = (<any>this.__entityType).entityUrl;
                            //HACK: se establece el valor __entityUrl para que no se cicle al hacer el merge
                            item["__" + uid] = (<any>this.__entityType).entityUrl;

                            newElement.merge(item, conditional, uid);

                            //Si es merge condicional, sólo se añade si el item no está en la lista de eliminados
                            if (!conditional || this.__deletedIDs.indexOf(newElement.getEntityID()) < 0)
                                this.add(<TEntity>newElement);
                        }
                    }
                });

                this.__trackChanges = true;
            }
        }

        createNew(): Promise<TEntity> {
            return rps.app.entityFactory.createNew((<any>this.__entityType).entityUrl).then<any>((newEntity) => {
                return newEntity;
            });
        }

        addNew(): Promise<TEntity> {
            return rps.app.entityFactory.createNew((<any>this.__entityType).entityUrl).then<any>((newEntity) => {
                this.add(newEntity);
                return newEntity;
            });
        }

        add(...items: TEntity[]): void {
            if (items) {
                items.forEach((item) => {
                    this.push(item);
                    //Establecer todas las propiedades de padre
                    item.__parent = this.__parent
                    if (this.__parent) {
                        item[item.getParentRelations()[0].ParentRelation] = this.__parent; //Referencia al padre
                        item[item.getParentRelations()[0].ParentId] = this.__parent.getEntityID(); //ID del padre
                    }
                    this.collectionChanged.emit({ Type: ChildCollectionChangedType.Add, Element: <TEntity>item });
                });
            }
            this.setParentAsModified();
        }

        contains(item: IBaseEntity): boolean {
            return Array.prototype.indexOf(item) >= 0;
        }

        Remove(item: TEntity): void {
            this.remove(item);
        }

        RemoveAll(): void {
            for (var i = this.length-1; i >= 0; i--) {
                this.remove(this[i]);
            }
        }

        remove(item: TEntity): void {
            var index = this.indexOf(item);
            if (index > -1) {
                //Añadir a lista de eliminados
                this.__deletedIDs.push(item.getEntityID());

                this.splice(index, 1);

                item.__parent = null;

                var iw = rps.app.entityFactory.isWorking;
                rps.app.entityFactory.isWorking = true;
                item[item.getParentRelations()[0].ParentRelation] = null; //Referencia al padre
                item[item.getParentRelations()[0].ParentId] = null; //ID del padre
                rps.app.entityFactory.isWorking = iw;

                this.collectionChanged.emit({ Type: ChildCollectionChangedType.Remove, Element: <TEntity>item });
            }

            this.setParentAsModified();
        }

        public getErrors(): rps.errors.ErrorDetail[] {
            //Devolver las suyas y las de sus hijos
            var errors: rps.errors.ErrorDetail[] = new Array<rps.errors.ErrorDetail>();
            this.forEach((child) =>
                errors = errors.concat(child.getErrors())
            );
            return errors;
        }

        /** @internal **/
        private setParentAsModified() {
            //Buscar el padre superior y ponerlo como que está modificado
            if (!this.__trackChanges || rps.app.entityFactory.isWorking)
                return;

            var p = this.__parent;
            while (p) {
                p.__hasModifiedRelations = true;
                p = p.__parent;
            }
        }

        public onDestroy() {
            if (!this.isDestroyed) {
                this.isDestroyed = true;
                this.forEach((item) => item.onDestroy());

                this.collectionChanged.onDestroy();
            }
        }
    }

    export enum ChildCollectionChangedType {
        Add = 1,
        Remove = 2
    }

    export interface IChildCollectionChangedArgs<T extends IBaseEntity> {
        Type: ChildCollectionChangedType;
        Element: T;
    }

    export interface IPropertyChangedArgs {
        PropertyName: string;
    }

}

module rps.entities.resources {

    export function RequiredPropertyIsNullErrorCode(): string {
        //TODO
        return "TODO: RequiredPropertyIsNullErrorCode";
    }

    export function RequiredPropertyIsNull(entity: any, nullPropertyName: string): string {
        //TODO
        return "TODO: RequiredPropertyIsNullErrorCode";
    }
}

