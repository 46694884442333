/// <reference path="parameters.ts" />

module rps.data.sources {

    export class HierarchicalQuerySource implements ISelectable, rps.viewmodels.rules.IRuleTrigger,IExecutableQuery,IActivable{   
        ISelectable: boolean = true;
        IRuleTrigger: boolean = true;
        isIActivable: boolean = true;

        private _parentVM: rps.viewmodels.BaseVM;
        private service: string;
        private queryName: string;                
        private relatedVMFunction: Function;
        private childrenCountPropertyPath: string = "ChildNumber";    
        private childrenPropertyPath: string = "Children";
        private canExecuteQuery: (() => rps.viewmodels.commands.CanExecuteResult) | undefined;
        private beforeExecuteQuery: (() => Promise<{ cancel?: boolean }>) | undefined;

        public loaded: boolean = false;
        public propertyChanged = rps.app.eventManager.createEmitter<rps.viewmodels.properties.VMPropertyChange>();
        public queryParams: parameters.QueryParams | undefined;
        //HACK: El treeList y el treeView, no pueden compartir origen de datos, por lo que se mantienen dos en paralelo en caso de hacer falta, en el constructor, se pasa la necesidad de estos orígenes de datos, para no mantenerlo sin necesidad…
        public createTreeListDataSource: boolean;
        public treeListDataSource: kendo.data.TreeListDataSource;
        public createHierarchicalDataSource: boolean;
        public hierarchicalDataSource: kendo.data.HierarchicalDataSource;

        public rules: rps.viewmodels.rules.Rule[] = [];

        private _selectedItems: Array<any>;
        public get selectedItems(): Array<any> {
            return this._selectedItems;
        }
        public set selectedItems(newValue: Array<any>) {
            if (this._selectedItems != newValue) {
                this._selectedItems = newValue;

                this.propertyChanged.emit({ propertyName: "selectedItems", newValue: newValue });

                //Lanzar las when del target (las que no tienen Trigger)
                if (this._parentVM && (<any>this._parentVM).rules && (<any>this._parentVM).rules.length > 0) {
                    Enumerable.From<rps.viewmodels.rules.Rule>((<any>this._parentVM).rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                        ForEach((rule) => {
                            rule.execute(this);
                        });
                }
            }
        }
        public hasSelectedItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.selectedItems) && this.selectedItems.length > 0)
                return true;
            else
                return false;
        }
        public getSelectedItems(): Array<any> {
            if (this.hasSelectedItems())
                return this.selectedItems;
            else
                return new Array<any>();
        }
        public setSelectedItems(newSelection: Array<any>) {
            this.selectedItems = newSelection;
        }

        constructor(params: {
            service: string;
            queryName: string;            
            relatedVMFunction: Function;
            executionPolicy: rps.queryExecutionPolicy;            
            parentVM: rps.viewmodels.BaseVM
            queryParams?: parameters.QueryParams;
            createTreeListDataSource: boolean;
            createHierarchicalDataSource: boolean;
        }) {            
            this._parentVM = params.parentVM;
            this.service = params.service;
            this.queryName = params.queryName;

            this.createTreeListDataSource = params.createTreeListDataSource;
            this.createHierarchicalDataSource = params.createHierarchicalDataSource;

            this.queryParams = params.queryParams;
            this.relatedVMFunction = (<any>(params.relatedVMFunction)).getResolvedType(params.relatedVMFunction);            
            this.executionPolicy = params.executionPolicy;

            this.createDataSource();

            //Si se autoexecuta, hay que añadir watches a las propiedades
            if ((this.executionPolicy == rps.queryExecutionPolicy.Automatic || this.executionPolicy == rps.queryExecutionPolicy.WhenActive) && this.queryParams) {
                this.queryParams.forEach((ip: parameters.QueryParam) => {
                    if (ip.value && ip.value instanceof rps.viewmodels.properties.VMProperty) {
                        (<rps.viewmodels.properties.VMProperty<any>>ip.value).propertyChanged.subscribe((subscriptionArgs: rps.viewmodels.properties.VMPropertyChange) => {
                            if (subscriptionArgs.propertyName == "value") {
                                this.load();
                            }
                        });
                    }
                });
            }
        }

        initialize(): Promise<any> {
            if (this.executionPolicy == rps.queryExecutionPolicy.Automatic)
                this.load();
            return Promise.resolve<any>(this);
        }

        load() {
            if (this.canExecuteLoadItems().result)
                this.executeLoadItems()
            else if (this.loaded) {
                this.loaded = false;
                this.propertyChanged.emit({ propertyName: "loaded", newValue: false });
            }
        }

        public onActivate(isFirstActivation: boolean) {
            //Si es la primera vez y la query está marcada como whenActivate, se intenta lanzar
            if (isFirstActivation) {
                if (this.executionPolicy == rps.queryExecutionPolicy.WhenActive)
                    this.execute();
            }
        }

        public executionPolicy: rps.queryExecutionPolicy;

        public execute = () => {
            return this.executeLoadItems().then(() => {
                this.processRules(rps.viewmodels.rules.Triggers.OnExecuteQuery);
                return this;
            });
        }

        public loadItems: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: this.execute,
            canExecute: this.canExecuteLoadItems
        });

        executeLoadItems(): Promise<any> {
            if (!this.beforeExecuteQuery) {
                if (!this.loaded) {
                    this.loaded = true;
                    this.propertyChanged.emit({ propertyName: "loaded", newValue: true });
                }
                return this.readDataSource();
            }
            else {
                return this.beforeExecuteQuery.call(this._parentVM).then((successCallback: { cancel?: boolean }) => {
                    if (!successCallback.cancel) {
                        if (!this.loaded) {
                            this.loaded = true;
                            this.propertyChanged.emit({ propertyName: "loaded", newValue: true });
                        }
                        return this.readDataSource();
                    }
                    else {
                        return Promise.resolve<HierarchicalQuerySource>(this);
                    }
                });
            }
        }
        
        canExecuteLoadItems(): rps.viewmodels.commands.CanExecuteResult {
            if (!this.canCreateParams()) {
                var reasons: Array<string> = new Array<string>();
                reasons.push(rps.app.resources.directives.FILL_REQUIRED_PARAMETERS);
                return rps.viewmodels.commands.CanExecuteResult.deny(reasons);
            }
            else if (this.canExecuteQuery)
                return this.canExecuteQuery.call(this._parentVM);
            else
                return rps.viewmodels.commands.CanExecuteResult.allow();
        }

        canCreateParams(): boolean {
            var canCreate: boolean = true;
            if (this.queryParams) {
                this.queryParams.forEach((ip: parameters.QueryParam) => {
                    //Mirar si todos los parámetros requeridos tienen valor
                    if (ip.isRequired && !rps.object.hasValue(ip.getValue()))
                        canCreate = false;
                });
            }
            return canCreate;
        }

        getParams(): { [id: string]: Object; } {
            var params: { [id: string]: Object; } = {};
            if (this.queryParams) {
                this.queryParams.forEach((queryParam: parameters.QueryParam) => {
                    var value = queryParam.getValue();
                    if (!rps.object.isNullOrUndefined(value)) {
                        if (value instanceof Date)
                            params[queryParam.paramName] = value.toISOString();
                        else if (Array.isArray(value)) {
                            if (value.length > 0) {
                                var newParamValue: string;
                                (<Array<any>>value).forEach((item) => {
                                    if (newParamValue == undefined)
                                        newParamValue = item;
                                    else
                                        newParamValue += "," + item;
                                });
                                params[queryParam.paramName] = newParamValue;
                            }
                        }
                        else
                            params[queryParam.paramName] = value;
                    }
                });
            }
            return params;            
        }

        createDataSource() {
            if (this.createTreeListDataSource) {
                var treeListDataSourceOptions: kendo.data.DataSourceOptions = {}
                treeListDataSourceOptions.transport = {
                    read: this.readTreeList
                };
                treeListDataSourceOptions.schema = {
                    model: {
                        id: "nodeID",
                        parentId: "parentNodeID",
                        fields: {
                            nodeID: {},
                            parentNodeID: { nullable: true }
                        }
                    }
                }
                this.treeListDataSource = new kendo.data.TreeListDataSource(treeListDataSourceOptions);
            }

            if (this.createHierarchicalDataSource) {
                var hierarchicalDataSourceOptions: kendo.data.HierarchicalDataSourceOptions = {}
                hierarchicalDataSourceOptions.transport = {
                    read: this.readHierarchical
                };
                hierarchicalDataSourceOptions.schema = {
                    model: {
                        id: "nodeID"
                    }
                }
                this.hierarchicalDataSource = new kendo.data.HierarchicalDataSource(hierarchicalDataSourceOptions);
            }
        }

        readDataSource(): Promise<any> {
            return new Promise((resolve, reject) => {
                if (this.canCreateParams()) {
                    if (this.hierarchicalDataSource)
                        this.hierarchicalDataSource.read()
                            .done(() => resolve(this))
                            .fail((e) => reject(e));
                    else if (this.treeListDataSource)
                        this.treeListDataSource.read()
                            .done(() => resolve(this))
                            .fail((e) => reject(e));
                    else
                        resolve(this);
                }
                else {
                    resolve(this);
                }
            });
            
        }

        readTreeList = (options: kendo.data.DataSourceTransportReadOptions) => {
            if (!options.data["id"]) {
                this.selectedItems = null;
                rps.app.api.query<rps.data.PaginatedViewResult>({
                    queryName: this.service + "/" + this.queryName,
                    params: this.getParams()
                }).then((result) => {
                    this.createViewModels(result, null).then((items: Array<rps.viewmodels.ModelVM>) => {
                        options.success(items);
                    });
                });
            }
            else {
                var item: rps.viewmodels.ModelVM = <any>this.treeListDataSource.get(options.data["id"]);
                rps.app.api.get<any>({
                    url: item.model[this.childrenPropertyPath],
                    urlType: rps.services.UrlType.Absolute
                }).then((result) => {
                    this.createViewModels(result, options.data["id"]).then((items: Array<rps.viewmodels.ModelVM>) => {
                        options.success(items);
                    });
                });
            }
        }

        readHierarchical = (options: kendo.data.DataSourceTransportReadOptions) => {
            if (!options.data["nodeID"]) {
                this.selectedItems = null;
                rps.app.api.query<rps.data.PaginatedViewResult>({
                    queryName: this.service + "/" + this.queryName,
                    params: this.getParams()
                }).then((result) => {
                    this.createViewModels(result, null).then((items: Array<rps.viewmodels.ModelVM>) => {
                        options.success(items);
                    });
                });
            }
            else {                
                var item: rps.viewmodels.ModelVM = <any>this.hierarchicalDataSource.get(options.data["nodeID"]);
                rps.app.api.get<any>({
                    url: item.model[this.childrenPropertyPath],
                    urlType: rps.services.UrlType.Absolute             
                }).then((result) => {
                    this.createViewModels(result, options.data["nodeID"]).then((items: Array<rps.viewmodels.ModelVM>) => {
                        options.success(items);
                    });
                });
            }
        }

        public export: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: this.executeExport,
            canExecute: this.canExecuteLoadItems
        });

        protected executeExport(commandParameters: { format: string }): Promise<any> {
            return rps.app.api.exportQuery({
                queryReference: this.service + "/" + this.queryName,
                exportFormat: commandParameters.format,
                params: this.getParams()
            });
        }

        createViewModels(result: rps.data.PaginatedViewResult, parentNodeID: string): Promise<Array<rps.viewmodels.ModelVM>> {
            var promises: Array<Promise<rps.viewmodels.ModelVM>> = new Array<Promise<rps.viewmodels.ModelVM>>();
            result.Data.forEach((dataItem) => {
                promises.push(this.createViewModel(dataItem, parentNodeID));
            });
            return Promise.all(promises).then((newItems) => {
                return newItems;
            });
        }

        createViewModel = (model: any, parentNodeID: string): Promise<rps.viewmodels.ModelVM> => {
            return new Promise<rps.viewmodels.ModelVM>((resolve) => {
                var initParams = { model: model };
                if (this._parentVM)
                    initParams["parentVM"] = this._parentVM;
                rps.app.viewModelFactory.createViewModel(this.relatedVMFunction, initParams).then((vm: rps.viewmodels.ModelVM) => {
                    vm["nodeID"] = rps.guid.newGuid();
                    vm["parentNodeID"] = parentNodeID;
                    vm["hasChildren"] = (vm.model[this.childrenCountPropertyPath] > 0);
                    resolve(vm);
                });
            });
        }        

        public configure(params: {
            canExecuteQuery?: () => rps.viewmodels.commands.CanExecuteResult,
            beforeExecuteQuery?: () => Promise<{ cancel?: boolean }>
        }) {
            if (rps.object.hasValue(params.canExecuteQuery))
                this.canExecuteQuery = params.canExecuteQuery;

            if (rps.object.hasValue(params.beforeExecuteQuery))
                this.beforeExecuteQuery = params.beforeExecuteQuery;
        }

        protected processRules(trigger?: rps.viewmodels.rules.Triggers): Promise<rps.viewmodels.rules.RuleExecutionResult> {
            var rulesToExecute: Array<rps.viewmodels.rules.Rule> = [];
            if (this.rules && this.rules.length > 0) {
                this.rules.forEach((rule) => {
                    if (trigger && rule.Trigger == trigger)
                        rulesToExecute.push(rule);
                });
            }

            return rulesToExecute.reduce((prevPromise, rule) => {
                return prevPromise.then((result) => {
                    if (result.value === rps.viewmodels.rules.RuleExecutionValue.Continue)
                        return rule.execute().catch((err) => {
                            //Si casca la ejecución de una regla, añadir el error y cancelar el resto de reglas
                            rps.app.errorManager.add(
                                new rps.errors.ErrorDetail("RULE_ERROR", rps.app.resources.errors.ERR_ERROR_EXECUTING_RULE.format(rule.ID, rps.errors.getErrorString(err))));

                            return Promise.resolve({ value: rps.viewmodels.rules.RuleExecutionValue.Cancel });
                        });
                    else
                        return Promise.resolve({ value: rps.viewmodels.rules.RuleExecutionValue.Cancel });
                })
            }, Promise.resolve<rps.viewmodels.rules.RuleExecutionResult>({ value: rps.viewmodels.rules.RuleExecutionValue.Continue }));

        }

    }
}