/// <reference path="../app/reportFiltersVM.ts" />
/// <reference path="../data/queryMethod.ts" />

module rps.viewmodels.commands {

    export class CanExecuteResult {
        result: boolean = true;
        denyReasons: Array<string>;

        static allow(): CanExecuteResult {
            return new CanExecuteResult();
        }

        static deny(reasons: Array<string>): CanExecuteResult {
            var canExecuteResult: CanExecuteResult = new CanExecuteResult();
            canExecuteResult.result = false;
            canExecuteResult.denyReasons = reasons;
            return canExecuteResult;
        }
    }

    export class CommandProperty implements rps.viewmodels.rules.IRuleTrigger {
        /** @internal */
        public IRuleTrigger: boolean = true;

        /** @internal */
        private _command: ((commandParameters?) => Promise<any>) | undefined;
        /** @internal */
        protected _canExecuteCommand: (commandParameters?) => CanExecuteResult;

        /** @internal */
        public propertyChanged = rps.app.eventManager.createEmitter<rps.viewmodels.properties.VMPropertyChange>();

        /** @internal */
        public rules: rules.Rule[] = [];

        /** @internal */
        protected _target: any;

        /** @internal */
        private _isBusy: boolean = false;
        /** @internal */
        public get isBusy(): boolean {
            return this._isBusy;
        }
        /** @internal */
        public set isBusy(newValue: boolean) {
            if (this._isBusy != newValue) {
                this._isBusy = newValue;
                this.propertyChanged.emit({ propertyName: "isBusy", newValue: newValue });
            }
        }

        constructor(params: {
            target: any;
            command?: (commandParameters?) => Promise<any>;
            canExecute?: ((commandParameters?) => CanExecuteResult);
            relatedAction?: rps.services.Action;
        }) {
            this._target = params.target;
            if (params.canExecute != undefined)
                this._canExecuteCommand = params.canExecute;
            this._command = params.command;
            if (rps.object.isDefined(params.relatedAction))
                this.relatedAction = params.relatedAction;
        }

        public relatedAction: rps.services.Action;

        public canExecute = (commandParameters?): CanExecuteResult => {
            return this._canExecute(commandParameters);
        }

        public execute = (commandParameters?: any): Promise<any> => {
            return this._execute(commandParameters);
        }

        /** @internal */
        public tryExecute = (commandParameters?: any): Promise<boolean> => {
            return this._tryExecute(commandParameters);
        }

        /** @internal */
        protected _canExecute(commandParameters?: any): CanExecuteResult {
            if (this._canExecuteCommand)
                return this._canExecuteCommand.call(this._target, commandParameters);
            else
                return CanExecuteResult.allow();
        }

        /** @internal */
        protected _execute(commandParameters?: any): Promise<any> {
            return this.checkAndExec(commandParameters, false);
        }

        /** @internal */
        protected _tryExecute(commandParameters?: any): Promise<boolean> {
            return this.checkAndExec(commandParameters, true).then(() => {
                return true;
            }).catch((error) => {
                //Si el error es el resultado del canExecute y el resultado es que no, significa, que no se ha podido ejecutar
                if (error instanceof CanExecuteResult && !error.result)
                    return false;
                throw error;
            });
        }

        /** @internal */
        protected checkAndExec(commandParameters?: any, showMessage?: boolean): Promise<any> {
            return this.canExec(commandParameters, showMessage).then(() => {
                this.isBusy = true;
                return this.exec(commandParameters).then((execResult) => {
                    return this.processRules(rules.Triggers.OnExecuteCommand).then(() => {
                        return Promise.resolve<any>(execResult);
                    }).finally(() => {
                        this.isBusy = false;
                    });
                }).catch((error) => {
                    this.isBusy = false;
                    return Promise.reject<any>(error);
                });
            });
        }

        /** @internal */
        protected canExec(commandParameters?: any, showMessage?: boolean): Promise<any> {
            var canExecuteResult: CanExecuteResult = this.canExecute(commandParameters);
            if (!canExecuteResult.result) {
                if (showMessage) {
                    if (canExecuteResult.denyReasons && canExecuteResult.denyReasons.length > 0) {
                        var message = canExecuteResult.denyReasons[0];
                        for (var i = 1; i < canExecuteResult.denyReasons.length; i++) {
                            if (canExecuteResult.denyReasons.length - 1 == i)
                                message += string.format(" {0} ", rps.app.resources.commonResources.AND) + canExecuteResult.denyReasons[i];
                            else
                                message += ", " + canExecuteResult.denyReasons[i];
                        }
                        return rps.app.messageManager.show({
                            message: message,
                            messageButton: services.MessageButton.Ok,
                            messageType: services.MessageType.Info
                        }).then((messageResult: services.MessageResult) => {
                            return Promise.reject<any>(canExecuteResult);
                        });
                    }
                    else
                        return Promise.reject<any>(canExecuteResult);
                }
                else
                    return Promise.reject<any>(canExecuteResult.denyReasons);
            }
            else if (this.isBusy) {
                if (showMessage)
                    return Promise.reject<any>(CanExecuteResult.deny(["The command is running"]));
                else
                    return Promise.reject<any>(new Error("The command is running"));
            }
            else
                return Promise.resolve<any>(this._target);
        }

        /** @internal */
        protected exec(commandParameters?: any): Promise<any> {
            if (this._command) {
                this.isBusy = true;
                var result: Promise<any> = this._command.call(this._target, commandParameters);
                if (result)
                    return result;
                else
                    return Promise.resolve<any>(this._target);
            }
            else
                return Promise.resolve<any>(this._target);
        }

        /** @internal */
        protected processRules(trigger?: rules.Triggers): Promise<rules.RuleExecutionResult> {
            var rulesToExecute: Array<rules.Rule> = [];
            if (this.rules && this.rules.length > 0) {
                this.rules.forEach((rule) => {
                    if (trigger && rule.Trigger == trigger)
                        rulesToExecute.push(rule);
                });
            }

            return rulesToExecute.reduce((prevPromise, rule) => {
                return prevPromise.then((result) => {
                    if (result.value === rules.RuleExecutionValue.Continue)
                        return rule.execute().catch((err) => {
                            //Si casca la ejecución de una regla, añadir el error y cancelar el resto de reglas
                            rps.app.errorManager.add(
                                new rps.errors.ErrorDetail("RULE_ERROR", rps.app.resources.errors.ERR_ERROR_EXECUTING_RULE.format(rule.ID, rps.errors.getErrorString(err))));

                            return Promise.resolve({ value: rules.RuleExecutionValue.Cancel });
                        });
                    else
                        return Promise.resolve({ value: rules.RuleExecutionValue.Cancel });
                })
            }, Promise.resolve<rules.RuleExecutionResult>({ value: rules.RuleExecutionValue.Continue }));

        }
    }

        /** @internal */
    export function isCommandProperty(value: any): value is CommandProperty {
        return value && value instanceof CommandProperty;
    }

    export class MessageCommandProperty extends CommandProperty {
        /** @internal */
        private _messageType: rps.services.MessageType;
        /** @internal */
        private _messageOption: rps.services.MessageButton;
        /** @internal */
        private _messageText: string;
        /** @internal */
        private _onClose: (selectedButton: rps.services.MessageResult) => void;

        constructor(params: {
            target: any;
            canExecute?: (() => CanExecuteResult);
            onClose?: (selectedButton: rps.services.MessageResult) => void;
            messageType: rps.services.MessageType,
            messageOption: rps.services.MessageButton,
            messageText: string
        }) {
            super({
                target: params.target,
                canExecute: params.canExecute
            });

            this._onClose = params.onClose;
            this._messageType = params.messageType;
            this._messageText = params.messageText;
            this._messageOption = params.messageOption;
        }

        /** @internal */
        exec(commandParameters?: any): Promise<any> {
            return rps.app.messageManager
                .show({
                    message: this._messageText,
                    messageButton: this._messageOption,
                    messageType: this._messageType
                })
                .then((result: rps.services.MessageResult) => {
                    if (this._onClose)
                        this._onClose(result);
                });
        }
    }

    export class ModalNavigationCommandProperty extends CommandProperty {
        /** @internal */
        private state: string;
        /** @internal */
        private vm: any;
        /** @internal */
        private vmFunction: Function;
        /** @internal */
        private resolve: Function;
        /** @internal */
        private reject: Function;
        /** @internal */
        private okCommand: rps.viewmodels.commands.CommandProperty;

        constructor(params: {
            target: any;
            canExecute?: (() => CanExecuteResult);
            state: string;
            vm?: any;
            vmFunction?: Function;
            resolve?: Function;
            reject?: Function;
            okCommand: rps.viewmodels.commands.CommandProperty;
        }) {
            super({
                target: params.target,
                canExecute: params.canExecute
            });

            this.state = params.state;
            this.vm = params.vm;
            this.vmFunction = params.vmFunction;
            this.resolve = params.resolve;
            this.reject = params.reject;
            this.okCommand = params.okCommand;
        }

        /** @internal */
        protected exec(commandParameters?: any): Promise<any> {
            var modelPromise: Promise<any>;
            if (this.vm)
                modelPromise = Promise.resolve(this.vm);
            else if (this.vmFunction)
                modelPromise = rps.app.viewModelFactory.createViewModel(this.vmFunction);

            return modelPromise.then<rps.services.NavigationResult>((vm) => {
                return rps.app.stateManager.navigateModal(this.state, vm, this.okCommand).
                    then((value: { result: rps.services.NavigationResult; data: any }) => {
                        this.resolve.call(this._target, value);
                        return value.result;
                    }).catch((reason) => {
                        if (this.reject)
                            this.reject.call(this._target, reason);
                        return rps.services.NavigationResult.Cancel;
                    });
            }).catch((error) => {
                return rps.services.NavigationResult.Cancel;
            });
        }
    }

    export class ApiActionCommand<TInput, TOutput> extends CommandProperty {
        /** @internal */
        protected actionName: string;
        /** @internal */
        private inputParams: rps.data.parameters.QueryParams;
        /** @internal */
        private outputParams: rps.data.parameters.QueryParams;
        /** @internal */
        protected callback: (value: TOutput) => void;

        constructor(params: {
            target: any;
            actionName: string;
            inputParams: rps.data.parameters.QueryParams;
            outputParams: rps.data.parameters.QueryParams;
            resolve?: (value: TOutput) => void;
        }) {
            super({
                target: params.target
            });

            this.actionName = params.actionName;
            this.inputParams = params.inputParams;
            this.outputParams = params.outputParams;
            this.callback = params.resolve;
        }

        /** @internal */
        protected _execute(inputParams?: TInput): Promise<TOutput> {
            return super._execute(inputParams);
        }

        public execute = (commandParameters?: TInput): Promise<TOutput> => {
            return this._execute(commandParameters);
        }

        public canExecute = (commandParameters?: TInput): CanExecuteResult => {
            return this._canExecute(commandParameters);
        }

        /** @internal */
        protected buildInputArguments(commandParameters?: TInput): any {
            const actionParams: any = {};

            if (this.inputParams) {
                this.inputParams.forEach((queryParam: rps.data.parameters.QueryParam) => {
                    if (!commandParameters || !commandParameters.hasOwnProperty(queryParam.paramName)) {
                        actionParams[queryParam.paramName] = queryParam.getValue();
                    }
                });
            }
            for (var param in commandParameters) {
                var paramValue = commandParameters[param];
                if (rps.viewmodels.properties.isVMProperty(paramValue))
                    actionParams[param] = paramValue.getServerParamValue();
                else if (rps.object.isFunction(paramValue))
                    actionParams[param] = paramValue();
                else
                    actionParams[param] = paramValue;
            }
            return actionParams;
        }

        /** @internal */
        protected setOuputParams(result: TOutput): void {
            if (this.outputParams) {
                this.outputParams.forEach((outParam: rps.data.parameters.QueryParam) => {
                    if (!rps.object.isNullOrUndefined(result[outParam.paramName])) {
                        if (outParam.value && outParam.value instanceof rps.viewmodels.properties.VMProperty)
                            outParam.value.value = result[outParam.paramName];
                        else
                            outParam.value = result[outParam.paramName];
                    }
                });
            }
        }

        /** @internal */
        exec(commandParameters?: TInput): Promise<TOutput> {

            const actionParams = this.buildInputArguments(commandParameters);
            return rps.app.api.invokeAction(this.actionName, actionParams).then((result: TOutput) => {
                //Asignar parámetros de salida
                this.setOuputParams(result);

                //Llamar al resolve si es caso
                if (this.callback)
                    this.callback(result);

                return this.processExecResult(result).then((execResult) => {
                    return this.processRules(rules.Triggers.OnExecuteAction).then(() => {
                        return execResult;
                    });
                });
            }).catch((error: rps.services.IServiceExceptionError) => {
                rps.app.errorManager.clear();
                var errors = rps.app.errorManager.add(error);
                if (errors.length > 0)
                    return Promise.reject(new rps.errors.ErrorDetail("HANDLED_API_ACTION_ERROR", rps.app.resources.errors.ERR_ERROR_EXECUTING_API_ACTION));
                else
                    return Promise.reject(error);
            });
        }

        /** @internal */
        protected processExecResult(result: any): Promise<any> {
            return Promise.resolve(result);
        }
    }

    export class ProcessActionCommand<TInput, TOutput extends rps.data.SyncProcessResult> extends ApiActionCommand<TInput, rps.data.SyncProcessResult> {
        /** @internal */
        private skipNotificationOnSuccess: boolean = false;
        private closeProgress: () => void;
        constructor(params: {
            target: any;
            actionName: string;
            inputParams: rps.data.parameters.QueryParams;
            outputParams: rps.data.parameters.QueryParams;
            resolve?: (value: TOutput) => void;
            reject?: (reason: any) => void;
            skipNotificationOnSuccess?: boolean;
        }) {
            super(params);
            if (params.skipNotificationOnSuccess) {
                this.skipNotificationOnSuccess = true;
            }
        }

        exec(commandParameters?: TInput): Promise<TOutput> {
            const actionParams = this.buildInputArguments(commandParameters);
            return rps.app.api.invokeAction(this.actionName, actionParams).then((result: rps.data.SyncProcessResult | rps.data.AsyncProcessResult) => {
                if (rps.data.isSyncProcessResult(result)) {
                    return this.processSyncResult(result);
                } else {
                    return this.processAsyncResult(result, actionParams);
                }
            }).catch((error: rps.services.IServiceExceptionError) => {
                rps.app.errorManager.clear();
                var errors = rps.app.errorManager.add(error);
                if (errors.length > 0)
                    return Promise.reject(new rps.errors.ErrorDetail("HANDLED_API_ACTION_ERROR", rps.app.resources.errors.ERR_ERROR_EXECUTING_API_ACTION));
                else
                    return Promise.reject(error);
            });
        }

        /** @internal */
        private processSyncResult(syncProcessResult: rps.data.SyncProcessResult): Promise<any> {

            //Asignar parámetros de salida
            this.setOuputParams(syncProcessResult);

            if (this.closeProgress) {
                this.closeProgress();
                this.closeProgress = null;
            }

            //Llamar al resolve si es caso
            if (this.callback)
                this.callback(syncProcessResult);

            return this.showResult(syncProcessResult).then(() => {
                return this.processRules(rules.Triggers.OnExecuteAction).then(() => {
                    return syncProcessResult;
                });
            });
        }

        private showResult(syncProcessResult: rps.data.SyncProcessResult): Promise<any> {
            if (!this.skipNotificationOnSuccess || (syncProcessResult.Details && Enumerable.From<rps.data.ProcessResultDetail>(syncProcessResult.Details).Any(detail => detail.State == rps.data.ProcessDetailState.Error)))
                return rps.app.processResultManager.show({ result: syncProcessResult });
            return Promise.resolve(this);
        }

        /** @internal */
        private processAsyncResult(asyncProcessResult: rps.data.AsyncProcessResult, actionParams: any): Promise<any> {
            this.closeProgress = rps.app.processResultManager.showAsync({ result: asyncProcessResult });
            actionParams["IDServiceJob"] = asyncProcessResult.IDProcessJob;

            return this.exec(actionParams);
        }
    }

    interface EntityTransformationInput {
        Entity: rps.entities.IBaseEntity
    }
    interface EntityTransformationOutput {
        Result: Array<any>
    }
    export class EntityTransformationCommand<TInput, TOutput> extends ApiActionCommand<TInput, TOutput>{
        constructor(params: {
            target: any;
            actionName: string;
            inputParams: rps.data.parameters.QueryParams;
            outputParams: rps.data.parameters.QueryParams;
            resolve?: (value: TOutput) => void;
            reject?: (reason: any) => void;
        }) {
            super(params);

            params.inputParams.push(new rps.data.parameters.QueryParam("Entity", () => { return (<rps.viewmodels.EntityVM<rps.entities.IBaseEntity>>this._target).model }, true, true));
        }

        /** @internal */
        processExecResult(result: any): Promise<any> {
            if (rps.object.isArray(result.Result))
                return (<rps.viewmodels.EntityVM<rps.entities.IBaseEntity>>this._target).applyPatch(result.Result)
                    .then(() => {
                        (<rps.viewmodels.BaseVM>this._target).sort();

                        return super.processExecResult(result);
                    }).catch((err) => {
                        throw err;
                    });
            else
                return super.processExecResult(result);
        }
    }

    interface GenericEntityActionInput {
        Entity: rps.entities.IBaseEntity
    }

    export class GenericEntityCommand<TInput, TOutput> extends ApiActionCommand<TInput, TOutput>{
        constructor(protected commandParams: {
            target: any;
            actionName: string;
            inputParams: rps.data.parameters.QueryParams;
            outputParams: rps.data.parameters.QueryParams;
            resolve?: (value: TOutput) => void;
            reject?: (reason: any) => void;
        }) {
            super(commandParams);

            this.commandParams.inputParams.push(new rps.data.parameters.QueryParam("Entity", () => { return (<rps.viewmodels.EntityVM<rps.entities.IBaseEntity>>this._target).model }, true, true));
        }
    }


    interface PersistedEntityInput {
        EntityID: string
    }
    interface PersistedEntityOutput {
        Result: Array<any>
    }
    export class PersistedEntityCommand<TInput, TOutput> extends ApiActionCommand<TInput, TOutput>{
        constructor(protected commandParams: {
            target: any;
            actionName: string;
            inputParams: rps.data.parameters.QueryParams;
            outputParams: rps.data.parameters.QueryParams;
            resolve?: (value: TOutput) => void;
            reject?: (reason: any) => void;
        }) {
            super(commandParams);

            this.commandParams.inputParams.push(new rps.data.parameters.QueryParam("EntityID", () => { return (<rps.viewmodels.EntityVM<rps.entities.IBaseEntity>>this._target).model.getEntityID() }, true, true));
        }

        /** @internal */
        processExecResult(result: any): Promise<any> {
            if (result.Refresh) {
                var id = (<rps.viewmodels.EntityVM<rps.entities.IBaseEntity>>this._target).model.getEntityID();
                return (<rps.viewmodels.MainEntityVM<rps.entities.IBaseEntity>>this._target).loadModel(id).then((vm) => {
                    vm.createExtendedData(true);
                    return super.processExecResult(result);
                });
            }
            else
                return super.processExecResult(result);
        }
    }

    export interface PersistedEntityCreationInput {
        EntityID: string;
        CodCompany: string;
    }
    export interface PersistedEntityCreationOutput {
        NavigateTo: any;
    }
    //TODO: Hago opcional el parámetro NavigateTo, ya que no compila
    export class PersistedEntityCreationCommand<TInput, TOutput extends { NavigateTo?: rps.data.IUILinkDefinition }> extends ApiActionCommand<TInput, TOutput>{
        constructor(protected commandParams: {
            target: any;
            actionName: string;
            inputParams: rps.data.parameters.QueryParams;
            outputParams: rps.data.parameters.QueryParams;
            resolve?: (value: TOutput) => void;
            reject?: (reason: any) => void;
        }) {
            super(commandParams);

            this.commandParams.inputParams.push(new rps.data.parameters.QueryParam("EntityID", () => { return (<rps.viewmodels.EntityVM<rps.entities.IBaseEntity>>this._target).model.getEntityID() }, true, true));
            this.commandParams.inputParams.push(new rps.data.parameters.QueryParam("CodCompany", () => { return rps.app.session.company }, true, true));
        }

        /** @internal */
        processExecResult(result: any): Promise<any> {
            if (result && result.NavigateTo && result.NavigateTo.length > 0) {
                return rps.app.stateManager.goTo(result.NavigateTo).then(() => {
                    return super.processExecResult(result);
                });
            }
            else
                return super.processExecResult(result);
        }
    }

    interface EntityCreationInput {
    }
    interface EntityCreationOutput {
        Entity: rps.entities.IBaseEntity
    }
    export class EntityCreationCommand<TInput, TOutput> extends ApiActionCommand<TInput, TOutput>{
        constructor(protected commandParams: {
            target: any;
            actionName: string;
            inputParams: rps.data.parameters.QueryParams;
            outputParams: rps.data.parameters.QueryParams;
            resolve?: (value: TOutput) => void;
            reject?: (reason: any) => void;
        }) {
            super(commandParams);
        }

        /** @internal */
        processExecResult(result: any): Promise<any> {
            if (result.Entity) {
                //Puede ser que el id de la entidad principal (de la url) no coincida con el id de la entidad que viene, y eso pueda
                //dar problemas luego -> se cambia el de la entidad
                let previousId = null;
                if (this._target.model instanceof rps.entities.BaseEntity)
                    previousId = (<rps.entities.BaseEntity>this._target.model).getEntityID();

                return (<rps.viewmodels.EntityVM<rps.entities.IBaseEntity>>this._target).loadModel(result.Entity).then((vm) => {
                    if (previousId)
                        vm.model.replaceId(previousId);
                    vm.model.__isNew = true;
                    return super.processExecResult(result);
                });
            }
            else
                return super.processExecResult(result);
        }
    }

    export class ReportCommandProperty extends CommandProperty {
        /** @internal */
        private report: rps.data.Report | rps.data.UserReport;
        /** @internal */
        private advancedCriteria: string;
        constructor(params: {
            target: any;
            report: rps.data.Report | rps.data.UserReport;
            advancedCriteria?: string;
            canExecute?: (() => CanExecuteResult);

        }) {
            super({
                target: params.target,
                canExecute: params.canExecute
            });

            this.report = params.report;
            this.advancedCriteria = params.advancedCriteria;
        }

        /** @internal */
        exec(commandParameters?: any): Promise<any> {

            var service: string, reportName: string, codReport: string;
            if (this.report instanceof rps.data.Report) {
                var parts = this.report.reportName.split("/");
                service = parts[0];
                reportName = parts[1];
            }
            else if (this.report instanceof rps.data.UserReport) {
                service = "Security";
                reportName = "UserReport";
                codReport = this.report.codReport;
            }

            //Mirar si tiene que mostrar ventana de filtros (siempre si es de usuario, por ahora)
            if (this.report instanceof rps.data.UserReport) {
                //Mostrar ventana de report
                return rps.app.uiFactory.showWindow({
                    template: `<rps-window [rpsOpen]='vm.isOpen'             [rpsTitle]="vm.reportTitle"             [rpsActions]="vm.actions">     <rps-rows-group>              <rps-filters [rpsFiltersManager]="vm.filtersManager">         </rps-filters>         <rps-row>             <rps-button *ngIf="vm.reportUrl"                         rpsColumns="2"                         rpsTemplate="TEXT_WITH_ICON"                         rpsLabel="{{$root.resources.directives.REFRESH}}"                         rpsType="PRIMARY"                         rpsIcon="fa fa-refresh"                         [rpsModel]="vm.showCommand">             </rps-button>             <rps-button *ngIf="!vm.reportUrl"                         rpsColumns="2"                         rpsTemplate="TEXT_WITH_ICON"                         rpsLabel="{{$root.resources.directives.EXECUTE}}"                         rpsType="PRIMARY"                         rpsIcon="fa fa-file-pdf-o"                         [rpsModel]="vm.showCommand">             </rps-button>             <rps-button *ngIf="vm.reportUrl"                         rpsColumns="2"                         rpsTemplate="TEXT_WITH_ICON"                         rpsLabel="{{$root.resources.directives.PRINT}}"                         rpsType="DEFAULT"                         rpsIcon="fa fa-file-pdf-o"                         [rpsModel]="vm.showExternalCommand">             </rps-button>         </rps-row>         <div>             <rps-pdf [rpsData]="vm.reportUrl"></rps-pdf>         </div>     </rps-rows-group> </rps-window>`,
                    viewModel: new rps.app.viewmodels.reportFiltersVM({ codReport: this.report.codReport })
                });
            }
            else {
                this.showReportWindow(service, reportName, null, commandParameters);
                return Promise.resolve<any>(this);
            }
        }

        /** @internal */
        private showReportWindow(service: string, reportQueryName: string, filter: string, commandParameters?: any) {
            var params: string = "";
            var resolvedParams = {};

            if (this.report instanceof rps.data.Report) {
                if (this.report.inputParams || commandParameters) {
                    if (this.report.inputParams) {
                        this.report.inputParams.forEach((queryParam: rps.data.parameters.QueryParam) => {
                            var value = queryParam.getValue();
                            if (value instanceof Date)
                                value = value.toISOString();

                            resolvedParams[queryParam.paramName] = value;
                        });
                    }
                    //Machacar o completar valores con posibles parámetros pasados en execute
                    if (commandParameters) {
                        for (var param in commandParameters) {
                            if (rps.object.isFunction(commandParameters[param]))
                                resolvedParams[param] = commandParameters[param].call();
                            else
                                resolvedParams[param] = commandParameters[param];
                        }
                    }
                    for (var param in resolvedParams) {
                        if (!rps.object.isNullOrUndefined(resolvedParams[param]))
                            params += (params ? "&" : "") + param + "=" + resolvedParams[param];
                    }
                }
                if (!string.isNullOrEmpty(filter))
                    params += "&$filter=" + filter;
            }
            else if (this.report instanceof rps.data.UserReport) {
                params += "CodReport=" + this.report.codReport;
                if (!string.isNullOrEmpty(filter))
                    params += "&$filter=" + filter;
            }

            var windowRef = rps.app.appSettings.rpsAPIAddress + "api/" + service + "/query/" + reportQueryName + "?" + params;
            window.open(windowRef, "_blank");

            return Promise.resolve<any>(this);
        }
    }

    export class ExecuteQueryCommandProperty extends CommandProperty {
        /** @internal */
        private query: rps.data.sources.QuerySource | rps.data.sources.QueryMethod | rps.data.sources.HierarchicalQuerySource;

        constructor(params: {
            target: any;
            query: rps.data.sources.QuerySource | rps.data.sources.QueryMethod | rps.data.sources.HierarchicalQuerySource;
            canExecute?: (() => CanExecuteResult);

        }) {
            super({
                target: params.target,
                canExecute: params.canExecute
            });

            this.query = params.query;
        }

        /** @internal */
        _canExecute(commandParameters?: any): CanExecuteResult {
            var result = super._canExecute(commandParameters);
            if (result.result) {
                if (this.query.canCreateParams())
                    return CanExecuteResult.allow();
                else
                    return CanExecuteResult.deny([rps.app.resources.directives.FILL_REQUIRED_PARAMETERS]);
            }
            else
                return result;
        }

        /** @internal */
        exec(commandParameters?: any): Promise<any> {
            return this.query.execute();
        }
    }

    export class ExecuteActionCommandProperty extends CommandProperty {
        /** @internal */
        private action: rps.viewmodels.commands.ApiActionCommand<{}, {}>

        constructor(params: {
            target: any;
            action: rps.viewmodels.commands.ApiActionCommand<{}, {}>
            canExecute?: (() => CanExecuteResult);

        }) {
            super({
                target: params.target,
                canExecute: params.canExecute
            });
            this.action = params.action;
        }

        /** @internal */
        _canExecute(commandParameters?: any): CanExecuteResult {
            var result = super._canExecute(commandParameters);
            if (result.result)
                return this.action.canExecute(commandParameters);
            else
                return result;
        }

        /** @internal */
        protected exec(commandParameters?: any): Promise<any> {
            return this.action.exec(commandParameters);
        }
    }

    export class LinkNavigationCommandProperty extends CommandProperty {
        /** @internal */
        private link: rps.viewmodels.properties.ILinkProperty;

        constructor(params: {
            target: any;
            link: rps.viewmodels.properties.ILinkProperty;
            canExecute?: (() => CanExecuteResult);

        }) {
            super({
                target: params.target,
                canExecute: params.canExecute
            });

            this.link = params.link;
        }

        /** @internal */
        exec(commandParameters?: any): Promise<any> {
            return this.link.go(commandParameters);
        }

        /** @internal */
        _canExecute(commandParameters?: any): CanExecuteResult {
            if (!rps.object.hasValue(this.link) || !this.link.canNavigate())
                return CanExecuteResult.deny(["No link or invalid"]);
            else
                return super._canExecute(commandParameters);
        }
    }

    export class ChildNavigationCommandProperty extends CommandProperty {
        /** @internal */
        private link: rps.viewmodels.properties.LinkProperty;
        /** @internal */
        private vm: any;
        /** @internal */
        private vmFunction: Function;
        /** @internal */
        private resolve: Function;
        /** @internal */
        private reject: Function;
        /** @internal */
        private okCommand: rps.viewmodels.commands.CommandProperty;

        constructor(params: {
            target: any;
            link: rps.viewmodels.properties.LinkProperty;
            canExecute?: (() => CanExecuteResult);
            vm?: any;
            vmFunction?: Function;
            resolve?: Function;
            reject?: Function;
            okCommand: rps.viewmodels.commands.CommandProperty
        }) {
            super({
                target: params.target,
                canExecute: params.canExecute
            });

            this.link = params.link;
            this.vm = params.vm;
            this.vmFunction = params.vmFunction;
            this.resolve = params.resolve;
            this.reject = params.reject;
            this.okCommand = params.okCommand;
        }

        /** @internal */
        _execute(commandParameters?: any): Promise<{ result: rps.services.NavigationResult; data: any }> {
            return super._execute(commandParameters);
        }

        /** @internal */
        exec(commandParameters?: any): Promise<{ result: rps.services.NavigationResult, data: any }> {
            var modelPromise: Promise<any>;
            if (this.vm)
                modelPromise = Promise.resolve(this.vm);
            else if (this.vmFunction)
                modelPromise = rps.app.viewModelFactory.createViewModel(this.vmFunction);

            return modelPromise.then<{ result: rps.services.NavigationResult, data: any }>((vm) => {
                return rps.app.stateManager.navigateChild(this.link, commandParameters, vm, this.okCommand).
                    then((value: { result: rps.services.NavigationResult; data: any }) => {
                        this.resolve.call(this._target, value);
                        return value;
                    }).catch((reason) => {
                        if (this.reject)
                            this.reject.call(this._target, reason);
                        return Promise.resolve({ result: rps.services.NavigationResult.Cancel, data: null });
                    });
            }).catch((error) => {
                return Promise.resolve({ result: rps.services.NavigationResult.Cancel, data: null });
            });
        }
    }
}