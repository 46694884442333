/// <reference path="parameters.ts" />

module rps.data.sources {

    export class GetEntitySource {
        private queryName: string;
        private queryParams: parameters.QueryParams;

        constructor(params: {
            queryName: string;
            queryParams?: parameters.QueryParams;
        }) {
            this.queryName = params.queryName;
            if (params.queryParams)
                this.queryParams = params.queryParams;
        }

        initialize(): Promise<any> {
            return Promise.resolve<any>(this);
        }

        private getParams(): { [id: string]: Object; } {
            var params: { [id: string]: Object; } = {};
            if (this.queryParams) {
                this.queryParams.forEach((queryParam: parameters.QueryParam) => {
                    params[queryParam.paramName] = queryParam.getValue();
                });
            }
            return params;
        }

        get(entityID: string): Promise<any> {
            var params: { [id: string]: Object; } = this.getParams();
            params["EntityId"] = entityID;
            return rps.app.api.query({
                queryName: this.queryName,
                params: params
            }).then((result) => {
                return result;
            });
        }

        
    }

} 