module rps.data.parameters {

    export class QueryParam extends rps.viewmodels.ObservableObject {
        public paramName: string;
        public value: any;
        public variable: boolean = false;
        public isRequired: boolean = false;
        constructor(paramName: string, value: any)
        constructor(paramName: string, value: any, variable: boolean, required: boolean)
        constructor(paramName: string, value: any, variable?: boolean, required?: boolean) {
            super();
            this.paramName = paramName;
            this.value = value;
            if (rps.object.hasValue(variable))
                this.variable = variable;
            if (rps.object.hasValue(required))
                this.isRequired = required;
        }

        public getValue(): any {
            if (!rps.object.isNullOrUndefined(this.value)) {
                if (this.value instanceof rps.viewmodels.properties.VMProperty)
                    return (<rps.viewmodels.properties.VMProperty<any>>this.value).getServerParamValue();
                else if (rps.object.isFunction(this.value))
                    return this.value();
                else
                    return this.value;
            }
            return null;
        }
    }

    export class QueryParams extends rps.viewmodels.ObservableArray<QueryParam>{
        public getParamValue(paramName: string): any{
            var param = this.asEnumerable().FirstOrDefault(null,(qp) => qp.paramName === paramName);
            if (param)
                return param.getValue();
            else
                return null;
        }
    }

} 