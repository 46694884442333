/// <reference path="./observable.ts" />
/// <reference path="../utils/utils.ts" />

module rps.viewmodels {    

    export interface IConfigOptions {
        stateName: string;
        viewModelName: string;
        statePath: string;
        stateAbsoluteName: string;
        hasDialogLayout: boolean;
        isDialogComponent?: boolean;
    }

    export class BaseVM extends rps.viewmodels.ObservableObject implements IDestroyable{
        /** @internal */
        public isIDestroyable: boolean = true;

        /** @internal */
        private collapsedTogglePanel: Array<string>;
        /** @internal */
        private tabControlsActiveItemIDs: Array<{ tabControlID: string, tabControlItemID: string }> = new Array<{ tabControlID: string, tabControlItemID: string }>();

        //Propiedad que se utiliza, para ver si es la primera vez que se activa la pantalla o no
        /** @internal */
        private isFirstTime: boolean = true;

        /**
         * @hidden
         * Evento que indica que se ha modificado alguna propiedad del VM
         **/
        public propertyChanged = rps.app.eventManager.createEmitter<rps.viewmodels.properties.VMPropertyChange>();

        /** @internal */
        public isUnloaded: boolean;
        public parentVM: BaseVM;

         //HACK: Variable en la que se hace referencia a el mismo, para obtener el VM, cuando los controles de kendo envuelven el ObservableObject
        /** @internal */
        public selfVM: BaseVM;

        /** @internal */
        public static relatedState: string = "";
        public relatedState: string = "";
        /** @internal */
        protected __hasErrors = false;

        /** Url origen desde la que se ha navegado al viewmodel (url en el momento de llamar a Navigate) */
        public navigationSourceUrl: string;

        /** @internal */
        public fillStateParams(params:Object) {
        }

        /** @internal */
        public static getResolvedType(originalFunction: Function): Function {
            if (!originalFunction["fullTypeName"])
                return originalFunction;

            var target = window;
            var nameParts: Array<string> = originalFunction["fullTypeName"].split('.');
            for (var i = 0; i < nameParts.length - 1; i++) {
                if (target[nameParts[i]])
                    target = (target[nameParts[i]]);
            }
            if (target && target[nameParts[nameParts.length - 1]])
                return target[nameParts[nameParts.length - 1]];

            return originalFunction;
        }   
             
        /**
         * Método usado por los viewmodels heredados para pasar parámetros de configuración necesarios en el modelo base para que funcione de forma adecuada
         * Es el primer método llamadado tras la inyección de dependencias
         */
        protected configure(configParams?: IParams): Promise<BaseVM> {
            return this.ensureDependenciesLoaded().then(() => {
                //Inicializar de las propiedades, todas menos modelCollections
                this.configureVMProperties();
                this.configureCommands();
                this.configureWizards();

                return this;
            });
        }

        /**
         * Método usado para cargar los tipos de los que depende el VM (sus propiedades)
         */
        protected ensureDependenciesLoaded(): Promise<any> {
            return Promise.resolve<any>(this);
        }

        /**
         * Método usado por los viewModels que heredan para personalizar las propiedades
         */
        protected configureVMProperties(): void { }

        /**
         * Deprecated: De momento no se puede eliminar, ya que muchos componentes generados con plantillas antiguas, lo invocan…
         */
        protected configureProperties(): void { }

        /**
         * Método usado por los viewModels que heredan para personalizar los comandos
         */
        protected configureCommands(): void { }
        /**
         * Método usado por los viewModels que heredan para personalizar los Wizards
         */
        protected configureWizards(): void { }
        /**
         * Método usado por los viewModels que heredan para personalizar las reglas
         */
        protected configureRules(): void { }

        /** @internal */
        public get errorManager(): rps.services.IErrorManager {
            return rps.app.errorManager;
        }

        public getErrors(propertyName?: string): rps.errors.ErrorDetail[] {
            var errors = new Array<rps.errors.ErrorDetail>();
            if (propertyName && this[propertyName] && this[propertyName] instanceof rps.viewmodels.properties.VMProperty)
                (<rps.viewmodels.properties.VMProperty<any>>this[propertyName]).updateErrors().forEach((error: rps.errors.ErrorDetail) => errors.push(error));
            else {
                for (var property in this) {
                    var propertyValue = this[property];
                    if (rps.viewmodels.properties.isVMProperty(propertyValue))
                        propertyValue.updateErrors().forEach((error: rps.errors.ErrorDetail) => errors.push(error));
                }
            }

            this.__hasErrors = errors.length > 0;

            return errors;
        }

        /**
         * Se usa para inicializar el viewModel para el caso concreto de uso: inicialización de variables, definición de orígenes de datos,...
         * Llamado justo después del configure a la hora de crear el viewModel con el viewModelFactory
         */
        public initialize(initParams?: IParams): Promise<BaseVM> {
            if (initParams && initParams["parentVM"])
                this.parentVM = initParams["parentVM"];
            this.selfVM = this;

            //Se busca en la cache, para mirar si tenemos guardados datos de la última visita a la página
            var key: string = string.format("BaseVM/{0}/{1}", rps.app.session.user, this.relatedState);
            var localStorageData: {
                collapsedTogglePanel: Array<string>;                
            } = rps.app.localStorage.get(key);
            if (localStorageData && localStorageData.collapsedTogglePanel)
                this.collapsedTogglePanel = localStorageData.collapsedTogglePanel;
            else
                this.collapsedTogglePanel = new Array<string>();

            return Promise.resolve<BaseVM>(this).then(() => {
                this.configureRules();

                //Se guardan las reglas en cada VMProperty, para mejorar rendimiento a la hora de invocar
                this.rules.forEach((rule) => {
                    if (rule.TriggerField)
                        rule.TriggerField.rules.push(rule);
                });

                return this;
            });
        }

        /** @rpsInternal (for framework use only) */
        public setStateParameter(stateParameters: IParams): void { }
        
        /** @rpsInternal (for framework use only) */
        public setStateArguments(stateArguments: IParams): void { }

        /**
         * Su función principal, es mostrar el valor en el Breadbrumb, aunque también se muestra en algunas pantallas
         */
        /** @internal */
        public title: rps.viewmodels.properties.VMProperty<string>;
        /** @internal */
        public setTitle(newTitle: any){
            if (newTitle instanceof rps.viewmodels.properties.VMProperty)
                this.title = newTitle;
            else
                this.title = new rps.viewmodels.properties.VMProperty({ target: this, initialValue: newTitle });            
        }

        /**
         * Reglas de UI que se aplicarán al viewmodel
         */
        /** @internal */
        private rules: rules.Rule[] = [];

        /**
         * Habilita o deshabilita el procesado automático de las reglas en los cambios de propiedad
         */
        /** @internal */
        public rulesEnabled: boolean = false;
        
        /**
         * Procesa las reglas de UI asociadas al viewmodel
         */
        /** @internal */
        public processRules(): Promise<any>
        /** @internal */
        public processRules(trigger: rules.Triggers): Promise<any>
        /** @internal */
        public processRules(trigger: rules.Triggers, field: rps.viewmodels.properties.VMProperty<any> | rps.viewmodels.commands.CommandProperty): Promise<any>
        /** @internal */
        public processRules(trigger?: rules.Triggers, field?: rps.viewmodels.properties.VMProperty<any> | rps.viewmodels.commands.CommandProperty): Promise<any> {
            if (!this.rules)
                return Promise.resolve(this);
            var rulesToExecute = new Array<Promise<any>>();
            this.rules.forEach((rule) => {
                //TODO: Si la regla no tiene trigger, se evalua siempre, quizás se deberían añadir watches para evaluar...
                if (!rule.Trigger)
                    rulesToExecute.push(rule.execute());
                else if (trigger && field && rule.Trigger == trigger && rule.TriggerField == field)
                    rulesToExecute.push(rule.execute());
                else if (trigger && !field && rule.Trigger == trigger)
                    rulesToExecute.push(rule.execute());
                else if (!trigger && !field)
                    rulesToExecute.push(rule.execute());
            });
            return Promise.all(rulesToExecute);
        }

        /** @internal */
        public processCancellableRules(trigger: rules.Triggers): Promise<rules.RuleExecutionResult>
        /** @internal */
        public processCancellableRules(trigger: rules.Triggers, field: rps.viewmodels.properties.VMProperty<any>): Promise<rules.RuleExecutionResult>
        /** @internal */
        public processCancellableRules(trigger: rules.Triggers, field?: rps.viewmodels.properties.VMProperty<any>): Promise<rules.RuleExecutionResult> {

            this.rules.forEach((rule) => {
                rule.triggerCancelled = false;
                rule.triggerCancelledParams.reasons.length = 0;
            });

            var processableRules = Enumerable.From(this.rules).Where((rule) => rule.Trigger === trigger && (!field || rule.TriggerField === field)).ToArray();
            if (processableRules.length > 0)
                return processableRules.reduce((prevPromise, rule) => {
                    return prevPromise.then((result: rules.RuleExecutionResult) => {
                        if (result.value === rules.RuleExecutionValue.Continue)
                            return rule.execute();
                        else
                            return Promise.resolve<rules.RuleExecutionResult>({ value: rules.RuleExecutionValue.Cancel, reasons: result.reasons.slice(0) });
                    })
                }, Promise.resolve<rules.RuleExecutionResult>({ value: rules.RuleExecutionValue.Continue }));
            else
                return Promise.resolve<rules.RuleExecutionResult>({ value: rules.RuleExecutionValue.Continue });
        }

        /**
         * Añadir una nueva regla
         * @param idRule
         * @example
         * ``` typescript
         *
         *  this.addRule("MyRule").onActivate().
         *      when().conditionGroup().equals(this.field1, 5).and().lessThan(this.field2, 10).
         *      then().lockField(this.fieldToLock);
         * ```
         */
        public addRule(idRule?: string): rules.ITriggers<any> {
            var newRule = new rules.Rule(idRule, this);
            this.rules.push(newRule);
            return newRule;
        }

        /**
         * @hidden
         * @param stateParams
         */
        /** @internal */
        public resolveStateViewModel(stateParams: rps.services.IStateParams): Promise<BaseVM | rps.errors.ErrorDetail > {
            return new Promise((resolve, reject) => {
                if (stateParams.stateName === this.relatedState) {
                    //Cuando la entidad no es nueva, se deben lanzar las reglas de OnNavigate al resolver
                    if (stateParams.stateParams && this["idPropertyName"] && stateParams.stateParams[this["idPropertyName"]] != rps.viewmodels.EntityVM.NEW_ID_VALUE) {
                        this.processRules(rules.Triggers.OnNavigate);
                    }

                    resolve(this);
                }
                else {
                    var results = new Array<Promise<BaseVM>>();
                    for (var property in this) {
                        if ((<string>property).charAt(0) !== "_") {
                            var propertyValue = this[property];
                            if (properties.isEntityVMCollectionProperty(propertyValue)) {
                                var entityVMCollection: EntityVMCollection<any> = propertyValue.value;
                                if (entityVMCollection.allowResolveStateViewModel(stateParams)) {
                                    results.push(propertyValue.value.resolveStateViewModel(stateParams));
                                }
                            }
                        }
                    }

                    Promise.all(results).then((findResults) => {
                        if (findResults.length == 0) {
                            resolve(null);
                            return;
                        }

                        //Si hay alguno que no sea error, se devuelve ese
                        for (var i = 0; i < findResults.length; i++) {
                            if (!(findResults[i] instanceof rps.errors.ErrorDetail)) {
                                resolve(findResults[i]);
                                return;
                            }
                        }

                        //Hay alguno con error
                        reject(findResults[0]);

                    }).catch((errors) => {
                        reject(errors);
                    });                    
                }
            });
        }

        public navigate(stateParams: IParams): Promise<BaseVM> {
            return Promise.resolve(this);      
        }

        /**
         * Salta una vez que se ha realizado la transición de URL de /new a /ID (útil para reemplazar al navigate en los casos de navegación al /new)
         * @param stateParams Los parámetros de navegación adicionales que se hayan usado para navegar al /new
         */
        public onReplaceNew(stateParams: IParams) {
        }

        onBeforeUnload(): Promise<boolean> {            
            //Se guarda en la cache, la las propiedades referentes a UI, para mostrarlo de la misma manera la siguiente vez que se entre
            var key: string = string.format("BaseVM/{0}/{1}", rps.app.session.user, this.relatedState);
            var localStorageData: {
                collapsedTogglePanel: Array<string>;
            } = {
                    collapsedTogglePanel: this.collapsedTogglePanel
                };
            rps.app.localStorage.add(key, localStorageData);

            //Se recorren las propiedades, para localizar las que quieren hacer algo al descargar y se invocan
            var promises: Array<Promise<any>> = new Array<Promise<any>>();
            for (var prop in this) {
                var propertyValue = this[prop];
                if (isIOnBeforeUnload(propertyValue))
                    promises.push(propertyValue.onBeforeUnload());
            }

            return Promise.all(promises).then(() => {
                this.isUnloaded = true;
                return true;
            });
        }

        /** @internal */
        private isDestroyed = false;
        public onDestroy() {
            if (!this.isDestroyed) {
                this.isDestroyed = true;

                for (var prop in this) {
                    var propertyValue = this[prop];
                    if (prop != "parentVM" && isIDestroyable(propertyValue)) {
                        propertyValue.onDestroy();
                    }
                }
            }
        }

        /**
         * Método sobreescribible que salta cuando se activa un viewModel en concreto (esto es, que el estado activo es el estado correspondiente a ese viewModel).
         Salta tanto la primera vez que se crea, como cuando se vuelve activo por volver de otro estado hijo suyo.
            - NO Salta cuando se cierra una ventana modal con el mismo viewmodel, SI cuando la modal es de otro viewmodel
            - Salta en toda la jerarquía cuando navegas directamente a un hijo
            - Al navegar atrás también, aunque estén cacheadas, vuelve a lanzarse en toda la jerarquía si vuelves de otro componente
            - Salta en el padre la siguiente vez que navegues a él desde un hijo, aunque se haya activado al entrar al hijo
         */
        onActivate(): void {
            //console.log("Activate " + rps.extensions.functionName(this) +( this.relatedState ? " state: " + this.relatedState : ""  ));

            for (var prop in this) {
                var propertyValue = this[prop];
                if (isIActivable(propertyValue))
                    propertyValue.onActivate(this.isFirstTime);
            }

            this.isFirstTime = false;
        }

        public reset(): void {
            for (var property in this) {
                var propertyValue = this[property];
                if (rps.viewmodels.properties.isVMProperty(propertyValue))
                    propertyValue.reset();
            }
        }        

        public validate(): void {            
            for (var property in this) {
                var propertyValue = this[property];
                if (rps.viewmodels.properties.isVMProperty(propertyValue))
                    propertyValue.validate();
            }
        }

        /**
         * Aplica el orden (si existe) a las propiedades de tipo colección (recursivamente)
         */
        public sort(): void {
            for (var property in this) {
                var propertyValue = this[property];
                if (rps.viewmodels.properties.isEntityVMCollectionProperty(propertyValue))
                    propertyValue.sort();
            }
        }      

        /**
        * Carga el estado del VM del SessionStorage
        */
        /** @internal */
        loadState(initParams?: rps.IParams): Promise<any> {
            var _state;
            if (initParams)
                _state = initParams["_state"];
            return Promise.resolve<any>(this);
        }

        /**
         * Sobreescribir para añadir rutas adicionales al componente relacionado al view model
         */
        /** @internal */
        getCustomRoutes(): Array<{ name: string, path: string, loader: () => any, useAsDefault?:boolean}> {
            return [
            ];
        }

        /** @internal */
        redirectToRoute(): Array<any> {
            return null;
        }

        public setLocked(value: boolean, reason?: string) {
            this.isLocked = value;            
            this.isLockedReason = reason;

            for (var property in this) {
                var propertyValue = this[property];
                if (rps.viewmodels.properties.isVMProperty(propertyValue) && propertyValue.isModelProperty)
                    propertyValue.setVMLocked(value);
            }
        }

        /** @internal */
        private _isLockedReason: string = "";
        /** @internal */
        get isLockedReason(): string {
            return this._isLockedReason;
        }
        /** @internal */
        set isLockedReason(newValue: string) {
            this._isLockedReason = newValue;
        }  

        /** @internal */
        protected _isLocked: boolean = false;
        /** @internal */
        get isLocked(): boolean {
            return this._isLocked || this._isParentLocked;
        }
        /** @internal */
        set isLocked(newValue: boolean) {
            if (newValue != this._isLocked) {
                this._isLocked = newValue;
                if (!this._isLocked)
                    this.isLockedReason = null;
                this.propertyChanged.emit({ propertyName: "isLocked", newValue: newValue });
            }
        }  

        /** @internal */
        protected _isParentLocked: boolean = false;
        /** @internal */
        public setParentLocked(value: boolean) {
            if (this._isParentLocked != value) {
                this._isParentLocked = value;
                this.propertyChanged.emit({ propertyName: "isLocked", newValue: this.isLocked });

                for (var property in this) {
                    var propertyValue = this[property];
                    if (rps.viewmodels.properties.isVMProperty(propertyValue) && propertyValue.isModelProperty)
                        propertyValue.setVMLocked(this.isLocked);
                }
            }
        }

        /**
         * Sobreescribir para ejecutar alguna acción al validar el formulario
         */
        public onSubmit() {
        }

        /**
         * Indica si el grupo está colapsado o no
         * @param togglePanelID ID del grupo
         */
        /** @internal */
        public getTogglePanelIsCollapsed(togglePanelID: string): boolean {
            if (this.collapsedTogglePanel.indexOf(togglePanelID) != -1)
                return true;
            else
                return false;
        }
        /**
         * Cambiar el estado del grupo
         * @param togglePanelID
         * @param collapsed
         */
        /** @internal */
        public setTogglePanelIsCollapsed(togglePanelID: string, collapsed: boolean) {
            if (collapsed && !this.getTogglePanelIsCollapsed(togglePanelID))
                this.collapsedTogglePanel.push(togglePanelID);
            else if (!collapsed && this.getTogglePanelIsCollapsed(togglePanelID))
                this.collapsedTogglePanel.splice(this.collapsedTogglePanel.indexOf(togglePanelID), 1);
        }

        /**
         * Indica la pestaña activa de cada tabControl
         * @param tabControlID ID del tab
         */
        /** @internal */
        public getTabControlActiveItemID(tabControlID: string): string {
            if (Enumerable.From<{ tabControlID: string, tabControlItemID: string }>(this.tabControlsActiveItemIDs).Any(item => item.tabControlID == tabControlID))
                return Enumerable.From<{ tabControlID: string, tabControlItemID: string }>(this.tabControlsActiveItemIDs).First(item => item.tabControlID == tabControlID).tabControlItemID;
            else
                return null;
        }
        /**
         * Cambia la pestaña activa del tabControl
         * @param tabControlID
         * @param tabControlItemID
         */
        /** @internal */
        public setTabControlActiveItemID(tabControlID: string, tabControlItemID) {
            if (Enumerable.From<{ tabControlID: string, tabControlItemID: string }>(this.tabControlsActiveItemIDs).Any(item => item.tabControlID == tabControlID))
                Enumerable.From<{ tabControlID: string, tabControlItemID: string }>(this.tabControlsActiveItemIDs).First(item => item.tabControlID == tabControlID).tabControlItemID = tabControlItemID;
            else
                this.tabControlsActiveItemIDs.push({ tabControlID: tabControlID, tabControlItemID: tabControlItemID});
        }
    }
} 