/// <reference path="../viewmodels/baseVM.ts" />
module rps.app.viewmodels {

    export class UIMenuItemGroup extends rps.viewmodels.BaseVM {
        public items: Array<UIMenuItem> = new Array<UIMenuItem>();
        constructor(public ID: string, public description: string, public imagePath: string) {
            super();
        }
    }

    export class UIMenuItem extends rps.viewmodels.BaseVM {
        constructor(public ID, public description: string, public imagePath: string, public isFavourite: boolean, public itemType:string) {
            super();
        }
    }

    export class UIMenuItemComponent extends UIMenuItem {
        constructor(public ID, public description: string, public imagePath: string, public link: string, public isFavourite: boolean) {
            super(ID, description, imagePath, isFavourite, "component");
        }

        private _linkParams: Array<any>;
        public get linkParams(): Array<any> {
            if (!this._linkParams) {
                var params = new Array<any>();
                var parts = this.link.split('.');
                params.push('app');
                params.push(rps.app.stateManager.getCurrentCompany())
                params.push(parts[0].toLowerCase() + "." + parts[1].toLowerCase());

                this._linkParams = params;
            }
            return this._linkParams;
        }
    }

    export class UIMenuItemWorkflow extends UIMenuItem {
        constructor(public ID, public description: string, public imagePath: string, public idWorkflow: string, public isFavourite: boolean) {
            super(ID, description, imagePath, isFavourite, "workflow");
        }

        public startWorkflow(): void {
            rps.app.api.invokeAction("Workflow/RunWorkflowManual", { "IDWorkflow": this.idWorkflow, "CodUser": rps.app.session.user }).then((ar: any) => {
                rps.app.notificationManager.show({
                    message: "The workflow was successfully launched",
                    notificationType: rps.services.NotificationType.Success
                });
                if (ar.NextTaskLink) {
                    return rps.app.stateManager.goTo(ar.NextTaskLink);
                }
            });
        }
    }

    export class UIMenuItemReport extends UIMenuItem {
        private reportCommand: rps.viewmodels.commands.ReportCommandProperty;
        constructor(public ID, public description: string, public imagePath: string, public codReport: string, public isFavourite: boolean) {
            super(ID, description, imagePath, isFavourite, "report");
            this.reportCommand = new rps.viewmodels.commands.ReportCommandProperty({
                target: this,
                report: new rps.data.UserReport({ codReport: codReport })
            });
        }

        public showReport(): void {
            this.reportCommand.exec();
        }
    }

    export class UIMenu extends rps.viewmodels.BaseVM {
        public modules: Array<UIModule> = new Array<UIModule>();
        public favourites: Array<UIMenuItemGroup> = new Array<UIMenuItemGroup>();

        private _selectedModule: UIModule;
        get selectedModule(): UIModule {
            return this._selectedModule;
        }

        set selectedModule(newValue: UIModule) {
            this._selectedModule = newValue;
        }
    }

    export class UIModule extends rps.viewmodels.BaseVM {
        public menuGroups: Array<UIMenuItemGroup> = new Array<UIMenuItemGroup>();

        constructor(public ID: string, public description: string, public imagePath: string) {
            super();
        }

    }
}
