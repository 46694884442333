
module rps.app {
    export var session: rps.services.ISession;
    export var stateManager: rps.services.IStateManager;
    export var api: rps.services.IApi;
    export var apiRef: rps.services.IApiRef;
    export var clientAPI: rps.services.IClientAPI;
    export var messageManager: rps.services.IMessageManager;
    export var errorManager: rps.services.IErrorManager;
    export var notificationManager: rps.services.INotificationManager;
    export var entityFactory: rps.services.IEntityFactory;
    export var viewModelFactory: rps.services.IViewModelFactory;
    export var viewFactory: rps.services.IViewFactory;
    export var uiFactory: rps.services.IUIFactory;
    export var resourceManager: rps.services.IResourceManager;
    export var eventManager: rps.services.IEventManager;
    export var appSettings: rps.services.IAppSettings;
    export var entityManager: rps.services.IEntityManager;
    export var processResultManager: rps.services.IProcessResultManager;
    export var busyIndicatorManager: rps.services.IBusyIndicatorManager;
        /** @internal */
    export var currencyResolver: rps.services.ICurrencyResolver;
    /** @internal */
    export var quantityUnitResolver: rps.services.IQuantityUnitResolver;
    /** @internal */
    export var priceUnitResolver: rps.services.IPriceUnitResolver;
        /** @internal */
    export var durationResolver: rps.services.IDurationResolver;
    export var sessionStorage: rps.services.IStorage;
    export var localStorage: rps.services.IStorage;
    export var userInputManager: rps.services.IUserInputManager;

    export var rpsComponent: any;
}

module rps.utils {
    export function roundAmount(amount: number, decimals: number): number {
        return roundToDecimals(amount, decimals);
    }

    export function roundToDecimals(value: number, decimals: number): number {
        if (typeof decimals === 'undefined' || +decimals === 0)
            return Math.round(value);

        value = +value;
        decimals = +decimals;

        if (isNaN(value) || !(typeof decimals === 'number' && decimals % 1 === 0))
            return NaN;

        // Shift
        var newValue = value.toString().split('e');
        value = Math.round(+(newValue[0] + 'e' + (newValue[1] ? (+newValue[1] + decimals) : decimals)));

        // Shift back
        newValue = value.toString().split('e');
        return +(newValue[0] + 'e' + (newValue[1] ? (+newValue[1] - decimals) : -decimals));
    }

    export function roundToPriceDecimals(codCompany: string, price: number): number {
        if (!codCompany)
            codCompany = rps.app.session.company;

        return roundToDecimals(price, rps.app.session.getCompanySessionData(codCompany).PriceDecimalDigits);
    }

    export function roundToAmountDecimals(codCompany: string, price: number): number {
        if (!codCompany)
            codCompany = rps.app.session.company;

        return roundToDecimals(price, rps.app.session.getCompanySessionData(codCompany).AmountDecimalDigits);
    }

    export function convertHourToSeconds(date: Date): number {
        return date.getSeconds() + (60 * date.getMinutes()) + (3600 * date.getHours());
    }

    export function validFactor(factor: string): boolean {
        if (!factor) {
            return false;
        }
        else {
            var mo: rps.enums.MultiplyOperator = rps.enums.MultiplyOperator.Multiply;
            var so: rps.enums.SumOperator = rps.enums.SumOperator.Sum;
            var sf: string = "";
            var dk: number = 0;
            var rk: rps.enums.RoundingType = rps.enums.RoundingType.Normal;
            var ik: number = 0;

            return validateFactorAndExtractParameters(factor, mo, dk, so, sf, rk, ik);
        }
    }

    function validateFactorAndExtractParameters(factor: string, MultiplyOperator: rps.enums.MultiplyOperator, MultiplyFactor: number,
        SumOperator: rps.enums.SumOperator, SumFactor: string, RoundType: rps.enums.RoundingType, DecimalDigit: number): boolean {

        //sFactor será el string “1” o cualquier cadena del tipo indicado abajo. Si no se ajusta al formato ERROR
        //•	RoundType(MultiplyOperator; Number1; SumOperator; Number2; nDecimalDigit) donde:
        //o	RoundType puede ser ROUND, ROUNDU, ROUNDD, ROUNDW
        //o	MultiplyOperator: “*” o “/”
        //o	SumOperator: “+” ó “-“
        //o	Number1 (MultiplyFactor) puede ser cualquier número
        //o	Number2 (SumFactor) puede ser cualquier número o la palabra reservada “SECOND_UNIT”

        if (factor == "1") {
            MultiplyOperator = rps.enums.MultiplyOperator.Multiply;
            MultiplyFactor = 1;
            SumFactor = "0";
            SumOperator = rps.enums.SumOperator.Sum;
            RoundType = rps.enums.RoundingType.None;
            DecimalDigit = 0;

            return true;
        }
        else {
            //Comprobar que Factor tiene el formato correcto; Mirar el tipo de redondeo ROUND, ROUNDU, ROUNDD ó ROUNDW
            var indexParentesisOpen: number = factor.indexOf('(');
            var indexParentesisClose: number = factor.indexOf(')');
            if (indexParentesisOpen == -1 || indexParentesisClose == -1)
                return false;
            else {
                var param: string = factor.substr(indexParentesisOpen + 1, indexParentesisClose - indexParentesisOpen - 1);
                var args = param.split(";");
                if (args.length != 5)
                    return false;
                else {
                    //•	RoundType(MultiplyOperator; Number1; SumOperator; Number2; nDecimalDigit) donde:
                    if (args[0] == "*")
                        MultiplyOperator = rps.enums.MultiplyOperator.Multiply;
                    else
                        MultiplyOperator = rps.enums.MultiplyOperator.Divide;
                    if (args[2] == "+")
                        SumOperator = rps.enums.SumOperator.Sum;
                    else
                        SumOperator = rps.enums.SumOperator.Substract;
                    SumFactor = args[3];
                    var dSumFactor: number = 0;
                    MultiplyFactor = 0;
                    DecimalDigit = 0;

                    //if (!decimal.TryParse(args[1], System.Globalization.NumberStyles.Number, CultureInfo.InvariantCulture, out MultiplyFactor) || !int.TryParse(args[4], System.Globalization.NumberStyles.Number, CultureInfo.InvariantCulture, out DecimalDigit)
                    //    || !(SumFactor.toUpperCase() == "SECOND_UNIT" || SumFactor.toUpperCase().includes("SECOND_UNIT") || decimal.TryParse(SumFactor, System.Globalization.NumberStyles.Number, CultureInfo.InvariantCulture, out dSumFactor)))

                    if (isNaN(parseFloat(args[1])) || isNaN(parseInt(args[4])) ||
                        !(SumFactor.toUpperCase() == "SECOND_UNIT" || SumFactor.toUpperCase().includes("SECOND_UNIT") ||
                            !isNaN(parseFloat(SumFactor))))
                        return false;
                    else {


                        if (SumFactor.includes(",")) {
                            return false;
                        }

                        if (DecimalDigit < 0 || (MultiplyOperator == rps.enums.MultiplyOperator.Divide && MultiplyFactor == 0))
                            return false;
                        else
                            switch (factor.substring(0, indexParentesisOpen).toUpperCase()) {
                                case "ROUND":
                                    {
                                        RoundType = rps.enums.RoundingType.Normal;
                                        return true;
                                    }
                                case "ROUNDU":
                                    {
                                        RoundType = rps.enums.RoundingType.Up;
                                        return true;
                                    }
                                case "ROUNDD":
                                    {
                                        RoundType = rps.enums.RoundingType.Down;
                                        return true;
                                    }
                                case "ROUNDW":
                                    {
                                        RoundType = rps.enums.RoundingType.None;
                                        return true;
                                    }
                                default:
                                    return false;
                            }
                    }
                }
            }
        }
    }

    export function applyDiscountToPrice(Price: number, Discount1: number, CascadeDiscount1: boolean,
        Discount2: number, CascadeDiscount2: boolean, Discount3: number, CascadeDiscount3: boolean,
        Discount4: number, CascadeDiscount4: boolean, Discount5: number, CascadeDiscount5: boolean,
        AmountDiscount: number, PositionAmountDiscount: rps.enums.ePositionAmountDiscount): number {

        var priceAux: number = Price;

        //Descuento 1
        if (AmountDiscount != 0 && PositionAmountDiscount == rps.enums.ePositionAmountDiscount.FirstDiscount)
            priceAux = priceAux - AmountDiscount;

        if (Discount1 != 0) {
            if (CascadeDiscount1)
                priceAux = priceAux - (priceAux * (Discount1 / 100));
            else
                priceAux = priceAux - (Price * (Discount1 / 100));
        }

        //Descuento 2
        if (AmountDiscount != 0 && PositionAmountDiscount == rps.enums.ePositionAmountDiscount.AfterDiscount1)
            priceAux = priceAux - AmountDiscount;

        if (Discount2 != 0) {
            if (CascadeDiscount2)
                priceAux = priceAux - (priceAux * (Discount2 / 100));
            else
                priceAux = priceAux - (Price * (Discount2 / 100));
        }

        //Descuento 3
        if (AmountDiscount != 0 && PositionAmountDiscount == rps.enums.ePositionAmountDiscount.AfterDiscount2)
            priceAux = priceAux - AmountDiscount;

        if (Discount3 != 0) {
            if (CascadeDiscount3)
                priceAux = priceAux - (priceAux * (Discount3 / 100));
            else
                priceAux = priceAux - (Price * (Discount3 / 100));
        }

        //Descuento 4
        if (AmountDiscount != 0 && PositionAmountDiscount == rps.enums.ePositionAmountDiscount.AfterDiscount3)
            priceAux = priceAux - AmountDiscount;

        if (Discount4 != 0) {
            if (CascadeDiscount4)
                priceAux = priceAux - (priceAux * (Discount4 / 100));
            else
                priceAux = priceAux - (Price * (Discount4 / 100));
        }

        //Descuento 5
        if (AmountDiscount != 0 && PositionAmountDiscount == rps.enums.ePositionAmountDiscount.AfterDiscount4)
            priceAux = priceAux - AmountDiscount;

        if (Discount5 != 0) {
            if (CascadeDiscount5)
                priceAux = priceAux - (priceAux * (Discount5 / 100));
            else
                priceAux = priceAux - (Price * (Discount5 / 100));
        }
        if (AmountDiscount != 0 && PositionAmountDiscount == rps.enums.ePositionAmountDiscount.LastDiscount)
            priceAux = priceAux - AmountDiscount;

        return priceAux;
    }

    export function checkFormulaSecondUnit(sSecondUnitFormula: string): boolean {
        if (!sSecondUnitFormula)
            return false;

        //Regex regexAM = new Regex("SECOND_UNIT", RegexOptions.IgnoreCase);
        //sFormula = regexAM.Replace(sFormula, "1", 1);
        var sFormula = sSecondUnitFormula.replace(/SECOND_UNIT/i, "1");

        return decimalFormulaValidate(sFormula);
    }

    export function decimalFormulaValidate(strFormula: string): boolean {
        try {
            var d: number = decimalFormulaEvaluate(strFormula);
            return true;
        }
        catch (e) {
            return false;
        }
    }

    export function decimalFormulaEvaluate(strFormula: string): number {
        try {
            //TODO Igor Mendi
            alert("TODO decimal formula evaluate: pendiente de llamar a un querymethod o algo para que evalue, igual que en SL");
            //IFormulaEvaluator evaluatorBL = BusinessLogicConfiguration.Create<IFormulaEvaluator>();
            //return evaluatorBL.DecimalFormulaEvaluate(strFormula); 
            return 0;
        }
        catch (e) {
            throw e;
        }
    }

    export function validateVariableData(entity: rps.entities.BaseEntity, errDetails: any[]) {
        var errorType = "";
        var idTable = "";


        switch (rps.object.getTypeName(entity).toUpperCase()) {
            case "DOCUMENTLABEL":
                idTable = rps.object.isNullOrUndefined(entity["IDDocumentVariableTable"]) ? "" : entity["IDDocumentVariableTable"].toString();
                break;
            case "FEATURELABEL":
                idTable = rps.object.isNullOrUndefined(entity["IDPROFeatureVariableTable"]) ? "" : entity["IDPROFeatureVariableTable"].toString();
                break;
            case "FEATURELABELSTR":
                idTable = rps.object.isNullOrUndefined(entity["IDFeatureVariableTable"]) ? "" : entity["IDFeatureVariableTable"].toString();
                break;
            default:
                idTable = rps.object.isNullOrUndefined(entity["IDTable"]) ? "" : entity["IDTable"].toString();
                break;
        }

        //Si no está informado el campo IDTable, es decir, los Valuees no pertenecen a una lista de posibles datos
        if (idTable == null || idTable == "") {
            var iDataType: number = entity["DataType"];

            var sMin: string = rps.object.isNullOrUndefined(entity["Min"]) ? "" : entity["Min"].toString();
            var sMax: string = rps.object.isNullOrUndefined(entity["Max"]) ? "" : entity["Max"].toString();
            var sDefaultValue: string = rps.object.isNullOrUndefined(entity["DefaultValue"]) ? "" : entity["DefaultValue"].toString();

            //enum eDataType: Integer = 1,Decimal = 2,Date = 3,String = 4,
            switch (iDataType) {
                case 1:     // Value entero

                    //Comprobar que Min,Max y DefaultValue tengan formato correcto
                    if (sMin != "") {
                        //Si tiene una ',' o un '.' genera excepción
                        if (sMin.indexOf(',') > 0 || sMin.indexOf('.') > 0)
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_INCORRECT_FORMAT.format("Min");
                        //Mira si es un entero
                        if (errorType == "" && !sMin.isNumeric())
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DATATYPE.format("Min");
                    }
                    if (errorType == "" && sMax != "") {
                        //Si tiene una ',' o un '.' genera excepción
                        if (sMax.indexOf(',') > 0 || sMax.indexOf('.') > 0)
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_INCORRECT_FORMAT.format("Max");
                        //Mira si es un entero
                        if (errorType == "" && !sMax.isNumeric())
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DATATYPE.format("Max");
                    }
                    if (errorType == "" && sDefaultValue != "") {
                        //Si tiene una ',' o un '.' genera excepción
                        if (sDefaultValue.indexOf(',') > 0 || sDefaultValue.indexOf('.') > 0)
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_INCORRECT_FORMAT.format("DefaultValue");
                        //Mira si es un entero
                        if (errorType == "" && !sDefaultValue.isNumeric())
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DATATYPE.format("DefaultValue");
                    }

                    if (errorType == "" && sMin != "" && sMax != "") {
                        if (parseInt(sMin) > parseInt(sMax))
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_LOWER;
                    }

                    if (errorType == "" && sMin != "" && sDefaultValue != "") {
                        if (parseInt(sMin) > parseInt(sDefaultValue))
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DEFAULT;
                    }

                    if (errorType == "" && sMax != "" && sDefaultValue != "") {
                        if (parseInt(sMax) < parseInt(sDefaultValue))
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DEFAULT;
                    }

                    break;

                case 2:     //Decimal
                    var decValue: number;

                    //Comprobar que Min,Max y DefaultValue tengan formato correcto
                    if (sMin != "") {
                        //Si tiene una ',' genera excepción
                        if (sMin.indexOf(',') > 0)
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_INCORRECT_FORMAT.format("Min");
                        //Mira si es un decimal
                        if (errorType == "" && !sMin.isNumeric())
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DATATYPE.format("Min");
                    }
                    if (errorType == "" && sMax != "") {
                        //Si tiene una ',' genera excepción
                        if (sMax.indexOf(',') > 0)
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_INCORRECT_FORMAT.format("Max");
                        //Mira si es un decimal
                        if (errorType == "" && !sMax.isNumeric())
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DATATYPE.format("Max");
                    }
                    if (errorType == "" && sDefaultValue != "") {
                        //Si tiene una ',' genera excepción
                        if (sDefaultValue.indexOf(',') > 0)
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_INCORRECT_FORMAT.format("DefaultValue");
                        //Mira si es un decimal
                        if (errorType == "" && !sDefaultValue.isNumeric())
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DATATYPE.format("DefaultValue");
                    }

                    if (errorType == "" && sMin != "" && sMax != "") {
                        if (parseFloat(sMin) > parseFloat(sMax))
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_LOWER;
                    }

                    if (errorType == "" && sMin != "" && sDefaultValue != "") {
                        if (parseFloat(sMin) > parseFloat(sDefaultValue))
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DEFAULT;
                    }

                    if (errorType == "" && sMax != "" && sDefaultValue != "") {
                        if (parseFloat(sMax) < parseFloat(sDefaultValue))
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DEFAULT;
                    }

                    break;

                case 3:     //DateTime
                    var dValue: Date;

                    //Comprobar que Min,Max y DefaultValue tengan formato correcto
                    if (sMin != "") {
                        //Mira si es un dateTime
                        if (errorType == "" && !sMin.isDate())
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DATATYPE.format("Min");
                    }
                    if (errorType == "" && sMax != "") {
                        //Mira si es un dateTime
                        if (errorType == "" && !sMax.isDate())
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DATATYPE.format("Max");
                    }
                    if (errorType == "" && sDefaultValue != "") {
                        //Mira si es un dateTime
                        if (errorType == "" && !sDefaultValue.isDate())
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DATATYPE.format("DefaultValue");
                    }

                    if (errorType == "" && sMin != "" && sMax != "") {
                        if (new Date(sMin) > new Date(sMax))
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_LOWER;
                    }

                    if (errorType == "" && sMin != "" && sDefaultValue != "") {
                        if (new Date(sMin) > new Date(sDefaultValue))
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DEFAULT;
                    }

                    if (errorType == "" && sMax != "" && sDefaultValue != "") {
                        if (new Date(sMax) < new Date(sDefaultValue))
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DEFAULT;
                    }

                    break;

                default:     //String
                    if (errorType == "" && sMin != "" && sMax != "") {
                        if (sMin > sMax)
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_LOWER;
                    }

                    if (errorType == "" && sMin != "" && sDefaultValue != "") {
                        if (sMin > sDefaultValue)
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DEFAULT;
                    }

                    if (errorType == "" && sMax != "" && sDefaultValue != "") {
                        if (sMax < sDefaultValue)
                            errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_DEFAULT;
                    }

                    break;
            }

            if (errorType != "")
                entity.addErrors([{ EntityPath: "", ErrorCode: "", ErrorDescription: errorType, Property: "" }]);
        }
    }

    export function validateVariableValue(entity: rps.entities.BaseEntity, sValue: string, errDetails: any[]) {
        var errorType = "";
        var bObligatory = false;

        if (rps.object.isDefined(entity["Obligatory"])) {
            bObligatory = entity["Obligatory"];
        }

        var sDesription: string = entity["Description"];
        if (bObligatory == true && sValue == null) {
            entity.addErrors([{ EntityPath: "", ErrorCode: "", ErrorDescription: rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_OBLIGATORYNOTEXIST, Property: "" }]);
            return;
        }

        if (string.isNullOrEmpty(sValue) && bObligatory == false) {
            return;
        }

        var idTable = "";

        switch (rps.object.getTypeName(entity).toUpperCase()) {
            case "DOCUMENTLABEL":
                idTable = rps.object.isNullOrUndefined(entity["IDDocumentVariableTable"]) ? "" : entity["IDDocumentVariableTable"].toString();
                break;
            case "FEATURELABEL":
                idTable = rps.object.isNullOrUndefined(entity["IDPROFeatureVariableTable"]) ? "" : entity["IDPROFeatureVariableTable"].toString();
                break;
            case "FEATURELABELSTR":
                idTable = rps.object.isNullOrUndefined(entity["IDFeatureVariableTable"]) ? "" : entity["IDFeatureVariableTable"].toString();
                break;
            default:
                idTable = rps.object.isNullOrUndefined(entity["IDTable"]) ? "" : entity["IDTable"].toString();
                break;
        }

        //Si no está informado el campo IDTable, es decir, los Valuees no pertenecen a una lista de posibles datos

        if (idTable == null || idTable == "") {
            var iDataType: number = entity["DataType"];

            var sMin: string = rps.object.isNullOrUndefined(entity["Min"]) ? "" : entity["Min"].toString();
            var sMax: string = rps.object.isNullOrUndefined(entity["Max"]) ? "" : entity["Max"].toString();

            //enum eDataType: Integer = 1,Decimal = 2,Date = 3,String = 4,
            switch (iDataType) {
                case 1: // Value entero

                    //Si tiene una ',' o un '.' genera excepción
                    if (sValue.indexOf(',') > 0 || sValue.indexOf('.') > 0)
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_TIPOVALUES;
                    //Mira si es un entero
                    if (errorType == "" && !sValue.isNumeric())
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_TIPOVALUES;

                    // Value entero
                    var intValue = parseInt(sValue);
                    if (errorType == "" && sMin != "" && intValue < parseInt(sMin))
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_OUT_LIMITS.format(sDesription, sMin, sMax);

                    if (errorType == "" && sMax != "" && intValue > parseInt(sMax))
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_OUT_LIMITS.format(sDesription, sMin, sMax);
                    break;

                case 2: // Value decimal

                    //Si tiene una ',' o un '.' genera excepción
                    if (sValue.indexOf(',') > 0)
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_TIPOVALUES;
                    //Mira si es un decimal
                    if (errorType == "" && !sValue.isNumeric())
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_TIPOVALUES;

                    // Value decimal
                    var dValue = parseFloat(sValue);
                    if (errorType == "" && sMin != "" && dValue < parseFloat(sMin))
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_OUT_LIMITS.format(sDesription, sMin, sMax);

                    if (errorType == "" && sMax != "" && dValue > parseFloat(sMax))
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_OUT_LIMITS.format(sDesription, sMin, sMax);
                    break;

                case 3: // Value DateTime

                    //Mira si es un datetime
                    if (errorType == "" && !sValue.isDate())
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_TIPOVALUES;

                    // Value DateTime
                    var dateValue: Date = new Date(sValue);
                    if (errorType == "" && sMin != "" && dateValue < new Date(sMin))
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_OUT_LIMITS.format(sDesription, sMin, sMax);

                    if (errorType == "" && sMax != "" && dateValue > new Date(sMax))
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_OUT_LIMITS.format(sDesription, sMin, sMax);
                    break;

                default: // Value string
                    if (errorType == "" && sMin != "" && sValue < sMin)
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_OUT_LIMITS.format(sDesription, sMin, sMax);

                    if (errorType == "" && sMax != "" && sValue > sMax)
                        errorType = rps.app.resources.validation.ERR_UTILS_VALIDATE_VALUE_OUT_LIMITS.format(sDesription, sMin, sMax);
                    break;
            }

            if (errorType != "")
                entity.addErrors([{ EntityPath: "", ErrorCode: "", ErrorDescription: errorType, Property: "" }]);
        }
    }

    /**
    * Converts the passed value from eTimeFrom units to eTimeTo
    * @param codcompany
    * @param rTime
    * @param eTimeFrom
    * @param eTimeTo
    * @param bRoundResult If true, the result of conversion is rounded
    */
    export function convertTime(codcompany: string, rTime: number, eTimeFrom: rps.enums.TimeUnit, eTimeTo: rps.enums.TimeUnit): number;
    export function convertTime(codcompany: string, rTime: number, eTimeFrom: rps.enums.TimeUnit, eTimeTo: rps.enums.TimeUnit, roundResult: boolean): number;
    export function convertTime(codcompany: string, rTime: number, eTimeFrom: rps.enums.TimeUnit, eTimeTo: rps.enums.TimeUnit, roundResult?: boolean): number {
        var bRoundResult: boolean = true;
        if (rps.object.hasValue(roundResult))
            bRoundResult = roundResult;

        if (rps.string.isNullOrEmpty(codcompany))
            codcompany = rps.app.session.company;

        if ((eTimeFrom == rps.enums.TimeUnit.Week && eTimeTo == rps.enums.TimeUnit.Month) ||
            (eTimeFrom == rps.enums.TimeUnit.Month && eTimeTo == rps.enums.TimeUnit.Week))
            throw rps.app.resources.validation.ERR_UTILS_OPERAND_NOT_CORRECT;

        if (eTimeTo == eTimeFrom)
            return rTime;

        var tcf: rps.services.TimeConversionFactors = rps.app.session.getCompanySessionData(codcompany).TimeConversionFactors;
        var tmpTime: number = rTime;
        switch (eTimeFrom) {
            case rps.enums.TimeUnit.Minute:
                if (eTimeTo == rps.enums.TimeUnit.Hour)
                    if (bRoundResult) {
                        return roundToDecimals(rTime / tcf.MinutesHour, 2);
                    }
                    else {
                        return rTime / tcf.MinutesHour;
                    }
                else {
                    tmpTime = rTime / tcf.MinutesHour; //en horas
                    return convertTime(codcompany, tmpTime, rps.enums.TimeUnit.Hour, eTimeTo, bRoundResult);
                }
            case rps.enums.TimeUnit.Hour:
                if (eTimeTo == rps.enums.TimeUnit.Minute)
                    if (bRoundResult)
                        return roundToDecimals(rTime * tcf.MinutesHour, 0);
                    else
                        return rTime * tcf.MinutesHour;

                else if (eTimeTo == rps.enums.TimeUnit.Day)
                    if (bRoundResult)
                        return roundToDecimals(rTime / tcf.HoursDay, 2);
                    else
                        return rTime / tcf.HoursDay;
                else {
                    tmpTime = rTime / tcf.HoursDay; //en días
                    return convertTime(codcompany, tmpTime, rps.enums.TimeUnit.Day, eTimeTo, bRoundResult);
                }
            case rps.enums.TimeUnit.Day:
                if (eTimeTo == rps.enums.TimeUnit.Hour)
                    return rTime * tcf.HoursDay;
                else if (eTimeTo == rps.enums.TimeUnit.Week)
                    if (bRoundResult)
                        return roundToDecimals(rTime / tcf.DaysWeek, 2);
                    else
                        return rTime / tcf.DaysWeek;
                else if (eTimeTo == rps.enums.TimeUnit.Month)
                    if (bRoundResult)
                        return roundToDecimals(rTime / tcf.DaysMonth, 2);
                    else
                        return rTime / tcf.DaysMonth;
                else if (eTimeTo == rps.enums.TimeUnit.Minute)
                    if (bRoundResult)
                        return roundToDecimals(rTime * tcf.HoursDay * tcf.MinutesHour, 0);
                    else
                        return rTime * tcf.HoursDay * tcf.MinutesHour;
                break;
            case rps.enums.TimeUnit.Week:
                if (eTimeTo == rps.enums.TimeUnit.Day)
                    return rTime * tcf.DaysWeek;
                else if (eTimeTo == rps.enums.TimeUnit.Hour)
                    return rTime * tcf.DaysWeek * tcf.HoursDay;
                else if (eTimeTo == rps.enums.TimeUnit.Minute)
                    if (bRoundResult)
                        return roundToDecimals(rTime * tcf.DaysWeek * tcf.HoursDay * tcf.MinutesHour, 0);
                    else
                        return rTime * tcf.DaysWeek * tcf.HoursDay * tcf.MinutesHour;
                break;
            case rps.enums.TimeUnit.Month:
                if (eTimeTo == rps.enums.TimeUnit.Day)
                    return rTime * tcf.DaysMonth;
                else if (eTimeTo == rps.enums.TimeUnit.Hour)
                    return rTime * tcf.DaysMonth * tcf.HoursDay;
                else if (eTimeTo == rps.enums.TimeUnit.Minute)
                    if (bRoundResult)
                        return roundToDecimals(rTime * tcf.DaysMonth * tcf.HoursDay * tcf.MinutesHour, 0);
                    else
                        return rTime * tcf.DaysMonth * tcf.HoursDay * tcf.MinutesHour;

                break;
            default:
                throw rps.app.resources.validation.ERR_UTILS_OPERAND_NOT_CORRECT;
        }
        throw rps.app.resources.validation.ERR_UTILS_OPERAND_NOT_CORRECT;
    }

    /**
     * Función que devuelve el formato necesario para los campos numéricos para el toString de Kendo en función de los parámetros
     * @param options
     */
    export function getDecimalFormat(options?:{
        suffix?: string,
        decimalPlaces?: number,
        minDecimalPlaces?: number;
        toKendoTemplate?:boolean
    }): string {
        //Guardo el valor de los decimales que tiene que tener como mínimo
        var mindecimal: number = rps.number.getMinDecimalPlaces();
        if (options && rps.object.hasValue(options.minDecimalPlaces))
            mindecimal = options.minDecimalPlaces;
        else
            mindecimal = rps.number.getMinDecimalPlaces();

        //Relleno el format con tantos ceros como decimales tenga que tener como minimo.
        var formatedtext: string = ",###.";
        var formatedtext2: string;
        if (options && rps.object.hasValue(options.decimalPlaces)) {
            formatedtext2 = "";
            for (var i = 0; i < options.decimalPlaces; i++) {
                formatedtext = formatedtext + "0";
            }
        }
        else {
            formatedtext2 = "##########";
            for (var i = 0; i < mindecimal; i++) {
                formatedtext = formatedtext + "0";
                formatedtext2 = formatedtext2.substr(1, formatedtext2.toString().length);
            }
        }

        formatedtext = formatedtext + formatedtext2;
        if (options && options.suffix)            
            formatedtext = rps.string.format("{0} {1}", formatedtext, options.suffix.replace("%", "\\%").replace("$", "\\$"));

        if (options && options.toKendoTemplate)
            formatedtext = formatedtext.replace(/#/g, "\\\\#");   

        return formatedtext;
    }

    /**
     * Función que devuelve un string con el número formateado en función de los parámetros
     * @param options
     */
    export function getFormattedDecimal(options: {
        value:number,
        suffix?: string,
        decimalPlaces?: number,
        minDecimalPlaces?: number;
    }): string {
        var format: string = rps.utils.getDecimalFormat(options);
        //Se redondea a 10, porque la plantilla tiene como mucho 10 decimales y en ocasiones aparecen 0-s al final por esta razón
        return kendo.toString(+options.value.toFixed(10), format)
    }

    /**
    *Función que controla la longitud de caracteres en los numeros con decimales
    */
    export function controlNumberLenght(object) {
        var val = $(object).val();
        var parts;
        var decimalpart = "";
        if (val.indexOf(rps.number.getSeparatorCharacter()) > 0)
            decimalpart = val.substr(val.indexOf(rps.number.getSeparatorCharacter()) + 1, val.length);
        parts = val.toString().length;
        if (parts > 15 || decimalpart.length > 10) {
            $(object).val(val.substr(0, val.length - 1));
        }
    }

    export function createColumnsDefinition(queryDefinition: rps.data.QueryReference): Array<rps.ui.itemsControl.ColumnDefinition> {
        var newColumns: Array<rps.ui.itemsControl.ColumnDefinition> = new Array<rps.ui.itemsControl.ColumnDefinition>();
        queryDefinition.ResultFields.forEach((resultField) => {
            var newColumnTemplate: rps.ui.itemsControl.ServerColumnTypes;
            var newColumnColumns: number;
            var insert: boolean = true;

            switch (resultField.SizeHint) {
                case "xs":
                    newColumnColumns = 1;
                    break;
                case "s":
                    newColumnColumns = 2;
                    break;
                case "m":
                    newColumnColumns = 3;
                    break;
                case "l":
                    newColumnColumns = 4;
                    break;
                case "xl":
                    newColumnColumns = 5;
                    break;
                default:
                    insert = false;
                    break;
            }

            var options: any;
            switch (resultField.Type) {
                case "string":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.textBox;
                    break;
                case "datetime":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.datePicker;
                    break;
                case "integer":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.integerEditor;
                    break;
                case "decimal":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.decimalEditor;
                    break;
                case "image":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.imageEditor;
                    break;
                case "icon":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.icon;
                    break;
                case "boolean":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.checkBox;
                    break;
                case "descriptor":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.lookup;
                    break;
                case "formattednumber":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.formattedNumber;
                    break;
                case "address":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.address;
                    break;
                case "uri":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.uri;
                    break;
                case "email":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.email;
                    break;
                case "icon":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.icon;
                    break;
                case "timespan":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.timePicker;
                    break;
                case "documents":
                    newColumnTemplate = rps.ui.itemsControl.ServerColumnTypes.documents;
                    break;
                case "descriptor":
                    insert = false;
                    break;
                default:
                    console.log("viewSource --> Not Implemented Column Type: " + resultField.Type);
                    insert = false;
                    break;
            }
            if (insert) {
                newColumns.push(new rps.ui.itemsControl.ServerColumnDefinition({
                    isEditable: false,
                    title: resultField.Label,
                    field: resultField.Name,
                    template: newColumnTemplate,
                    columns: newColumnColumns,
                    options: options
                }));
            }
        });
        return newColumns;
    }
    export function completeDateFormat(value: string): Date | null {
        //Funcion para formatear las fechas, si está incompleta, rellena la fecha con los datos que falten
        var format: string = getPatternDate();       
        var newValue: string = value.trim();
        var day: number | undefined ;
        var month: number | undefined;
        var year: number | undefined;

        //Recogemos los datos que se han introducido en la fecha y los guardamos por dia, mes y año
        for (var i = 0; i < newValue.length; i++) {
            if (format.slice(i, i + 1) == "d") {
                if (day)
                    day = +(day.toString() + newValue.slice(i, i + 1));
                else
                    day = +newValue.slice(i, i + 1);
            } else if (format.slice(i, i + 1) == "M") {
                if (month)
                    month = +(month.toString() + newValue.slice(i, i + 1));
                else
                    month = +newValue.slice(i, i + 1);
            } else if (format.slice(i, i + 1) == "y") {
                if (year)
                    year = +(year.toString() + newValue.slice(i, i + 1));
                else
                    year = +newValue.slice(i, i + 1);
            }
        }
        //Comprobamos que valor no se ha rellenado para meter el valor del dia actual
        if (!day && !month && !year) {
            return null;
        } else {
            if (!day)
                day = new Date().getDate();
            if (!month)
                month = new Date().getMonth();
            else
                month--;
            if (!year)
                year = new Date().getFullYear();
            
            //Si la fecha es incorrecta devolvemos null para que la deje vacia, en caso contrario devolvemos la fecha
            if ((day > new Date(year, month + 1, 0).getDate()) || (month > 11) || (year < 1970)) {
                return null;
            } else {
                var setDate = new Date();
                setDate.setHours(0, 0, 0, 0);
                setDate.setFullYear(year);  
                setDate.setMonth(month);
                setDate.setDate(day);
                return setDate;
            }
        }
       
    }
    export function completeTimeFormat(value: string): number {
        //Funcion para formatear las horas, si está incompleta, rellena la hora con los datos que falten
        var format: string = 'HH:mm:ss';
        var newValue: string = value.trim();
        var second: number;
        var minute: number;
        var hour: number;
        //Recogemos los datos que se han introducido en la hora y los guardamos por segundo, minuto y hora
        for (var i = 0; i < newValue.length; i++) {
            if (format.slice(i, i + 1) == "s") {
                if (second)
                    second = +(second.toString() + newValue.slice(i, i + 1));
                else
                    second = +newValue.slice(i, i + 1);
            } else if (format.slice(i, i + 1) == "m") {
                if (minute)
                    minute = +(minute.toString() + newValue.slice(i, i + 1));
                else
                    minute = +newValue.slice(i, i + 1);
            } else if (format.slice(i, i + 1) == "H") {
                if (hour)
                    hour = +(hour.toString() + newValue.slice(i, i + 1));
                else
                    hour = +newValue.slice(i, i + 1);
            }
        }
        //Comprobamos que valor no se ha rellenado para meter el valor de la hora       
        if (!second)
            second = 0;
        if (!minute)
            minute = 0;       
        if (!hour)
            hour = 0;
        //Si la hora es incorrecta devolvemos null para que la deje vacia, en caso contrario devolvemos la hora   
        if ((second > 60) || (minute > 60) || (hour > 24)) {    
            var time = new Date();
            time.setHours(0);
            time.setMinutes(0);
            time.setSeconds(0);
            return rps.time.convertToNumber(time);
        } else {
            var time = new Date();
            time.setHours(hour);
            time.setMinutes(minute);
            time.setSeconds(second);
            return rps.time.convertToNumber(time);
        }

    }
    /** @internal */
    export function selectInputContent(element: any, option: number) {
        //Si la opcion es 1 crea el timeout y se usa en el focus
        //La opcion 0 es por quitar el timeout si existe y se usa en el blur
        if (option == 1) {
            var input = element;
            clearTimeout(input.data("selectTimeId"));
            var selectTimeId = setTimeout(function () {
                input.select();
            });
            input.data("selectTimeId", selectTimeId);
        } else if (option == 0) {
            clearTimeout(element.data("selectTimeId")); 
        }
    }
    //Funcion para refrescar imagenes que no cambian la URL
    /** @internal */
    export function refreshImage(urlImage: string): string {
        if (urlImage.indexOf('&time=') != -1)
            return urlImage.replace(/([?&]time)=([^#&]*)/g, '&time=' + new Date().getTime());
        else
            return urlImage + '&time=' + new Date().getTime();
    }
    /*Retorna el patron de el formato de fecha con la cultura de html5*/
    export function getPatternDate(): string {
        var format = kendo.culture().calendar.patterns.d;
        //workaround para evitar que si el formato de fecha tiene solo un espacio para fecha o mes, se le ponen dos.
        if (format.match(new RegExp("d", "g")).length == 1) {
            format = [format.slice(0, format.indexOf("d")), "d", format.slice(format.indexOf("d"))].join('');
        }
        if (format.match(new RegExp("M", "g")).length == 1) {
            format = [format.slice(0, format.indexOf("M")), "M", format.slice(format.indexOf("M"))].join('');
        }
        return format;
    }
    /*Retorna el patron de la separacion decimal segun la cultura de html5*/
    /** @internal */
    export function getDecimalSeparator(): string {
        return kendo.culture().numberFormat["."];
    }

    /*Retorna los milisegundos de espera para que aparezca el Tooltip*/
    /** @rpsInternal */
    export function getTooltipDelay(): number {
        return 700;
    }
    
    /** @internal */
    class scrollBar {
        static scrollBarWidth: number;
    }

    /** @internal */
    export function getScrollBarWidth() {
        if (scrollBar.scrollBarWidth)
            return scrollBar.scrollBarWidth;
        else {
            var inner = document.createElement('p');
            inner.style.width = "100%";
            inner.style.height = "200px";

            var outer = document.createElement('div');
            outer.style.position = "absolute";
            outer.style.top = "0px";
            outer.style.left = "0px";
            outer.style.visibility = "hidden";
            outer.style.width = "200px";
            outer.style.height = "150px";
            outer.style.overflow = "hidden";
            outer.appendChild(inner);

            document.body.appendChild(outer);
            var w1 = inner.offsetWidth;
            outer.style.overflow = 'scroll';
            var w2 = inner.offsetWidth;
            if (w1 == w2) w2 = outer.clientWidth;

            document.body.removeChild(outer);

            scrollBar.scrollBarWidth = (w1 - w2);
            return scrollBar.scrollBarWidth;
        }
    };

    /** @internal */
    export function getWindowTop():string {
        return "60px";
    }

    /** @internal */
    export function getWindowMaxHeight():number {
        return $(window).height() - 120;
    }

    /** @internal */
    export function showIdNotFound(): Promise<rps.services.MessageResult> {
        return rps.app.messageManager.show({
            message: rps.app.resources.errors.ERR_REGISTRY_DOES_NOT_EXIST,
            messageType: rps.services.MessageType.Error,
            messageButton: rps.services.MessageButton.Ok
        });
    }
}