/// <reference path="../services/interfaces.ts" />

module rps.viewmodels.rules {

    export interface IRuleTrigger {
        /** @internal */
        IRuleTrigger: boolean;
        /** @internal */
        rules:Rule[];
    }

    export interface ICollectionChangedRuleTrigger<T> extends IRuleTrigger {
        /** @internal */
        ICollectionChangedRuleTrigger: boolean;
        /** @internal */
        foo():T;
    }

    export interface ITriggers<TParams> {
        /**
        * Estas reglas se lanzan en el proceso de creación de un viewmodel, una vez que están configuradas todas las propiedades,
        * y justo después de que se lancen las reglas de tipo onNavigate. Se ejecutan sólo una vez por cada instancia del viewmodel
        **/
        onLoad(): rps.viewmodels.rules.IAfterTrigger<TParams>

        /**
        * Estas reglas se lanzan en el proceso de creación de un viewmodel, una vez que están configuradas todas las propiedades,
        * y una vez que se hayan establecido los posibles parámetros de navegación. Estas reglas modificarían el comportamiento
        * del viewmodel basándose en los parámetros de navegación. Las reglas se lanzarían es asíncrono, con lo que la creación
        * del viewmodel no se quedaría esperando a que terminase la ejecución de estas reglas.
        **/
        onNavigate(): rps.viewmodels.rules.IAfterTrigger<TParams>

        /**
        * Estas reglas se lanzan antes de que la aplicación salga del estado correspondiente al viewmodel
        * Esta regla no es cancelable, y la salida no se puede evitar
        */
        onExit(): rps.viewmodels.rules.IAfterTrigger<TParams>

        /**
        * Estas reglas se lanzan cada vez que se activa la pantalla correspondiente al estado del viewmodel, bien porque
        * se active concretamente el estado, o porque se active cualquier estado hijo
        **/
        onActivate(): rps.viewmodels.rules.IAfterTrigger<TParams>

        /**
        * Estas reglas se lanzan la primera vez que se crea un viewmodel, después de terminar todo el ciclo de configuración del viewmodel
        * y de que se establezca como oriden de datos de la pantalla. Salta justo antes del onActivate
        */
        onEnter(): rps.viewmodels.rules.IAfterTrigger<TParams>

        /**
        * Indica que la regla se ejecutará tras el cambio en el valor de una propiedad del viewmodel
        * @param property Propiedad sobre la que se establece la regla
        */
        onPropertyChanged(property: rps.viewmodels.properties.VMProperty<any>): rps.viewmodels.rules.IAfterTrigger<TParams>

        /**
        * Indica que la regla se ejecutará justo antes de que se produzca un cambio en el valor de una propiedad del viewmodel
        * Tanto en la parte de condiciones como en las acciones, estarán disponibles el valor anterior y el siguiente de la propiedad
        * Estas reglas son cancelables, con lo que se evitaría el establecimiento del nuevo valor
        * @param property Propiedad sobre la que se establece la regla
        * @example
        * ```typescript

        *   this.addRule("CancelPropertyChanged").onBeforePropertyChanged(this.Property1)
        *       .when().isTrue((params) => params.previousValue < params.nextValue)
        *       .then().cancelTrigger(["New value cannot be greater than the original"]);
        * ```
        */
        onBeforePropertyChanged<T>(property: rps.viewmodels.properties.VMProperty<T>): rps.viewmodels.rules.IAfterTrigger<rps.viewmodels.properties.IBeforePropertyChangedArgs<T>>;

        /**
        * Indica que la regla se ejecutará justo antes de que se produzca un cambio en una colección, bien porque se añade o porque se elimina un elemento
        * Tanto en la parte de condiciones como en las acciones, estarán disponibles la acción realizada (Add/Remove) y el viewmodel añadido o eliminado
        * @param property Colección sobre la que se establece la regla
        */
        onCollectionChanged<VMT>(property: ICollectionChangedRuleTrigger<VMT>): rps.viewmodels.rules.IAfterTrigger<rps.viewmodels.IChildCollectionChangedArgs<VMT>>

        /**
        * Indica que la regla se ejecutará justo antes de que se produzca un cambio en una colección, bien porque se añade o porque se elimina un elemento
        * Tanto en la parte de condiciones como en las acciones, estarán disponibles la acción realizada (Add/Remove) y el viewmodel añadido o eliminado
        * Estas reglas son cancelables, con lo que se evitaría la continuación de la acción
        * @param property Colección sobre la que se establece la regla
        */
        onBeforeCollectionChanged<VMT>(property: ICollectionChangedRuleTrigger<VMT>): rps.viewmodels.rules.IAfterTrigger<rps.viewmodels.IChildCollectionChangedArgs<VMT>>;

        /**
        * Indica que la regla se ejecutará después de la ejecución de un comando
        * @param command Comando sobre la que se establece la regla
        */
        onExecuteCommand(command: rps.viewmodels.commands.CommandProperty): rps.viewmodels.rules.IAfterTrigger<TParams>;

        /**
        * Indica que la regla se ejecutará después de la ejecución de una query
        * @param query Query sobre la que se establece la regla
        */
        onExecuteQuery(query: IRuleTrigger): rps.viewmodels.rules.IAfterTrigger<TParams>;

        /**
        * Indica que la regla se ejecutará después de la ejecución de una acción
        * @param action Acción sobre la que se establece la regla
        */
        onExecuteAction(action: rps.viewmodels.commands.ApiActionCommand<any, any>): rps.viewmodels.rules.IAfterTrigger<TParams>;

        /**
        * Inicia el grupo de condiciones de la regla
        */
        when(): IMainConditionBuilder<TParams>
    }

    export interface IExtendedTriggers<TParams> extends ITriggers<TParams> {
        /**
        * Indica que la regla se lanzará antes de grabar el modelo, después de realizar la validación de datos de cliente
        * Es una regla que admite cancelación mediante CancelTrigger
        */
        onBeforeSave(): rps.viewmodels.rules.IAfterTrigger<TParams>;

        /**
        * Indica que la regla se lanzará después del grabado del modelo, siempre y cuando no haya habido errores en el grabado
        * Esta regla no salta tras el borrado del modelo
        */
        onAfterSave(): rps.viewmodels.rules.IAfterTrigger<TParams>;

        /**
        * Indica que la regla se lanzará antes de eliminar un modelo, después de que el usuario haya aceptado posibles mensajes de confirmación
        * Es una regla que admite cancelación mediante CancelTrigger
        */
        onBeforeDelete(): rps.viewmodels.rules.IAfterTrigger<TParams>;
    }

    export interface IAfterTrigger<TParams> {
        then(): IMainActionGroup<TParams>
        when(): IMainConditionBuilder<TParams>
    }

    export class ICancellationArguments {
        reasons?: Array<string>;
        notificationOptions?: {
            showModalWindow?: boolean;
            showNotificationWindow?: boolean;
            messageType?: rps.services.MessageType;
        };
    }

    export class RuleExecutionResult implements ICancellationArguments {
        value: RuleExecutionValue;
        reasons?: Array<string>;
        notificationOptions?: {
            showModalWindow?: boolean;
            showNotificationWindow?: boolean;
            messageType?: rps.services.MessageType;
        };
    }
    export enum RuleExecutionValue {
        Continue = 0,
        Cancel = 1
    }

    export class TypedRule<TParams> extends rps.viewmodels.ObservableObject implements IExtendedTriggers<TParams>, IAfterTrigger<TParams> {

        public triggerCancelled = false;
        public triggerCancelledParams: ICancellationArguments = {
            reasons: new Array<string>()
        };

        ID: string;
        Conditions: TypedConditionGroup<TParams>;
        ThenActions: IMainActionGroup<TParams>;
        ElseActions: IActionGroup<TParams>;

        Trigger: Triggers;
        TriggerField: IRuleTrigger;

        ParentVM: BaseVM = null;

        constructor(id: string, parentVM: BaseVM) {
            super();

            this.ParentVM = parentVM;
            this.ID = id;
        }

        private isFirstCheck: boolean = true;
        private lastCheckResult: boolean;

        public execute(trigger?: rps.viewmodels.rules.IRuleTrigger, triggerParams?: any): Promise<RuleExecutionResult> {
            var preventActions = false;
            if (!this.Trigger) {
                var currentCheckResult = this.Conditions.check(trigger, triggerParams);
                if (!this.isFirstCheck && currentCheckResult == this.lastCheckResult)
                    preventActions = true;
                else {
                    this.lastCheckResult = currentCheckResult;
                    this.isFirstCheck = false;
                }
            }
            if (preventActions)
                return Promise.resolve({ value: RuleExecutionValue.Continue });
            if (!this.Conditions || this.Conditions.check(trigger, triggerParams))
                return (<ActionGroup<TParams>>this.ThenActions).execute(triggerParams);
            else if (this.ElseActions)
                return (<ActionGroup<TParams>>this.ElseActions).execute(triggerParams);
            else
                return Promise.resolve({ value: RuleExecutionValue.Continue });
        }

        public on(trigger: Triggers): TypedRule<TParams> {
            this.Trigger = trigger;
            return this;
        }

        public onLoad(): rps.viewmodels.rules.TypedRule<TParams> {
            this.Trigger = Triggers.OnLoad;
            return this;
        }

        public onNavigate(): rps.viewmodels.rules.TypedRule<TParams> {
            this.Trigger = Triggers.OnNavigate;
            return this;
        }

        public onExit(): rps.viewmodels.rules.TypedRule<TParams> {
            this.Trigger = Triggers.OnExit;
            return this;
        }

        public onBeforeSave(): rps.viewmodels.rules.TypedRule<TParams> {
            this.Trigger = Triggers.OnBeforeSave;
            return this;
        }

        public onAfterSave(): rps.viewmodels.rules.TypedRule<TParams> {
            this.Trigger = Triggers.OnAfterSave;
            return this;
        }

        /**
         * esta salta al activar el viewmodel
         */
        public onActivate(): rps.viewmodels.rules.TypedRule<TParams> {
            this.Trigger = Triggers.OnActivate;
            return this;
        }

        public onEnter(): rps.viewmodels.rules.TypedRule<TParams> {
            this.Trigger = Triggers.OnEnter;
            return this;
        }

        public onBeforeDelete(): rps.viewmodels.rules.TypedRule<TParams> {
            this.Trigger = Triggers.OnBeforeDelete;
            return this;
        }

        public onBeforePropertyChanged<T>(property: rps.viewmodels.properties.VMProperty<T>): rps.viewmodels.rules.TypedRule<rps.viewmodels.properties.IBeforePropertyChangedArgs<T>> {
            this.Trigger = Triggers.OnBeforePropertyChanged;
            this.TriggerField = property;
            return <any>this;
        }

        public onPropertyChanged(propertyName: rps.viewmodels.properties.VMProperty<any>): rps.viewmodels.rules.TypedRule<TParams> {
            this.Trigger = Triggers.PropertyChanged;
            this.TriggerField = propertyName;
            return this;
        }

        public onBeforeCollectionChanged<T>(property: ICollectionChangedRuleTrigger<T>): rps.viewmodels.rules.TypedRule<rps.viewmodels.IChildCollectionChangedArgs<T>> {
            this.Trigger = Triggers.OnBeforeCollectionChanged;
            this.TriggerField = property;
            return <any>this;
        }

        public onCollectionChanged<T>(propertyName: ICollectionChangedRuleTrigger<T>): rps.viewmodels.rules.TypedRule<rps.viewmodels.IChildCollectionChangedArgs<T>> {
            this.Trigger = Triggers.OnCollectionChanged;
            this.TriggerField = propertyName;
            return <any>this;
        }

        public onExecuteCommand(command: rps.viewmodels.commands.CommandProperty): rps.viewmodels.rules.TypedRule<TParams> {
            this.Trigger = Triggers.OnExecuteCommand;
            this.TriggerField = command;
            return this;
        }

        public onExecuteQuery(query: IRuleTrigger): rps.viewmodels.rules.TypedRule<TParams>{
            this.Trigger = Triggers.OnExecuteQuery;
            this.TriggerField = query;
            return this;
        }

        public onExecuteAction(action: rps.viewmodels.commands.ApiActionCommand<any, any>): rps.viewmodels.rules.TypedRule<TParams>{
            this.Trigger = Triggers.OnExecuteAction;
            this.TriggerField = action;
            return this;
        }

        public when(): IMainConditionBuilder<TParams> {
            if (this.Conditions)
                throw (rps.app.resources.errors.ERR_RULE_CONDITIONS_DEFINED);
            this.Conditions = new ConditionGroup(this);
            return this.Conditions;
        }

        public then(): IMainActionGroup<TParams> {
            if (this.ThenActions)
                throw (rps.app.resources.errors.ERR_RULE_THEN_DEFINED);
            this.ThenActions = new ActionGroup(this);
            return this.ThenActions;
        }

        public else(): IActionGroup<TParams> {
            if (this.ElseActions)
                throw (rps.app.resources.errors.ERR_RULE_ELSE_DEFINED);
            this.ElseActions = new ActionGroup(this);
            return this.ElseActions;
        }

        public cancelTrigger(reasons?: Array<string> | ICancellationArguments): void {
            this.triggerCancelled = true;
            if (reasons) {
                if (rps.object.isArray(reasons))
                    this.triggerCancelledParams = {
                        reasons: reasons.slice(0)
                    };
                else {
                    this.triggerCancelledParams = reasons;
                }
            }
        }
    }
    export class Rule extends TypedRule<any>{ }

    export interface IMainConditionBuilder<TParams> extends IConditionBuilder<IAndOrConditionChainer<TParams>, TParams> {
        conditionGroup(): IConditionBuilder<IAndOrConditionChainer<TParams>, TParams>
        notConditionGroup(): IConditionBuilder<IAndOrConditionChainer<TParams>, TParams>
        then(): IMainActionGroup<TParams>;
        getConditionGroup(): TypedConditionGroup<TParams>;
    }
    export interface IConditionBuilder<TChainer extends IConditionChainer,TParams> {
        equals(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): TChainer
        notEquals(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): TChainer
        greaterThan(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): TChainer
        greaterOrEquals(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): TChainer
        lessThan(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): TChainer
        lessOrEquals(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): TChainer
        isNull(field: rps.viewmodels.properties.VMProperty<any>): TChainer
        isNotNull(field: rps.viewmodels.properties.VMProperty<any>): TChainer
        startsWith(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): TChainer
        notStartsWith(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): TChainer
        endsWith(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): TChainer
        notEndsWith(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): TChainer
        contains(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): TChainer
        isNew(): TChainer
        isChanged(field: rps.viewmodels.properties.VMProperty<any>): TChainer
        isTrue(func: (params?: TParams) => boolean, vm?:BaseVM): TChainer;
        hasSelectedItems(field: rps.ISelectable): TChainer;
        hasAnyItem(field: rps.IItemContainer): TChainer;
        sessionUserIn(users: string[]): TChainer;
        sessionRoleIn(roles: string[]): TChainer;
        not(): IConditionBuilder<TChainer, TParams>
        closeGroup(): IAndOrConditionChainer<TParams>;
    }
    export interface IAndOrConditionChainer<TParams> extends IConditionChainer {
        and(): IConditionBuilder<IAndConditionChainer<TParams>,TParams>;
        or(): IConditionBuilder<IOrConditionChainer<TParams>, TParams>;
        andConditionGroup(): IConditionBuilder<IAndOrConditionChainer<TParams>, TParams>;
        andNotConditionGroup(): IConditionBuilder<IAndOrConditionChainer<TParams>, TParams>;
        orConditionGroup(): IConditionBuilder<IAndOrConditionChainer<TParams>, TParams>;
        orNotConditionGroup(): IConditionBuilder<IAndOrConditionChainer<TParams>, TParams>;
        closeGroup(): IAndOrConditionChainer<TParams>;
        then(): IMainActionGroup<TParams>;
        getConditionGroup(): TypedConditionGroup<TParams>;
    }
    export interface IConditionChainer {
    }
    export interface IAndConditionChainer<TParams> extends IConditionChainer {
        and(): IConditionBuilder<IAndConditionChainer<TParams>,TParams>;
        andConditionGroup(): IConditionBuilder<IAndOrConditionChainer<TParams>, TParams>;
        andNotConditionGroup(): IConditionBuilder<IAndOrConditionChainer<TParams>, TParams>;
        closeGroup(): IAndOrConditionChainer<TParams>;
        then(): IMainActionGroup<TParams>;
        getConditionGroup(): TypedConditionGroup<TParams>;
    }
    export interface IOrConditionChainer<TParams> extends IConditionChainer {
        or(): IConditionBuilder<IOrConditionChainer<TParams>, TParams>;
        orConditionGroup(): IConditionBuilder<IAndOrConditionChainer<TParams>, TParams>;
        orNotConditionGroup(): IConditionBuilder<IAndOrConditionChainer<TParams>, TParams>;
        closeGroup(): IAndOrConditionChainer<TParams>;
        then(): IMainActionGroup<TParams>;
        getConditionGroup(): TypedConditionGroup<TParams>;
    }

    export class TypedConditionGroup<TParams> implements IMainConditionBuilder<TParams>, IAndOrConditionChainer<TParams>,
        IConditionBuilder<IAndConditionChainer<TParams>, TParams>, IConditionBuilder<IOrConditionChainer<TParams>, TParams>{

        public ConditionType: ConditionTypes = null;
        private conditions: Array<Condition | TypedConditionGroup<TParams>>;
        private parentRule: TypedRule<TParams>;
        private parentGroup: TypedConditionGroup<TParams>;
        private applyNotToNextCondition: boolean = false;
        private applyNotToGroup: boolean = false;

        constructor(parentRule: TypedRule<TParams>, parentGroup?: TypedConditionGroup<TParams>) {
            this.parentRule = parentRule;
            this.parentGroup = parentGroup;
            this.conditions = new Array < Condition | TypedConditionGroup<TParams>>();
        }

        public check(trigger?: rps.viewmodels.rules.IRuleTrigger,triggerParams?: any): boolean {
            if (this.ConditionType == null || this.ConditionType == ConditionTypes.And) {
                for (var i = 0; i < this.conditions.length; i += 1) {
                    if (!this.conditions[i].check(trigger,triggerParams)) {
                        return (this.applyNotToGroup ? true : false);
                    }
                }
                return (this.applyNotToGroup ? false : true);
            }
            else if (this.ConditionType == ConditionTypes.Or) {
                for (var i = 0; i < this.conditions.length; i += 1) {
                    if (this.conditions[i].check(trigger, triggerParams)) {
                        return (this.applyNotToGroup ? false : true);
                    }
                }
                return (this.applyNotToGroup ? true : false);
            }
            return (this.applyNotToGroup ? true : false);
        }

        public conditionGroup(): IConditionBuilder<IAndOrConditionChainer<TParams>, TParams> {
            return this.andConditionGroup();
        }

        public notConditionGroup(): IConditionBuilder<IAndOrConditionChainer<TParams>, TParams> {
            return this.andNotConditionGroup();
        }

        public and(): any {
            this.ConditionType = ConditionTypes.And;
            return this;
        }

        public andConditionGroup(): any {
            this.ConditionType = ConditionTypes.And;
            var newGroup = new ConditionGroup(this.parentRule, this);
            this.conditions.push(newGroup);
            return newGroup;
        }

        public andNotConditionGroup(): any {
            this.ConditionType = ConditionTypes.And;
            var newGroup = new ConditionGroup(this.parentRule, this);
            newGroup.applyNotToGroup = true;
            this.conditions.push(newGroup);
            return newGroup;
        }

        public orConditionGroup(): any {
            this.ConditionType = ConditionTypes.Or;
            var newGroup = new ConditionGroup(this.parentRule, this);
            this.conditions.push(newGroup);
            return newGroup;
        }

        public orNotConditionGroup(): any {
            this.ConditionType = ConditionTypes.Or;
            var newGroup = new ConditionGroup(this.parentRule, this);
            newGroup.applyNotToGroup = true;
            this.conditions.push(newGroup);
            return newGroup;
        }

        public closeGroup(): any {
            return (this.parentGroup || this);
        }

        public or(): any {
            this.ConditionType = ConditionTypes.Or;
            return this;
        }

        public not(): any {
            this.applyNotToNextCondition = true;
            return this;
        }

        private addCondition(newCondition: Condition): any {
            newCondition.ApplyNot = this.applyNotToNextCondition;
            this.applyNotToNextCondition = false;
            this.conditions.push(newCondition);
            return this;
        }

        public greaterThan(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): any {
            return this.addCondition(new Condition(field, Operator.GT, value));
        }
        public greaterOrEquals(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): any {
            return this.addCondition(new Condition(field, Operator.GTE, value));
        }
        public lessThan(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): any {
            return this.addCondition(new Condition(field, Operator.LT, value));
        }
        public lessOrEquals(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): any {
            return this.addCondition(new Condition(field, Operator.LTE, value));
        }
        public equals(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): any {
            return this.addCondition(new Condition(field, Operator.EQ, value));
        }
        public notEquals(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): any {
            return this.addCondition(new Condition(field, Operator.NEQ, value));
        }
        public isNull(field: rps.viewmodels.properties.VMProperty<any>): any {
            return this.addCondition(new Condition(field, Operator.NULL));
        }
        public isNotNull(field: rps.viewmodels.properties.VMProperty<any>): any {
            return this.addCondition(new Condition(field, Operator.NOTNULL));
        }
        public startsWith(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): any {
            return this.addCondition(new Condition(field, Operator.SW, value));
        }
        public notStartsWith(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): any {
            this.conditions.push(new Condition(field, Operator.NSW, value));
        }
        public endsWith(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): any {
            return this.addCondition(new Condition(field, Operator.EW, value));
        }
        public notEndsWith(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): any {
            return this.addCondition(new Condition(field, Operator.NEW, value));
        }
        public contains(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): any {
            return this.addCondition(new Condition(field, Operator.CONTAINS, value));
        }
        public isNew(): any {
            var newCondition = new Condition(null, Operator.ISNEW);
            newCondition.VMToCheck = this.parentRule.ParentVM;
            return this.addCondition(newCondition);
        }

        public isTrue(func: (params?: any) => boolean, vm?:BaseVM): any {
            var newCondition = new Condition(null, Operator.CUSTOM);
            newCondition.VMToCheck = vm ? vm : this.parentRule.ParentVM;
            if (func) {
                newCondition.CustomCondition = func;
            }
            else
                newCondition.CustomCondition = (() => { return true; });
            return this.addCondition(newCondition);
        }

        public sessionUserIn(users: string[]): any {
            var newCondition = new Condition(null, Operator.CUSTOM);
            newCondition.VMToCheck = this.parentRule.ParentVM;
            newCondition.CustomCondition = (() => {
                return users.indexOf(rps.app.session.user) >= 0;
            });
            return this.addCondition(newCondition);
        }

        public sessionRoleIn(roles: string[]): any {
            var newCondition = new Condition(null, Operator.CUSTOM);
            newCondition.VMToCheck = this.parentRule.ParentVM;
            newCondition.CustomCondition = (() => {
                return roles.indexOf(rps.app.session.role) >= 0;
            });
            return this.addCondition(newCondition);
        }

        public hasSelectedItems(field: rps.ISelectable & IRuleTrigger): any {
            return this.addCondition(new Condition(field, Operator.HASSELECTED));
        }

        public hasAnyItem(field: rps.IItemContainer & IRuleTrigger): any {
            return this.addCondition(new Condition(field, Operator.HASANYITEM));
        }

        public isChanged(field: rps.viewmodels.properties.VMProperty<any>): any {
            return this.addCondition(new Condition(field, Operator.CHANGED));
        }
        public then(): IMainActionGroup<TParams> {
            return this.parentRule.then();
        }

        public getConditionGroup(): TypedConditionGroup<TParams> {
            var parent: TypedConditionGroup<TParams> = this;
            while (parent.parentGroup)
                parent = parent.parentGroup;
            return parent;
        }
    }

    export class ConditionGroup extends TypedConditionGroup<any>{ }

    /** @internal */
    export class Condition {
        Field: rps.viewmodels.rules.IRuleTrigger ;
        Operator: Operator;
        Expression: any;
        ApplyNot: boolean = false;
        VMToCheck: BaseVM = null;
        CustomCondition: (params?: any) => boolean;
        TriggerParams: any;

        constructor(field: rps.viewmodels.rules.IRuleTrigger, operator: Operator, expression?: any) {
            this.Field = field;
            this.Operator = operator;
            this.Expression = expression;
        }

        public check(trigger?: rps.viewmodels.rules.IRuleTrigger, triggerParams?: any): boolean {
            var result: boolean = false;
            var field: rps.viewmodels.properties.VMProperty<any> = <rps.viewmodels.properties.VMProperty<any>>this.Field;

            switch (this.Operator) {
                case Operator.CUSTOM:
                    try {
                        result = this.CustomCondition.call(this.VMToCheck, triggerParams);
                    }
                    catch (ex) {
                        return false;
                    }
                    break;                
                case Operator.ISNEW:
                    if (this.VMToCheck && (<any>this.VMToCheck).model) {
                        var model = (<any>this.VMToCheck).model;
                        if (model)
                            result = model.__isNew;
                    }
                    break;
                case Operator.CHANGED:
                    result = (field.isChanged);
                    break;
                case Operator.CONTAINS:
                    if (rps.object.isString(field.value)) {
                        if (this.Expression instanceof rps.viewmodels.properties.VMProperty)
                            result = (<String>field.value).includes(this.Expression.value);
                        else
                            result = (<String>field.value).includes(this.Expression);
                    }
                    break;
                case Operator.EQ:
                    if (this.Expression instanceof rps.viewmodels.properties.VMProperty)
                        result = (field.value == this.Expression.value);
                    else
                        result = (field.value == this.Expression);
                    break;
                case Operator.EW:
                    if (rps.object.isString(field.value)) {
                        if (this.Expression instanceof rps.viewmodels.properties.VMProperty)
                            result = (<String>field.value).endsWith(this.Expression.value);
                        else
                            result = (<String>field.value).endsWith(this.Expression);
                    }
                    break;
                case Operator.GT:
                    if (this.Expression instanceof rps.viewmodels.properties.VMProperty)
                        result = field.value > this.Expression.value;
                    else
                        result = field.value > this.Expression;
                    break;
                case Operator.GTE:
                    if (this.Expression instanceof rps.viewmodels.properties.VMProperty)
                        result = field.value >= this.Expression.value;
                    else
                        result = field.value >= this.Expression;
                    break;
                case Operator.LT:
                    if (this.Expression instanceof rps.viewmodels.properties.VMProperty)
                        result = field.value < this.Expression.value;
                    else
                        result = field.value < this.Expression;
                    break;
                case Operator.LTE:
                    if (this.Expression instanceof rps.viewmodels.properties.VMProperty)
                        result = field.value <= this.Expression.value;
                    else
                        result = field.value <= this.Expression;
                    break;
                case Operator.NEQ:
                    if (this.Expression instanceof rps.viewmodels.properties.VMProperty)
                        result = field.value !== this.Expression.value;
                    else
                        result = field.value !== this.Expression;
                    break;
                case Operator.NEW:
                    if (rps.object.isString(field.value)) {
                        if (this.Expression instanceof rps.viewmodels.properties.VMProperty)
                            result = !(<String>field.value).endsWith(this.Expression.value);
                        else
                            result = !(<String>field.value).endsWith(this.Expression);
                    }
                    break;
                case Operator.NOTNULL:
                    if (rps.object.isString(field.value))
                        result = !rps.string.isNullOrEmpty(field.value)
                    else
                        result = !rps.object.isNullOrUndefined(field.value)
                    break;
                case Operator.NSW:
                    if (rps.object.isString(field.value)) {
                        if (this.Expression instanceof rps.viewmodels.properties.VMProperty)
                            result = !(<String>field.value).startsWith(this.Expression.value);
                        else
                            result = !(<String>field.value).startsWith(this.Expression);
                    }
                    break;
                case Operator.NULL:
                    if (rps.object.isString(field.value))
                        result = rps.string.isNullOrEmpty(field.value)
                    else
                        result = rps.object.isNullOrUndefined(field.value)
                    break;
                case Operator.SW:
                    if (rps.object.isString(field.value)) {
                        if (this.Expression instanceof rps.viewmodels.properties.VMProperty)
                            result = (<String>field.value).startsWith(this.Expression.value);
                        else
                            result = (<String>field.value).startsWith(this.Expression);
                    }
                    break;
                case Operator.HASSELECTED:
                    if (rps.isISelectable(this.Field))
                        result = this.Field.hasSelectedItems();
                    else
                        result = false;
                    break;
                case Operator.HASANYITEM:
                    if (rps.isItemContainer(this.Field))
                        result = this.Field.hasItems();
                    else
                        result = false;
                    break;

            }

            return (this.ApplyNot ? !result : result);
        }
    }

    export enum Operator {
        EQ, NEQ, GT, GTE, LT, LTE, NULL, NOTNULL, SW, NSW, EW, NEW, CONTAINS, CHANGED,ISNEW,CUSTOM,HASSELECTED,HASANYITEM
    }

    export enum ActionTypes {
        SetValue,
        CleanValue,
        LockField,
        UnlockField,
        LockModel,
        UnlockModel,
        DisableAddEntity,
        ValidationError,
        InvokeCommand,
        SetAccent,
        ExecuteQuery,
        ShowMessage,
        ExecuteCustomCode,
        ExecuteAsyncCustomCode,
        ExecuteAction,
        SetSemanticState,
        CancelTrigger,
        ShowNotification
    }

    export enum SemanticState {
        /** Estado por defecto */
        Neutral = 0,
        Positive = 1,
        Info = 2,
        Warning = 3,
        Negative = 4
    }

    export enum ConditionTypes {
        And,
        Or
    }

    export interface IAsyncActionCallback<TResult, TParentActionGroup,TParams> {
        then(callback: (result: TResult, action: IAsyncAction<TParams>, params:TParams) => void): TParentActionGroup
    }

    export interface IAsyncAction<TParams> {
        continueWith(): IActionGroup<TParams>;
        cancelTrigger(reasons?: Array<string> | ICancellationArguments): void
    }

    export interface IActionGroupBase<TReturnActionGroup, TParams> {
        /**
        * Acción que limpia el valor de una propiedad. El valor establecido depende del tipo de propiedad al que se aplica
        *   1. Numérico -> 0
        *   2. Boolean -> false
        *   3. Colección -> se eliminan todos los elementos
        *   4. Resto -> se establece a null
        * Esta acción es síncrona, con lo que no espera a terminar para ejecutar siguientes acciones.
        * @param field Propiedad sobre la que se establece la regla
        * @example
        * ```typescript
        *
        *   this.addRule("RemoveStateOnCountryChanged").onPropertyChanged(this.Country)
        *       .then().cleanValue(this.State);
        * ```
        */
        cleanValue(field: rps.viewmodels.properties.VMProperty<any>): TReturnActionGroup;

        /**
        * Acción que bloquea una propiedad, poniéndola de sólo lectura.
        * Esta acción es síncrona, con lo que no espera a terminar para ejecutar siguientes acciones.
        * @param field Propiedad sobre la que se establece la regla
        * @example
        * ```typescript
        *
        *   this.addRule("RemoveCountry").onPropertyChanged(this.CodCountry)
        *       .when().isNull(this.CodCountry)
        *       .then().cleanValue(this.IDState).cleanValue(this.IDCounty)
        *           .lockField(this.IDState).lockField(this.IDCounty);
        * ```
        */
        lockField(field: rps.viewmodels.properties.VMProperty<any>): TReturnActionGroup;

        /**
        * Acción que desbloquea una propiedad. La propiedad se desbloquea siempre y cuando su viewmodel padre no esté bloqueado.
        * Esta acción es síncrona, con lo que no espera a terminar para ejecutar siguientes acciones.
        * @param field Propiedad sobre la que se establece la regla
        */
        unlockField(field: rps.viewmodels.properties.VMProperty<any>): TReturnActionGroup;

        /**
        * Acción que bloquea un viewmodel y todas las propiedades que están definidas en ese viewmodel (incluyendo viewmodels hijo)
        * Esta acción es síncrona, con lo que no espera a terminar para ejecutar siguientes acciones.
        * @param target Viewmodel sobre el que se aplica el bloqueo
        * @param reason Razón para aplicar el bloqueo; este texto se muestra en pantalla para describir el porqué del bloqueo
        */
        lockModel(target: rps.viewmodels.BaseVM, reason: string): TReturnActionGroup;

        /**
        * Acción que desbloquea un viewmodel y todos sus viewmodels hijo. EL desbloqueo de las propiedades del viewmodel tendrá
        * efecto siempre y cuando la propiedad no esté bloqueada expresamente.
        * Esta acción es síncrona, con lo que no espera a terminar para ejecutar siguientes acciones.
        * @param target Viewmodel sobre el que se aplica el bloqueo
        * @param reason Razón para aplicar el bloqueo; este texto se muestra en pantalla para describir el porqué del bloqueo
        */
        unlockModel(target: rps.viewmodels.BaseVM): TReturnActionGroup;

        /**
        * Acción que deshabilita la opción de añadir un nuevo elemento en una pantalla de mantenimiento
        * Esta acción es síncrona, con lo que no espera a terminar para ejecutar siguientes acciones.
        * @param target Viewmodel sobre el que se aplica el bloqueo
        * @param reason Razón para deshabilitar la opción
        */
        disableAddEntity(target: rps.ICanAddEntity, reason: string): TReturnActionGroup;

        /**
        * Acción que establece el valor de una propiedad a un valor concreto o al valor de otro viewmodel
        * Esta acción es síncrona, con lo que no espera a terminar para ejecutar siguientes acciones.
        * @param field Propiedad a la que se va a establecer el nuevo valor
        * @param value Nuevo valor a establecer, o propiedad cuyo valor se establecerá a la propiedad field
        */
        setValue(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): TReturnActionGroup;

        /**
        * Acción que establece el estado semántico de una propiedad
        * Esta acción es síncrona, con lo que no espera a terminar para ejecutar siguientes acciones.
        * @param field Propiedad a la que se va a establecer el estado semántico
        * @param state Estado semántico a establecer
        */
        setSemanticState(field: rps.viewmodels.properties.VMProperty<any>, state: rps.viewmodels.rules.SemanticState): TReturnActionGroup;

        /**
        * Acción que ejecuta una query (clase que implemente IExecutableQuery)
        * Esta acción es asíncrona; en su acción siguiente, se tiene acceso a los valores devueltos por la query
        * @param  query Método que se va a ejecutar
        * @example
        * ```typescript

        *   this.addRule("ExecuteQuery").onPropertyChanged(this.IDArticle)
        *       .when().isNew()
        *       .then().executeQuery(this.IsArticleKitComponent)
        *           .then((result, action) => {
        *               if (result.getParamValue("IsKit") == true)
        *                   action.continueWith().setValue(this.ArticleIsKit, true);
        *           });
        * ```
        */
        executeQuery(query: IExecutableQuery): IAsyncActionCallback<rps.data.parameters.QueryParams, TReturnActionGroup, TParams>;

        /**
        * Acción que ejecuta un comando
        * Esta acción es asíncrona; en su acción siguiente, se tiene acceso a los valores devueltos por el comando (si los hay)
        * @param  command Comando que se va a ejecutar
        */
        invokeCommand(command: commands.CommandProperty): IAsyncActionCallback<any, TReturnActionGroup, TParams>;

        showMessage(message: string): IAsyncActionCallback<rps.services.MessageResult, TReturnActionGroup, TParams>;

        /**
        * Acción que muestra un mensaje en pantalla. Puede tratarse de una confirmación o de una pregunta al usuario
        * Esta acción es asíncrona; en su acción siguiente, se tiene acceso al valor del botón seleccionado por el usuario (Ok, Yes, No, Cancel)
        * @param  message Mensaje que se muestra al usuario
        * @param  messageButton Botones disponibles en la pantalla (Ok, YesNo, YesNoCancel)
        * @param  messageType Tipo del mensaje (Info, Success, Warning, Error, Question)
        * @example
        * ```typescript

        *   this.addRule("DeleteQuantityOnArticleChange").onPropertyChanged(this.IDArticle)
        *       .then().showMessage("Do you want to change the quantity?", services.MessageButton.YesNo, services.MessageType.Question)
        *       .then((result, action) => {
        *           if (result == services.MessageResult.Yes)
        *               action.continueWith().setValue(this.Quantity, 0);
        *       });
        * ```
        */
        showMessage(message: string, messageButton: rps.services.MessageButton, messageType: rps.services.MessageType): IAsyncActionCallback<rps.services.MessageResult, TReturnActionGroup, TParams>;
        //showMessage(message?: string, messageButton?: rps.services.MessageButton, messageType?: rps.services.MessageType): IAsyncActionCallback<rps.services.MessageResult, TReturnActionGroup, TParams>;

        showNotification(message: string): TReturnActionGroup;

        /**
        * Acción que muestra una notificación en pantalla
        * Esta acción es síncrona, con lo que no espera a terminar para ejecutar siguientes acciones.
        * @param  message Mensaje que se muestra al usuario
        * @param  notificationType Tipo del mensaje (Info, Success, Warning, Error)
        */
        showNotification(message: string, notificationType: rps.services.NotificationType): TReturnActionGroup;

        /**
        * Acción que cancela la finalización del desencadenante de la regla. Por ejemplo, cancelaría la grabación de un modelo en una regla de tipo OnBeforeSave
        * @param  reasons Razones que se muestran como justificación a la cancelación
        * @example
        * ```typescript
        *
        *   this.addRule("DontSaveWithoutLines").onBeforeSave()
        *       .when().isTrue(() => {
        *           return !this.OrderLineSLs.hasItems();
        *       }).then()
        *           .cancelTrigger([rps.Sales.viewmodels.OrderSL.resources.OrderSL.Mess_NoLines]);
        * ```
        */
        cancelTrigger(reasons?: Array<string> | ICancellationArguments): void;

        /**
        * Acción que muestra una ventana modal (ejecutando un ModalNavigationCommand)
        * Esta acción es asíncrona; en su acción siguiente, se tiene acceso al valor del botón seleccionado para cerrar la ventana (Ok, Cancel)
        * @param  command Comando que muestra la ventana modal
        */
        openModalWindow(command: commands.ModalNavigationCommandProperty): IAsyncActionCallback<rps.services.NavigationResult, IActionGroup<TParams>, TParams>;

        /**
        * Acción que ejecuta código de usuario de forma asíncrona (esperando para ejecutar la siguiente acción)
        * Esta acción es asíncrona; en su acción siguiente, se tiene acceso al valor devuelto por la promesa
        * @param  func Código que se ejecutará. Es importante que la función devuelva una promesa
        * @example
        * ```typescript
        *
        *   this.addRule("InvokeOnTimeout").onLoad()
        *       .then().executeAsyncCode(() => {
        *           return new Promise<string>((res, rej) => {
        *               setTimeout(() => {
        *                   res("Five seconds passed");
        *               });
        *           });
        *       }).then((result, action) => {
        *           action.continueWith().showMessage(result);
        *       });
        * ```
        */
        executeAsyncCode<TOut>(func: (params: TParams) => Promise<TOut>): IAsyncActionCallback<TOut, TReturnActionGroup, TParams>;

        /**
        * Acción que ejecuta código typescript de usuario
        * Esta acción es síncrona, con lo que no espera a terminar para ejecutar siguientes acciones.
        * @param func Código a ejecutar
        * @example
        * ```typescript
        *
        *   this.addRule("OnChangeCustomer").onPropertyChanged(this.CodCustomer)
        *       .then().executeCode(() => {
        *           if (this.CodCustomer.value == "XXXX")
        *               this.IsAutomatic.value = true;
        *           }).cleanValue(this.CodArticle);
        * ```
        */
        executeCode(func: (params: TParams) => any): TReturnActionGroup;

        /**
        * Acción que ejecuta código typescript asíncrono de usuario
        * @param func Código a ejecutar
        * @example
        * ```typescript
        *
        *   this.addRule("OnChangeCustomer").onPropertyChanged(this.CodCustomer)
        *       .then().addAsyncCode(() => {
        *           return this.Query.execute();
        * ```
        */
        addAsyncCode(func: (params: TParams) => Promise<any>): TReturnActionGroup;

        /**
        * Acción que ejecuta una acción (clase que implemente ApiActionCommand)
        * Esta acción es asíncrona; en su acción siguiente, se tiene acceso a los valores devueltos por la acción
        * @param  action Acción (ApiActionCommand) que se va a ejecutar
        * @example
        * ```typescript
        *
        *   this.addRule("InsertRest").onPropertyChanged(this.CodFromOrder)
        *       .then().executeAction(this.InsertRestOrderFromOrder,
        *           {
        *               CodOrder: this.CodFromOrder,
        *               OrderDate: (() => { return new Date(); })
        *       }).then((result, action) => {
        *           action.continueWith().setValue(this.NewCodOrder, result.CodOrderRest);
        *       });
        * ```
        */
        executeAction<TInput, TOutput>(action: rps.viewmodels.commands.ApiActionCommand<TInput, TOutput>, inputParams?: TInput): IAsyncActionCallback<TOutput, TReturnActionGroup, TParams>;

        pass(): TReturnActionGroup;
    }

    export interface IActionGroup<TParams> extends IActionGroupBase<IActionGroup<TParams>,TParams> {
    }

    export interface IMainActionGroup<TParams> extends IActionGroupBase<IMainActionGroup<TParams>, TParams> {
        else(): IActionGroup<TParams>;
    }

    export class ActionGroup<TParams> implements IMainActionGroup<TParams> {

        private actions: Array<Action>;
        private parentRule: TypedRule<TParams>;

        constructor(parentRule, actions?:Array<Action> ) {
            this.parentRule = parentRule;
            this.actions = actions || new Array<Action>();
        }

        public execute(triggerParams?:any): Promise<RuleExecutionResult> {
            return this.actions.reduce((prevPromise, action) => {
                return prevPromise.then((result) => {
                    if (result.value === RuleExecutionValue.Continue)
                        return action.executeAsync(triggerParams).catch((err) => {
                            //Si casca la ejecución de una regla, añadir el error y cancelar el resto de reglas
                            rps.app.errorManager.add(
                                new rps.errors.ErrorDetail("RULE_ERROR", rps.app.resources.errors.ERR_ERROR_EXECUTING_RULE.format((this.parentRule?this.parentRule.ID : ""), rps.errors.getErrorString(err))));

                            return Promise.resolve<rules.RuleExecutionResult>({ value: RuleExecutionValue.Cancel,reasons: result.reasons });
                        });
                    else
                        return Promise.resolve<rules.RuleExecutionResult>({ value: RuleExecutionValue.Cancel, reasons: result.reasons});
                })
            }, Promise.resolve<RuleExecutionResult>({ value: RuleExecutionValue.Continue }));
        }

        public else(): IActionGroup<TParams> {
            return this.parentRule.else();
        }

        public cleanValue(field: rps.viewmodels.properties.VMProperty<any>): IMainActionGroup<TParams> {
            this.actions.push(new CleanValueAction(field,this.parentRule));
            return this;
        }

        public lockField(field: rps.viewmodels.properties.VMProperty<any>): IMainActionGroup<TParams> {
            this.actions.push(new LockFieldAction(field, this.parentRule));
            return this;
        }

        public unlockField(field: rps.viewmodels.properties.VMProperty<any>): IMainActionGroup<TParams> {
            this.actions.push(new UnlockFieldAction(field, this.parentRule));
            return this;
        }

        public lockModel(target: rps.viewmodels.BaseVM, reason: string): IMainActionGroup<TParams> {
            this.actions.push(new LockModelAction(target, this.parentRule, reason));
            return this;
        }

        public unlockModel(target: rps.viewmodels.BaseVM): IMainActionGroup<TParams> {
            this.actions.push(new UnlockModelAction(target, this.parentRule));
            return this;
        }

        public disableAddEntity(target: rps.ICanAddEntity, reason: string): IMainActionGroup<TParams> {
            this.actions.push(new DisableAddEntityAction(target, this.parentRule, reason));
            return this;
        }

        public setValue(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any): IMainActionGroup<TParams> {
            this.actions.push(new SetValueAction(field, value, this.parentRule));
            return this;
        }

        public setSemanticState(field: rps.viewmodels.properties.VMProperty<any>, state: rps.viewmodels.rules.SemanticState): IMainActionGroup<TParams> {
            this.actions.push(new SetSemanticStateAction(field, state, this.parentRule));
            return this;
        }

        public executeQuery(query: IExecutableQuery): IAsyncActionCallback<rps.data.parameters.QueryParams, IMainActionGroup<TParams>, TParams> {
            var action = new ExecuteQueryAction<IMainActionGroup<TParams>>(this.parentRule, this, query);
            this.actions.push(action);
            return action;
        }

        public executeAction(apiAction: rps.viewmodels.commands.ApiActionCommand<{}, {}>, inputParams?: {}): IAsyncActionCallback<{}, IMainActionGroup<TParams>, TParams> {
            var action = new ExecuteApiActionAction<IMainActionGroup<TParams>>(this.parentRule, this, apiAction,inputParams);
            this.actions.push(action);
            return action;
        }

        public invokeCommand(command: commands.CommandProperty): IAsyncActionCallback<any, IMainActionGroup<TParams>, TParams> {
            var action = new InvokeCommandAction<IMainActionGroup<TParams>>(this.parentRule,this,command);
            this.actions.push(action);
            return action;
        }

        public executeCode(func: (params: TParams) => void): IMainActionGroup<TParams> {
            this.actions.push(new ExecuteCustomCodeAction(this.parentRule, func));
            return this;
        }

        public showMessage(message: string): IAsyncActionCallback<rps.services.MessageResult, IMainActionGroup<TParams>, TParams>
        public showMessage(message: string, messageButton: rps.services.MessageButton, messageType: rps.services.MessageType): IAsyncActionCallback<rps.services.MessageResult, IMainActionGroup<TParams>, TParams>
        public showMessage(message?: string, messageButton?: rps.services.MessageButton, messageType?: rps.services.MessageType): IAsyncActionCallback<rps.services.MessageResult, IMainActionGroup<TParams>, TParams> {
            var action = new ShowMessageAction<IMainActionGroup<TParams>>(this.parentRule, this, message, messageButton, messageType)
            this.actions.push(action);
            return action;
        }

        public showNotification(message: string): IMainActionGroup<TParams>
        public showNotification(message: string, notificationType: rps.services.NotificationType): IMainActionGroup<TParams>
        public showNotification(message: string, notificationType?: rps.services.NotificationType): IMainActionGroup<TParams>{ 
            this.actions.push(new ShowNotificationAction(this.parentRule, message,));
            return this;
        }

        public openModalWindow(command: commands.ModalNavigationCommandProperty): IAsyncActionCallback<rps.services.NavigationResult, IActionGroup<TParams>, TParams> {
            var openModalAction = new OpenModalWindowAction<IActionGroup<TParams>>(this.parentRule, this, command);
            this.actions.push(openModalAction);
            return openModalAction;
        }

        public executeAsyncCode(func: (params: TParams) => Promise<any>): IAsyncActionCallback<any, IMainActionGroup<TParams>, TParams>{
            var executeCodeAction = new ExecuteAsyncCustomCodeAction<IMainActionGroup<TParams>,TParams>(this.parentRule, this, func);
            this.actions.push(executeCodeAction);
            return executeCodeAction;
        }

        public addAsyncCode(func: (params: TParams) => Promise<any>): IMainActionGroup<TParams> {
            var executeCodeAction = new ExecuteAsyncCustomCodeAction<IMainActionGroup<TParams>, TParams>(this.parentRule, this, func);
            this.actions.push(executeCodeAction);
            return this;
        }

        public cancelTrigger(reasons?: Array<string> | ICancellationArguments): void {
            let r: ICancellationArguments;
            if (rps.object.isArray(reasons))
                r = {
                    reasons: reasons
                };
            else
                r = reasons;

            var cancelTriggerAction = new CancelTriggerAction<IMainActionGroup<TParams>>(this.parentRule, this, r);
            this.actions.push(cancelTriggerAction);
        }

        public pass(): IMainActionGroup<TParams> {
            return this;
        }

    }

    export class Action {
        Type: ActionTypes;
        protected parentRule: TypedRule<any>;

        constructor(actionType: ActionTypes, parentRule: TypedRule<any>) {
            this.Type = actionType;
            this.parentRule = parentRule;
        }

        public executeAsync(triggerParams?: any): Promise<RuleExecutionResult> {
            this.execute(triggerParams);
            return Promise.resolve({ value: RuleExecutionValue.Continue });
        }

        public execute(triggerParams?: any): void {
        }
    }

    export class AsyncAction<TResult, TParentActionGroup, TParams> extends Action implements IAsyncActionCallback<TResult, TParentActionGroup, TParams>, IAsyncAction<TParams> {
        protected callback: (result: TResult, action: IAsyncAction<TParams>, params:TParams) => void;
        private parentActionGroup: TParentActionGroup;
        protected nestedActionGroup: ActionGroup<any>;

        constructor(actionType: ActionTypes, parentRule: TypedRule<any>, parentActionGroup: TParentActionGroup) {
            super(actionType, parentRule);
            this.parentActionGroup = parentActionGroup;
        }

        continueWith(): ActionGroup<any> {
            this.nestedActionGroup = new ActionGroup(this.parentRule);
            return this.nestedActionGroup;
        }

        cancelTrigger(reasons?: Array<string> | ICancellationArguments): void {
            this.parentRule.cancelTrigger(reasons);
        }

        then(callback: (result: TResult, action: IAsyncAction<TParams>, params:TParams) => void): TParentActionGroup {
            this.callback = callback;

            //Devuelve el grupo de acciones, por si quiere encadenar más sin esperar al callback
            return this.parentActionGroup;
        }

        protected processCallback(result: any, triggerParams: TParams): Promise<RuleExecutionResult> {
            if (this.callback)
                this.callback.call(this, result, this,triggerParams);

            if (this.parentRule && this.parentRule.triggerCancelled) {
                this.nestedActionGroup = null;
                return Promise.resolve<RuleExecutionResult>({
                    value: RuleExecutionValue.Cancel,
                    reasons: this.parentRule.triggerCancelledParams.reasons,
                    notificationOptions: this.parentRule.triggerCancelledParams.notificationOptions
                });
            }

            if (this.nestedActionGroup) {
                var nestedActionGroupRetValue = this.nestedActionGroup.execute(triggerParams);
                this.nestedActionGroup = null;
                return nestedActionGroupRetValue;
            }

            if (this.parentRule && this.parentRule.triggerCancelled)
                return Promise.resolve({
                    value: RuleExecutionValue.Cancel,
                    reasons: this.parentRule.triggerCancelledParams.reasons,
                    notificationOptions: this.parentRule.triggerCancelledParams.notificationOptions
                });
            else
                return Promise.resolve({ value: RuleExecutionValue.Continue });
        }
    }


    export class FieldAction extends Action {
        protected field: rps.viewmodels.properties.VMProperty<any>;
        constructor(actionType: ActionTypes, field: rps.viewmodels.properties.VMProperty<any>, parentRule: TypedRule<any>) {
            super(actionType, parentRule);
            this.field = field;
        }
    }

    class CleanValueAction extends FieldAction {
        constructor(field: rps.viewmodels.properties.VMProperty<any>, parentRule: TypedRule<any>) {
            super(ActionTypes.CleanValue, field,parentRule);
        }

        public execute() {
            this.field.cleanValue();
        }
    }

    class LockFieldAction extends FieldAction {
        constructor(field: rps.viewmodels.properties.VMProperty<any>, parentRule: TypedRule<any>) {
            super(ActionTypes.LockField, field, parentRule);
        }

        public execute() {
            this.field.isLocked = true;
        }
    }

    class UnlockFieldAction extends FieldAction {
        constructor(field: rps.viewmodels.properties.VMProperty<any>, parentRule: TypedRule<any>) {
            super(ActionTypes.UnlockField, field, parentRule);
        }

        public execute() {
            this.field.isLocked = false;
        }
    }

    class LockModelAction extends Action {
        constructor(private target: rps.viewmodels.BaseVM, parentRule: TypedRule<any>, private reason: string) {
            super(ActionTypes.LockModel,parentRule);
        }

        public execute() {
            this.target.setLocked(true, this.reason);
        }
    }

    class UnlockModelAction extends Action {
        constructor(private target: rps.viewmodels.BaseVM, parentRule: TypedRule<any>) {
            super(ActionTypes.UnlockModel, parentRule);
        }

        public execute() {
            this.target.setLocked(false);
        }
    }

    class DisableAddEntityAction extends Action {
        constructor(private target: rps.ICanAddEntity, parentRule: TypedRule<any>, private reason: string) {
            super(ActionTypes.DisableAddEntity, parentRule);
        }

        public execute() {
            this.target.disableAddEntity(this.reason);
        }
    }

    class SetValueAction extends FieldAction {

        private value: rps.viewmodels.properties.VMProperty<any> | any;

        constructor(field: rps.viewmodels.properties.VMProperty<any>, value: rps.viewmodels.properties.VMProperty<any> | any, parentRule: TypedRule<any>) {
            super(ActionTypes.SetValue, field, parentRule);
            this.value = value;
        }

        public execute() {
            if (this.value instanceof rps.viewmodels.properties.VMProperty)
                this.field.value = this.value.value;
            else if (rps.object.isFunction(this.value))
                this.field.value = this.value.call(this.field.getTarget());
            else
                this.field.value = this.value;
        }
    }

    export class SetSemanticStateAction extends FieldAction {

        private value: rps.viewmodels.properties.VMProperty<any> | any;

        constructor(field: rps.viewmodels.properties.VMProperty<any>, state: SemanticState, parentRule: TypedRule<any>) {
            super(ActionTypes.SetSemanticState, field, parentRule);
            this.value = state;
        }

        public execute() {
            //Los valores de los enumerados coinciden en los dos (VMProperty y reglas), aunque sean diferentes
            this.field.semanticState = this.value;
        }
    }

    class ShowMessageAction<TParentActionGroup> extends AsyncAction<rps.services.MessageResult, TParentActionGroup,any>{

        private message: string;
        private messageButton: rps.services.MessageButton;
        private messageType: rps.services.MessageType;

        constructor(parentRule: TypedRule<any>, parentActionGroup: TParentActionGroup, message: string , messageButton?: rps.services.MessageButton, messageType?: rps.services.MessageType) {
            super(ActionTypes.ShowMessage,parentRule, parentActionGroup)

            this.message = message;
            this.messageButton = messageButton;
            this.messageType = messageType;
        }

        executeAsync(triggerParams?: any): Promise<RuleExecutionResult> {            
            return rps.app.messageManager.show({
                message: this.message,
                messageButton: this.messageButton | rps.services.MessageButton.Ok,
                messageType: this.messageType | rps.services.MessageType.Info
            }).then((result) => {
                return this.processCallback(result, triggerParams);
            });
        }
    }

    class ShowNotificationAction extends Action{

        private message: string;
        private notificationType: rps.services.NotificationType;

        constructor(parentRule: TypedRule<any>, message: string, notificationType?: rps.services.NotificationType) {
            super(ActionTypes.ShowNotification, parentRule);

            this.message = message;
            this.notificationType = notificationType;
        }

        public execute(triggerParams?: any) {
            rps.app.notificationManager.show({
                message: this.message,
                notificationType: this.notificationType | rps.services.NotificationType.Success,
            });
        }
    }

    class ExecuteAsyncCustomCodeAction<TParentActionGroup,TParams> extends AsyncAction<any, TParentActionGroup,TParams>{

        private executionFunc: (triggerParams?: any) => Promise<any>;
        constructor(parentRule: TypedRule<any>, parentActionGroup: TParentActionGroup, func: (triggerParams?: any) => Promise<any>) {
            super(ActionTypes.ExecuteAsyncCustomCode, parentRule, parentActionGroup)

            this.executionFunc = func;
        }

        executeAsync(triggerParams?: any): Promise<RuleExecutionResult> {
            var result: any = null;
            if (this.executionFunc) {
                var target:any = this;
                if (this.parentRule && this.parentRule.ParentVM)
                    target = this.parentRule.ParentVM;
                return this.executionFunc.call(target, triggerParams).then((result) => {
                    return this.processCallback(result,triggerParams);
                }).catch((err) => {
                    return Promise.reject(err);
                });
            }
            else
                return Promise.resolve({ value: RuleExecutionValue.Continue });
        }
    }

    export class ExecuteCustomCodeAction extends FieldAction {

        private executionFunc: (triggerParams?: any) => void;

        constructor(parentRule: TypedRule<any>,executionFunc: (triggerParams?: any) => void) {
            super(ActionTypes.ExecuteCustomCode, null,parentRule);

            this.executionFunc = executionFunc;
        }

        public execute(triggerParams?: any) {
            if (this.executionFunc) {
                var target: any = this;
                if (this.parentRule && this.parentRule.ParentVM)
                    target = this.parentRule.ParentVM;
                this.executionFunc.call(target, triggerParams);
            }
        }
    }

    class ExecuteQueryAction<TParentActionGroup> extends AsyncAction<rps.data.parameters.QueryParams, TParentActionGroup,any>{

        private query: IExecutableQuery;

        constructor(parentRule: TypedRule<any>, parentActionGroup: TParentActionGroup, query: IExecutableQuery) {
            super(ActionTypes.ExecuteQuery, parentRule, parentActionGroup);
            this.query = query;
        }

        executeAsync(triggerParams?: any): Promise<RuleExecutionResult> {
            if (this.query) {
                return this.query.execute().then((result) => {
                    return this.processCallback(result, triggerParams);
                }).catch((err) => {
                    return Promise.reject(err);
                });
            }

            return Promise.resolve({ value: RuleExecutionValue.Continue });
        }
    }

    class ExecuteApiActionAction<TParentActionGroup> extends AsyncAction<rps.data.parameters.QueryParams, TParentActionGroup,any>{

        private action: rps.viewmodels.commands.ApiActionCommand<{}, {}>;
        private inputParams: {};
        constructor(parentRule: TypedRule<any>, parentActionGroup: TParentActionGroup, action: rps.viewmodels.commands.ApiActionCommand<{}, {}>, inputParams?: {}) {
            super(ActionTypes.ExecuteAction, parentRule, parentActionGroup);
            this.action = action;
            if (inputParams)
                this.inputParams = inputParams;
        }

        executeAsync(triggerParams?: any): Promise<RuleExecutionResult> {
            if (this.action) {
                return this.action.execute(this.inputParams).then((result) => {
                    return this.processCallback(result, triggerParams);
                }).catch((err) => {
                    return Promise.reject(err);
                });
            }

            return Promise.resolve({ value: RuleExecutionValue.Continue });
        }
    }
    
    class InvokeCommandAction<TParentActionGroup> extends AsyncAction<any, TParentActionGroup,any>{

        private command: commands.CommandProperty;

        constructor(parentRule: TypedRule<any>, parentActionGroup: TParentActionGroup, command: commands.CommandProperty) {
            super(ActionTypes.InvokeCommand, parentRule, parentActionGroup)

            this.command = command;
        }

        executeAsync(triggerParams?: any): Promise<RuleExecutionResult> {            
            return this.command.execute().then((result) => {
                return this.processCallback(result, triggerParams);
            }).catch((reason) => {
                return this.processCallback(reason, triggerParams);
            });
        }
    }

    class OpenModalWindowAction<TParentActionGroup> extends AsyncAction<rps.services.NavigationResult, TParentActionGroup,any>{

        private command: commands.ModalNavigationCommandProperty;

        constructor(parentRule: TypedRule<any>, parentActionGroup: TParentActionGroup, command: commands.ModalNavigationCommandProperty ) {
            super(ActionTypes.ShowMessage, parentRule, parentActionGroup)

            this.command = command;
        }

        executeAsync(triggerParams?: any): Promise<RuleExecutionResult> {
            return this.command.execute().then((result) => {
                return this.processCallback(result,triggerParams);
            }).catch((reason) => {
                return this.processCallback(reason,triggerParams);
            });
        }
    }

    class CancelTriggerAction<TParentActionGroup> extends AsyncAction<any, TParentActionGroup,any>{

        private reasons: ICancellationArguments;

        constructor(parentRule: TypedRule<any>, parentActionGroup: TParentActionGroup, reasons:ICancellationArguments) {
            super(ActionTypes.CancelTrigger, parentRule, parentActionGroup)

            this.reasons = reasons;
        }

        executeAsync(triggerParams?: any): Promise<RuleExecutionResult> {
            this.parentRule.triggerCancelled = true;
            if (this.reasons)
                this.parentRule.triggerCancelledParams = this.reasons;
            return this.processCallback(true,triggerParams);
        }
    }

    export enum Triggers {
        None,
        PropertyChanged,
        OnLoad,
        OnNavigate,
        OnExit,
        OnBeforeSave,
        OnBeforeDelete,
        OnAfterSave,
        OnBeforePropertyChanged,
        OnBeforeCollectionChanged,
        OnCollectionChanged,
        OnExecuteCommand,
        OnExecuteQuery,
        OnExecuteAction,
        OnActivate,
        OnEnter
    }
} 