/// <reference path="./baseVM.ts" />
/// <reference path="./componentVM.ts" />
module rps.viewmodels {

    export class MaintenanceComponentVM<T extends rps.viewmodels.MainEntityVM<rps.entities.IMainEntity>> extends ComponentVM
        implements rps.ICanAddEntity {        

        /** @internal */
        private modelVMFunction: Function;
        /** @internal */
        private modelVMState: string;
        /** @internal */
        protected denyAddEntityReason: string;

        /** @internal */
        public addEntityCommand: rps.viewmodels.commands.CommandProperty;

        configure(configParams: {
            modelVMFunction: Function;
            modelVMState: string;
        }): Promise<BaseVM> {
            this.modelVMFunction = (<any>(configParams.modelVMFunction)).getResolvedType(configParams.modelVMFunction);
            this.modelVMState = configParams.modelVMState;
            return super.configure(configParams);
        }

        /** @internal */
        configureVMProperties() {
            this.addEntityCommand = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: this.addEntityCommandExecute,
                canExecute: this.addEntityCommandCanExecute,
                relatedAction: rps.services.Action.New
            });

            super.configureVMProperties();
        }

        /** @internal */
        public resolveStateViewModel(stateParams: rps.services.IStateParams): Promise<BaseVM> {
            //Crear una copia
            var createParams = JSON.parse(JSON.stringify(stateParams));
            createParams["parentVM"] = this;

            //Crear el VM y meterlo en la lista
            if (createParams.stateName == this.modelVMFunction["relatedState"]) {
                return rps.app.viewModelFactory.createViewModel(this.modelVMFunction, createParams).then((item: BaseVM) => {
                    return item.resolveStateViewModel(createParams);
                });
            }
            else
                return super.resolveStateViewModel(createParams);
        }

        /** @internal */
        public disableAddEntity(reason: string): void {
            this.denyAddEntityReason = reason;
        }

        /** @internal */
        public addEntityCommandExecute(): Promise<any> {
            var newEntityLink: rps.viewmodels.properties.LinkProperty = new rps.viewmodels.properties.LinkProperty({
                state: this.modelVMState,
                parameters: [{
                    name: this.modelVMFunction["idPropertyName"],
                    property: EntityVM.NEW_ID_VALUE
                }]
            });
            return newEntityLink.go();
        }

        /** @internal */
        public addEntityCommandCanExecute(): rps.viewmodels.commands.CanExecuteResult {
            if (rps.string.isNullOrEmpty(this.denyAddEntityReason))
                return rps.viewmodels.commands.CanExecuteResult.allow();
            else
                return rps.viewmodels.commands.CanExecuteResult.deny([this.denyAddEntityReason]);
        }
    }

    export class RecursiveHierarchicalMaintenanceComponentVM<T extends rps.viewmodels.RecursiveHierarchicalMainEntityVM<rps.entities.IMainEntity>> extends rps.viewmodels.MaintenanceComponentVM<T> {

        //Declaración de las propiedades
        /** @rpsInternal */
        public RecursiveHierarchicalQuerySource: rps.data.sources.RecursiveHierarchicalQuerySource;

        /** @internal */
        public refreshNew(newEntityVM: T): Promise<any> {
            return this.RecursiveHierarchicalQuerySource.refreshNew(newEntityVM);
        }

        /** @internal */
        public deleteItem(deletedEntityID: string, deletedEntityParentID: string) {
            this.RecursiveHierarchicalQuerySource.deleteItem(deletedEntityID, deletedEntityParentID);
        }

        /** @internal */
        public cancelNew(newEntityVM: T) {
            this.RecursiveHierarchicalQuerySource.cancelNew(newEntityVM);
        }

        /** @internal */
        public undoDragend(entityID: string) {
            this.RecursiveHierarchicalQuerySource.undoDragend(entityID);            
        }

        /** @internal */
        public selectSelectedVMID() {
            this.RecursiveHierarchicalQuerySource.selectSelectedID();
        }

        /** @internal */
        onActivate() {
            super.onActivate();            
            return this.RecursiveHierarchicalQuerySource.findAndSelectEntityVM(null);
        }
    }

    export class MultipleMaintenanceComponentVM<T extends rps.viewmodels.MultipleMainEntityVM<rps.entities.IBaseEntity>> extends ComponentVM {

        /** @internal */
        private modelVMFunction: Function;

        //Lista de entidades que mantiene el VM
        /** @rpsInternal */
        public entitySource: rps.data.sources.EntitySource;

        /** @internal */
        configure(configParams: {
            modelVMFunction: Function;
        }): Promise<BaseVM> {
            this.modelVMFunction = (<any>(configParams.modelVMFunction)).getResolvedType(configParams.modelVMFunction);
            return super.configure(configParams);
        }

        /** @internal */
        resolveStateViewModel(stateParams: rps.services.IStateParams): Promise<BaseVM> {
            if (stateParams.stateName == this.modelVMFunction["relatedState"])
                return this.entitySource.resolveStateViewModel(stateParams);
            else
                return super.resolveStateViewModel(stateParams);
        }

        /** @internal */
        save: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                return this.executeSave();
            },
            canExecute: this.canExecuteSave,
            relatedAction: rps.services.Action.Save
        });

        canExecuteSave(): commands.CanExecuteResult {
            return this.entitySource.canExecuteSave();
        }

        executeSave(): Promise<boolean> {
            return this.entitySource.executeSave();               
        }

        /** @internal */
        new: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                return this.executeNew();
            },
            canExecute: this.canExecuteNew,
            relatedAction: rps.services.Action.New
        });

        canExecuteNew(): commands.CanExecuteResult {
            if (this.entitySource.loaded)
                return commands.CanExecuteResult.allow();
            else
                return commands.CanExecuteResult.deny([rps.app.resources.messages.MSG_UNLOADED_SOURCE]);
        }

        executeNew(): Promise<rps.viewmodels.MultipleMainEntityVM<rps.entities.BaseEntity>> {
            return this.entitySource.add().then((newVM) => {
                if (newVM.selfLink)
                    return newVM.selfLink.go();
            });
        }

        onBeforeUnload(): Promise<boolean> {
            return super.onBeforeUnload().then((result) => {
                if (result)
                    return this.savedBeforeUnload();
            });
        }

        /** @internal */
        savedBeforeUnload(): Promise<boolean> {
            if (this.canExecuteSave().result) {
                return rps.app.messageManager.show({
                    message: rps.app.resources.messages.MSG_ASK_SAVE_CHANGES,
                    messageButton: rps.services.MessageButton.YesNoCancel,
                    messageType: rps.services.MessageType.Question,
                    yesOptions: {
                        text: rps.app.resources.messages.MSG_SAVE_CHANGES,
                        isPrimary: true
                    },
                    noOptions: {
                        text: rps.app.resources.messages.MSG_DISCARD_CHANGES
                    }
                }).then((result) => {
                    if (result === rps.services.MessageResult.Yes)
                        return this.executeSave();
                    else if (result === rps.services.MessageResult.No)
                        return true;
                    else
                        return false;
                });
            }
            else
                return Promise.resolve<boolean>(true);
        }
    }
}