/// <reference path="../viewmodels/baseVM.ts" />
module rps.data.sources {

    export class EnumSourceItem extends rps.viewmodels.BaseVM{        
        get stringValue(): string {
            return this.value.toString();
        } 
        set stringValue(newValue: string) {
        }
        constructor(public value: number, public description: string) {
            super();
        }
    }

    export class EnumSource extends rps.viewmodels.ObservableArray<EnumSourceItem>{

        constructor(values: Array<{ value: number, description: string }>) {
            var items: Array<EnumSourceItem> = new Array<EnumSourceItem>();

            values.forEach((value) => {
                items.push(new EnumSourceItem(value.value, value.description));
            });

            super(items);
        }
    }
} 