/// <reference path="../baseVM.ts" />

module rps.viewmodels.adapters {
    export abstract class FileUploaderAdapterBase extends rps.viewmodels.BaseVM {
        protected vm: rps.viewmodels.BaseVM;

        public enabledChanged: rps.services.IEventEmitter<boolean>;
        public multipleChanged: rps.services.IEventEmitter<boolean>;
        public allowedExtensionsChanged: rps.services.IEventEmitter<Array<string>>;
        public fileUploaded: rps.services.IEventEmitter<{ uploadTicket: string, fileName: string }>;

        constructor() {
            super();
            this.enabledChanged = rps.app.eventManager.createEmitter<boolean>();
            this.multipleChanged = rps.app.eventManager.createEmitter<boolean>();
            this.allowedExtensionsChanged = rps.app.eventManager.createEmitter<Array<string>>();
            this.fileUploaded = rps.app.eventManager.createEmitter<{ uploadTicket: string, fileName: string }>();
        }

        initialize(parameters: rps.viewmodels.adapters.InitParams): Promise<GanttAdapterBase> {
            this.vm = parameters.vm;
            return super.initialize(parameters);
        }

        onFileUploaded(uploadTicket: string, fileName: string): void {
            this.fileUploaded.emit({ uploadTicket: uploadTicket, fileName: fileName });
        }

        private _enabled: boolean = true;
        public get enabled(): boolean {
            return this._enabled;
        }
        public set enabled(value: boolean) {
            if (this._enabled != value) {
                this._enabled = value;
                this.enabledChanged.emit(this._enabled);
            }
        }

        private _multiple: boolean = true;
        public get multiple(): boolean {
            return this._multiple;
        }
        public set multiple(value: boolean) {
            if (this._multiple != value) {
                this._multiple = value;
                this.multipleChanged.emit(this._multiple);
            }
        }

        private _allowedExtensions: Array<string> = null;
        public get allowedExtensions(): Array<string> {
            return this._allowedExtensions;
        }
        public set allowedExtensions(value: Array<string>) {
            if (this._allowedExtensions != value) {
                this._allowedExtensions = value;
                this.allowedExtensionsChanged.emit(this._allowedExtensions);
            }
        }        
    }
}