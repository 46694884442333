/// <reference path="parameters.ts" />

module rps.data.sources {

    export class LookupSource implements IDestroyable {
        public isIDestroyable: boolean = true;

        private queryName: string;
        private paramsChanged: rps.services.IEventEmitter<any>;
        public queryParams: parameters.QueryParams;
        public hasColumnsToRender: boolean = false;
        public hasDescriptorField: boolean = false;

        constructor(params: {
            queryName: string;
            queryParams?: parameters.QueryParams;
            hasColumnsToRender?: boolean;
            hasDescriptorField?: boolean;
        }) {
            this.queryName = params.queryName;
            if (params.queryParams)
                this.queryParams = params.queryParams;
            if (params.hasColumnsToRender)
                this.hasColumnsToRender = true;
            if (params.hasDescriptorField)
                this.hasDescriptorField = true;
            this.paramsChanged = rps.app.eventManager.createEmitter<any>(false);
        }

        private getParams(): { [id: string]: Object; } {
            var params: { [id: string]: Object; } = {};
            params["$forlookup"] = true;
            if (this.queryParams) {
                this.queryParams.forEach((queryParam: parameters.QueryParam) => {
                    params[queryParam.paramName] = queryParam.getValue();
                });
            }
            return params;
        }

        onDestroy() {
            this.paramsChanged.onDestroy();
        }

        private launchParamsChanged() {
            this.paramsChanged.emit(null);
        }

        public initialize(): Promise<any> {
            //Nos interesa saber que un parámetro ha cambiado su valor, para lanzar el evento y que se enteren los combos en cascada
            if (this.queryParams) {
                this.queryParams.forEach((ip: parameters.QueryParam) => {
                    if (ip.value && ip.value instanceof rps.viewmodels.properties.VMProperty) {
                        (<rps.viewmodels.properties.VMProperty<any>>ip.value).propertyChanged.subscribe((subscriptionArgs: rps.viewmodels.properties.VMPropertyChange) => {
                            if (subscriptionArgs.propertyName == "value") {
                                this.launchParamsChanged();
                            }
                        });
                    }
                });
            }

            return Promise.resolve<any>(this);
        }        

        public getService(): string {
            return this.queryName.substr(0, this.queryName.indexOf("/"));
        }

        public getQueryName(): string {
            return this.queryName.substr(this.queryName.indexOf("/") + 1, this.queryName.length - this.queryName.indexOf("/") - 1)
        }

        public getMatches(params: {
            filter: string;
            exact?: boolean;
            page: number;
            pageSize: number; }): Promise<{ count: number; items: rps.viewmodels.ObservableArray<any> }> {
            return new Promise<{ count: number; items: rps.viewmodels.ObservableArray<any> }>((resolve,reject) => {
                var queryParams: { [id: string]: Object; } = this.getParams();
                if (params.filter && params.filter.length > 0) {
                    queryParams["$search"] = params.filter;
                    if (params.exact) {
                        queryParams["$searchexact"] = "true";
                    }
                }
                rps.app.api.query<rps.data.PaginatedViewResult>({
                    queryName: this.queryName,
                    pageOptions: { page: params.page, pageSize: params.pageSize },
                    params: queryParams
                }).then((result) => {
                    var newItems: rps.viewmodels.ObservableArray<any> = new rps.viewmodels.ObservableArray<any>(result.Data);
                    resolve({
                        count: result.Count,
                        items: newItems
                    });
                }).catch((error) => {
                    reject(error);
                });
            });
        }

        getItems(entityIDs: Array<string>): Promise<Array<any>> {
            var promises: Array<Promise<any>> = new Array<Promise<any>>();
            entityIDs.forEach((entityID: string) => {
                promises.push(this.getItem(entityID));
            });
            return Promise.all(promises).then((results: Array<any>) => {
                return results;
            });
        }

        public getItem(entityID: string): Promise<any> {
            var params: { [id: string]: Object; } = this.getParams();
            params["$id"] = entityID;
            return rps.app.api.query<rps.data.PaginatedViewResult>({
                queryName: this.queryName,
                params: params
            }).then((result) => {
                if (result.Data.length > 0)
                    return result.Data[0];
                else
                    throw new rps.errors.ErrorDetail("ERR_ENTITY_NOT_FOUND", "Entity not found");
            });
        }

        public variable = ():boolean => {
            if (this.queryParams && this.queryParams.asEnumerable().Any(qp => qp.variable))
                return true;
            else
                return false;
        }

        public exist(entityID: string): Promise<boolean> {
            var params: { [id: string]: Object; } = this.getParams();       
            params["$id"] = entityID;
            return rps.app.api.query<rps.data.PaginatedViewResult>({
                queryName: this.queryName,                
                params: params
            }).then((result) => {
                if (result.Data && result.Data.length > 0)
                    return true;
                else
                    return false;
            }).catch((error) => {
                return false;
            });
        }
        
        public attachToParamsChanged = (newFunction: () => void) => {
            this.paramsChanged.subscribe(newFunction);
        }
    }

} 