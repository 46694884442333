module rps.data.sources {

    export interface IColumnsHandler {
        IColumnsHandler: boolean;
        variableColumns: Array<rps.ui.itemsControl.ColumnDefinition>
    }

    export enum ViewType {
        Grid = 0, Card = 1
    };

    export class IView {
        value: string;
        type: ViewType;
        name: string;
        allowEditItem: boolean;
    }

    export class ComboSourceItem extends rps.viewmodels.BaseVM {
        constructor(public value: string, public description: string, public group?: string) {
            super();
        }
    }

    export class ComboSource extends rps.viewmodels.ObservableArray<ComboSourceItem>{
        public isGrouped: boolean;

        constructor(params: {
            values: Array<any>;
            valuePath: string;
            displayPath: string;
            groupPath?: string;
            preventAlphabeticalOrder?: boolean}) {
            //Se pueden crear orígenes de datos, que aparezcan agrupados al desplegar el dropDown
            var isGrouped: boolean =false;
            if (rps.object.hasValue(params.groupPath))
                isGrouped = true;  

            //Se crea un array con los elementos, para llamarle al constructor padre
            var items: Array<any> = new Array<any>();

            //En el caso de que no se especifique explícitamente, se ordena la lista por el valor que se muestra
            var orderedValues: linq.Enumerable<any>;
            if (params.preventAlphabeticalOrder)
                orderedValues = Enumerable.From<any>(params.values);
            else
                orderedValues = Enumerable.From<any>(params.values).OrderBy(v => v[params.displayPath])

            orderedValues.forEach((value) => {                
                if (isGrouped)
                    items.push(new ComboSourceItem(value[params.valuePath], value[params.displayPath], value[params.groupPath]));                    
                else
                    items.push(new ComboSourceItem(value[params.valuePath], value[params.displayPath]));                    
            });

            //Llamar a la base y configurar la clase
            super(items);
            this.isGrouped = isGrouped;
        }
    }
}