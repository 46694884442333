/// <reference path="../entities/entityModel.ts" />
declare var System;

module rps.viewmodels.properties {

    export enum SemanticState {
        Neutral = 0,
        Positive = 1,
        Info = 2,
        Warning = 3,
        Negative = 4
    }

    export enum DateStyles {
        Date = 0,
        DateAndTime = 1,
        Calendar = 2,
        TimeAgo = 3
    }

    export interface VMPropertyChange {
        propertyName: string;
        newValue: any;
    }

    export interface IBeforePropertyChangedArgs<T> {
        previousValue: T;
        nextValue: T;
    }

    /**
     * Clase que define una propiedad de un viewModel; todas las propiedades de un Vm serán de este tipo complejo, 
     * que además del valor, dota de funcionalidad a la propiedad
     */
    export class VMProperty<T> extends rps.viewmodels.ObservableObject implements IDestroyable, rps.viewmodels.rules.IRuleTrigger {
        /** @internal */
        public isIDestroyable: boolean = true;

        /** @internal */
        protected preventValidateValue: boolean = true;

        /** @internal */
        public IRuleTrigger: boolean = true;
        
        public propertyChanged = rps.app.eventManager.createEmitter<VMPropertyChange>();
        /** @internal */
        public valueChangeCancelled = rps.app.eventManager.createEmitter<null>();

        /** @internal */
        protected _target: BaseVM | any;
        get parentVM(): BaseVM | any {
            return this._target;
        }
        protected _pathParts: string[];
        public originalValue: T;
        /** @internal */
        protected setOriginalValue(newValue: T) {
            this.originalValue = newValue;
        }
        /** @internal */
        public rules: rules.Rule[] = [];
        /** @internal */
        public respondToModelChanges = true;

        /** @internal */
        private _semanticState: rps.viewmodels.properties.SemanticState;
        get semanticState(): rps.viewmodels.properties.SemanticState {
            return this._semanticState;
        }
        set semanticState(value: rps.viewmodels.properties.SemanticState) {
            if (this._semanticState != value) {
                this._semanticState = value;
                this.propertyChanged.emit({ propertyName: "semanticState", newValue: this.semanticState });
            }
        }

        /** @internal */
        private _errors: rps.errors.ErrorDetails = new rps.errors.ErrorDetails();
        public get errors(): rps.errors.ErrorDetails {
            return this._errors;;
        }
        public set errors(value: rps.errors.ErrorDetails) {
            this._errors = value;
            this.propertyChanged.emit({ propertyName: "errors", newValue: this.errors });
            this.hasErrors = (this.errors.length > 0);
        }

        /** @internal */
        private _hasErrors: boolean = false;
        public get hasErrors(): boolean {
            return this._hasErrors;;
        }
        public set hasErrors(value: boolean) {
            if (this.hasErrors != value) {
                this._hasErrors = value;
                this.propertyChanged.emit({ propertyName: "hasErrors", newValue: this.hasErrors });                
            }
        }

        constructor(params: {
            target: BaseVM | any;
            valuePropertyPath?: string;
            initialValue?: T;
            isRequired?: boolean;
            maxLength?: number;
        }) {
            super();

            this._target = params.target;

            if (params.valuePropertyPath)
                this._pathParts = params.valuePropertyPath.split('.');

            if (!rps.object.isUndefined(params.initialValue)) {
                this.value = params.initialValue;
                this.setOriginalValue(this.value);
            }

            if (!rps.object.isNullOrUndefined(params.isRequired))
                this.isRequired = params.isRequired;

            if (!rps.object.isNullOrUndefined(params.maxLength) && params.maxLength > 0)
                this.maxLength = params.maxLength;

            this.semanticState = SemanticState.Neutral;
        }

        /** @internal */
        public subscribeToModelPropertyChanged(model: rps.entities.IBaseEntity,raisePropertyChanged?:boolean) {
            if (this._pathParts) {
                if (this._pathParts.length == 2 && this._target[this._pathParts[0]] instanceof rps.entities.BaseEntity) {
                    (<rps.entities.BaseEntity>this._target[this._pathParts[0]]).propertyChanged.subscribe((e) => this.modelPropertyChanged1(e));

                    if (raisePropertyChanged)
                        this.modelPropertyChanged(true);
                }
                else if (this._pathParts.length == 3 && this._target[this._pathParts[0]] && this._target[this._pathParts[0]][this._pathParts[1]] instanceof rps.entities.BaseEntity) {
                    (<rps.entities.BaseEntity>this._target[this._pathParts[0]][this._pathParts[1]]).propertyChanged.subscribe((e) => this.modelPropertyChanged2(e));

                    if (raisePropertyChanged)
                        this.modelPropertyChanged(true);
                }
            }
        }

        /** @internal */
        private modelPropertyChanged1(e: { PropertyName: string }) {
            if (this.respondToModelChanges && e.PropertyName == this._pathParts[1])
                this.modelPropertyChanged(false);
        }

        /** @internal */
        private modelPropertyChanged2(e: { PropertyName: string }) {
            if (this.respondToModelChanges && e.PropertyName == this._pathParts[2])
                this.modelPropertyChanged(false);
        }

        /** @internal */
        private modelPropertyChanged(preventRules: boolean) {
            //Al ser cl quien ha modificado el valor, simplemente limpiara los errores
            this.clearErrors();

            //Lanzar el propertyChanged
            this.propertyChanged.emit({ propertyName: "value" , newValue: this.value });

            if (this._textWorking)
                this.refreshText();

            //Lanzar las reglas del target de tipo propertyChanged (porque los cambios en CL no hacen el setValue del vmProperty)
            if (!preventRules && this._target instanceof rps.viewmodels.BaseVM)
                this.processRules(rps.viewmodels.rules.Triggers.PropertyChanged);
        }

        /**
        Indica si la propiedad tiene como target un modelo o no
        */
        public get isModelProperty(): boolean {
            if (this._pathParts && this._pathParts.length > 0 && this._pathParts[0] == "model")
                return true;

            return false;
        }

        /** @internal */
        protected _value: T;
        get value(): T {
            if (this._pathParts) {
                var _value: T;
                if (this._pathParts.length == 1)
                    _value = this._target[this._pathParts[0]];
                else if (this._pathParts.length == 2 && this._target[this._pathParts[0]])
                    _value = this._target[this._pathParts[0]][this._pathParts[1]];
                else if (this._pathParts.length == 3 && this._target[this._pathParts[0]] && this._target[this._pathParts[0]][this._pathParts[1]])
                    _value = this._target[this._pathParts[0]][this._pathParts[1]][this._pathParts[2]];

                if (typeof this.originalValue === "undefined")
                    this.setOriginalValue(_value);

                return _value;
            }
            else
                return this._value;
        }
        set value(newValue: T) {
            if (rps.object.equals(newValue, this.value))
                return;

            //Ejecutar reglas de pre-cambio
            if (this.rules.length > 0 && Enumerable.From(this.rules).Any((rule) => rule.Trigger === rules.Triggers.OnBeforePropertyChanged)) {
                this.processCancellableRules(rules.Triggers.OnBeforePropertyChanged, {previousValue: this.value,nextValue: newValue }).then((result) => {
                    var changed: boolean = false;
                    if (result.value === rules.RuleExecutionValue.Continue)
                        this.doSetValue(newValue);
                    else {
                        //Emitir evento para que el editor se dé cuenta de que no ha habido cambio
                        this.valueChangeCancelled.emit();

                        //Mostrar notificación de la cancelación si es caso
                        if (result.notificationOptions) {
                            if (rps.object.hasValue(result.notificationOptions.showModalWindow) && result.notificationOptions.showModalWindow) {
                                rps.app.messageManager.show({
                                    message: result.reasons.join("\r\n"),
                                    messageButton: services.MessageButton.Ok,
                                    messageType: rps.object.hasValue(result.notificationOptions.messageType) ?
                                        result.notificationOptions.messageType : services.MessageType.Info,
                                });
                            }
                            else if (rps.object.hasValue(result.notificationOptions.showNotificationWindow) && result.notificationOptions.showNotificationWindow) {
                                rps.app.notificationManager.show({
                                    message: result.reasons.join("\r\n"),
                                    notificationType: rps.object.hasValue(result.notificationOptions.messageType) ?
                                        <any>result.notificationOptions.messageType : services.NotificationType.Info
                                });
                            }
                        }
                    }
                });
            }
            else {
                this.doSetValue(newValue);
            }
        }

         //Método que se invoca, cuando es el usuario el que ha establecido el nuevo valor a través de un editor
        /** @internal */
        userSetValue(newValue: T): boolean {
            //En el caso de que el valor no sea nullable, no se establece y se devuelve un false indicando que no se ha establecido el valor
            if (!this.isNullable() &&
                !rps.object.hasValue(newValue) &&
                rps.object.hasValue(this.value)) {
                return false
            }
            else {
                this.preventValidateValue = false;
                this.value = newValue;
                this.preventValidateValue = true;
                return true;
            }
        }

        protected isNullable() {
            if (this.isRequired && (typeof this.value == "number" || this.value instanceof Date))
                return false;
            else
                return true;
        }

        /** @internal */
        protected doSetValue(newValue: T): boolean {
            var hasChanged = false;
            this.respondToModelChanges = false;
            if (this._pathParts) {
                if (this._pathParts.length == 1 && this._target[this._pathParts[0]] !== newValue) {
                    hasChanged = true;
                    this._target[this._pathParts[0]] = newValue;
                }
                else if (this._pathParts.length == 2 && this._target[this._pathParts[0]][this._pathParts[1]] !== newValue) {
                    hasChanged = true;
                    this._target[this._pathParts[0]][this._pathParts[1]] = newValue;
                }
                else if (this._pathParts.length == 3 && this._target[this._pathParts[0]][this._pathParts[1]][this._pathParts[2]] !== newValue) {
                    hasChanged = true;
                    this._target[this._pathParts[0]][this._pathParts[1]][this._pathParts[2]] = newValue;
                }
            }
            else if (this._value !== newValue) {
                hasChanged = true;
                this._value = newValue;
            }
            this.respondToModelChanges = true;

            if (hasChanged) {
                this.propertyChanged.emit({ propertyName: "value", newValue: newValue });

                if (this._textWorking)
                    this.refreshText();

                //Ejecutar las reglas de UI si ha habido cambio de valor y el vm tiene tracking habilitado
                this.processRules(rules.Triggers.PropertyChanged);

                if (!this.preventValidateValue)
                    this.errors = this.checkValue(false);
                else
                    this.clearErrors();
            }

            return hasChanged;
        }

        /** @internal */
        protected checkValue(checkModelErrors:boolean): rps.errors.ErrorDetails {
            var result: rps.errors.ErrorDetails = new rps.errors.ErrorDetails();

            if (this.isRequired &&
                ((rps.object.isString(this.value) &&
                    rps.string.isNullOrEmpty(<any>this.value)) ||
                    rps.object.isNullOrUndefined(this.value))) {
                result.push(new rps.errors.ErrorDetail("isRequired", string.format(rps.app.resources.errors.ERR_VALUE_REQUIRED, this.getPropertyName())));
            }

            if (this.maxLength && this.maxLength > 0 &&
                rps.object.isString(this.value) &&
                (<string>(<any>this.value)).length > this.maxLength) {
                result.push(new rps.errors.ErrorDetail("maxLength", rps.string.format(rps.app.resources.errors.ERR_MAX_LENGTH, this.maxLength.toString(), this.getPropertyName())));                
            }

            //Mirar también si tiene errores en el target relacionado
            if (checkModelErrors && this._pathParts) {
                let errorList: errors.ErrorDetails = null;
                if (this._pathParts.length == 1 && this._target.__errors instanceof errors.ErrorDetails)
                    errorList = this._target.__errors;
                else if (this._pathParts.length == 2 && this._target[this._pathParts[0]].__errors instanceof errors.ErrorDetails)
                    errorList = this._target[this._pathParts[0]].__errors;
                else if (this._pathParts.length == 3 && this._target[this._pathParts[0]][this._pathParts[1]].__errors instanceof errors.ErrorDetails)
                    errorList = this._target[this._pathParts[0]][this._pathParts[1]].__errors;

                if (errorList && errorList.length > 0) {
                    let propertyName = this.getUnderlyingPropertyName();
                    rps.errors.createErrorDetails(
                        Enumerable.From(errorList.toArray()).Where((err) => err.property == propertyName).ToArray()).forEach((error) => {
                            result.push(error);
                    });
                }
            }

            return result;
        }

        /** @internal */
        protected getPropertyName(): string {
            for (var property in this._target) {
                if (this._target[property] == this) {
                    return property
                }
            }

            return null;
        }

        /** @internal */
        private processCancellableRules(trigger: rules.Triggers, triggerParams: IBeforePropertyChangedArgs<T>): Promise<rules.RuleExecutionResult> {

            this.rules.forEach((rule) => {
                rule.triggerCancelled = false;
                rule.triggerCancelledParams.reasons.length = 0;
            });
            var processableRules = Enumerable.From(this.rules).Where((rule) => rule.Trigger === trigger).ToArray();
            if (processableRules.length > 0)
                return processableRules.reduce((prevPromise, rule) => {
                    return prevPromise.then((result: rules.RuleExecutionResult) => {
                        if (result.value === rules.RuleExecutionValue.Continue)
                            return rule.execute(null,triggerParams);
                        else
                            return Promise.resolve<rules.RuleExecutionResult>({ value: rules.RuleExecutionValue.Cancel, reasons: result.reasons.slice(0)});
                    })
                }, Promise.resolve<rules.RuleExecutionResult>({ value: rules.RuleExecutionValue.Continue }));
            else
                return Promise.resolve<rules.RuleExecutionResult>({ value: rules.RuleExecutionValue.Continue });
        }

        /** @internal */
        private processRules(trigger?: rules.Triggers): void {
            if (this.rules && this.rules.length > 0) {
                this.rules.forEach((rule) => {
                    if (!rule.Trigger)
                        rule.execute();
                    else if (trigger && rule.Trigger == trigger)
                        rule.execute()
                });
            }

            //Lanzar también las when del target (las que no tienen Trigger)
            if (this._target && this._target.rules && this._target.rules.length > 0 && this._target.rulesEnabled) {
                Enumerable.From<rps.viewmodels.rules.Rule>(this._target.rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                    ForEach((rule) => {
                        rule.execute(this);
                    });
            }
        }

        /** @internal */
        private _text: string;
        /** @internal */
        private _textWorking: boolean = false;
        get text(): string {
            if (!this._textWorking) {
                this._textWorking = true;
                this.refreshText();
            }
            return this._text;
        }
        /** @internal */
        refreshText() {
            if (rps.object.isNullOrUndefined(this.value))
                this.set_text("");
            else {
                this.formatValue().then((result:string) => {
                    if (string.isNullOrEmpty(result))
                        this.set_text("");
                    else
                        this.set_text(result);
                });
            }
        }
        /** @internal */
        set_text(newValue: string) {
            if (this._text != newValue) {
                this._text = newValue;
                this.propertyChanged.emit({ propertyName: "text", newValue: this._text });
            }
        }

        /** @internal */
        public formatValue(): Promise<string> {
            var unformValue: T = this.value;
            if (unformValue && unformValue.toString)
                return Promise.resolve(unformValue.toString());
            else
                return Promise.resolve<any>(unformValue);
        }

        /** @internal */
        protected _isLocked: boolean = false;
        get isLocked(): boolean {
            return this._isLocked || this._isVMLocked;
        }
        set isLocked(newValue: boolean) {
            if (newValue != this._isLocked) {
                this._isLocked = newValue;
                this.propertyChanged.emit({ propertyName: "isLocked", newValue: newValue });
            }
        }  

        /** @internal */
        protected _isVMLocked: boolean = false;
        /** @internal */
        public setVMLocked(value: boolean) {
            if (this._isVMLocked != value) {
                this._isVMLocked = value;
                this.propertyChanged.emit({ propertyName: "isLocked", newValue: this.isLocked });
            }
        }

        /** @internal */
        private _isRequired: boolean;
        get isRequired(): boolean {
            return this._isRequired;
        }
        set isRequired(newValue: boolean) {
            if (newValue != this._isRequired) {
                this._isRequired = newValue;
                this.propertyChanged.emit({ propertyName: "isRequired", newValue: newValue });
            }
        }
        public maxLength: number;

        get isChanged(): boolean {
            return this.originalValue !== this.value;
        }

        public reset = (): void => { this.setOriginalValue(this.value); };
        
        public addError(errDetail: rps.errors.ErrorDetail);
        public addError(serverErrors: Array<rps.data.ValidationError>);
        public addError(code: string, message: string);
        public addError(param: any, additionalMessage?: any) {
            var newErrors:rps.errors.ErrorDetails  = new rps.errors.ErrorDetails(this.errors.toArray());
            newErrors.addError(param, additionalMessage);
            this.errors = newErrors;
        }
        public updateErrors(): rps.errors.ErrorDetails {
            //Lanza validación de cliente
            this.errors = this.checkValue(true);

            return this.errors;
        }
        public clearErrors = () => {
            if (this.hasErrors)
                this.errors = new rps.errors.ErrorDetails();
        }

        public validate() {
            this.errors = this.checkValue(false);
        }

        /** @internal */
        public getUnderlyingPropertyName(): string{
            if (this._pathParts)
                return this._pathParts[this._pathParts.length - 1];
            else
                return null;
        }

        /** @rpsInternal (for framework use only) */
        public getServerParamValue(): any {
            return this.value;
        }

        /** @internal */
        public getTarget(): any {
            return this._target;
        }

        public hasValue(): boolean {
            if (rps.object.isString(this.value))
                return !rps.string.isNullOrEmpty(<any>this.value);
            else
                return !rps.object.isNullOrUndefined(this.value);
        }

        public cleanValue() {
            if (this.hasValue()) {                
                //Si es numérico, poner a cero
                if (typeof this.value == "number")
                    this.value = <any>0;
                else if (typeof this.value == "boolean")
                    this.value = <any>false;
                else
                    this.value = null;                
            }
        }

        /** @internal */
        protected isDestroyed = false;
        public onDestroy() {
            if (!this.isDestroyed) {
                this.isDestroyed = true;

                this.propertyChanged.onDestroy();
            }
        }
    }

    /** @internal */
    export function isVMProperty(value: any): value is VMProperty<any> {
         return value && value instanceof rps.viewmodels.properties.VMProperty
    } 

    export class IntegerProperty extends VMProperty<number>{

        /** @internal */
        checkValue(checkModelErrors: boolean): rps.errors.ErrorDetails {
            var result: rps.errors.ErrorDetails = super.checkValue(checkModelErrors);

            if (this.value > rps.number.integerMaxValue()) {
                result.push(new rps.errors.ErrorDetail("maxValue", rps.string.format(rps.app.resources.errors.ERR_MAX_VALUE, rps.number.integerMaxValue(), this.getPropertyName())));
            }

            return result;
        }

        /** @internal */
        public formatValue(): Promise<string> {
            var format = "n0";
            return Promise.resolve(kendo.toString(this.value, format));
        }
    }

    export class DecimalProperty extends VMProperty<number>{

        /** @internal */
        checkValue(checkModelErrors: boolean): rps.errors.ErrorDetails {
            var result: rps.errors.ErrorDetails = super.checkValue(checkModelErrors);

            if (this.value > rps.number.decimalMaxValue()) {
                result.push(new rps.errors.ErrorDetail("maxValue", rps.string.format(rps.app.resources.errors.ERR_MAX_VALUE, rps.number.decimalMaxValue(), this.getPropertyName())));
            }

            return result;
        }

        /** @internal */
        public formatValue(): Promise<string> {
            return Promise.resolve(kendo.toString(this.value, rps.utils.getDecimalFormat()));
        }
    }

    export class EnumProperty extends IntegerProperty {

        public enumSource: rps.data.sources.EnumSource;

        get description(): string {
            if (this.value != undefined && this.value != null) {                
                var selectedItem = this.enumSource.asEnumerable().FirstOrDefault(undefined,item=> item.value == this.value);
                if (selectedItem)
                    return selectedItem.description;
                else
                    return "";
            }
            else{
                return "";
            }            
        }

        /** @internal */
        public formatValue(): Promise<string> {
            return Promise.resolve(this.description);
        }

        //constructor() {
        constructor(params: {
            enumItems: Array<{value:number,description:string}>;
            target: any;
            valuePropertyPath?: string;
            initialValue?: number;
            isRequired?: boolean;
        }) {
            super(params);
            this.enumSource = new rps.data.sources.EnumSource(params.enumItems);            
        }
    }

    export class MultiEnumProperty extends VMProperty<Array<number>> implements rps.IItemContainer{

        /** @internal */
        public IItemContainer = true;

        public enumSource: rps.data.sources.EnumSource;

        //constructor() {
        constructor(params: {
            enumItems: Array<{value:number,description:string}>;
            target: any;
            valuePropertyPath?: string;
            initialValue?: Array<number>;
            isRequired?: boolean;
        }) {
            super(params);
            this.enumSource = new rps.data.sources.EnumSource(params.enumItems);            
        }

        /** @internal */
        public formatValue(): Promise<string> {
            if (this.hasValue()) {
                var newText: string;
                this.value.forEach((value: number) => {
                    var descriptor = Enumerable.From<rps.data.sources.EnumSourceItem>(this.enumSource).FirstOrDefault(null,esi => esi.value == value);
                    if (descriptor && descriptor.description) {
                        if (newText)
                            newText = rps.string.format("{0}, {1}", newText, descriptor.description);
                        else
                            newText = descriptor.description;
                    }
                });
                return Promise.resolve(newText);
            }
            else
                return Promise.resolve<any>(this.value);
        }

        public hasItems(): boolean {
            if (this.value)
                return this.value.length > 0;
            return false;
        }
    }

    export class MultiComboBoxProperty extends VMProperty<Array<string>> implements rps.IItemContainer{

        /** @internal */
        public IItemContainer = true;

        /** @internal */
        public comboBoxSource: Array<{ value: string;description:string}>;

        //constructor() {
        constructor(params: {
            comboBoxItems: Array<{ value: string; description: string }>;
            target: any;
            valuePropertyPath?: string;
            initialValue?: Array<string>;
            isRequired?: boolean;
        }) {
            super(params);
            this.comboBoxSource = params.comboBoxItems;
        }

        /** @internal */
        public formatValue(): Promise<string> {
            if (this.hasValue()) {
                var newText: string;
                this.value.forEach((value: string) => {
                    var descriptor = Enumerable.From<{ value: string; description: string }>(this.comboBoxSource).FirstOrDefault(null, esi => esi.value == value);
                    if (descriptor && descriptor.description) {
                        if (newText)
                            newText = rps.string.format("{0}, {1}", newText, descriptor.description);
                        else
                            newText = descriptor.description;
                    }
                });
                return Promise.resolve(newText);
            }
            else
                return Promise.resolve<any>(this.value);
        }

        public hasItems(): boolean {
            if (this.value)
                return this.value.length > 0;
            return false;
        }
    }

    export class ComboProperty extends VMProperty<string>{
        /** @internal */
        private valuePath: string;
        /** @internal */
        private relatedItems: Array<any> | rps.data.sources.ComboSource;

        /** @internal */
        public comboSource: rps.data.sources.ComboSource;

        public hasItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.comboSource) && this.comboSource.length > 0)
                return true;
            else
                return false;
        }
        public getItems(): Array<any> {
            if (this.hasItems())
                return this.comboSource.toArray();
            else
                return new Array<any>();
        }

        get description(): string {
            if (this.value != undefined && this.value != null) {
                var selectedItem = this.comboSource.asEnumerable().FirstOrDefault(undefined, item=> item.value == this.value);
                if (selectedItem)
                    return selectedItem.description;
                else
                    return "";
            }
            else {
                return "";
            }
        }

        /** @internal */
        public formatValue(): Promise<string> {
            return Promise.resolve(this.description);
        }

        //constructor() {
        constructor(params: {
            relatedItems: Array<any> | rps.data.sources.ComboSource;
            target: any;
            valuePropertyPath?: string;
            initialValue?: string;
            valuePath: string;
            displayPath: string;
            groupPath?: string;
            preventAlphabeticalOrder?: boolean;
        }) {
            super(params);
            this.valuePath = params.valuePath;
            this.relatedItems = params.relatedItems;

            if (params.relatedItems instanceof rps.data.sources.ComboSource)
                this.comboSource = <rps.data.sources.ComboSource>params.relatedItems;            
            else
                this.comboSource = new rps.data.sources.ComboSource({
                    values: <Array<any>>params.relatedItems,
                    valuePath: params.valuePath,
                    displayPath: params.displayPath,
                    groupPath: params.groupPath,
                    preventAlphabeticalOrder: params.preventAlphabeticalOrder
                });
        }

        public getSelectedItem = (): any => {
            if (!rps.object.isNullOrUndefined(this.value)) {
                var selectedItem = Enumerable.From(this.relatedItems).FirstOrDefault(null, item=> item[this.valuePath] == this.value);
                return selectedItem;
            }
            else
                return undefined;
        }
    }

    export class LookupProperty extends VMProperty<string> {

        /** @internal */
        public entityName: string;
        /** @internal */
        public hasAnyEntityView: boolean = false;
        /** @internal */
        public allowAddNew: boolean = false;
        /** @internal */
        public allowNavigate: boolean = false;
        
        constructor(params: {
            entityName: string;
            target: any;
            valuePropertyPath?: string;
            initialValue?: string;
            isRequired?: boolean;
            hasAnyEntityView?: boolean;
            allowAddNew?: boolean;  
            allowNavigate?: boolean;             
        }) {    
            super(params);
            this.entityName = params.entityName;            
            if (params.hasAnyEntityView)
                this.hasAnyEntityView = true;
            if (params.allowAddNew)
                this.allowAddNew = true;
            if (params.allowNavigate)
                this.allowNavigate = true;
        }

        /** @internal */
        protected doSetValue(newValue: string): boolean {
            var changed = super.doSetValue(newValue);
            return changed;
        }
        /** @internal */
        protected setOriginalValue(newValue: string) {
            super.setOriginalValue(newValue);            
        }        

        /** @rpsInternal */
        public getHeader(): Promise<data.Descriptor> {
            if (this.value && this.value.length > 0) {
                return rps.app.api.get({
                    url: this.entityName + "/header",
                    queryParams: { id: this.value },
                    urlType: rps.services.UrlType.Relative
                }).then((result: data.Descriptor) => {
                    return result;
                }).catch((res) => {
                    if (res && res.code == "404")
                        return null;
                    else
                        throw res;
                });
            }
            else {
                return Promise.resolve<data.Descriptor>(null);
            }
        }

        /** @internal */
        public getDescriptor(): Promise<data.FullDescriptor> {
            if (this.value && this.value.length > 0) {
                return rps.app.api.get({
                    url: this.entityName + "/descriptor",
                    queryParams: { id: this.value },
                    urlType: rps.services.UrlType.Relative
                }).then((result: data.FullDescriptor) => {
                    return result;
                }).catch((res) => {
                    if (res && res.code == "404")
                        return null;
                    else
                        throw res;
                });
            }
            else {
                return Promise.resolve<data.FullDescriptor>(null);
            }
        }

        /** @internal */
        public formatValue(): Promise<string> {
            return this.getHeader().then((descriptor) => {
                return descriptor ?  descriptor.MainDescriptor : "";
            });
        }

        public search(dataSource: rps.data.sources.LookupSource) {
            rps.app.entityManager.searchEntityID(this.entityName,this.hasAnyEntityView,dataSource).then((newValue: string) => {
                this.value = newValue;
            }).catch(() => { });
        }
    }

    export class MultiLookupProperty extends VMProperty<Array<string>> implements rps.IItemContainer{

        /** @internal */
        public entityName: string;
        /** @internal */
        public hasAnyEntityView: boolean = false;
        /** @internal */
        public allowAddNew: boolean = false;
        /** @internal */
        public IItemContainer = true;

        constructor(params: {
            entityName: string;
            target: any;
            valuePropertyPath?: string;
            initialValue?: Array<string>;
            isRequired?: boolean;
            hasAnyEntityView?: boolean;
            allowAddNew?: boolean;
        }) {
            super(params);
            if (params.hasAnyEntityView)
                this.hasAnyEntityView = true;
            if (params.allowAddNew)
                this.allowAddNew = true;
            this.entityName = params.entityName;            
        }

        public hasValue(): boolean {
            return (rps.object.hasValue(this.value) && this.value.length > 0);
        }

        /** @internal */
        public cleanValue() {
            if (this.hasValue())
                this.value = new Array<string>();
        }

        /** @internal */
        public getDescriptor(pk: string): Promise<data.FullDescriptor> {
            if (pk && pk.length > 0) {
                return rps.app.api.get({
                    url: this.entityName + "/descriptor",
                    queryParams: { id: pk },
                    urlType: rps.services.UrlType.Relative
                }).then((result: data.FullDescriptor) => {
                    return result;
                });
            }
            else {
                return Promise.resolve<data.FullDescriptor>(null);
            }
        }

        public search(dataSource: rps.data.sources.LookupSource): Promise<Array<string>> {
            return new Promise<Array<string>>((resolve) => {
                rps.app.entityManager.searchEntitiesIds(dataSource, this.hasAnyEntityView, this).then((newValues: Array<string>) => {
                    this.value = newValues;
                    resolve(this.value);
                }).catch(() => {
                    resolve(this.value);
                });
            });
        }

        /** @internal */
        private getService(): string {
            return this.entityName.substr(0, this.entityName.indexOf("/"));
        }

        /** @internal */
        private getEntityName(): string {
            return this.entityName.substr(this.entityName.indexOf("/") + 1, this.entityName.length - this.entityName.indexOf("/") - 1)
        }

        /** @internal */
        public formatValue(): Promise<string> {
            if (this.hasValue()) {
                var promises : Array<Promise<data.FullDescriptor>> = new Array<Promise<data.FullDescriptor>>();
                for (var i:number = 0; i < this.value.length; i++) {
                    promises.push(this.getDescriptor(this.value[i]));
                    //Si hay más de tres elementos, se añaden unos puntos suspensivos, para no hacer la descripción demasiado larga
                    if (i >= 2)
                        break;
                }
                return Promise.all(promises).then((result: Array<data.FullDescriptor>) => {
                    var newText: string;
                    result.forEach((descriptor: data.FullDescriptor) => {
                        if (descriptor && descriptor.MainDescriptor) {
                            if (newText)
                                newText = rps.string.format("{0}, {1}", newText, descriptor.MainDescriptor);
                            else
                                newText = descriptor.MainDescriptor;
                        }
                    });
                    //Si hay más de tres elementos, se añaden unos puntos suspensivos, para no hacer la descripción demasiado larga
                    if (this.value.length >= 4)
                        newText = rps.string.format("{0}...", newText);
                    return Promise.resolve(newText);
                });
            }
            else {
                return Promise.resolve<any>(this.value);
            }
        }

        public hasItems(): boolean {
            if (this.value)
                return this.value.length > 0;
            return false;
        }
    }

    export abstract class BaseVMCollectionProperty<MT extends BaseVM, AT extends rps.viewmodels.ObservableArray<MT>>
        extends VMProperty<AT>
        implements rps.ISelectable, rps.IItemContainer,IDestroyable {
        /** @internal */
        public IItemContainer = true;
        /** @internal */
        public ISelectable: boolean = true;
        /** @internal */
        public isIDestroyable: boolean = true;

        /** @internal */
        public customCardViewItemUrl: string;
        /** @internal */
        private beforeRemoveItem: (item: MT) => Promise<{ cancel?: boolean }>;

        public hasItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.value) && this.value.length > 0)
                return true;
            else
                return false;
        }
        public getItems(): AT {
            if (this.hasItems())
                return this.value;
            else
                return this.createEmptyNew();
        }        

        /** @internal */
        protected doSetValue(newValue: AT): boolean {
            var result = super.doSetValue(newValue);
            if (result) {

                this.propertyChanged.emit({ propertyName: "value", newValue: newValue });

                //Lanzar las when del target (las que no tienen Trigger)
                if (this._target && (<any>this._target).rules && (<any>this._target).rules.length > 0) {
                    Enumerable.From<rps.viewmodels.rules.Rule>((<any>this._target).rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                        ForEach((rule) => {
                            rule.execute(this);
                        });
                }
            }

            return result;
        }

        /** @internal */
        public _selectedItems: Array<MT>;
        public get selectedItems(): Array<MT> {
            return this._selectedItems;
        }
        public set selectedItems(newValue: Array<MT>) {
            if (this._selectedItems != newValue) {
                this._selectedItems = newValue;

                this.propertyChanged.emit({ propertyName: "selectedItems", newValue: newValue });

                //Lanzar las when del target (las que no tienen Trigger)
                if (this._target && (<any>this._target).rules && (<any>this._target).rules.length > 0) {
                    Enumerable.From<rps.viewmodels.rules.Rule>((<any>this._target).rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                        ForEach((rule) => {
                            rule.execute(this);
                        });
                }
            }
        }

        public hasSelectedItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.selectedItems) && this.selectedItems.length > 0)
                return true;
            else
                return false;
        }
        public getSelectedItems(): Array<any> {
            if (this.hasSelectedItems())
                return this.selectedItems;
            else
                return new Array<any>();
        }
        public setSelectedItems(newSelection: Array<any>) {
            this.selectedItems = newSelection;
        }

        constructor(params: {
            target: any;            
            allowAdd?: boolean;
            allowDelete?: boolean;
        }) {
            super({
                target: params.target
            });

            if (rps.object.hasValue(params.allowAdd))
                this.allowAdd = params.allowAdd;

            if (rps.object.hasValue(params.allowDelete))
                this.allowDelete = params.allowDelete;    
        }

        /** @internal */
        abstract createEmptyNew(): AT;

        /** @internal */
        abstract createNew(initParams?: {
            valuePropertyPath?: string;
            viewModelFunction: Function;                       
        }): Promise<AT>;

        remove(item: MT) {
            this.value.remove(item);
        }

        //Método que se invoca, cuando es el usuario el que quiere eliminar un elemento a través de un editor
        /** @internal */
        userRemove(item: MT): Promise<boolean> {
            if (!this.beforeRemoveItem) {
                this.value.remove(item);
                return Promise.resolve<boolean>(true);
            }
            else {
                return this.beforeRemoveItem.call(this._target, item).then((result: { cancel?: boolean }) => {
                    if (result.cancel)
                        return false;
                    else {
                        this.value.remove(item);
                        return true;
                    }
                });
            }
        }

        /** @internal */
        abstract onSetValue();

        //El parámetro initialValue, no se utiliza, pero sería muy complicado hacer que el TSFileGenerator, no le estableciera un valor
        initialize(params: {
            valuePropertyPath?: string;
            initialValue?: AT;
            viewModelFunction: Function;
        }): Promise<any> {
            //En el caso de que esté relacionado con un View Model padre, no se genera la lista de VM.
            //En el caso contrario, se genera una lista, para no modificar la del modelo, envolviendo los elementos en el VM especifico
            if (!params.valuePropertyPath || !params.valuePropertyPath.startsWith("parentVM.")) {
                return new Promise<any>((resolve, reject) => {
                    this.createNew({
                        valuePropertyPath: params.valuePropertyPath,
                        viewModelFunction: (<any>(params.viewModelFunction)).getResolvedType(params.viewModelFunction)
                    }).then((newViewModelCollection) => {
                        this.value = newViewModelCollection;
                        this.setOriginalValue(this.value);
                        this.onSetValue();

                        //Trasladar el isVMLocked
                        if (this.value && rps.object.hasValue(this._isVMLocked))
                            this.value.forEach((item: MT) => item.setParentLocked(this._isVMLocked));

                        resolve(this);
                    });
                });
            }
            else {
                this._pathParts = params.valuePropertyPath.split('.');
                return Promise.resolve(this);
            }
        }

        /** @internal */
        private _allowDelete: boolean =true;
        /** @internal */
        public get allowDelete(): boolean {
            return this._allowDelete && !this.isLocked;
        }
        /** @internal */
        public set allowDelete(newValue: boolean) {
            if (this._allowDelete != newValue) {
                this._allowDelete = newValue;
                this.propertyChanged.emit({ propertyName: "allowDelete", newValue: newValue });
            }
        }

        /** @internal */
        private _allowAdd: boolean = true;
        /** @internal */
        public get allowAdd(): boolean {
            return this._allowAdd && !this.isLocked;
        }
        /** @internal */
        public set allowAdd(newValue: boolean) {
            if (this._allowAdd != newValue) {
                this._allowAdd = newValue;
                this.propertyChanged.emit({ propertyName: "allowAdd", newValue: newValue });
            }
        }

        get isLocked(): boolean {
            return this._isLocked || this._isVMLocked;
        }
        set isLocked(newValue: boolean) {
            if (newValue != this._isLocked) {
                this._isLocked = newValue;

                this.propertyChanged.emit({ propertyName: "isLocked", newValue: newValue });
                this.propertyChanged.emit({ propertyName: "allowAdd", newValue: this.allowAdd });
                this.propertyChanged.emit({ propertyName: "allowDelete", newValue: this.allowDelete });
            }
        }  

        /** @internal */
        public setVMLocked(value: boolean) {
            if (this._isVMLocked != value) {
                this._isVMLocked = value;

                this.propertyChanged.emit({ propertyName: "isLocked", newValue: this.isLocked });
                this.propertyChanged.emit({ propertyName: "allowAdd", newValue: this.allowAdd });
                this.propertyChanged.emit({ propertyName: "allowDelete", newValue: this.allowDelete });

                //Establecer también en los hijos
                if (this.value)
                    this.value.forEach((item: MT) => item.setParentLocked(value));
            }
        }

        abstract add(): Promise<MT>;

        canAdd(): rps.viewmodels.commands.CanExecuteResult{
            if (this.allowAdd)
                return rps.viewmodels.commands.CanExecuteResult.allow();
            else
                return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.commonResources.MSG_READ_ONLY]);
        }

        public addCommand: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: this.add,
            canExecute: this.canAdd
        });

        public reset = (): void => {
            this.value.forEach((item: BaseVM) => item.reset());
        }

        //Sobreescrito para que pueda preguntar a su ChildCollection relacionado
        /** @internal */
        public validate(): void {
            this.value.forEach((item: BaseVM) => {                
                item.validate()
            });
        }

        /** @internal */
        protected checkValue(checkModelErrors: boolean): rps.errors.ErrorDetails {
            var result: rps.errors.ErrorDetails = new rps.errors.ErrorDetails();
            if (this.value) {
                this.value.forEach((item: BaseVM) => {
                    result.addError(item.getErrors());
                });
            }
            result.addError(super.checkValue(checkModelErrors));
            return result;
        }

        public onDestroy() {
            if (this.value) {
                this.value.forEach((item: MT) => {
                    item.onDestroy();
                });
                this.value = null;
            }

            super.onDestroy();
        }

        public configure(params: {
            allowAdd?: boolean,
            allowDelete?: boolean,            
            beforeRemoveItem?: (item: MT) => Promise<{ cancel?: boolean }>
            customCardViewItemUrl?: string
        }) {
            if (rps.object.hasValue(params.allowAdd))
                this.allowAdd = params.allowAdd;
            if (rps.object.hasValue(params.allowDelete))
                this.allowDelete = params.allowDelete;
            if (rps.object.hasValue(params.beforeRemoveItem))
                this.beforeRemoveItem = params.beforeRemoveItem;
            if (rps.object.hasValue(params.customCardViewItemUrl))
                this.customCardViewItemUrl = params.customCardViewItemUrl;
        }

        public asEnumerable() : linq.Enumerable<MT>{
            return Enumerable.From<MT>(this.value);
        }
    }

    export class VMCollectionProperty<T extends BaseVM> extends BaseVMCollectionProperty<T, rps.viewmodels.ObservableArray<T>>{
        /** @internal */
        private viewModelFunction: Function;

        //Las plantillas, añaden el parámetro orderDefinition y aunque en este tipo de listas, no se le aplique a nada, es un parámetro del constructor        
        constructor(params: {
            target: any;
            orderDefinition: Array<{ propertyName: string; direction: number }>;            
        }) {
            //No se permite modificar esta listas, ya que el add, no está implementado y no se ha tenido la necesidad
            super({
                target: params.target,
                allowAdd: false,
                allowDelete: false
            });
        }

        /** @internal */
        createEmptyNew(): rps.viewmodels.ObservableArray<T> {
            return new rps.viewmodels.ObservableArray<T>();
        };
        
        /** @internal */
        createNew(initParams: {
            valuePropertyPath?: string;
            viewModelFunction: Function;            
        }): Promise<rps.viewmodels.ObservableArray<T>> {
            this.viewModelFunction = initParams.viewModelFunction;
            
            return Promise.resolve<rps.viewmodels.ObservableArray<T>>(new rps.viewmodels.ObservableArray<T>());
        };

        add(): Promise<T> {
            if (this.allowAdd) {
                //Crea el viewModel
                return rps.app.viewModelFactory.createViewModel(this.viewModelFunction, { parentVM: this.parentVM }).then((vm: T) => {                    
                    //Añadirlo a la lista
                    this.value.push(vm);
                    return vm;
                });
            }
            else
                return Promise.resolve<any>(null);
        }

        /** @internal */
        onSetValue() {
        }

        clear() {
            this.value.splice(0, this.value.length);
        }


    }

    export class CollectionProperty<T extends rps.viewmodels.ObservableObject>
        extends VMProperty<rps.viewmodels.ObservableArray<T>>
        implements IDestroyable{
        /** @internal */
        public isIDestroyable: boolean = true;

        /** @internal */
        private _viewModelFunction: Function;
        constructor(params: {
            target: any;
            initialValue: rps.viewmodels.ObservableArray<T>;
            viewModelFunction?: Function;
            allowAdd?: boolean;
            allowDelete?: boolean;
        }) {
            super(params);

            this._viewModelFunction = params.viewModelFunction;

            if (params.allowAdd && this._viewModelFunction)
                this.allowAdd = true;
            else
                this.allowAdd = false;

            if (params.allowDelete)
                this.allowDelete = true;
            else
                this.allowDelete = false;
        }

        /** @internal */
        allowDelete: boolean;

        /** @internal */
        allowAdd: boolean;

        add() {
            if (this.allowAdd) {
                alert("add");
            }
        }

        public cleanValue() {
            if (this.hasValue())
                this.value.splice(0, this.value.length)
        }

        public onDestroy() {
            if (!this.isDestroyed) {
                this.isDestroyed = true;

                this.value.forEach((item) => {
                    if ((<any>item).onDestroy)
                        (<any>item).onDestroy();
                });
            }
        }
    }

    export class FormattedNumberProperty extends VMProperty<rps.data.FormattedNumber>{
        constructor(params: {
            target: any;
            valuePropertyPath?: string;
            initialValue?: rps.data.FormattedNumber;
            isRequired?: boolean;
            maxLength?: number;
        }) {
            super(params);
        }

        /** @internal */
        public formatValue(): Promise<string> {
           
            if (this.value) {
                var newFormat = "#.";
                for (var i = 0; i < this.value.DecimalPlaces; i++) {
                    newFormat = newFormat + "0"
                }
                if (this.value.Suffix)
                    newFormat = string.format("{0} {1}", newFormat,this.value.Suffix.replace("%","\\%"));
                return Promise.resolve<string>(kendo.toString(this.value.Value, newFormat));
            }
            else
                return Promise.resolve<string>("");
        }
    }

    export class IconProperty extends VMProperty<rps.data.Icon>{        
        /** @internal */
        get semanticState(): rps.viewmodels.properties.SemanticState {
            if (rps.object.hasValue(this.value)) {
                switch (this.value.SemanticState) {
                    case rps.data.SemanticState.Info:
                        return rps.viewmodels.properties.SemanticState.Info;
                    case rps.data.SemanticState.Negative:
                        return rps.viewmodels.properties.SemanticState.Negative;
                    case rps.data.SemanticState.Neutral:
                        return rps.viewmodels.properties.SemanticState.Neutral;
                    case rps.data.SemanticState.Positive:
                        return rps.viewmodels.properties.SemanticState.Positive;
                    case rps.data.SemanticState.Warning:
                        return rps.viewmodels.properties.SemanticState.Warning;
                    default:
                        return rps.viewmodels.properties.SemanticState.Neutral;
                } 
            }                
            return rps.viewmodels.properties.SemanticState.Neutral;
        }
        /** @internal */
        set semanticState(newValue: rps.viewmodels.properties.SemanticState) {
            if (rps.object.hasValue(this.value)) {
                switch (newValue) {
                    case rps.viewmodels.properties.SemanticState.Info:
                        this.value.SemanticState = rps.data.SemanticState.Info;
                        break;
                    case rps.viewmodels.properties.SemanticState.Negative:
                        this.value.SemanticState = rps.data.SemanticState.Negative;
                        break;
                    case rps.viewmodels.properties.SemanticState.Neutral:
                        this.value.SemanticState = rps.data.SemanticState.Neutral;
                        break;
                    case rps.viewmodels.properties.SemanticState.Positive:
                        this.value.SemanticState = rps.data.SemanticState.Positive;
                        break;
                    case rps.viewmodels.properties.SemanticState.Warning:
                        this.value.SemanticState = rps.data.SemanticState.Warning;
                        break;
                    default:
                        this.value.SemanticState = rps.data.SemanticState.Neutral;
                }
                this.propertyChanged.emit({ propertyName: "semanticState", newValue: this.semanticState });
            }                
        }
    }

    export class DocumentsProperty extends VMProperty<rps.data.DocumentsResult>{
        /** @internal */
        public formatValue(): Promise<string> {
            if (this.value)
                return Promise.resolve(rps.string.format("TODO (Count {0})", this.value.Count));
            else
                return Promise.resolve("");            
        }
    }

    export interface ILinkProperty {
        go(): Promise<any>;
        go(navigationArguments: IParams): Promise<any>;
        go(navigationArguments: IParams, options: rps.services.INavigationOptions): Promise<any>;

        getRouterLinkParameters(): { routerLink: Array<any>, queryParams: rps.IParams };
        /** @internal */
        canNavigate(): boolean;
    }

        /** @internal */
    export function isILinkProperty(value: any): value is ILinkProperty {
        return value && rps.object.hasValue(value.go);
    } 

    export class LinkProperty implements ILinkProperty{

        /** @internal */
        private parameters: Array<{
            _name: string;
            _property: VMProperty<any> |String | Function
        }>;
        /** @internal */
        public state: rps.data.IUILinkDefinition;
        /** @internal */
        private openInNewTab: boolean = false;


        constructor(params: {            
            state: string ;
            parameters?: Array<{
                name: string;
                property: VMProperty<any> | String | Function
            }>;
            arguments?: Array<{
                name: string;
                property: VMProperty<any> | String | Function
            }>;
            openInNewTab?: boolean;
        }) {
            this.parameters = [];
            if (params.parameters) {
                params.parameters.forEach((p) => {
                    this.parameters.push({
                        _name: p.name,
                        _property: p.property
                    });
                });
            }
            //A día de hoy, los argumentos son equivalentes a los parámetros, no se hace distinción a la hora de tratarlos; sí al crear el link, aunque en realidad no haría falta
            if (params.arguments) {
                params.arguments.forEach((p) => {
                    this.parameters.push({
                        _name: p.name,
                        _property: p.property
                    });
                });
            }
            this.state = JSON.parse(params.state);

             if (!rps.object.isNullOrUndefined(params.openInNewTab))
                this.openInNewTab = params.openInNewTab;
        }

        public go(): Promise<any>;
        public go(navigationArguments: IParams): Promise<any>;
        public go(navigationArguments: IParams, options: rps.services.INavigationOptions): Promise<any>;
        public go(navigationArguments?: IParams, options?: rps.services.INavigationOptions): Promise<any> {
            var parameters = this.getParameters();
            if (navigationArguments) {
                for (var param in navigationArguments) {
                    parameters[param] = navigationArguments[param];
                }
            }
            var newWindow: boolean = !options || rps.object.isUndefined(options.openInNewWindow) ? this.openInNewTab : options.openInNewWindow;
            if (!options)
                options = { openInNewWindow: newWindow };
            else
                options.openInNewWindow = newWindow;

            return rps.app.stateManager.goTo(this.state, parameters, options);
        }

        /** @internal */
        public getParameters(): Object {
            //Se pone el _state a null, porque al estar definido en todos los estados, parece que 
            //se truña al generar el href para estados hijo, tiene en cuenta el _state del padre, y genera mal los href
            var parameters = {};//_state:null};
            parameters['currentCompany'] = rps.app.stateManager.getCurrentCompany();

            for (var i = 0; i < this.parameters.length; i++) {
                var parameterProperty = this.parameters[i]._property;
                var parameterValue = null;
                if (parameterProperty instanceof VMProperty)
                    parameterValue = parameterProperty.value;
                else if (rps.object.isString(parameterProperty))
                    parameterValue = parameterProperty;
                else if (parameterProperty instanceof Function)
                    parameterValue = parameterProperty.call(this);

                if (!rps.object.isNullOrUndefined(parameterValue))
                    parameters[this.parameters[i]._name] = parameterValue;
            }

            return parameters;
        }

        public getRouterLinkParameters(): { routerLink: Array<any>, queryParams: rps.IParams } {
            return rps.app.stateManager.createRouterLink(this.state, this.getParameters());
        }

        /** @internal */
        public canNavigate(): boolean {
            if (!rps.object.hasValue(this.state) || this.state.length == 0)
                return false;
            return true;
        }
    }

    export class LinkValueProperty extends VMProperty<rps.data.IUILinkDefinition> implements ILinkProperty{
        public go(): Promise<any>;
        public go(navigationArguments: IParams): Promise<any>;
        public go(navigationArguments: IParams, options: rps.services.INavigationOptions): Promise<any>;
        public go(navigationArguments?: IParams, options?: rps.services.INavigationOptions): Promise<any> {
            return rps.app.stateManager.goTo(this.value, navigationArguments, options);
        }

        public getRouterLinkParameters(): { routerLink: Array<any>, queryParams: rps.IParams } {
            return rps.app.stateManager.createRouterLink(this.value);
        }

        /** @internal */
        public canNavigate(): boolean {
            if (!rps.object.hasValue(this.value) || this.value.length == 0)
                return false;

            return true;
        }
    }

    export interface IFormattedNumberProperty {
        formattedValue: string;
        suffix: string;
    }

    export class AmountProperty extends DecimalProperty implements IFormattedNumberProperty {
        
        /** @internal */
        private currency: LookupProperty;
        /** @internal */
        private _currencySymbol: rps.data.CurrencySymbol;
        /** @internal */
        private _currencySymbolWorking: boolean =false;
        /** @internal */
        get currencySymbol(): rps.data.CurrencySymbol {
            if (!this._currencySymbolWorking) {
                this._currencySymbolWorking = true;
                this.resolveCurrency();
            }
            return this._currencySymbol;
        }
        /** @internal */
        set currencySymbol(newValue: rps.data.CurrencySymbol) {
            if (newValue != this._currencySymbol) {
                this._currencySymbol = newValue;
                this.propertyChanged.emit({ propertyName: "currencySymbol", newValue: newValue });
                this.refreshText();
            }
        }

        /** @internal */
        get formattedValue(): string {
            const format = rps.utils.getDecimalFormat();
            return kendo.toString(this.value, format);
        }

        /** @internal */
        get suffix(): string {
            return this.currencySymbol ? this.currencySymbol.Symbol : '';
        }

        constructor(params: {
            target: any;
            currency: LookupProperty;
            valuePropertyPath?: string;
            initialValue?: number;
            isRequired?: boolean;
            maxLength?: number;
        }) {
            super(params);
            this.currency = params.currency;                     
        }

        initialize(): Promise<any> {
            this.currency.propertyChanged.subscribe(this.currencyPropertyChanged);
            return Promise.resolve<any>(this);            
        }

        /** @internal */
        currencyPropertyChanged = (e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value" && this._currencySymbolWorking)
                this.resolveCurrency();
        }

        /** @internal */
        resolveCurrency(): Promise<any> {
            if (this.currency && this.currency.value && this.currency.value.length > 0) {
                return rps.app.currencyResolver.resolve(rps.app.stateManager.getCurrentCompany(), this.currency.entityName, this.currency.value).then((result: rps.data.CurrencySymbol) => {
                    this.currencySymbol = result;
                });
            }
            else {
                this.currencySymbol = null;
                return Promise.resolve<any>(this);
            }
        }

        /** @internal */
        public formatValue(): Promise<string> {
            var format: string;
            if (this.currencySymbol)
                format = rps.utils.getDecimalFormat({suffix: this.currencySymbol.Symbol});
            else
                format = rps.utils.getDecimalFormat();
            return Promise.resolve(kendo.toString(this.value, format));
        }
    }

    export class PriceProperty extends DecimalProperty implements IFormattedNumberProperty {

        /** @internal */
        private currency: LookupProperty;
        /** @internal */
        private priceUnit: LookupProperty;
        /** @internal */
        private _priceUnitAbbreviation: rps.data.PriceUnitAbbreviation;
        /** @internal */
        private _priceUnitAbbreviationWorking: boolean = false;
        /** @internal */
        get priceUnitAbbreviation(): rps.data.PriceUnitAbbreviation {
            if (!this._priceUnitAbbreviationWorking) {
                this._priceUnitAbbreviationWorking = true;
                this.resolvePriceUnit();
            }
            return this._priceUnitAbbreviation;
        }
        set priceUnitAbbreviation(newValue: rps.data.PriceUnitAbbreviation) {
            if (this._priceUnitAbbreviation != newValue) {
                this._priceUnitAbbreviation = newValue;
                this.propertyChanged.emit({ propertyName: "priceUnitAbbreviation", newValue: newValue });
                this.refreshText();
            }
        }
        /** @internal */
        private _currencySymbol: rps.data.CurrencySymbol;
        /** @internal */
        private _currencySymbolWorking: boolean = false;
        get currencySymbol(): rps.data.CurrencySymbol {
            if (!this._currencySymbolWorking) {
                this._currencySymbolWorking = true;
                this.resolveCurrency();
            }
            return this._currencySymbol;
        }
        set currencySymbol(newValue: rps.data.CurrencySymbol) {
            if (this._currencySymbol != newValue) {
                this._currencySymbol = newValue;
                this.propertyChanged.emit({ propertyName: "currencySymbol", newValue: newValue });
                this.refreshText();
            }
        }

        constructor(params: {
            target: any;
            currency: LookupProperty;
            priceUnit?: LookupProperty;
            valuePropertyPath?: string;
            initialValue?: number;
            isRequired?: boolean;
            maxLength?: number;
        }) {
            super(params);
            this.currency = params.currency;
            this.priceUnit = params.priceUnit;
        }

        initialize(): Promise<any> {
            this.currency.propertyChanged.subscribe(this.currencyPropertyChanged);
            if (this.priceUnit)
                this.priceUnit.propertyChanged.subscribe(this.priceUnitPropertyChanged);
            return Promise.resolve<any>(this);
        }

        /** @internal */
        currencyPropertyChanged = (e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value" && this._currencySymbolWorking)
                this.resolveCurrency();
        }

        /** @internal */
        priceUnitPropertyChanged = (e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value" && this._priceUnitAbbreviationWorking)
                this.resolvePriceUnit();
        }

        /** @internal */
        resolveCurrency(): Promise<any> {
            if (this.currency && this.currency.value && this.currency.value.length > 0) {
                return rps.app.currencyResolver.resolve(rps.app.stateManager.getCurrentCompany(), this.currency.entityName, this.currency.value).then((result: rps.data.CurrencySymbol) => {
                    this.currencySymbol = result;
                });
            }
            else {
                this.currencySymbol = null;
                return Promise.resolve<any>(this);
            }
        }

        /** @internal */
        resolvePriceUnit(): Promise<any> {
            if (this.priceUnit && this.priceUnit.value && this.priceUnit.value.length > 0) {
                return rps.app.priceUnitResolver.resolve(rps.app.stateManager.getCurrentCompany(), this.priceUnit.entityName, this.priceUnit.value).then((result: rps.data.PriceUnitAbbreviation) => {
                    this.priceUnitAbbreviation = result;
                });
            }
            else {
                this.priceUnitAbbreviation = null;
                return Promise.resolve<any>(this);
            }
        }

        /** @internal */
        public formatValue(): Promise<string> {
            var format: string;
            if (this.currencySymbol) {
                var suffix: string;
                if (this.currencySymbol.Symbol) {
                    suffix = this.currencySymbol.Symbol;
                    if (this.priceUnitAbbreviation && this.priceUnitAbbreviation.Abbreviation)
                        suffix = suffix + "/" + this.priceUnitAbbreviation.Abbreviation;
                }
                format = rps.utils.getDecimalFormat({ suffix: suffix});
            }
            else
                format = rps.utils.getDecimalFormat();
            return Promise.resolve(kendo.toString(this.value, format));
        }

         /** @internal */
        get formattedValue(): string {
            var format: string = rps.utils.getDecimalFormat();
            return kendo.toString(this.value, format);
        }

        /** @internal */
        get suffix(): string {
            if (this.currencySymbol) {
                let suffix: string;
                if (this.currencySymbol.Symbol) {
                    suffix = this.currencySymbol.Symbol;
                    if (this.priceUnitAbbreviation && this.priceUnitAbbreviation.Abbreviation)
                        suffix = suffix + "/" + this.priceUnitAbbreviation.Abbreviation;
                }
                return suffix;
            }
            else
                return '';
        }
    }

    export class QuantityProperty extends DecimalProperty{
        
        /** @internal */
        private unit: LookupProperty;
        /** @internal */
        private isSecondaryUnit: boolean;
        /** @internal */
        private _quantityUnitAbbreviation: rps.data.QuantityUnitAbbreviation;
        /** @internal */
        private _quantityUnitAbbreviationWorking: boolean = false;
        get quantityUnitAbbreviation(): rps.data.QuantityUnitAbbreviation {
            if (!this._quantityUnitAbbreviationWorking) {
                this._quantityUnitAbbreviationWorking = true;
                this.resolveUnit();
            }
            return this._quantityUnitAbbreviation;
        }
        set quantityUnitAbbreviation(newValue: rps.data.QuantityUnitAbbreviation) {
            if (this._quantityUnitAbbreviation != newValue) {
                this._quantityUnitAbbreviation = newValue;
                this.propertyChanged.emit({ propertyName: "quantityUnitAbbreviation", newValue: newValue });
                this.refreshText();
            }
        }

        constructor(params: {
            target: any;
            unit: LookupProperty;
            isSecondaryUnit: boolean;
            valuePropertyPath?: string;
            initialValue?: number;
            isRequired?: boolean;
            maxLength?: number;
        }) {
            super(params);
            this.unit = params.unit;
            this.isSecondaryUnit = params.isSecondaryUnit;            
        }

        initialize(): Promise<any> {
            this.unit.propertyChanged.subscribe(this.unitPropertyChanged);
            return Promise.resolve<any>(this);
        }

        /** @internal */
        unitPropertyChanged = (e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value" && this._quantityUnitAbbreviationWorking)
                this.resolveUnit();
        }

        /** @internal */
        resolveUnit(): Promise<any> {
            if (this.unit && this.unit.value && this.unit.value.length > 0) {
                return rps.app.quantityUnitResolver.resolve(rps.app.stateManager.getCurrentCompany(), this.unit.entityName, this.unit.value, this.isSecondaryUnit).then((result: rps.data.QuantityUnitAbbreviation) => {
                    this.quantityUnitAbbreviation = result;
                });
            }
            else {
                this.quantityUnitAbbreviation = null;
                return Promise.resolve<any>(this);
            }
        }

        /** @internal */
        public formatValue(): Promise<string> {
            var format: string;
            if (this.quantityUnitAbbreviation)
                format = rps.utils.getDecimalFormat({ suffix: this.quantityUnitAbbreviation.Abbreviation});                
            else
                format = rps.utils.getDecimalFormat();

            return Promise.resolve(kendo.toString(this.value, format));
        }

         /** @internal */
        get formattedValue(): string {
            const format = rps.utils.getDecimalFormat();
            return kendo.toString(this.value, format);
        }

         /** @internal */
        get suffix(): string {
            if (this.quantityUnitAbbreviation) {
                return this.quantityUnitAbbreviation.Abbreviation;
            } else {
                return '';
            }
        }
    }

    export class DurationProperty extends DecimalProperty implements IFormattedNumberProperty {

        /** @internal */
        private timeUnit: EnumProperty;
        /** @internal */
        private _durationFactor: rps.data.DurationFactor;
        /** @internal */
        private _durationFactorWorking: boolean=false;
        /** @internal */
        get durationFactor(): rps.data.DurationFactor {
            if (!this._durationFactorWorking) {
                this._durationFactorWorking = true;
                this.resolveDurationFactor();
            }
            return this._durationFactor;
        }
        set durationFactor(newValue: rps.data.DurationFactor) {
            if (this._durationFactor != newValue) {
                this._durationFactor = newValue;
                this.propertyChanged.emit({ propertyName: "durationFactor", newValue: newValue });
                this.refreshText();
            }
        }

        /** @internal */
        get formattedValue(): string {
            if (this.durationFactor)
                return rps.utils.getFormattedDecimal({
                    value: this.value / this.durationFactor.Factor,
                    decimalPlaces: 2
                });
            else
                return rps.utils.getFormattedDecimal({
                    value: this.value,
                    decimalPlaces: 2
                });
        }

        /** @internal */
        get suffix(): string {
            if (this.durationFactor) {
                return this.durationFactor.Unit;
            } else {
                return '';
            }
        }

        constructor(params: {
            target: any;
            timeUnit: EnumProperty;
            valuePropertyPath?: string;
            initialValue?: number;
            isRequired?: boolean;
            maxLength?: number;
        }) {
            super(params);
            this.timeUnit = params.timeUnit;            
        }

        initialize(): Promise<any> {
            this.timeUnit.propertyChanged.subscribe(this.timeUnitPropertyChanged);
            return Promise.resolve<any>(this);
        }

        /** @internal */
        timeUnitPropertyChanged = (e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value" && this._durationFactorWorking)
                this.resolveDurationFactor();
        }

        /** @internal */
        resolveDurationFactor(): Promise<any> {
            if (this.timeUnit && this.timeUnit.value) {
                return rps.app.durationResolver.resolve(rps.app.stateManager.getCurrentCompany(), this.timeUnit.value).then((result: rps.data.DurationFactor) => {
                    if (this.durationFactor != result) {
                        //En el caso de que se cambie la unidad de medida, cambiamos el valor, para que siga representando el mismo número…
                        var previousDurationFactor = this.durationFactor;
                        this.durationFactor = result;
                        //Si el valor anterior contenía valor, significa que el valor ha cambiado
                        //También se comprueba, que contenga valor el actual
                        if (rps.object.hasValue(previousDurationFactor) && rps.object.hasValue(this.durationFactor)) {
                            var displayedValue = this.value / previousDurationFactor.Factor;
                            this.value = displayedValue * this.durationFactor.Factor;
                        }
                    }
                });
            }
            else {
                this.durationFactor = null;
                return Promise.resolve<any>(this);
            }
        }

        /** @internal */
        public formatValue(): Promise<string> {
            if (this.durationFactor)
                return Promise.resolve(rps.utils.getFormattedDecimal({
                    value: this.value / this.durationFactor.Factor,
                    decimalPlaces:2,
                    suffix: this.durationFactor.Unit
                }));
            else
                return Promise.resolve(rps.utils.getFormattedDecimal({
                    value: this.value,
                    decimalPlaces: 2
                }));
        }
    }

    export class AddressProperty extends VMProperty<any>{

        /** @internal */
        private city: VMProperty<string>;
        /** @internal */
        private address: VMProperty<string>;
        /** @internal */
        private country: LookupProperty;
        /** @internal */
        private state: LookupProperty;
        /** @internal */
        private county: LookupProperty;
        /** @internal */
        private zipCode: VMProperty<string>;

        /** @internal */
        public _Image: string;
        get Image() {
            return this._Image;
        }
        set Image(value: string) {
            if (this._Image != value) {
                this._Image = value;
                this.propertyChanged.emit({ propertyName: "Image", newValue: this._Image });
            }
        }
        /** @internal */
        public _Text: string;
        get Text() {
            return this._Text;
        }
        set Text(value: string) {
            if (this._Text != value) {
                this._Text = value;
                this.propertyChanged.emit({ propertyName: "Text", newValue: this._Text });
            }
        }
        /** @internal */
        public _countryText: string;
        get countryText() {
            return this._countryText;
        }
        set countryText(value: string) {
            if (this._countryText != value) {
                this._countryText = value;
                this.propertyChanged.emit({ propertyName: "countryText", newValue: this._countryText });
            }
        }
        /** @internal */
        public _countryFlag: string;
        get countryFlag() {
            return this._countryFlag;
        }
        set countryFlag(value: string) {
            if (this._countryFlag != value) {
                this._countryFlag = value;
                this.propertyChanged.emit({ propertyName: "countryFlag", newValue: this._countryFlag });
            }
        }
        /** @internal */
        public _stateText: string;
        get stateText() {
            return this._stateText;
        }
        set stateText(value: string) {
            if (this._stateText != value) {
                this._stateText = value;
                this.propertyChanged.emit({ propertyName: "stateText", newValue: this._stateText });
            }
        }
        /** @internal */
        public _countyText: string;
        get countyText() {
            return this._countyText;
        }
        set countyText(value: string) {
            if (this._countyText != value) {
                this._countyText = value;
                this.propertyChanged.emit({ propertyName: "countyText", newValue: this._countyText });
            }
        }
        /** @internal */
        public _zipCodeText: string;
        get zipCodeText() {
            return this._zipCodeText;
        }
        set zipCodeText(value: string) {
            if (this._zipCodeText != value) {
                this._zipCodeText = value;
                this.propertyChanged.emit({ propertyName: "zipCodeText", newValue: this._zipCodeText });
            }
        }
        /** @internal */
        public _cityText: string;
        get cityText() {
            return this._cityText;
        }
        set cityText(value: string) {
            if (this._cityText != value) {
                this._cityText = value;
                this.propertyChanged.emit({ propertyName: "cityText", newValue: this._cityText });
            }
        }
        /** @internal */
        public _addressText: string;
        get addressText() {
            return this._addressText;
        }
        set addressText(value: string) {
            if (this._addressText != value) {
                this._addressText = value;
                this.propertyChanged.emit({ propertyName: "addressText", newValue: this._addressText });
            }
        }

        constructor(params: {
            target: BaseVM | any;
            city: VMProperty<string>;
            address: VMProperty<string>;
            country: LookupProperty;
            state: LookupProperty;
            county: LookupProperty;
            zipCode: VMProperty<string>;
        }) {
            super(params);
            this.city = params.city;
            this.address = params.address;
            this.country = params.country;
            this.state = params.state;
            this.county = params.county;
            this.zipCode = params.zipCode;
        }

        initialize(): Promise<any> {
            this.country.propertyChanged.subscribe((value: VMPropertyChange) => {
                if (value.propertyName == "value")
                    this.refreshCountry();
            });
            this.refreshCountry();

            this.state.propertyChanged.subscribe((value: VMPropertyChange) => {
                if (value.propertyName == "value")
                    this.refreshState();
            });
            this.refreshState();

            this.county.propertyChanged.subscribe((value: VMPropertyChange) => {
                if (value.propertyName == "value")
                    this.refreshCounty();
            });
            this.refreshCounty();

            this.zipCode.propertyChanged.subscribe((value: VMPropertyChange) => {
                if (value.propertyName == "value")
                    this.refreshZipCode();
            });
            this.refreshZipCode();

            this.city.propertyChanged.subscribe((value: VMPropertyChange) => {
                if (value.propertyName == "value")
                    this.refreshCity();
            });
            this.refreshCity();

            this.address.propertyChanged.subscribe((value: VMPropertyChange) => {
                if (value.propertyName == "value")
                    this.refreshAddress();
            });
            this.refreshAddress();

            this.city.propertyChanged.subscribe((value: VMPropertyChange) => {
                if (value.propertyName == "value")
                    this.refreshValues();
            });
            this.country.propertyChanged.subscribe((value: VMPropertyChange) => {
                if (value.propertyName == "value")
                    this.refreshValues();
            });
            return this.refreshValues();
        }

        refreshValues(): Promise<any>{
            this.Text = null;
            this.Image = null;            
            return this.country.getHeader().then((header: data.Descriptor) => {
                if (header && !string.isNullOrEmpty(header.MainDescriptor)) {
                    if (!string.isNullOrEmpty(this.city.value))
                        this.Text = string.format("{0}, {1}", this.city.value, header.MainDescriptor);
                    else
                        this.Text = string.format("{0}", header.MainDescriptor);
                    this.Image = header.Image;
                }
                else if (!string.isNullOrEmpty(this.city.value)) {
                    this.Text = string.format("{0}", this.city.value);
                }
            });
        }

        refreshCountry() {
            this.countryText = null;
            this.countryFlag = null;
            this.country.getHeader().then((header: data.Descriptor) => {
                if (header) {
                    this.countryText = header.MainDescriptor;
                    this.countryFlag = header.Image;
                }
            });
        }

        refreshState() {
            this.stateText = null;
            this.state.getHeader().then((header: data.Descriptor) => {
                if (header)
                    this.stateText = header.MainDescriptor;
            });
        }

        refreshCounty() {
            this.countyText = null;
            this.county.getHeader().then((header: data.Descriptor) => {
                if (header)
                    this.countyText = header.MainDescriptor;
            });
        }

        refreshZipCode() {
            this.zipCodeText = this.zipCode.value;
        }

        refreshCity() {
            this.cityText = this.city.value;
        }

        refreshAddress() {
            this.addressText = this.address.value;
        }
    }

    export class AddressValueProperty extends VMProperty<rps.data.AddressValue>{

        /** @internal */
        public _Image: string;
        get Image() {
            return this._Image;
        }
        set Image(value: string) {
            if (this._Image != value) {
                this._Image = value;
                this.propertyChanged.emit({ propertyName: "Image", newValue: this._Image });
            }
        }
        /** @internal */
        public _CountryText: string;
        get CountryText() {
            return this._CountryText;
        }
        set CountryText(value: string) {
            if (this._CountryText != value) {
                this._CountryText = value;
                this.propertyChanged.emit({ propertyName: "CountryText", newValue: this._CountryText });
            }
        }
        /** @internal */
        public _CountryFlag: string;
        get CountryFlag() {
            return this._CountryFlag;
        }
        set CountryFlag(value: string) {
            if (this._CountryFlag != value) {
                this._CountryFlag = value;
                this.propertyChanged.emit({ propertyName: "CountryFlag", newValue: this._CountryFlag });
            }
        }
        /** @internal */
        public _StateText: string;
        get StateText() {
            return this._StateText;
        }
        set StateText(value: string) {
            if (this._StateText != value) {
                this._StateText = value;
                this.propertyChanged.emit({ propertyName: "StateText", newValue: this._StateText });
            }
        }
        /** @internal */
        public _CountyText: string;
        get CountyText() {
            return this._CountyText;
        }
        set CountyText(value: string) {
            if (this._CountyText != value) {
                this._CountyText = value;
                this.propertyChanged.emit({ propertyName: "CountyText", newValue: this._CountyText });
            }
        }
        /** @internal */
        public _ZipCodeText: string;
        get ZipCodeText() {
            return this._ZipCodeText;
        }
        set ZipCodeText(value: string) {
            if (this._ZipCodeText != value) {
                this._ZipCodeText = value;
                this.propertyChanged.emit({ propertyName: "ZipCodeText", newValue: this._ZipCodeText });
            }
        }
        /** @internal */
        public _CityText: string;
        get CityText() {
            return this._CityText;
        }
        set CityText(value: string) {
            if (this._CityText != value) {
                this._CityText = value;
                this.propertyChanged.emit({ propertyName: "CityText", newValue: this._CityText });
            }
        }
        /** @internal */
        public _AddressText: string;
        get AddressText() {
            return this._AddressText;
        }
        set AddressText(value: string) {
            if (this._AddressText != value) {
                this._AddressText = value;
                this.propertyChanged.emit({ propertyName: "AddressText", newValue: this._AddressText });
            }
        }

        constructor(params: {
            target: any;
            valuePropertyPath?: string;
            initialValue?: rps.data.AddressValue;
            isRequired?: boolean;
            maxLength?: number;
        }) {
            super(params);
        }

        /** @internal */
        public formatValue(): Promise<string> {
            this.Image = this.value.CountryFlag;
            this.CountryText = this.value.CountyName;
            this.CountryFlag = this.value.CountryFlag;
            this.StateText = this.value.StateName;
            this.CountyText = this.value.CountyName;
            this.ZipCodeText = this.value.ZipCode;
            this.CityText = this.value.City;
            this.AddressText = this.value.Address; 

            if (!string.isNullOrEmpty(this.value.CountryName)) {                
                if (!string.isNullOrEmpty(this.value.City))
                    return Promise.resolve<string>(string.format("{0}, {1}", this.value.City, this.value.CountryName));
                else
                    return Promise.resolve<string>(string.format("{0}", this.value.CountryName));
            }
            else if (!string.isNullOrEmpty(this.value.City)) {
                return Promise.resolve<string>(string.format("{0}", this.value.City));
            }
            else
                return Promise.resolve<string>("");
        }
    }

    export class CustomComponentHandler {

        /** @internal */
        private componentPath: string;
        /** @internal */
        private componentName: string;
        /** @internal */
        private templateUrl: string;
        /** @internal */
        private template: string;
        /** @internal */
        private jsPath: string;
        /** @internal */
        private viewModelFunction: Function;
        /** @internal */
        private viewModelFunctionFullName: string;
        /** @internal */
        private viewModelParams: IParams;
        
        /** @internal */
        private vmInstance: any;
        public VM<T>() : T {
            return <T>this.vmInstance;
        }

        public configure(params: {
            componentPath?:string,
            componentName?: string,
            templateUrl?: string,
            template?: string,
            jsPath?: string,
            viewModelFunction?: Function,
            viewModelInstance?: any,
            viewModelFunctionFullName?:string,
            viewModelParams?: IParams
        }) {
            this.componentPath = params.componentPath;
            this.componentName = params.componentName;
            this.templateUrl = params.templateUrl;
            this.template = params.template;
            this.jsPath = params.jsPath;
            this.viewModelFunction = params.viewModelFunction;
            this.viewModelFunctionFullName = params.viewModelFunctionFullName;
            this.viewModelParams = params.viewModelParams;
            if (rps.object.hasValue(params.viewModelInstance))
                this.vmInstance = params.viewModelInstance;
        }

        public getComponent(): Promise<any> {
            if (this.componentPath && this.componentName) {
                return System.import(this.componentPath).then((comp) => {
                    return comp[this.componentName];
                }).catch((err) => {
                    console.error.bind(console);
                });
            }
            return Promise.resolve(null);
        }

        public getHTML(): Promise<string> {
            if (this.template)
                return Promise.resolve(this.template);

            return rps.app.apiRef.getFile(this.templateUrl);
        }

        public getVM(): Promise<any> {
            if (this.vmInstance)
                return Promise.resolve(this.vmInstance);

            if (!this.viewModelFunction && !this.viewModelFunctionFullName) {
                console.log("ViewModelFunction or function name must be specified");
                return Promise.resolve({});
            }

            if (this.viewModelFunction)
                return rps.app.viewModelFactory.createViewModel(this.viewModelFunction, this.viewModelParams).then((vm) => {
                    this.vmInstance = vm;
                    return vm;
                });

            //Mirar si puede crear el tipo sin cargar js
            this.viewModelFunction = this.getFunction();
            if (this.viewModelFunction)
                return rps.app.viewModelFactory.createViewModel(this.viewModelFunction, this.viewModelParams).then((vm) => {
                    this.vmInstance = vm;
                    return vm;
                });

            //Si no, mirar si cargando el jsPath se puede crear
            if (this.jsPath) {
                return rps.app.apiRef.getFile(this.jsPath).then((code: string) => {
                    var types = eval.call(this, code);
                    this.viewModelFunction = this.getFunction();
                    if (this.viewModelFunction)
                        return rps.app.viewModelFactory.createViewModel(this.viewModelFunction, this.viewModelParams).then((vm) => {
                            this.vmInstance = vm;
                            return vm;
                        });
                });
            }

            throw "Could not create view model instance";
        }

        private getFunction(): Function {
            if (this.viewModelFunction)
                return this.viewModelFunction;

            if (this.viewModelFunctionFullName) {
                var funcNames = this.viewModelFunctionFullName.split('.');
                var func: any = window[funcNames[0]];
                for (var i = 1; i < funcNames.length; i++) {
                    if (!func)
                        break;
                    func = func[funcNames[i]];
                }
                if (rps.object.isFunction(func))
                    return func;
            }

            return null;
        }

    }

    export class PercentageProperty extends DecimalProperty implements IFormattedNumberProperty {
        /** @internal */
        public formatValue(): Promise<string> {
            var format = rps.utils.getDecimalFormat({ suffix:"%"});
            return Promise.resolve(kendo.toString(this.value, format));
        }

        get formattedValue(): string {
            var format = rps.utils.getDecimalFormat();
            return kendo.toString(this.value, format);
        }

        get suffix(): string {
            return '%';
        }
    }

    export class TimeProperty extends DecimalProperty{
        /** @internal */
        public formatValue(): Promise<string> {
            var format = 'HH:mm:ss';
            return Promise.resolve(kendo.toString(rps.time.convertToDate(this.value), format));
        }
    }

    export class DateProperty extends VMProperty<Date>{
        constructor(params: {
            target: any;
            valuePropertyPath?: string;
            initialValue?: Date;
            isRequired?: boolean;
            maxLength?: number;
            dateStyle: DateStyles;
        }) {
            super(params);
            this.dateStyle = params.dateStyle;
        }
        /** @internal */
        private dateStyle: DateStyles;
        /** @internal */
        public formatValue(): Promise<string> {
            if (!this.value)
                return Promise.resolve<string>("");
            switch (this.dateStyle) {
                case DateStyles.Date:
                    return Promise.resolve(rps.date.toDateString(this.value)); 
                case DateStyles.DateAndTime:
                    return Promise.resolve(rps.date.toDateTimeString(this.value));
                case DateStyles.Calendar:
                    return Promise.resolve(rps.date.asCalendarTime(this.value));
                case DateStyles.TimeAgo:
                    return Promise.resolve(rps.date.asRelativeTime(this.value));
                default:
                    return Promise.resolve("TODO");
            }
        }

        /** @internal */
        public getServerParamValue(): any {
            if (this.value)
                return this.value.toISOString();
            else
                return null;
        }

        //Método que establece un nuevo valor, con la misma fecha con y la nueva hora
        public setTime(seconds: number) {
            var hours: number = Math.round(seconds / 3600);
            var minutes: number = Math.round((seconds % 3600) / 60);
            var secs: number = Math.round(seconds - hours * 3600 - minutes * 60);
            var newDate = rps.date.clone(this.value);
            newDate.setHours(hours);
            newDate.setMinutes(minutes);
            newDate.setSeconds(secs);
            this.value = newDate;
        }
    }

    export class ColorProperty extends DecimalProperty{

        /** @internal */
        public formatValue(): Promise<string> {
            if (rps.object.hasValue(this.value) && this.value != 0) {
                var number: number = this.value;
                if (number < 0)
                    number = 0xFFFFFFFF + number + 1;
                var result = number.toString(16);
                if (result.length == 8)
                    result = result.substr(2, 6);
                else if (result.length < 6) {
                    while (result.length < 6) {
                        result = "0" + result;
                    }
                }
                return Promise.resolve("#" + result);
            }
            else {
                return Promise.resolve("");
            }
        }

    }   

    export class ImageProperty extends VMProperty<string> {
        //Se crea una propiedad desligada del modelo, 
        //ya que en el caso de que se modifique la imagen, no se quiere modificar el modelo, 
        //ya que la entidad se guarda de forma independiente
        /** @internal */
        private _detachedValue: string;
        get value(): string {
            if (this._detachedValue)
                return this._detachedValue;
            else {
                if (this._pathParts) {
                    var _value: string;
                    if (this._pathParts.length == 1)
                        _value = this._target[this._pathParts[0]];
                    else if (this._pathParts.length == 2 && this._target[this._pathParts[0]])
                        _value = this._target[this._pathParts[0]][this._pathParts[1]];
                    else if (this._pathParts.length == 3 && this._target[this._pathParts[0]] && this._target[this._pathParts[0]][this._pathParts[1]])
                        _value = this._target[this._pathParts[0]][this._pathParts[1]][this._pathParts[2]];

                    if (typeof this.originalValue === "undefined")
                        this.setOriginalValue(_value);

                    return _value;
                }
                else
                    return this._value;
            }                
        }
        set value(newValue: string) {
            this._detachedValue = newValue;
            this.propertyChanged.emit({ propertyName: "value", newValue: newValue });
        }
    }
}  