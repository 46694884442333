/// <reference path="../utils/errors.ts" />
/// <reference path="../utils/utils.ts" />
/// <reference path="./baseVM.ts" />
/// <reference path="./componentVM.ts" />
module rps.viewmodels {

    export interface IModelVMInitParams {
        model: any;
    }

    export class ModelVM extends BaseVM {

        model: any = null;

        public initialize(initParams?:IModelVMInitParams): Promise<ModelVM> {
            var initPromise: Promise<ModelVM> = super.initialize(initParams);
            if (initParams && initParams.model) {
                return initPromise.then((vm) => {
                    this.model = initParams.model;
                    return vm;
                });
            }
            else
                return initPromise;
        }
    }    
} 