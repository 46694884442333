/// <reference path="parameters.ts" />
/// <reference path="../viewmodels/modelVM.ts" />

module rps.data.sources {

    export class ChronologicalQuerySource implements ISelectable, IActivable, IDestroyable, IExecutableQuery {
        public ISelectable: boolean = true;
        public isIActivable: boolean = true;
        public isIDestroyable: boolean = true;

        private fromDate: Date;
        private toDate: Date;

        private relatedVMFunction: Function;
        private saveState: boolean;
        private service: string;
        private queryName: string;        
        private _parentVM: rps.viewmodels.BaseVM;

        public propertyChanged = rps.app.eventManager.createEmitter<rps.viewmodels.properties.VMPropertyChange>();

        public filter: ChronologicalQuerySource.IChronologicalQuerySourceFilter;
        private _loaded: boolean = false;
        public get loaded(): boolean {
            return this._loaded;
        }
        public set loaded(newValue: boolean) {
            if (this._loaded != newValue) {
                this._loaded = newValue;
                this.propertyChanged.emit({ propertyName: "loaded", newValue: newValue });
            }
        }

        public _items: { data: Array<any>; count: number };
        get items(): { data: Array<any>; count: number } {        
            return this._items;
        }
        set items(newValue: { data: Array<any>; count: number }) {
            if (this._items != newValue) {
                this._items = newValue;
                this.propertyChanged.emit({
                    propertyName: "items",
                    newValue: this.items
                });                
            }
        }
        public hasItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.items) && this.items.count > 0)
                return true;
            else
                return false;
        }
        public getItems(): Array<any> {
            if (this.hasItems())
                return this.items.data;
            else
                return new Array<any>();
        }
        private _selectedItems: Array<any>;
        public get selectedItems(): Array<any> {
            return this._selectedItems;
        }
        public set selectedItems(newValue: Array<any>) {
            if (this._selectedItems != newValue) {
                this._selectedItems = newValue;
                this.propertyChanged.emit({
                    propertyName: "selectedItems",
                    newValue: this.selectedItems
                });

                //TODO
                //Lanzar las when del target (las que no tienen Trigger)
                //if (this._parentVM && (<any>this._parentVM).rules && (<any>this._parentVM).rules.length > 0) {
                //    Enumerable.From<rps.viewmodels.rules.Rule>((<any>this._parentVM).rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                //        ForEach((rule) => {
                //            rule.execute(this);
                //        });
                //}
            }
        }        
        public hasSelectedItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.selectedItems) && this.selectedItems.length > 0)
                return true;
            else
                return false;
        }
        public getSelectedItems(): Array<any> {
            if (this.hasSelectedItems())
                return this.selectedItems;
            else
                return new Array<any>();
        }
        public setSelectedItems(newSelection: Array<any>) {
            this.selectedItems = newSelection;
        }

        public queryParams: parameters.QueryParams;
        public dataSource: kendo.data.SchedulerDataSource;        

        constructor(params: {
            service: string;
            queryName: string;     
            relatedVMFunction: Function;  
            executionPolicy: rps.queryExecutionPolicy;
            parentVM?:rps.viewmodels.BaseVM
            queryParams?: parameters.QueryParams;   
            saveState?: boolean;                     
        }) {
            this._parentVM = params.parentVM;
            this.service = params.service;
            this.queryName = params.queryName;
            this.queryParams = params.queryParams;
            this.relatedVMFunction = (<any>(params.relatedVMFunction)).getResolvedType(params.relatedVMFunction);                        
            this.executionPolicy = params.executionPolicy;
            this.saveState = params.saveState;

            this.createDataSource();

            //Si se autoexecuta, hay que añadir watches a las propiedades
            if ((this.executionPolicy == rps.queryExecutionPolicy.Automatic || this.executionPolicy == rps.queryExecutionPolicy.WhenActive) && this.queryParams) {
                this.queryParams.forEach((ip: parameters.QueryParam) => {
                    if (ip.value && ip.value instanceof rps.viewmodels.properties.VMProperty) {
                        (<rps.viewmodels.properties.VMProperty<any>>ip.value).propertyChanged.subscribe((subscriptionArgs: rps.viewmodels.properties.VMPropertyChange) => {
                            if (subscriptionArgs.propertyName == "value" && this.canCreateParams()) {
                                this.load();
                            }
                        });
                    }
                });
            }
        }

        initialize(): Promise<any> {
            if (this.executionPolicy == rps.queryExecutionPolicy.Automatic && this.canCreateParams())
                this.load();
            return Promise.resolve<any>(this);
        }

        public executionPolicy: rps.queryExecutionPolicy;

        public execute = (): Promise<any> => {
            if (this.canCreateParams())
                return this.load();
        }

        private load(): Promise<any> {
            if (this.canCreateParams()) {
                this.loaded = true;                
                return new Promise<any>((resolve, reject) => {
                    this.dataSource.read().then(() => {
                        resolve(this);
                    }).fail((error) => {
                        reject(error);
                    });
                });
            }
            else if(this.loaded) {
                this.loaded = false;
                var items: Array<any> = new Array<any>();          
                this.items = {
                    data: items,
                    count: 0
                };
                this.dataSource.data(items);                
            }

            return rps.app.messageManager.show({
                message: `${this.queryName}: ${rps.app.resources.directives.FILL_REQUIRED_PARAMETERS}`,
                messageButton: services.MessageButton.Ok,
                messageType: services.MessageType.Warning
            }).then(() => {
                let result = new viewmodels.commands.CanExecuteResult();
                result.result = false;
                return Promise.reject<any>(result);
            });
        }

        private canCreateParams(): boolean {
            var canCreate: boolean = true;
            if (this.queryParams) {
                this.queryParams.forEach((ip: parameters.QueryParam) => {
                    //Mirar si todos los parámetros requeridos tienen valor
                    if (ip.isRequired && !rps.object.hasValue(ip.getValue()))
                        canCreate = false;
                });
            }
            return canCreate;
        }

        getParams():{ [id: string]: Object; } {
            var params: { [id: string]: Object; } = {};
            if (this.queryParams) {
                this.queryParams.forEach((queryParam: parameters.QueryParam) => {
                    var value = queryParam.getValue();
                    if (!rps.object.isNullOrUndefined(value)) {
                        if (value instanceof Date)
                            params[queryParam.paramName] = value.toISOString();
                        else if (Array.isArray(value)) {
                            if (value.length > 0)
                            {
                                var newParamValue: string;
                                (<Array<any>>value).forEach((item) => {
                                    if (newParamValue == undefined)
                                        newParamValue = item;
                                    else
                                        newParamValue += "," + item;
                                });
                                params[queryParam.paramName] = newParamValue;
                            }
                        }
                        else
                            params[queryParam.paramName] = value;
                    }                    
                });
            }
                        
            if (this.filter && this.filter.fromDate() && this.filter.toDate()) {
                params["$fromdate"] = this.filter.fromDate();
                params["$todate"] = this.filter.toDate();
            }
            else if (this.fromDate) {
                params["$fromdate"] = this.fromDate;
                params["$todate"] = this.toDate;
            }
            else {
                params["$fromdate"] = rps.data.sources.ChronologicalQuerySource.getFromDefaultDate();
                params["$todate"] = rps.data.sources.ChronologicalQuerySource.getToDefaultDate();
            }
            this.fromDate = <Date>params["$fromdate"];
            this.toDate = <Date>params["$todate"];

            return params;              
        }

        createDataSource() {
            var dataSourceOptions: kendo.data.DataSourceOptions = {}
            dataSourceOptions.serverFiltering = true;
            dataSourceOptions.transport= {
                read: this.read
            };
            dataSourceOptions.schema = {
                model: {
                    id: "taskId",
                    fields: {
                        taskId: { from: "model.ID", type: "string" },
                        title: { from: "model.event.EventName", type: "string" },
                        start: { from: "model.event.EventStart",type: "date" },
                        end: { from: "model.event.EventEnd",type: "date" },                        
                        isAllDay: { from: "model.event.AllDay", type: "boolean" },
                        customColor: { from: "model.event.CustomColor", type: "string" },
                        calculatedForeColor: { from: "model.event.CalculatedForeColor", type: "string" },
                    }
                }
            }
            this.dataSource = new kendo.data.SchedulerDataSource(dataSourceOptions);
        }

        read = (options: kendo.data.DataSourceTransportReadOptions) => {            
            this.selectedItems = undefined;
            var params: { [id: string]: Object; } = this.getParams();
            rps.app.api.query<Array<any>>({
                queryName: this.service + "/" + this.queryName,                
                params: params
            }).then((result) => {
                this.createViewModels(result).then((items: Array<rps.viewmodels.ModelVM>) => {
                    this.items = { data: items, count: items.length };
                    options.success(items);
                });            
            }).catch((error) => {
                throw rps.app.resources.errors.ERR_EXECUTING_QUERY.format(error.toString());
            });
        }

        createViewModels(result: Array<any>): Promise<Array<rps.viewmodels.ModelVM>> {
            var promises: Array<Promise<rps.viewmodels.BaseVM>> = [];
            for (var i = 0; i < result.length; i += 1)
                promises.push(this.createViewModel(result[i]));
            return Promise.all(promises);
        }

        createViewModel(model: any): Promise<rps.viewmodels.BaseVM> {
            var calendarEventResultModel: rps.data.CalendarEventResult = model.event;
            if (!calendarEventResultModel.EventName)
                calendarEventResultModel.EventName = " ";
            if (!calendarEventResultModel.AllDay)
                calendarEventResultModel.AllDay = false;
            //Calcular el color de fuente blanco o negro dependiendo del color
            calendarEventResultModel.CalculatedForeColor = this.calculateForeColor(calendarEventResultModel.CustomColor);
            var initParams = { model: model };
            if (this._parentVM)
                initParams["parentVM"] = this._parentVM;
            return rps.app.viewModelFactory.createViewModel(this.relatedVMFunction, initParams).then((vm) => {
                return vm;
            });
        }

            //Calcular el color de fuente blanco o negro dependiendo del color de fondo pasado
        calculateForeColor(customHexColor: number): string {
            if (rps.object.hasValue(customHexColor)) {
                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(customHexColor.toString());
                let rgb = result ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                } : null;
                if (rgb) {
                    if ((rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186)
                        return "#000000";//Negro
                    else
                        return "#ffffff";//Blanco
                }
            }

            return "#ffffff"; //Blanco
        }

        onActivate(isFirstActivation:boolean): void {
            //Si es la primera vez y la query está marcada como whenActivate, se intenta lanzar
            if (isFirstActivation) {
                if (this.executionPolicy == rps.queryExecutionPolicy.WhenActive)
                    this.execute();
            }
            else {
                if (this.loaded)
                    this.load();
            }
        }

        onDestroy(): void {
            this.propertyChanged.onDestroy();
        }

        public static getFromDefaultDate():Date {
            var fromdate: Date = new Date();
            fromdate.setDate(15);
            fromdate.setMonth(fromdate.getMonth() - 1);
            return fromdate;
        }

        public static getToDefaultDate(): Date {
            var todate: Date = new Date();
            todate.setDate(15);
            todate.setMonth(todate.getMonth() + 1); 
            return todate;
        }
    }    
}

module rps.data.sources.ChronologicalQuerySource {
    export interface IChronologicalQuerySourceFilter {
        fromDate(): Date;
        toDate(): Date;        
    }
}