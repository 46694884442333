/// <reference path="parameters.ts" />

module rps.data.sources {

    export class QueryMethod implements IExecutableQuery, IActivable, rps.viewmodels.rules.IRuleTrigger{
        private queryName: string;
        public inputParams: parameters.QueryParams;
        public outputParams: parameters.QueryParams;

        public isIActivable: boolean = true;
        public IRuleTrigger: boolean = true;
        public rules: rps.viewmodels.rules.Rule[] = [];

        constructor(params: {
            queryName: string;
            inputParams?: parameters.QueryParams;
            outputParams: parameters.QueryParams;
            executionPolicy?: rps.queryExecutionPolicy;
        }) {
            this.queryName = params.queryName;
            if (params.inputParams)
                this.inputParams = params.inputParams;
            if (rps.object.hasValue(params.executionPolicy))
                this.executionPolicy = params.executionPolicy;

            this.outputParams = params.outputParams;

            //Si se autoexecuta, hay que añadir watches a las propiedades
            if (this.executionPolicy == rps.queryExecutionPolicy.Automatic || this.executionPolicy == rps.queryExecutionPolicy.WhenActive) {
                this.inputParams.forEach((ip: parameters.QueryParam) => {
                    if (ip.value && ip.value instanceof rps.viewmodels.properties.VMProperty) {
                        (<rps.viewmodels.properties.VMProperty<any>>ip.value).propertyChanged.subscribe((subscriptionArgs: rps.viewmodels.properties.VMPropertyChange) => {
                            if (subscriptionArgs.propertyName == "value") {
                                this.tryToExecute();
                            }
                        });
                    }
                });
            }
        }

        initialize(): Promise<any> {
            if (this.executionPolicy == rps.queryExecutionPolicy.Automatic)
                return this.tryToExecute();            
        }

        public canCreateParams(commandParameters?: any): boolean {
            var canCreate: boolean = true;
            if (this.inputParams) {
                this.inputParams.forEach((ip: parameters.QueryParam) => {
                    //Mirar si todos los parámetros requeridos tienen valor
                    if (ip.isRequired) {
                        if (!rps.object.hasValue(ip.getValue())
                            && (rps.object.isNullOrUndefined(commandParameters) || !rps.object.hasValue(commandParameters[ip.paramName]))) {

                            canCreate = false;
                        }
                    }
                });
            }
            return canCreate;
        }

        private tryToExecute(commandParameters?: any): Promise<any> {
            if (this.canCreateParams(commandParameters))
                return this.execute(commandParameters);

            return Promise.resolve<any>(this);
        }

        public executionPolicy: rps.queryExecutionPolicy = rps.queryExecutionPolicy.Manual;

        public execute(commandParameters?: any): Promise<parameters.QueryParams> {
            return new Promise<parameters.QueryParams>((resolve,reject) => {
                var params: { [id: string]: Object; } = {};
                if (this.inputParams) {
                    this.inputParams.forEach((queryParam: parameters.QueryParam) => {
                        params[queryParam.paramName] = queryParam.getValue();
                    });
                }  
                                
                for (var param in commandParameters) {
                    if (commandParameters[param] instanceof rps.viewmodels.properties.VMProperty)
                        params[param] = (<rps.viewmodels.properties.VMProperty<any>>commandParameters[param]).getServerParamValue();
                    else if (rps.object.isFunction(commandParameters[param]))
                        params[param] = commandParameters[param].call();
                    else
                        params[param] = commandParameters[param];
                }                
          
                rps.app.api.query({ queryName: this.queryName, params: params }).then((result) => {
                    //Asignar valores a las variables de retorno (también si son nulos)
                    this.outputParams.forEach((outParam: parameters.QueryParam) => {
                        if (!rps.object.isUndefined(result[outParam.paramName])) {
                            if (outParam.value && outParam.value instanceof rps.viewmodels.properties.VMProperty)
                                outParam.value.value = result[outParam.paramName];
                            else
                                outParam.value = result[outParam.paramName];
                        }
                    });
                    resolve(this.outputParams);

                    return this.processRules(rps.viewmodels.rules.Triggers.OnExecuteQuery).then(() => {
                        return result;
                    });
                }).catch((err) => {
                    reject(err);
                });
            });
        }

        public onActivate(isFirstActivation: boolean) {
            //Si es la primera vez y la query está marcada como whenActivate, se intenta lanzar
            if (isFirstActivation) {
                if (this.executionPolicy == rps.queryExecutionPolicy.WhenActive)
                    this.tryToExecute();
            }
        }

        protected processRules(trigger?: rps.viewmodels.rules.Triggers): Promise<rps.viewmodels.rules.RuleExecutionResult> {
            var rulesToExecute: Array<rps.viewmodels.rules.Rule> = [];
            if (this.rules && this.rules.length > 0) {
                this.rules.forEach((rule) => {
                    if (trigger && rule.Trigger == trigger)
                        rulesToExecute.push(rule);
                });
            }

            return rulesToExecute.reduce((prevPromise, rule) => {
                return prevPromise.then((result) => {
                    if (result.value === rps.viewmodels.rules.RuleExecutionValue.Continue)
                        return rule.execute().catch((err) => {
                            //Si casca la ejecución de una regla, añadir el error y cancelar el resto de reglas
                            rps.app.errorManager.add(
                                new rps.errors.ErrorDetail("RULE_ERROR", rps.app.resources.errors.ERR_ERROR_EXECUTING_RULE.format(rule.ID,rps.errors.getErrorString(err))));

                            return Promise.resolve({ value: rps.viewmodels.rules.RuleExecutionValue.Cancel });
                        });
                    else
                        return Promise.resolve({ value: rps.viewmodels.rules.RuleExecutionValue.Cancel });
                })
            }, Promise.resolve({ value: rps.viewmodels.rules.RuleExecutionValue.Continue }));

        }

    }

} 