/// <reference path="../utils/errors.ts" />
/// <reference path="../entities/entityModel.ts" />
/// <reference path="../utils/utils.ts" />
/// <reference path="./baseVM.ts" />
/// <reference path="./componentVM.ts" />
/// <reference path="./properties.ts" />
/// <reference path="../app/attachedDocumentsVM.ts" />

module rps.viewmodels {

    export class MainEntityVM<T extends rps.entities.IBaseEntity> extends BaseVM implements rps.errors.INotifyDataErrorInfo {

        public static idPropertyName: string = "";
        public idPropertyName: string = "";
        public fillStateParams(params: Object) {
            params[this.idPropertyName] = this.model.getEntityID();
        }
        public hasManualID: boolean = false;

        public descriptor: rps.viewmodels.properties.LookupProperty;
        public attachments: rps.app.viewmodels.attachedDocumentsVM;
        public __hasErrors: boolean = false;

        protected _tConstructor: rps.services.entityFactory.IEntityDefinition<T>;

        protected updateMethod = MainEntityVM.UpdateMethods.Overwrite;
        protected customQuery: rps.data.sources.GetEntitySource;

        private pendingPromises: Promise<any>[];

        selfLink: rps.viewmodels.properties.LinkProperty;

        model: T = null;

        HasModifiedRelations: rps.viewmodels.properties.VMProperty<boolean>;
        IsNew: rps.viewmodels.properties.VMProperty<boolean>;
        IsModified: rps.viewmodels.properties.VMProperty<boolean>;

        /** @rpsInternal (for framework use only) */
        allowNavigateToComponent: boolean = false;
        navigateToComponent: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: this.executeNavigateToComponent,
            canExecute: this.canExecuteNavigateToComponent
        });

        constructor() {
            super();
        }

        /**
         * Configura el modelVM; en los parámetros hay que pasar en tConstructor el ModelDefinition del modelo (entidad) relacionado con este ViewModel
         */
        protected configure(configParams: {
            tConstructor: rps.services.entityFactory.IEntityDefinition<T>,
            updateMethod?: MainEntityVM.UpdateMethods
        }): Promise<MainEntityVM<T>> {
            this._tConstructor = configParams.tConstructor;
            if (rps.object.hasValue(configParams.updateMethod))
                this.updateMethod = configParams.updateMethod;

            return this._tConstructor.ensureLoaded().then(() => {
                return super.configure(configParams);
            });
        }

        protected configureVMProperties(): void {
            super.configureVMProperties();

            this.HasModifiedRelations = new rps.viewmodels.properties.VMProperty<boolean>({ target: this, valuePropertyPath: "model.__hasModifiedRelations" });
            this.IsNew = new rps.viewmodels.properties.VMProperty<boolean>({ target: this, valuePropertyPath: "model.__isNew" });
            this.IsModified = new rps.viewmodels.properties.VMProperty<boolean>({ target: this, valuePropertyPath: "model.__isModified" });
        }

        //En MultipleMaintenanceComponentVM, crea los MV, pasando la entidad, sin embargo, las demás pantallas que mantienen entidades, pasan el ID o “New” en un objeto de tipo IStateParams
        public initialize(initParams: rps.services.IStateParams | IParams): Promise<MainEntityVM<T>> {
            return super.initialize(initParams).then((vm: MainEntityVM<T>) => {
                if (initParams["stateParams"] && (<rps.services.IStateParams>initParams).stateParams[this.idPropertyName])
                    return vm.loadModel((<rps.services.IStateParams>initParams).stateParams[this.idPropertyName]);
                else if (initParams["model"])
                    return vm.loadModel(initParams["model"]);
                else
                    return vm;
            });
        }

        loadModel(data: string | {}): Promise<MainEntityVM<T>> {
            if (!data)
                return Promise.resolve(this);

            if (data == rps.viewmodels.EntityVM.NEW_ID_VALUE) {
                return this.newModel().then((vm) => {
                    return vm
                });
            }
            else {
                if (data instanceof rps.entities.BaseEntity)
                    return this.setEntity(<any>data);
                else
                    return this._tConstructor.get(data, this.updateMethod == MainEntityVM.UpdateMethods.Patch, this.customQuery).then((result) => {
                        return this.setEntity(result);
                    });
            }
        }

        newModel(): Promise<MainEntityVM<T>> {
            return this._tConstructor.createNew(this.updateMethod == MainEntityVM.UpdateMethods.Patch).then((result) => {
                return this.setEntity(result);
            });
        }

        /**
         * Recarga los datos de la entidad actual desde base de datos y refresca la pantalla
         */
        refreshModel(): Promise<MainEntityVM<T>> {
            let id = (<rps.entities.IBaseEntity>this.model).getEntityID();
            return this.loadModel(id).then((vm) => {
                vm.createExtendedData(true);
                return this;
            });
        }

        setEntity(entity: T): Promise<MainEntityVM<T>> {
            var shouldRaisePropertyChanged = (this.model != null);

            this.model = entity;

            if (this.model) {
                //Recorrer todas las propiedades para engancharse al propertyChanged del modelo
                for (var prop in this) {
                    var propertyValue = this[prop];
                    if (rps.viewmodels.properties.isVMProperty(propertyValue))
                        propertyValue.subscribeToModelPropertyChanged(this.model, shouldRaisePropertyChanged);
                }
            }

            this.treatWIfProperties();

            return Promise.resolve<MainEntityVM<T>>(this);
        }

        createDescriptor() {
            if (!this.model.__isNew) {
                this.descriptor = new rps.viewmodels.properties.LookupProperty({
                    entityName: this.model.__entityUrl,
                    target: this,
                    initialValue: this.model.getEntityID()
                });
            }
            else {
                this.descriptor = null;
            }
        }

        createAttachments() {
            if (!this.model.__isNew) {
                if (!this.attachments || string.isNullOrEmpty(this.attachments.entityID)) {
                    this.attachments = new rps.app.viewmodels.attachedDocumentsVM(
                        {
                            entityType: this._tConstructor.url,
                            entityID: this.model.getEntityID()
                        });
                }
            }
            else {
                this.attachments = new rps.app.viewmodels.attachedDocumentsVM(
                    {
                        entityType: this._tConstructor.url
                    });
            }
        }

        save: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                return this.executeSave();
            },
            canExecute: this.canExecuteSave,
            relatedAction: rps.services.Action.Save
        });

        canExecuteSave(): commands.CanExecuteResult {
            if (!this.hasPendingChanges())
                return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.messages.MSG_NO_PENDING_CHANGES]);
            else if (this.isLocked)
                return commands.CanExecuteResult.deny([rps.app.resources.commonResources.MSG_READ_ONLY]);
            else
                return commands.CanExecuteResult.allow();
        }

        executeSave(): Promise<boolean> {
            rps.app.errorManager.clear();

            return this.savePendingChanges()
                .then(() => {
                    return true;
                })
                .catch((errors) => {
                    if (errors){
                        rps.app.errorManager.add(errors);
                    }
                    return false;
                });
        }

        saveAndClose: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                return this.executeSaveAndClose();
            }
        });

        executeSaveAndClose(): Promise<boolean> {
            if (this.canExecuteSave().result) {
                return this.executeSave().then((result: boolean) => {
                    if (result)
                        return rps.app.stateManager.goToParent().then(() => {
                            return true;
                        });
                    else
                        return false;
                })
            }
            else
            {
                return rps.app.stateManager.goToParent().then(() => {
                    return true;
                });
            }
        }

        savePendingChanges(): Promise<any> {
            this.model.clearAllErrors();
            this.clearErrors();

            //Valida la entidad con lo básico antes de lanzar el grabado
            this.validate();
            var errors = this.getErrors();
            if (errors.length > 0)
                return Promise.reject(errors);

            return this.processCancellableRules(rules.Triggers.OnBeforeSave).then((result) => {
                if (result.value === rules.RuleExecutionValue.Continue) {
                    //Si no es nueva y usa patch, llama al patch; si no, update normal (POST o PUT)
                    var actionPromise = this.doSaveModel();
                    return actionPromise.then((result) => {
                        this.reset();
                        this.sort();
                        this.createExtendedData(true);
                        rps.app.notificationManager.show({
                            message: rps.app.resources.directives.ENTITY_SAVED,
                            notificationType: rps.services.NotificationType.Success
                        });
                        this.processRules(rules.Triggers.OnAfterSave);
                        return result;
                    }).catch((saveErrors) => {
                        var errors: Array<any> = new Array<any>();
                        if (saveErrors) {
                            if (Array.isArray(saveErrors))
                                saveErrors.forEach((errError) => {
                                    errors.push(errError);
                                });
                            else
                                errors.push(saveErrors);
                        }
                        if (this.getErrors().length) {
                            this.getErrors().forEach((getErrorsError) => {
                                if (errors.indexOf(getErrorsError) < 0) //Para que no los duplique
                                    errors.push(getErrorsError);
                            });
                        }

                        return Promise.reject(errors);
                    });
                }
                else if (result.value === rules.RuleExecutionValue.Cancel) {
                    if (result.reasons && result.reasons.length > 0)
                        return Promise.reject(result.reasons.slice(0));
                    else
                        return Promise.reject(null);
                }

                return Promise.resolve(null);
            });
        }

        new: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<EntityVM<T>> => {
                return this.executeNew();
            },
            relatedAction: rps.services.Action.New
        });

        executeNew(): Promise<any> {
            if (this.canExecuteSave().result) {
                return this.executeSave().then((result: boolean) => {
                    if (result) {
                        var maintenanceComponentVM: rps.viewmodels.MaintenanceComponentVM<rps.viewmodels.MainEntityVM<rps.entities.BaseEntity>> = <any>this.parentVM;
                        return maintenanceComponentVM.addEntityCommand.execute();
                    }
                })
            }
            else {
                var maintenanceComponentVM: rps.viewmodels.MaintenanceComponentVM<rps.viewmodels.MainEntityVM<rps.entities.BaseEntity>> = <any>this.parentVM;
                return maintenanceComponentVM.addEntityCommand.execute();
            }           
        }

        /**
        * Método que ejecuta la acción de grabado del modelo interno del VM.
        *  Es el lugar correcto para poder modificar la acción a realizar para grabar una entidad, o para hacer algo justo antes o después del grabado de la entidad
        *  Se ejecuta después de haber realizado preguntas y haber ejecutado las reglas de onBeforeSave
         */
        doSaveModel(): Promise<any> {
            return ((this.updateMethod == MainEntityVM.UpdateMethods.Patch && !this.model.__isNew) ? this.model.patch(this.customQuery) : this.model.update());
        }

        delete: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                return this.executeDelete();
            },
            canExecute: this.canExecuteDelete,
            relatedAction: rps.services.Action.Delete
        });

        canExecuteDelete(): commands.CanExecuteResult {
            if (this.isLocked)
                return commands.CanExecuteResult.deny([rps.app.resources.commonResources.MSG_READ_ONLY]);
            else
                return commands.CanExecuteResult.allow();
        }

        executeDelete(): Promise<boolean> {
            return rps.app.messageManager.show({
                message: rps.app.resources.messages.MSG_ASK_DELETE_ITEM,
                messageButton: rps.services.MessageButton.YesNo,
                messageType: rps.services.MessageType.Question
            }).then((result: rps.services.MessageResult) => {
                if (result == rps.services.MessageResult.Yes) {
                    rps.app.errorManager.clear();
                    return this.deleteModel().then(() => {
                        //Anular el modelo, para que ignore el control de cambios
                        this.model = null;
                        this.navigateAfterDelete();
                        return true;
                    }).catch((err) => {
                        rps.app.errorManager.add(err);
                        return false;
                    });
                }
                else
                    return false;
            });
        }

        deleteModel(): Promise<any> {
            return this.processCancellableRules(rules.Triggers.OnBeforeDelete).then((result) => {
                if (result.value === rules.RuleExecutionValue.Continue) {
                    return this.doDeleteModel().then((result) => {
                        rps.app.notificationManager.show({
                            message: rps.app.resources.directives.ENTITY_DELETED,
                            notificationType: rps.services.NotificationType.Success
                        });
                        return result;
                    })
                }
            });
        }

        /**
         * Método que ejecuta la acción de borrado del modelo interno del VM.
        *  Es el lugar correcto para poder modificar la acción a realizar para eliminar una entidad, o para hacer algo justo antes o después del borrado de la entidad
        *  Se ejecuta después de haber realizado preguntas y haber ejecutado las reglas de onBeforeDelete
         */
        doDeleteModel(): Promise<any> {
            return this.model.delete();
        }

        clearErrors = () => {
            for (var property in this) {
                var propertyValue = this[property];
                if (rps.viewmodels.properties.isVMProperty(propertyValue))
                    propertyValue.clearErrors();
            }
        }

        createExtendedData(forceRefresh: boolean = false) {
            if (!this.attachments || forceRefresh)
                this.createAttachments();
            if (!this.descriptor || forceRefresh)
                this.createDescriptor();
        }

        onActivate(): void {
            super.onActivate();

            this.createExtendedData();
        }

        onBeforeUnload(): Promise<boolean> {
            return super.onBeforeUnload().then((result) => {
                if (result)
                    return this.savedBeforeUnload();
            });
        }

        savedBeforeUnload(): Promise<boolean> {
            if (this.canExecuteSave().result) {
                return rps.app.messageManager.show({
                    message: rps.app.resources.messages.MSG_ASK_SAVE_CHANGES,
                    messageButton: rps.services.MessageButton.YesNoCancel,
                    messageType: rps.services.MessageType.Question,
                    yesOptions: {
                        text: rps.app.resources.messages.MSG_SAVE_CHANGES,
                        isPrimary: true
                    },
                    noOptions: {
                        text: rps.app.resources.messages.MSG_DISCARD_CHANGES
                    }
                }).then((result) => {
                    if (result === rps.services.MessageResult.Yes)
                        return this.executeSave();
                    else if (result === rps.services.MessageResult.No)
                        return true;
                    else
                        return false;
                });
            }
            else
                return Promise.resolve<boolean>(true);
        }

        addRule(idRule?: string): rules.IExtendedTriggers<any> {
            return <rules.IExtendedTriggers<any>>super.addRule(idRule);
        }

        public reset(): void {
            super.reset();

            //Aprovechar el reset, que se llama en el afterSave para tratar las wifnew
            this.treatWIfProperties();
        }

        private treatWIfProperties() {
            //Tras establecer la entidad, tratar las propiedades que sean WriteableIfIsNew
            if (!this.model)
                return;

            var wifNewProps: Array<string> = this.model.getWIfIsNewProps();
            for (let propName in this) {
                let prop = this[propName];
                if (rps.viewmodels.properties.isVMProperty(prop)) {
                    if (wifNewProps.indexOf(prop.getUnderlyingPropertyName()) >= 0) {
                        prop.isLocked = !this.model.__isNew;
                    }
                }
            }
        }

        hasPendingChanges(): boolean {
            if (this.model && (this.IsNew.value || this.IsModified.value || this.HasModifiedRelations.value))
                return true;
            else
                return false;
        }

        private executeNavigateToComponent(): Promise<any> {
            rps.app.entityManager.navigateToEntity(this.model.__entityUrl,
                this.model.getEntityID(),
                rps.app.stateManager.getCurrentCompany());
            return Promise.resolve(this);
        }

        private canExecuteNavigateToComponent(): rps.viewmodels.commands.CanExecuteResult {
            if (this.allowNavigateToComponent)
                return rps.viewmodels.commands.CanExecuteResult.allow();
            else
                return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.messages.MSG_NO_NAVIGABLE_ITEM]);
        }

        public onDestroy() {
            if (this.model)
                this.model.onDestroy();

            super.onDestroy();
        }

        /**
         * Método llamado tras eliminar una entidad; por defecto, navega al estado padre, pero se puede sobreescribir
           para modificar este comportamiento
         */
        protected navigateAfterDelete() {
            rps.app.stateManager.navigateToUrl(this.navigationSourceUrl, true);
            //rps.app.stateManager.goToParent();
        }

        /**
         * Aplica una lista de patch al modelo que tiene por debajo el vm. Crea una lista de promesas que se rellenan con posibles
           hijos, nietos,... que se creen mediante el patch. Al ser creados en asíncrono, el método devuelve una promesa que se resolverá
           cuando todos los viewmodels relacionados se inicialicen correctamente.
         * @param patchSet
         */
        public applyPatch(patchSet: rps.entities.IPropertyPatch[]): Promise<any> {
            this.pendingPromises = new Array<Promise<any>>();
            this.model.applyPatch(patchSet);
            return Promise.all(this.pendingPromises).then(() => {
                this.pendingPromises = null;
                return this;
            }).catch((err) => {
                this.pendingPromises = null;
                return this;
            });
        }
    }

    export class MultipleMainEntityVM<T extends rps.entities.IBaseEntity> extends MainEntityVM<T> {

        constructor() {
            super();
        }

        accept: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                return this.executeAccept();
            }
        });

        executeAccept(): Promise<any> {
            rps.app.stateManager.goToParent();
            return Promise.resolve<MultipleMainEntityVM<T>>(this);
        }

        delete: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                this.navigateAfterDelete();
                var multipleParentVM: rps.viewmodels.MultipleMaintenanceComponentVM<rps.viewmodels.MultipleMainEntityVM<rps.entities.BaseEntity>> = <any>this.parentVM;
                multipleParentVM.entitySource.remove(this);
                return Promise.resolve<any>(this);
            },
            canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
                if (this.isLocked)
                    return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.commonResources.MSG_READ_ONLY]);
                else
                    return rps.viewmodels.commands.CanExecuteResult.allow();
            },
            relatedAction: rps.services.Action.Delete
        });

        previous: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<EntityVM<T>> => {
                return this.executePrevious();
            },
            canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
                if (this.canExecutePrevious())
                    return rps.viewmodels.commands.CanExecuteResult.allow();
                else
                    return rps.viewmodels.commands.CanExecuteResult.deny(null);
            },
            relatedAction: rps.services.Action.Previous
        });

        canExecutePrevious() {
            if (this.selfLink) {
                var multipleParentVM: rps.viewmodels.MultipleMaintenanceComponentVM<rps.viewmodels.MultipleMainEntityVM<rps.entities.BaseEntity>> = <any>this.parentVM;
                var currentIndex = multipleParentVM.entitySource.getItems().indexOf(this);
                if (currentIndex >= 1)
                    return true;
            }
            return false;
        }

        executePrevious(): Promise<any> {
            if (this.selfLink) {
                var multipleParentVM: rps.viewmodels.MultipleMaintenanceComponentVM<rps.viewmodels.MultipleMainEntityVM<rps.entities.BaseEntity>> = <any>this.parentVM;
                var currentIndex = multipleParentVM.entitySource.getItems().indexOf(this);
                if (currentIndex >= 1) {
                    var previousItem: EntityVM<T> = multipleParentVM.entitySource.getItems()[currentIndex - 1];
                    previousItem.selfLink.go();
                }
            }
            return Promise.resolve<MultipleMainEntityVM<T>>(this);
        }

        next: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                return this.executeNext();
            },
            canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
                if (this.canExecuteNext())
                    return rps.viewmodels.commands.CanExecuteResult.allow();
                else
                    return rps.viewmodels.commands.CanExecuteResult.deny(null);
            },
            relatedAction: rps.services.Action.Next
        });

        canExecuteNext() {
            if (this.selfLink) {
                var multipleParentVM: rps.viewmodels.MultipleMaintenanceComponentVM<rps.viewmodels.MultipleMainEntityVM<rps.entities.BaseEntity>> = <any>this.parentVM;
                var currentIndex = multipleParentVM.entitySource.getItems().indexOf(this);
                if (multipleParentVM.entitySource.getItems().length > (currentIndex + 1))
                    return true;
            }
            return false;
        }

        executeNext(): Promise<any> {
            if (this.selfLink) {
                var multipleParentVM: rps.viewmodels.MultipleMaintenanceComponentVM<rps.viewmodels.MultipleMainEntityVM<rps.entities.BaseEntity>> = <any>this.parentVM;
                var currentIndex = multipleParentVM.entitySource.getItems().indexOf(this);
                if (multipleParentVM.entitySource.getItems().length > (currentIndex + 1)) {
                    var nextItem: EntityVM<T> = multipleParentVM.entitySource.getItems()[currentIndex + 1];
                    nextItem.selfLink.go();
                }
            }
            return Promise.resolve<MultipleMainEntityVM<T>>(this);
        }

        new: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<EntityVM<T>> => {
                return this.executeNew();
            },
            canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
                if (!this.selfLink || this.isLocked)
                    return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.commonResources.MSG_READ_ONLY]);
                else
                    return rps.viewmodels.commands.CanExecuteResult.allow();
            },
            relatedAction: rps.services.Action.New
        });

        executeNew(): Promise<any> {
            if (this.selfLink) {
                var multipleParentVM: rps.viewmodels.MultipleMaintenanceComponentVM<rps.viewmodels.MultipleMainEntityVM<rps.entities.BaseEntity>> = <any>this.parentVM;
                return multipleParentVM.executeNew();
            }

            return Promise.resolve(this);
        }

        savedBeforeUnload(): Promise<boolean> {
            return Promise.resolve<boolean>(true);
        }
    }

    export abstract class RecursiveHierarchicalMainEntityVM<T extends rps.entities.IMainEntity> extends MainEntityVM<T>{

        public resolveStateViewModel(stateParams: rps.services.IStateParams): Promise<BaseVM> {
            return super.resolveStateViewModel(stateParams).then((mainEntityVM: RecursiveHierarchicalMainEntityVM<T>) => {
                if (mainEntityVM) {
                    if (mainEntityVM.IsNew.value && stateParams.stateParams["_IDParent"])
                        mainEntityVM.setParentID(stateParams.stateParams["_IDParent"]);
                }
                return mainEntityVM;
            });
        }

        setParentID(parentID: string): void {
            var hierarchicalEntity: rps.entities.IHierarchicalEntity = <any>this.model;
            hierarchicalEntity.IDParent = parentID;
        };

        getEntityID(): string {
            return this.model.getEntityID();
        }

        getParentID(): string {
            var hierarchicalEntity: rps.entities.IHierarchicalEntity = <any>this.model;
            return hierarchicalEntity.IDParent;
        }

        executeSave(): Promise<boolean> {
            var isNew = this.IsNew.value;
            return super.executeSave().then((result) => {
                if (result && isNew) {
                    return (<RecursiveHierarchicalMaintenanceComponentVM<RecursiveHierarchicalMainEntityVM<T>>>this.parentVM)
                        .refreshNew(this).then(() => {
                            return true;
                        })
                }
                else {
                    return result;
                }
            });
        }

        executeDelete(): Promise<boolean> {
            var deletedEntityID: string = this[this.idPropertyName].value;
            var deletedEntityParentID: string = this.getParentID();
            return super.executeDelete().then((result) => {
                if (result)
                    (<RecursiveHierarchicalMaintenanceComponentVM<RecursiveHierarchicalMainEntityVM<T>>>this.parentVM).deleteItem(deletedEntityID, deletedEntityParentID);
                return result;
            });
        }

        onBeforeUnload(): Promise<boolean> {
            return super.onBeforeUnload().then((result) => {
                if (result && this.hasPendingChanges()) {
                    if (this.IsNew.value)
                        (<RecursiveHierarchicalMaintenanceComponentVM<RecursiveHierarchicalMainEntityVM<T>>>this.parentVM).cancelNew(this);
                    else
                        (<RecursiveHierarchicalMaintenanceComponentVM<RecursiveHierarchicalMainEntityVM<T>>>this.parentVM).undoDragend(this.model.getEntityID());
                }
                else if (!result)
                    (<RecursiveHierarchicalMaintenanceComponentVM<RecursiveHierarchicalMainEntityVM<T>>>this.parentVM).selectSelectedVMID();
                return result;
            });
        }

        onActivate() {
            super.onActivate();

            (<RecursiveHierarchicalMaintenanceComponentVM<any>>this.parentVM).RecursiveHierarchicalQuerySource.findAndSelectEntityVM(this);
        }
    }

    export interface IEntityVMInitParams<T extends rps.entities.IBaseEntity> {
        entity: T;
        collection: rps.viewmodels.EntityVMCollection<EntityVM<T>>;
    }

    export class EntityVM<T extends rps.entities.IBaseEntity> extends BaseVM implements rps.errors.INotifyDataErrorInfo {

        public static NEW_ID_VALUE: string = "new";

        public static idPropertyName: string = "";
        public idPropertyName: string = "";
        /** @internal */
        public fillStateParams(params: Object) {
            params[this.idPropertyName] = this.model.getEntityID();
        }
        public hasManualID: boolean = false;

        /** @internal */
        public descriptor: rps.viewmodels.properties.VMProperty<string>;
        /** @internal */
        public attachments: rps.app.viewmodels.attachedDocumentsVM;
        /** @internal */
        public __hasErrors: boolean = false;
        /** @internal */
        private collection: rps.viewmodels.EntityVMCollection<EntityVM<T>>;
        /** @internal */
        private pendingPromises: Promise<any>[];


        /** @internal */
        protected _tConstructor: rps.services.entityFactory.IEntityDefinition<T>;

        selfLink: rps.viewmodels.properties.LinkProperty;

        model: T = null;

        HasModifiedRelations: rps.viewmodels.properties.VMProperty<boolean>;
        IsNew: rps.viewmodels.properties.VMProperty<boolean>;
        IsModified: rps.viewmodels.properties.VMProperty<boolean>;

        /** @rpsInternal (for framework use only) */
        allowNavigateToComponent: boolean = false;
        /** @internal */
        navigateToComponent: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: this.executeNavigateToComponent,
            canExecute: this.canExecuteNavigateToComponent
        });

        constructor() {
            super();
        }

        /**
         * Configura el modelVM; en los parámetros hay que pasar en tConstructor el ModelDefinition del modelo (entidad) relacionado con este ViewModel
         */
        protected configure(configParams: {
            tConstructor: rps.services.entityFactory.IEntityDefinition<T>
        }): Promise<EntityVM<T>> {
            this._tConstructor = configParams.tConstructor;
            return this._tConstructor.ensureLoaded().then(() => {
                return super.configure(configParams);
            });
        }
        protected configureVMProperties(): void {
            super.configureVMProperties();

            this.HasModifiedRelations = new rps.viewmodels.properties.VMProperty<boolean>({ target: this, valuePropertyPath: "model.__hasModifiedRelations" });
            this.IsNew = new rps.viewmodels.properties.VMProperty<boolean>({ target: this, valuePropertyPath: "model.__isNew" });
            this.IsModified = new rps.viewmodels.properties.VMProperty<boolean>({ target: this, valuePropertyPath: "model.__isModified" });
        }

        public initialize(initParams: IEntityVMInitParams<T>): Promise<EntityVM<T>> {
            this.collection = initParams.collection;
            return super.initialize(initParams).then((vm) => {
                return this.setEntity(initParams.entity);
            });
        }

        loadModel(data: string | {}): Promise<EntityVM<T>> {
            return this._tConstructor.get(data).then((result) => {
                return this.setEntity(result);
            });
        }

        /** @internal */
        newModel(): Promise<EntityVM<T>> {
            return this._tConstructor.createNew().then((result) => {
                return this.setEntity(result);
            });
        }

        setEntity(entity: T): Promise<EntityVM<T>> {
            var shouldRaisePropertyChanged = (this.model != null);

            this.model = entity;

            if (this.model) {
                //Recorrer todas las propiedades para engancharse al propertyChanged del modelo
                for (var prop in this) {
                    var propertyValue = this[prop];
                    if (rps.viewmodels.properties.isVMProperty(propertyValue))
                        propertyValue.subscribeToModelPropertyChanged(this.model, shouldRaisePropertyChanged);
                }
            }

            this.treatWIfProperties();

            return Promise.resolve<EntityVM<T>>(this);
        }

        /** @internal */
        private createExtendedData(forceRefresh: boolean) {
            if (!this.attachments || forceRefresh)
                this.createAttachments();
            if (!this.descriptor || forceRefresh)
                this.createDescriptor();
        }

        /** @internal */
        createDescriptor() {
            if (!this.model.__isNew) {
                this.descriptor = new rps.viewmodels.properties.LookupProperty({
                    entityName: this.model.__entityUrl,
                    target: this,
                    initialValue: this.model.getEntityID()
                });
            }
            else {
                this.descriptor = null;
            }
        }

        /** @internal */
        createAttachments() {
            if (!this.model.__isNew) {
                if (!this.attachments || string.isNullOrEmpty(this.attachments.entityID)) {
                    this.attachments = new rps.app.viewmodels.attachedDocumentsVM(
                        {
                            entityType: this._tConstructor.url,
                            entityID: this.model.getEntityID()
                        });
                }
            }
            else {
                this.attachments = new rps.app.viewmodels.attachedDocumentsVM(
                    {
                        entityType: this._tConstructor.url
                    });
            }
        }

        accept: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                return this.executeAccept();
            }
        });

        executeAccept(): Promise<any> {
            rps.app.stateManager.goToParent();
            return Promise.resolve<EntityVM<T>>(this);
        }

        delete: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                return this.executeDelete();
            },
            canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
                if (this.isLocked)
                    return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.commonResources.MSG_READ_ONLY]);
                else
                    return rps.viewmodels.commands.CanExecuteResult.allow();
            },
            relatedAction: rps.services.Action.Delete
        });

        executeDelete(): Promise<any> {
            this.collection.remove(this);
            this.navigateAfterDelete();
            return Promise.resolve<EntityVM<T>>(this);
        }

        /** @internal */
        protected navigateAfterDelete() {
            rps.app.stateManager.goToParent();
        }

        previous: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<EntityVM<T>> => {
                return this.executePrevious();
            },
            canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
                if (this.canExecutePrevious())
                    return rps.viewmodels.commands.CanExecuteResult.allow();
                else
                    return rps.viewmodels.commands.CanExecuteResult.deny(null);
            },
            relatedAction: rps.services.Action.Previous
        });

        canExecutePrevious() {
            if (this.selfLink) {
                var currentIndex = this.collection.indexOf(this);
                if (currentIndex >= 1)
                    return true;
            }
            return false;
        }

        executePrevious(): Promise<any> {
            if (this.selfLink) {
                var currentIndex = this.collection.indexOf(this);
                if (currentIndex >= 1) {
                    var previousItem: EntityVM<T> = this.collection[currentIndex - 1];
                    previousItem.selfLink.go();
                }
            }
            return Promise.resolve<EntityVM<T>>(this);
        }

        next: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                return this.executeNext();
            },
            canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
                if (this.canExecuteNext())
                    return rps.viewmodels.commands.CanExecuteResult.allow();
                else
                    return rps.viewmodels.commands.CanExecuteResult.deny(null);
            },
            relatedAction: rps.services.Action.Next
        });

        canExecuteNext() {
            if (this.selfLink) {
                var currentIndex = this.collection.indexOf(this);
                if (this.collection.length > (currentIndex + 1))
                    return true;
            }
            return false;
        }

        executeNext(): Promise<any> {
            if (this.selfLink) {
                var currentIndex = this.collection.indexOf(this);
                if (this.collection.length > (currentIndex + 1)) {
                    var nextItem: EntityVM<T> = this.collection[currentIndex + 1];
                    nextItem.selfLink.go();
                }
            }
            return Promise.resolve<EntityVM<T>>(this);
        }

        new: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<EntityVM<T>> => {
                return this.executeNew();
            },
            canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
                if (!this.selfLink || this.isLocked)
                    return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.commonResources.MSG_READ_ONLY]);
                else
                    return rps.viewmodels.commands.CanExecuteResult.allow();
            },
            relatedAction: rps.services.Action.New
        });

        executeNew(): Promise<any> {
            if (this.selfLink) {
                //Se obtienen los parámetros del link de este VM
                var parameters = this.selfLink.getParameters();
                //Se sustituye el valor del parámetro del ID, por el literal new
                parameters[this.idPropertyName] = rps.viewmodels.EntityVM.NEW_ID_VALUE;
                //Se navega a este estado
                return rps.app.stateManager.goTo(this.selfLink.state, parameters);
            }

            return Promise.resolve(this);
        }

        clearErrors = () => {
            for (var property in this) {
                var propertyValue = this[property];
                if (rps.viewmodels.properties.isVMProperty(propertyValue))
                    propertyValue.clearErrors();
            }
            this.__hasErrors = false;
        }

        onActivate(): void {
            super.onActivate();

            this.createExtendedData(true);
        }

        public reset(): void {
            super.reset();

            //Aprovechar el reset, que se llama en el afterSave para tratar las wifnew
            this.treatWIfProperties();
        }

        /** @internal */
        private treatWIfProperties() {
            //Tras establecer la entidad, tratar las propiedades que sean WriteableIfIsNew
            if (!this.model)
                return;

            var wifNewProps: Array<string> = this.model.getWIfIsNewProps();
            for (let propName in this) {
                let prop = this[propName];
                if (rps.viewmodels.properties.isVMProperty(prop)) {
                    if (wifNewProps.indexOf(prop.getUnderlyingPropertyName()) >= 0) {
                        prop.isLocked = !this.model.__isNew;
                    }
                }
            }
        }

        /** @internal */
        private executeNavigateToComponent(): Promise<any> {
            rps.app.entityManager.navigateToEntity(this.model.__entityUrl,
                this.model.getEntityID(),
                rps.app.stateManager.getCurrentCompany());
            return Promise.resolve(this);
        }

        /** @internal */
        private canExecuteNavigateToComponent(): rps.viewmodels.commands.CanExecuteResult {
            if (this.allowNavigateToComponent)
                return rps.viewmodels.commands.CanExecuteResult.allow();
            else
                return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.messages.MSG_NO_NAVIGABLE_ITEM]);
        }

        public onDestroy() {
            if (this.model) {
                this.model.onDestroy();
                this.model = null;
            }

            super.onDestroy();
        }

        /**
         * Aplica una lista de patch al modelo que tiene por debajo el vm. Crea una lista de promesas que se rellenan con posibles
           hijos, nietos,... que se creen mediante el patch. Al ser creados en asíncrono, el método devuelve una promesa que se resolverá
           cuando todos los viewmodels relacionados se inicialicen correctamente.
         * @param patchSet
         */
        /** @internal */
        public applyPatch(patchSet: rps.entities.IPropertyPatch[]): Promise<any> {
            this.pendingPromises = new Array<Promise<any>>();
            this.model.applyPatch(patchSet);
            return Promise.all(this.pendingPromises).then(() => {
                this.pendingPromises = null;
                return this;
            }).catch((err) => {
                this.pendingPromises = null;
                return this;
            });
        }

    }

    export enum CollectionChangedType {
        Add = 1,
        Remove = 2
    }

    export interface IChildCollectionChangedArgs<T> {
        Type: CollectionChangedType;
        Element: T;
    }

    export class EntityVMCollection<T extends EntityVM<rps.entities.IBaseEntity>>
        extends rps.viewmodels.ObservableArray<T> implements IDestroyable {
        /** @internal */
        public isIDestroyable: boolean = true;

        /** @internal */
        private childCollection: rps.entities.ChildCollection<rps.entities.IBaseEntity>;
        /** @internal */
        private viewModelFunction: Function;
        /** @internal */
        private parentVM: BaseVM;
        /** @internal */
        private respondeToChildCollectionChanged: boolean;
        /** @internal */
        private rules: rules.Rule[];
        /** @internal */
        public orderDefinition: Array<{ propertyName: string; direction: number }>;

        public onDestroy() {
            if (this.childCollection) {
                this.childCollection.onDestroy();
                this.childCollection = null;
            }

            this.forEach((item: T) => {
                item.onDestroy();
            });
        }

        constructor() {
            super();
        }

        public initialize(initParams: {
            childCollection: rps.entities.ChildCollection<rps.entities.IBaseEntity>;
            viewModelFunction: Function;
            checkFilters: (viewModel: T) => boolean;
            orderDefinition: Array<{ propertyName: string; direction: number }>;
            parentVM: BaseVM;
        }): Promise<EntityVMCollection<T>> {
            this.viewModelFunction = initParams.viewModelFunction;
            this.parentVM = initParams.parentVM;
            this.orderDefinition = initParams.orderDefinition;

            if (initParams.childCollection) {
                this.childCollection = initParams.childCollection;

                //Se engancha al collectionchanged para poder mantener la lista de EntityVM si se quitan o añaden entidades 
                this.childCollection.collectionChanged.subscribe((args) => {
                    if (this.respondeToChildCollectionChanged && args.Element) {
                        if (args.Type === rps.entities.ChildCollectionChangedType.Add) {
                            let newPromise = new Promise((resolve, reject) => {
                                rps.app.viewModelFactory.createViewModel(this.viewModelFunction, { entity: args.Element, collection: this, parentVM: this.parentVM }).then((vm: T) => {
                                    //Se chequea que los nuevos elementos añadidos en la lista de entidades, cumplan los criterios modelados
                                    if (initParams.checkFilters(vm))
                                        this.push(vm);

                                    resolve(vm);

                                    //Lanzar las when del target (las que no tienen Trigger)
                                    if (this.parentVM && (<any>this.parentVM).rules && (<any>this.parentVM).rules.length > 0) {
                                        Enumerable.From<rps.viewmodels.rules.Rule>((<any>this.parentVM).rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                                            ForEach((rule) => {
                                                rule.execute();
                                            });
                                    }
                                }).catch((err) => {
                                    reject(err);
                                });
                            });
                            //Buscar algún 'contenedor de promesas' al que añadir esta
                            let parentVM = this.parentVM;
                            while (parentVM) {
                                if (parentVM["pendingPromises"]) {
                                    parentVM["pendingPromises"].push(newPromise);
                                    parentVM = null;
                                }
                                else
                                    parentVM = parentVM.parentVM;
                            }
                        }
                        else if (args.Type === rps.entities.ChildCollectionChangedType.Remove) {
                            var removingModel = this.asEnumerable().FirstOrDefault(undefined, vm => vm.model === args.Element);
                            if (removingModel) {
                                this.remove(removingModel);

                                //Lanzar las when del target (las que no tienen Trigger)
                                if (this.parentVM && (<any>this.parentVM).rules && (<any>this.parentVM).rules.length > 0) {
                                    Enumerable.From<rps.viewmodels.rules.Rule>((<any>this.parentVM).rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                                        ForEach((rule) => {
                                            rule.execute();
                                        });
                                }
                            }
                        }
                    }
                });

                var promises: Array<Promise<BaseVM>> = [];
                initParams.childCollection.forEach((model) => {
                    promises.push(rps.app.viewModelFactory.createViewModel(this.viewModelFunction, {
                        entity: model,
                        collection: this,
                        parentVM: this.parentVM
                    }).then((newVM: BaseVM) => {
                        return newVM;
                    }));
                });
                return Promise.all(promises).then((result: Array<T>) => {
                    //Una vez generada la lista de vm-s, se rellena la lista ordenada con los elementos que cumplan el filtro modelado
                    //Se filtra la lista
                    var finalResult: linq.Enumerable<T> = Enumerable.From<T>(result).Where(ri => initParams.checkFilters(ri));
                    //se ordena la lista
                    initParams.orderDefinition.forEach((orderDefinitionItem) => {
                        if (orderDefinitionItem.direction == 0)
                            finalResult = finalResult.OrderBy(item => item[orderDefinitionItem.propertyName].value);
                        else if (orderDefinitionItem.direction == 1)
                            finalResult = finalResult.OrderByDescending(item => item[orderDefinitionItem.propertyName].value);
                    });
                    finalResult.forEach((vm: T) => {
                        this.push(vm);
                    });
                    this.respondeToChildCollectionChanged = true;
                    return this;
                });
            }
            else
                return Promise.resolve<EntityVMCollection<T>>(this);
        }

        /**
         * Aplica el orden definido en los parámetros de inicio a la colección, reordenando si hace falta
         * @returns Devuelve un boolean indicando si la propiedad tenía especificado un orden a aplicar
         */
        public sort(): boolean {
            if (!this.orderDefinition || this.orderDefinition.length == 0)
                return false;

            //se ordena la lista
            super.sort((a, b): number => {
                for (let i = 0; i < this.orderDefinition.length; i++) {
                    if (a[this.orderDefinition[i].propertyName].value != b[this.orderDefinition[i].propertyName].value) {
                        if (this.orderDefinition[i].direction == 0) {
                            if (a[this.orderDefinition[i].propertyName].value > b[this.orderDefinition[i].propertyName].value)
                                return 1;
                            else
                                return -1;
                        } else {
                            if (a[this.orderDefinition[i].propertyName].value > b[this.orderDefinition[i].propertyName].value)
                                return -1;
                            else
                                return 1;
                        }
                    }
                }
                return 0;
            });

            return true;
        }

        /** @internal */
        public setRules(rules: rules.Rule[]): void {
            this.rules = rules;
        }

        /** @internal */
        private processRules(trigger: rules.Triggers, triggerParams: { Type: CollectionChangedType; Element: BaseVM }): void {
            if (this.rules) {
                this.rules.forEach((rule) => {
                    if (!rule.Trigger)
                        rule.execute(null, triggerParams);
                    else if (trigger && rule.Trigger == trigger)
                        rule.execute(null, triggerParams)
                });
            }
        }

        /** @internal */
        private processCancellableRules(trigger: rules.Triggers, triggerParams: { Type: CollectionChangedType; Element: BaseVM }): Promise<rules.RuleExecutionResult> {
            if (this.rules) {
                this.rules.forEach((rule) => {
                    rule.triggerCancelled = false;
                    rule.triggerCancelledParams.reasons.length = 0;
                });

                return Enumerable.From(this.rules).Where((rule) => rule.Trigger === trigger).ToArray().reduce((prevPromise, rule) => {
                    return prevPromise.then((result: rules.RuleExecutionResult) => {
                        if (result.value === rules.RuleExecutionValue.Continue)
                            return rule.execute(null, triggerParams);
                        else
                            return Promise.resolve<rules.RuleExecutionResult>({ value: rules.RuleExecutionValue.Cancel, reasons: result.reasons.slice(0) });
                    })
                }, Promise.resolve<rules.RuleExecutionResult>({ value: rules.RuleExecutionValue.Continue }));
            } else
                return Promise.resolve<rules.RuleExecutionResult>({ value: rules.RuleExecutionValue.Continue });
        }


        public add(): Promise<T>;
        public add(newModel?: T): Promise<T> {
            if (newModel) {
                return this.processCancellableRules(rules.Triggers.OnBeforeCollectionChanged, { Type: CollectionChangedType.Add, Element: newModel }).then((result) => {
                    if (result.value === rules.RuleExecutionValue.Continue) {
                        //Si se pasa un EntityVM, Se añade a la lista
                        this.push(newModel);
                        //Y si  tiene un modelo, se añade a la childCollection
                        if ((<EntityVM<rps.entities.IBaseEntity>>newModel).model) {
                            this.respondeToChildCollectionChanged = false;
                            this.childCollection.add((<EntityVM<rps.entities.IBaseEntity>>newModel).model);
                            this.respondeToChildCollectionChanged = true;
                        }

                        this.processRules(rules.Triggers.OnCollectionChanged, { Type: CollectionChangedType.Add, Element: newModel });

                        //Lanzar las when del target (las que no tienen Trigger)
                        if (this.parentVM && (<any>this.parentVM).rules && (<any>this.parentVM).rules.length > 0) {
                            Enumerable.From<rps.viewmodels.rules.Rule>((<any>this.parentVM).rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                                ForEach((rule) => {
                                    rule.execute();
                                });
                        }
                    }
                    return Promise.resolve(newModel);
                });
            }
            else {
                this.respondeToChildCollectionChanged = false;

                //Crea la entidad
                return this.childCollection.createNew().then((newEntity) => {
                    //Crea el viewModel
                    return rps.app.viewModelFactory.createViewModel(this.viewModelFunction, { entity: newEntity, collection: this, parentVM: this.parentVM }).then((vm: T) => {
                        //Procesa onBefore
                        return this.processCancellableRules(rules.Triggers.OnBeforeCollectionChanged, { Type: CollectionChangedType.Add, Element: vm }).then((result) => {
                            if (result.value === rules.RuleExecutionValue.Continue) {
                                this.childCollection.add(newEntity);
                                this.push(vm);
                                this.respondeToChildCollectionChanged = true;

                                this.processRules(rules.Triggers.OnCollectionChanged, { Type: CollectionChangedType.Add, Element: vm });

                                //Lanzar las when del target (las que no tienen Trigger)
                                if (this.parentVM && (<any>this.parentVM).rules && (<any>this.parentVM).rules.length > 0) {
                                    Enumerable.From<rps.viewmodels.rules.Rule>((<any>this.parentVM).rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                                        ForEach((rule) => {
                                            rule.execute();
                                        });
                                }

                                return vm;
                            }
                            return null;
                        });
                    });
                }).finally(() => {
                    this.respondeToChildCollectionChanged = true;
                });
            }
        }

        public remove(id: string): Promise<boolean>;
        public remove(model: EntityVM<rps.entities.IBaseEntity>): Promise<boolean>;
        public remove(param: any): Promise<boolean> {
            if (rps.object.isString(param)) {
                var childToDelete = this.asEnumerable().FirstOrDefault(undefined, child => child[child.idPropertyName].value === param);
                if (childToDelete) {
                    if (this.childCollection.getTrackChanges()) {
                        return this.processCancellableRules(rules.Triggers.OnBeforeCollectionChanged, { Type: CollectionChangedType.Remove, Element: childToDelete }).then((result) => {
                            if (result.value === rules.RuleExecutionValue.Continue) {
                                this.remove(childToDelete);

                                this.processRules(rules.Triggers.OnCollectionChanged, { Type: CollectionChangedType.Remove, Element: childToDelete });
                            }

                            return Promise.resolve(true);
                        });
                    }
                    else {
                        //Si no tiene control de cambios, no lanza las reglas que puedan estar asociadas
                        this.remove(childToDelete);
                        return Promise.resolve(true);
                    }
                }
            }
            else if (param && param instanceof EntityVM) {
                if (this.childCollection.getTrackChanges()) {
                    return this.processCancellableRules(rules.Triggers.OnBeforeCollectionChanged, { Type: CollectionChangedType.Remove, Element: param }).then((result) => {
                        if (result.value === rules.RuleExecutionValue.Continue) {
                            //Si se pasa un EntityVM, Se quita de la lista                
                            this.splice(this.indexOf(param), 1);

                            //Y si  tiene un modelo, se añade quita childCollection
                            if ((<EntityVM<rps.entities.IBaseEntity>>param).model) {
                                this.respondeToChildCollectionChanged = false;
                                this.childCollection.remove((<EntityVM<rps.entities.IBaseEntity>>param).model);
                                this.respondeToChildCollectionChanged = true;
                            }

                            this.processRules(rules.Triggers.OnCollectionChanged, { Type: CollectionChangedType.Remove, Element: param });

                            return Promise.resolve(true);
                        }
                    });
                }
                else {
                    //Si no tiene control de cambios, no lanza las reglas que puedan estar asociadas

                    //Si se pasa un EntityVM, Se quita de la lista                
                    this.splice(this.indexOf(param), 1);

                    //Y si  tiene un modelo, se añade quita childCollection
                    if ((<EntityVM<rps.entities.IBaseEntity>>param).model) {
                        this.respondeToChildCollectionChanged = false;
                        this.childCollection.remove((<EntityVM<rps.entities.IBaseEntity>>param).model);
                        this.respondeToChildCollectionChanged = true;
                    }

                    return Promise.resolve(true);
                }
            }

            return Promise.resolve(false);
        }

        //TODO: Igor, pendiente de revisar la funcionalidad de este método
        public setSelectedElement(element: T): Promise<T | rps.errors.ErrorDetail>
        public setSelectedElement(stateParams: IParams): Promise<T | rps.errors.ErrorDetail>
        public setSelectedElement(modelId: string): Promise<T | rps.errors.ErrorDetail>
        public setSelectedElement(params: any): Promise<T | rps.errors.ErrorDetail> {
            if (params === null) {
                return Promise.resolve(null);
            }
            else if (params instanceof EntityVM && this.indexOf(params) > -1) {
                return Promise.resolve(<T>params);
            }
            else if (rps.object.isString(params)) {
                return Promise.resolve(<T>this.asEnumerable().FirstOrDefault(null, (element) => element.model && element.model.getEntityID() === params));
            }
            else if (this.viewModelFunction && (<any>this.viewModelFunction).idPropertyName && params[(<any>this.viewModelFunction).idPropertyName]) {
                var navId: string = params[(<any>this.viewModelFunction).idPropertyName];
                //Si el id de navegación es new, crear un nuevo elemento y cambiar la ruta al nuevo id; se añade un replace para cambiar también el historial,
                //de forma que si pulsa al botón de atrás o adelante, no vuelva al estado de New
                if (navId.toLowerCase() === rps.viewmodels.EntityVM.NEW_ID_VALUE) {
                    return this.add().then((newModel) => {
                        //rps.app.stateManager.replaceID(newModel.model.getEntityID());
                        return newModel;
                    });
                }
                else {
                    var result = <T>this.asEnumerable().FirstOrDefault(null, (element) => element.model && element.model.getEntityID() === navId);
                    if (result)
                        return Promise.resolve(result);
                    else
                        return Promise.resolve(new rps.errors.ErrorDetail("NOT_FOUND", "Not found"));
                }
            }
            else {
                return Promise.resolve(new rps.errors.ErrorDetail("NOT_FOUND", "Not found"));
            }
        }

        /**
         * Si el nombre del estado al que se navega es parte del relatedState, se establece el elemento seleccionado a aquel que indiquen los parámetros de la URL
         * También se establece a visible la colección, y además, se llama recursivamente a la navegación en el elemento seleccionado
         */
        /** @internal */
        public allowResolveStateViewModel(stateParams: rps.services.IStateParams): boolean {
            if ((<any>this.viewModelFunction).relatedState && stateParams.stateName == (<any>this.viewModelFunction).relatedState)
                return true;
            else
                return false;
        }

        /** @internal */
        public resolveStateViewModel(stateParams: rps.services.IStateParams): Promise<BaseVM | rps.errors.ErrorDetail> {
            if (this.allowResolveStateViewModel(stateParams)) {
                return this.setSelectedElement(stateParams.stateParams).then((selElement) => {
                    if (selElement instanceof rps.errors.ErrorDetail)
                        return selElement;
                    else if (selElement)
                        return selElement.resolveStateViewModel(stateParams);
                });
            }
            else {
                return this.setSelectedElement(null);
            }
        }

        public getErrors(): rps.errors.ErrorDetail[] {
            var errors = new Array<rps.errors.ErrorDetail>();
            this.forEach((child: EntityVM<rps.entities.IBaseEntity>) => {
                errors = errors.concat(child.getErrors());
            });
            return errors;
        }
    }

    export class EntityComponentVM<T extends rps.entities.IBaseEntity> extends ComponentVM implements rps.errors.INotifyDataErrorInfo {
        /** @internal */
        public __hasErrors: boolean = false;

        /** @internal */
        protected _tConstructor: rps.services.entityFactory.IEntityDefinition<T>;

        model: T = null;

        static relatedState: string = "";
        relatedState: string = "";

        IsNew: rps.viewmodels.properties.VMProperty<boolean>;
        IsModified: rps.viewmodels.properties.VMProperty<boolean>;

        constructor() {
            super();
        }

        /**
         * Configura el modelVM; en los parámetros hay que pasar en tConstructor el ModelDefinition del modelo (entidad) relacionado con este ViewModel
         */
        protected configure(configParams: {
            tConstructor: rps.services.entityFactory.IEntityDefinition<T>
        }): Promise<BaseVM> {
            this._tConstructor = configParams.tConstructor;
            return this._tConstructor.ensureLoaded().then(() => {
                return super.configure(configParams).then(() => {
                    this.IsNew = new rps.viewmodels.properties.VMProperty<boolean>({ target: this, valuePropertyPath: "model.__isNew" });
                    this.IsModified = new rps.viewmodels.properties.VMProperty<boolean>({ target: this, valuePropertyPath: "model.__isModified" });
                    return this;
                });
            });
        }

        public initialize(initParams?: rps.IParams): Promise<EntityComponentVM<T>> {
            return super.initialize(initParams).then((vm: EntityComponentVM<T>) => {
                return vm.loadModel();
            });
        }

        /** @internal */
        loadModel(): Promise<EntityComponentVM<T>> {
            return this._tConstructor.getCompanySingleEntity().then((result) => {
                return this.setEntity(result);
            });
        }

        setEntity(entity: T): Promise<EntityComponentVM<T>> {
            var shouldRaisePropertyChanged = (this.model != null);

            this.model = entity;

            if (this.model) {
                //Recorrer todas las propiedades para engancharse al propertyChanged del modelo
                for (var prop in this) {
                    var propertyValue = this[prop];
                    if (rps.viewmodels.properties.isVMProperty(propertyValue))
                        propertyValue.subscribeToModelPropertyChanged(this.model, shouldRaisePropertyChanged);
                }
            }

            return Promise.resolve<EntityComponentVM<T>>(this);
        }

        /** @internal */
        save: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                return this.executeSave();
            },
            canExecute: this.canExecuteSave,
            relatedAction: rps.services.Action.Save
        });

        executeSave(): Promise<boolean> {
            rps.app.errorManager.clear();

            return this.savePendingChanges()
                .then(() => {
                    return true;
                })
                .catch((errors) => {
                    rps.app.errorManager.add(errors);
                    return false;
                });
        }

        /** @internal */
        savePendingChanges(): Promise<any> {
            this.model.clearAllErrors();
            this.clearErrors();

            //Valida la entidad con lo básico antes de lanzar el grabado
            this.validate();
            var errors = this.getErrors();
            if (errors.length > 0)
                return Promise.reject(errors);

            return this.processCancellableRules(rules.Triggers.OnBeforeSave).then((result) => {
                if (result.value === rules.RuleExecutionValue.Continue) {
                    return this.doSaveModel().then((result) => {
                        this.reset();
                        this.sort();
                        rps.app.notificationManager.show({
                            message: rps.app.resources.directives.ENTITY_SAVED,
                            notificationType: rps.services.NotificationType.Success
                        });
                        this.processRules(rules.Triggers.OnAfterSave);
                        return result;
                    }).catch((saveErrors) => {
                        var errors: Array<any> = new Array<any>();
                        if (saveErrors) {
                            if (Array.isArray(saveErrors))
                                saveErrors.forEach((errError) => {
                                    errors.push(errError);
                                });
                            else
                                errors.push(saveErrors);
                        }
                        if (this.getErrors().length) {
                            this.getErrors().forEach((getErrorsError) => {
                                if (errors.indexOf(getErrorsError) < 0) //Para que no los duplique
                                    errors.push(getErrorsError);
                            });
                        }

                        return Promise.reject(errors);
                    });
                }
                else if (result.value === rules.RuleExecutionValue.Cancel)
                    return Promise.reject(result.reasons.slice(0));

                return Promise.resolve(null);
            });
        }

        /**
        * Método que ejecuta la acción de grabado del modelo interno del VM.
        *  Es el lugar correcto para poder modificar la acción a realizar para grabar una entidad, o para hacer algo justo antes o después del grabado de la entidad
        *  Se ejecuta después de haber realizado preguntas y haber ejecutado las reglas de onBeforeSave
         */
        doSaveModel(): Promise<any> {
            return this.model.update();
        }

        canExecuteSave(): commands.CanExecuteResult {
            if (!this.hasPendingChanges())
                return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.messages.MSG_NO_PENDING_CHANGES]);
            else if (this.isLocked)
                return commands.CanExecuteResult.deny([rps.app.resources.commonResources.MSG_READ_ONLY]);
            else
                return commands.CanExecuteResult.allow();
        }

        /** @internal */
        resolveStateViewModel(stateParams: rps.services.IStateParams): Promise<BaseVM> {
            var promise: Promise<BaseVM> = null;
            for (var property in this) {
                if ((<string>property).charAt(0) !== "_") {
                    var propertyValue = this[property];
                    if (properties.isEntityVMCollectionProperty(propertyValue)) {
                        var entityVMCollection: EntityVMCollection<any> = propertyValue.value;
                        if (entityVMCollection.allowResolveStateViewModel(stateParams))
                            promise = propertyValue.value.resolveStateViewModel(stateParams) || promise;
                    }
                }
            }
            if (stateParams.stateName === this.relatedState)
                return Promise.resolve(this);
            else
                return promise || Promise.resolve(null);
        }

        onBeforeUnload(): Promise<boolean> {
            return super.onBeforeUnload().then((result) => {
                if (result && this.canExecuteSave().result) {
                    return rps.app.messageManager.show({
                        message: rps.app.resources.messages.MSG_ASK_SAVE_CHANGES,
                        messageButton: rps.services.MessageButton.YesNoCancel,
                        messageType: rps.services.MessageType.Question,
                        yesOptions: {
                            text: rps.app.resources.messages.MSG_SAVE_CHANGES,
                            isPrimary: true
                        },
                        noOptions: {
                            text: rps.app.resources.messages.MSG_DISCARD_CHANGES
                        }
                    }).then((result) => {
                        if (result === rps.services.MessageResult.Yes) {
                            return this.executeSave();
                        }
                        else if (result === rps.services.MessageResult.No)
                            return true;
                        else
                            return false;
                    });
                }
                else
                    return result;
            });
        }

        hasPendingChanges(): boolean {
            if (this.IsNew.value || this.IsModified.value)
                return true;
            else
                return false;
        }

        addRule(idRule?: string): rules.IExtendedTriggers<any> {
            return <rules.IExtendedTriggers<any>>super.addRule(idRule);
        }

        clearErrors = () => {
            for (var property in this) {
                var propertyValue = this[property];
                if (rps.viewmodels.properties.isVMProperty(propertyValue))
                    propertyValue.clearErrors();
            }
        }
    }
}

module rps.viewmodels.MainEntityVM {
    export enum UpdateMethods {
        Overwrite,
        Patch
    }
}

module rps.viewmodels.properties {
    export class EntityVMCollectionProperty<VMT extends EntityVM<rps.entities.IBaseEntity>> extends BaseVMCollectionProperty<VMT, EntityVMCollection<VMT>>
        implements rps.viewmodels.rules.ICollectionChangedRuleTrigger<VMT> {

        /** @internal */
        public ICollectionChangedRuleTrigger: boolean = true;
        /** @internal */
        public foo(): VMT{
            return null;
        }

        /** @internal */
        private orderDefinition: Array<{ propertyName: string; direction: number }>;

        //El nombre de la propiedad de cada hijo, donde contiene las condiciones que indican si el elemento cumple las condiciones modeladas
        /** @internal */
        private filterPropertyPath: string;

        constructor(params: {
            target: any;
            orderDefinition: Array<{ propertyName: string; direction: number }>;
            filterPropertyPath?: string;
            allowAdd?: boolean;
            allowDelete?: boolean;
        }) {
            super(params);
            this.orderDefinition = params.orderDefinition;
            this.filterPropertyPath = params.filterPropertyPath;
        }

        //Evalúa si el VM cumple con los filtros modelados desde el diseñador
        /** @internal */
        checkFilters = (viewModel: VMT): boolean => {
            if (string.isNullOrEmpty(this.filterPropertyPath))
                return true;
            else
                return (<rps.viewmodels.rules.ConditionGroup>viewModel[this.filterPropertyPath]).check();
        }

        /** @internal */
        createEmptyNew(): EntityVMCollection<VMT> {
            return new EntityVMCollection<VMT>();
        };

        /** @internal */
        createNew(initParams: {
            valuePropertyPath?: string;
            viewModelFunction: Function;
        }): Promise<EntityVMCollection<VMT>> {
            var items;
            if (!string.isNullOrEmpty(initParams.valuePropertyPath)) {
                var pathParts = initParams.valuePropertyPath.split('.');
                if (pathParts.length == 1)
                    items = this._target[pathParts[0]];
                else if (pathParts.length == 2 && this._target[pathParts[0]])
                    items = this._target[pathParts[0]][pathParts[1]];
                else if (pathParts.length == 3 && this._target[pathParts[0]] && this._target[pathParts[0]][pathParts[1]])
                    items = this._target[pathParts[0]][pathParts[1]][pathParts[2]];
            }
            if (items instanceof rps.viewmodels.EntityVMCollection)
                return Promise.resolve(items);
            else {
                return rps.app.viewModelFactory.createEntityViewModelCollection<VMT>({
                    viewModelCollectionFunction: rps.viewmodels.EntityVMCollection,
                    initParams: {
                        childCollection: items,
                        viewModelFunction: initParams.viewModelFunction,
                        checkFilters: this.checkFilters,
                        orderDefinition: this.orderDefinition,
                        parentVM: this.parentVM
                    }
                });
            }
        }

        /** @internal */
        onSetValue() {
            //Se "pasan" las reglas del property al viewModelCollection, porque es el que controla bien los collectionchanged
            if (this._target instanceof BaseVM && rps.object.hasValue(this.value)) {
                var rules = Enumerable.From((<Array<rules.Rule>>(<any>this._target).rules)).Where((rule) => rule.TriggerField === this).ToArray();
                if (rules && rules.length > 0)
                    this.value.setRules(rules);
            }
        }

        add(): Promise<VMT> {
            if (this.allowAdd) {
                return this.value.add().then((newState) => {
                    var newVM = this.value.setSelectedElement(newState);
                    return newVM;
                });
            }
            else
                return Promise.resolve<any>(null);
        }

        cleanValue() {
            if (this.hasValue()) {
                for (var i = this.value.length - 1; i >= 0; i--) {
                    this.value.remove(this.value[i]);
                }
            }
        }

        public sort(): void {
            //Si hay reordenamiento, se emite un evento para que la grid se entere y se refresque
            if (this.value.sort())
                this.propertyChanged.emit({ newValue: null, propertyName: "sort" });

            this.value.forEach((item: BaseVM) => item.sort());
        }

        /**
        Indica si la propiedad tiene como target un modelo o no
        */
        public get isModelProperty(): boolean {
            return true;
        }

    }

    /** @internal */
    export function isEntityVMCollectionProperty(value: any): value is EntityVMCollectionProperty<any> {
        return value && value instanceof properties.EntityVMCollectionProperty;
    }
}