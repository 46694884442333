/// <reference path="../entities/entityModel.ts" />

module rps.data {

    export enum SemanticState {
        Neutral = 0,
        Positive = 1,
        Info = 2,
        Warning = 3,
        Negative = 4
    }

    export class EntityState {
        public StateName: string;
        public StateInfo: SemanticState;
    }

    export class EntityDate {
        public DateName: string;
        public Date: Date;
        public StateInfo: SemanticState;
    }

    export class EntityAmount {
        public Amount: number;
        public Unit: string;
        public StateInfo: SemanticState;
    }

    export class Descriptor {
        public EntityType: string;
        public EntityID: string;
        public MainDescriptor: string;
        public SecondaryDescriptor: string;
        public Image: string;
        public UILink: IUILinkDefinition;
    }

    export class FullDescriptor extends Descriptor {
        public EntityState: EntityState;
        public EntityDate: EntityDate;
        public EntityAmount: EntityAmount;
        public FirstAttribute: string;
        public SecondAttribute: string;
    }

    export class CurrencySymbol {
        Symbol: string;
        DecimalPlaces: number;
    }

    export class DurationFactor {
        Factor: number;
        Unit: string;
        DecimalPlaces: number;
    }

    export class FormattedNumber {
        Value: number;
        DecimalPlaces: number;
        Suffix: string;
    }

    export interface Icon {
        IconType: string;
        IconLabel: string;
        SemanticState?: SemanticState;
    }

    export class QuantityUnitAbbreviation {
        Abbreviation: string;
        DecimalPlaces: number;
    }

    export class PriceUnitAbbreviation {
        Abbreviation: string;
    }

    export class AddressValue {
        City: string;
        CountryName: string;
        CountryFlag: string;
        Address: string;
        StateName: string;
        ZipCode: string;
        CountyName: string;
    }

    export enum ProcessDetailState {
        Ok = 0,
        Error = 1
    }

    export interface ProcessResultDetail {
        State: ProcessDetailState;
        RelatedEntity?: Descriptor;
        Message?: string;
    }

    export interface ProcessResult {
        ProcessDescription: string;
    }

    export interface SyncProcessResult extends ProcessResult {
        Details: Array<ProcessResultDetail>;
    }

    /** @internal */
    export function isSyncProcessResult(value: any): value is SyncProcessResult {
        return value && rps.object.isDefined(value.Details);
    }

    export interface AsyncProcessResult extends ProcessResult {
        IDProcessJob: string;
        Progress: string;
    }

    export class PaginatedViewResult {
        Data: Array<any>;
        Count: number;
    }

    export interface DocumentsResult {
        Data: Array<{ descriptor: Descriptor }>;
        Count: number;
    }

    export class CalendarEventResult {
        EventStart: Date;
        EventEnd: Date;
        AllDay: boolean;
        EventName: string;
        CustomColor: number;
        CalculatedForeColor: string;
    }

    export class NavigableState {
        Image: string;
        Description: string;
        TargetState: IUILinkDefinition;
    }

    export class IUILinkDefinition extends rps.entities.InheritableCollection<rps.services.IUIStateDefinition>{

        constructor(...initialItems: rps.services.IUIStateDefinition[]) {
            var s = <any>super();
            rps.entities.InheritableCollection.init(s, initialItems, IUILinkDefinition.prototype);
            return s;
        }

        public static create(stateName: string): IUILinkDefinition {
            var link = new IUILinkDefinition({ State: stateName, Parameters: {}, Arguments: {} });
            return link;
        }
    }

    export interface IFilterClause {
        $type: string;
    }

    export enum ConditionalOperatorTypes {
        And = 0,
        Or = 1
    }

    export class FilterExpression implements IFilterClause {
        $type: string = "BasoaWebAPI.Models.FilterExpression, BasoaWebAPI";
        Clauses: Array<IFilterClause>;
        OperatorType: ConditionalOperatorTypes;
    }

    export class FilterClause implements IFilterClause {
        $type: string = "BasoaWebAPI.Models.FilterClause, BasoaWebAPI";
        FilterSpec: string;
        Operator: string;
        Value: string;
    }

    export class FilterDefinition {
        Name: string;
        FilterExpression: FilterExpression;
    }

    export interface QueryHeader {
        Name: string;
        Label: string;
        Documentation: string;
        Scope: string;
        ReferenceUrl: string;
    }

    export interface QueryReference {
        Name: string;
        Label: string;
        Scope: string;
        EditState: rps.data.IUILinkDefinition;
        Documentation: string;
        Service: string;
        EntityName: string;
        Url: string;
        IsSearchable: boolean;
        IsPaginated: boolean;
        IsChronological: boolean;
        IsLookupSource: boolean;
        IsFilterable: boolean;
        IsHierarchical: boolean;
        IsEntityGet: boolean;
        IsEntitySource: boolean;
        SupportedExportFormats: Array<string>;
        Parameters: Array<APIParameter>;
        ResultFields: Array<QueryResultField>;
        OutParameters: Array<APIParameter>;
    }

    export interface APIParameter {
        Name: string;
        Documentation: string;
        Type: string;
        IsRequired: boolean;
    }

    export interface QueryResultField {
        Name: string;
        Type: string;
        Label: string;
        RelatedEntity: string;
        SizeHint: string;
    }

    export interface ChartData {
        Title: string;
        ValueOptions: ValueOptions;
    }

    export interface ValueOptions {
        DecimalPlaces: number;
        Description: string;
        Suffix: string;
    }

    export interface SeriesChartData extends ChartData {
        Categories: Array<string>;
        Series: Array<ChartSerie>;
    }

    export interface ChartSerie {
        Name: string;
        SeriesType: string;
        Data: Array<SerieValue>;
    }

    export interface SerieValue {
        Value: number;
    }

    export interface PieChartData extends ChartData {
        Values: Array<PieValue>;
    }

    export interface PieValue {
        Description: string;
        Value: number;
    }

    export interface ValidationError {
        EntityPath: string;
        ErrorCode: string;
        ErrorDescription: string;
        Property: string;
    }

    export function isValidationError(val): val is ValidationError {
        return val && rps.object.isDefined(val.ErrorCode) && rps.object.isDefined(val.ErrorDescription)
    }
}