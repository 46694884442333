module rps.data.wizards {

    export interface IWizard {
        Steps: any;
    }

    export interface IWizardStep {
        TransitionTo: any;
    }

    export interface IWizardStepTransition {
        setConditions(): rps.viewmodels.rules.IMainConditionBuilder<any>;
        extendConditions(): rps.viewmodels.rules.IMainConditionBuilder<any>;

        setActions(): rps.viewmodels.rules.IActionGroup<any>;
        extendActions(): rps.viewmodels.rules.IActionGroup<any>;
    }

    export class Wizard implements IWizard {
        private target: any;
        private activeWizardStep: WizardStep;
        private backQueue: Array<WizardStep> = new Array<WizardStep>();

        public steps: Array<WizardStep>;        
        public cancel: rps.viewmodels.commands.CommandProperty;
        public next: rps.viewmodels.commands.CommandProperty;
        public ok: rps.viewmodels.commands.CommandProperty;
        public back: rps.viewmodels.commands.CommandProperty;
        public Steps: any;

        constructor(params: {
            target: any,
            steps: Array<WizardStep>
        }) {
            this.target = params.target;
            this.steps = params.steps;

            //Crear el objeto para poder acceder por nombre
            this.Steps = {};
            this.steps.forEach((step) => {
                this.Steps[step.name] = step;
            });

            this.cancel = new rps.viewmodels.commands.CommandProperty({
                target: this,
                canExecute: this.canExecuteCancel,
                command: this.executeCancel
            });

            this.next = new rps.viewmodels.commands.CommandProperty({
                target: this,
                canExecute: this.canExecuteNext,
                command: this.executeNext
            });

            this.ok = new rps.viewmodels.commands.CommandProperty({
                target: this,
                canExecute: this.canExecuteOk,
                command: this.executeOk
            });

            this.back = new rps.viewmodels.commands.CommandProperty({
                target: this,
                canExecute: this.canExecuteBack,
                command: this.executeBack
            });

            this.setActiveStep(this.steps[0]);
        }

        public getStep(stepName: string) {
            return Enumerable.From<WizardStep>(this.steps).First(s => s.name == stepName);
        }

        private setActiveStep(newActiveStep: WizardStep) {
            if (newActiveStep != this.activeWizardStep) {
                var newBackQueue: Array<WizardStep> = new Array<WizardStep>();
                var back: boolean = false;
                this.backQueue.forEach((wizardStep) => {
                    if (back == false && wizardStep != newActiveStep)
                        newBackQueue.push(wizardStep);
                    else
                        back = true;
                });

                if (this.activeWizardStep) {
                    this.activeWizardStep.isActive = false;
                    if (!back)
                        newBackQueue.push(this.activeWizardStep);
                }
                this.backQueue = newBackQueue;
                this.activeWizardStep = newActiveStep;
                newActiveStep.isActive = true;
            }
        }

        private canExecuteCancel = (): rps.viewmodels.commands.CanExecuteResult => {            
            if (this.target["__CancelCommand" + rps.app.stateManager.stateStack.current.configOptions.stateName.toLowerCase()])
                return (<rps.viewmodels.commands.CommandProperty>this.target["__CancelCommand" + rps.app.stateManager.stateStack.current.configOptions.stateName.toLowerCase()]).canExecute();
            else
                return rps.viewmodels.commands.CanExecuteResult.allow();
        }

        private executeCancel = (): Promise<any> => {
            this.setActiveStep(this.steps[0]);            
            if (this.target["__CancelCommand" + rps.app.stateManager.stateStack.current.configOptions.stateName.toLowerCase()])
                return this.target["__CancelCommand" + rps.app.stateManager.stateStack.current.configOptions.stateName.toLowerCase()].execute();
            else {
                rps.app.stateManager.goBack();
                return Promise.resolve(this);
                //return rps.app.stateManager.goToParent();
            }
        }
                
        private canExecuteNext = (): rps.viewmodels.commands.CanExecuteResult => {
            var nextWizardStepTransition: WizardStepTransition = this.activeWizardStep.getNextWizardStepTransition();
            if (nextWizardStepTransition && !string.isNullOrEmpty(nextWizardStepTransition.stepName))
                return rps.viewmodels.commands.CanExecuteResult.allow();
            else
                return rps.viewmodels.commands.CanExecuteResult.deny(null);
        }

        public allowBack = ():boolean => {
            if (this.backQueue.length > 0)
                return true
            else
                return false;
        }

        public allowNext = (): boolean => {
            if (this.activeWizardStep && Enumerable.From<WizardStepTransition>(this.activeWizardStep.transitions).Any(t => !string.isNullOrEmpty(t.stepName))) {
                var nextWizardStepTransition: WizardStepTransition = this.activeWizardStep.getNextWizardStepTransition();
                if (nextWizardStepTransition && string.isNullOrEmpty(nextWizardStepTransition.stepName))
                    return false;
                else
                    return true;
            }
            else
                return false;
        }

        private executeNext = (): Promise<any> => {
            var nextWizardStepTransition: WizardStepTransition = this.activeWizardStep.getNextWizardStepTransition();
            return nextWizardStepTransition.execute().then(() => {
                var newActiveWizardStep: WizardStep = Enumerable.From<WizardStep>(this.steps).First(s => s.name == nextWizardStepTransition.stepName);
                this.setActiveStep(newActiveWizardStep);                
            });
        }

        private canExecuteOk = (): rps.viewmodels.commands.CanExecuteResult => {
            var nextWizardStepTransition: WizardStepTransition = this.activeWizardStep.getNextWizardStepTransition();
            if (nextWizardStepTransition && string.isNullOrEmpty(nextWizardStepTransition.stepName))
                return rps.viewmodels.commands.CanExecuteResult.allow();
            else
                return rps.viewmodels.commands.CanExecuteResult.deny(null);
        }

        public allowOk = (): boolean => {
            return !this.allowNext();
        }

        private executeOk = (): Promise<any> => {            
            return this.activeWizardStep.getNextWizardStepTransition().execute().then(() => {
                this.setActiveStep(this.steps[0]);
                if (this.target["__OKCommand" + rps.app.stateManager.stateStack.current.configOptions.stateName.toLowerCase()])
                    return this.target["__OKCommand" + rps.app.stateManager.stateStack.current.configOptions.stateName.toLowerCase()].execute();
                else if (this.target.relatedState == rps.app.stateManager.stateStack.current.configOptions.stateAbsoluteName) {
                    rps.app.stateManager.goBack();
                    return Promise.resolve(this);
                    //return rps.app.stateManager.goToParent();
                }
            });
        }

        private canExecuteBack = (): rps.viewmodels.commands.CanExecuteResult => {
            if (this.allowBack())
                return rps.viewmodels.commands.CanExecuteResult.allow();
            else
                return rps.viewmodels.commands.CanExecuteResult.deny(null);
        }

        private executeBack = (): Promise<any> => {
            this.setActiveStep(this.backQueue[this.backQueue.length - 1]);
            return Promise.resolve<any>(this);
        }
    }

    export class WizardStep implements IWizardStep{
        public propertyChanged = rps.app.eventManager.createEmitter<rps.viewmodels.properties.VMPropertyChange>();

        public _isActive: boolean = false;
        public get isActive(): boolean {
            return this._isActive;
        }
        public set isActive(newValue: boolean) {
            if (this._isActive != newValue) {
                this._isActive = newValue;
                this.propertyChanged.emit({ newValue:newValue, propertyName:"isActive" });
            }
        }

        public name: string;
        transitions: Array<WizardStepTransition>
        public TransitionTo: any;

        constructor(params: {
            name: string,            
            transitions: Array<WizardStepTransition>
        }) {
            this.name = params.name;
            this.transitions = params.transitions;

            //Crear el objeto para poder acceder por nombre
            this.TransitionTo = {};
            this.transitions.forEach((transition) => {                
                this.TransitionTo[transition.name] = transition;
            });
        }

        getNextWizardStepTransition(): WizardStepTransition {
            for (var i = 0; i < this.transitions.length; i++) {
                if (this.transitions[i].check())
                    return this.transitions[i];
            }
            return null;
        }
    }

    export class WizardStepTransition implements IWizardStepTransition {
        public name: string;
        public stepName: string;
        public isFinal: boolean = false;
        public conditions: rps.viewmodels.rules.IMainConditionBuilder<any>;
        public actions: rps.viewmodels.rules.IActionGroup<any>;

        constructor(params: {
            name
            stepName?: string,
            isFinal?: boolean,
            conditions: rps.viewmodels.rules.ConditionGroup,
            actions: rps.viewmodels.rules.IMainActionGroup<any>
        }) {
            this.name = params.name;
            this.stepName = params.stepName;
            if (params.isFinal)
                this.isFinal = true;
            this.conditions = params.conditions;
            this.actions = params.actions;
        }

        public check(): boolean {
            if (this.conditions)
                return (<rps.viewmodels.rules.ConditionGroup>this.conditions).check();
            else
                return true;
        }

        public execute(): Promise<any> {
            if (this.actions)
                return (<rps.viewmodels.rules.ActionGroup<any>>this.actions).execute();
            else
                return Promise.resolve<any>(this);
        }

        public setConditions(): rps.viewmodels.rules.IMainConditionBuilder<any> {
            this.conditions = new rps.viewmodels.rules.ConditionGroup(null);
            return this.conditions;
        }

        public extendConditions(): rps.viewmodels.rules.IMainConditionBuilder<any> {
            if (!this.conditions)
                this.conditions = new rps.viewmodels.rules.ConditionGroup(null);

            return this.conditions;
        }

        public setActions(): rps.viewmodels.rules.IActionGroup<any>{
            this.actions = new rps.viewmodels.rules.ActionGroup(null);
            return this.actions;
        }

        public extendActions(): rps.viewmodels.rules.IActionGroup<any>{
            if (!this.actions)
                this.actions = new rps.viewmodels.rules.ActionGroup(null);

            return this.actions;
        }
    }
} 