/// <reference path="../entities/propertyPatch.ts" />

module rps.services {

    export interface ISubscription {
        unsubscribe();
    }
    export interface IEventEmitter<T> extends IDestroyable {
        emit(value?: T): void;
        subscribe(generatorOrNext?: (value:T) => void, error?: any, complete?: any): ISubscription;
        unsubscribe(): void;
    }

    export interface IEventManager {
        createEmitter<T>(isAsync?: boolean): IEventEmitter<T>;
        configure(): void;

        //Eventos globales
        rpsSessionDataChanged: IEventEmitter<any>;
        rpsMenuChanged: IEventEmitter<{ menu: any[], favourites: any[] }>;
    }

    export class SessionData {
        User: string;
        CodCompany: string;
        Role: string;
        IDSite: string;
    }

    export class TimeConversionFactors {
        MinutesHour: number;
        HoursDay: number;
        DaysWeek: number;
        DaysMonth: number;
    }

    export class CompanySessionData {
        CompanyCurrencyID: string;
        IDInvoicingPoint: string;
        IDEmployee: string;
        AmountDecimalDigits: number;
        PriceDecimalDigits: number;
        TypeCompany: number;
        TimeConversionFactors: TimeConversionFactors
    }

    export interface ISession {
        logged: boolean;
        user: string;
        role: string;
        language: string;
        company: string;
        idSite: string;
        codAccType: string;
        isAdmin: boolean;

        /** @rpsIternal **/
        configure(): void;
        logOn(user: string, password: string): any;
        windowsLogOn(): any;
        logOff(): Promise<boolean>;
        /** @internal **/
        setSessionData(sessionData: any, restoreLastSession: boolean);

        /** @internal **/
        checkSessionState(restoreLastSession: boolean): void;
        getSessionData(): SessionData;
        getCompanySessionData(codCompany: string): CompanySessionData;
    }

    /**
    * Servicio para guardar elementos en el local storage del servidor
    */
    export interface IStorage {
        "get"(key: string): any;
        remove(key: string): any;
        add(key: string, value: any);        
        //isSupported: browserSupportsStorage,
        //set: addToStorage,
        //get: getFromStorage,
        //keys: getKeysForStorage,
        //remove: removeFromStorage,
        //clearAll: clearAllFromStorage,
        //onStorageChanged: onStorageChanged
    }

    /**
    * Las excepciones de C#
    */
    export interface IException {
        Code: string;
        Description: string;
    }

    export function isIException(val): val is IException {
        return val && val.Code && val.Description;
    }

    export interface IServiceExceptionError {
        Message: string;
        Details: Array<IServiceExceptionErrorDetail>;
    }

    export interface IServiceExceptionErrorDetail {
        Code: string;
        Message: string;
    }

    export interface IErrorManager {
        /** @rpsInternal **/
        configure(): void;
        clear();
        /** @internal **/
        clearDialogErrorDetails();
        /** @internal **/
        remove(key: string): any;
        add(errDetail: rps.errors.ErrorDetail): Array<rps.errors.ErrorDetail>;
        add(validationErrors: Array<rps.data.ValidationError>): Array<rps.errors.ErrorDetail>;
        add(errorDetails: Array<rps.errors.ErrorDetail>): Array<rps.errors.ErrorDetail>;
        add(errorDetails: rps.errors.ErrorDetails): Array<rps.errors.ErrorDetail>;
        add(errorMessages: Array<string>): Array<rps.errors.ErrorDetail>;
        add(errorMessages: Array<rps.services.IException>): Array<rps.errors.ErrorDetail>;
        add(code: string, message: string): Array<rps.errors.ErrorDetail>;
        add(error: Error): Array<rps.errors.ErrorDetail>;
        add(serviceExceptionError: rps.services.IServiceExceptionError): Array<rps.errors.ErrorDetail>; 
        /** @internal **/
        handleError(error: any);
        errorDetails: rps.errors.ErrorDetails;
        /** @internal **/
        showError(errDetail: rps.errors.ErrorDetail): Array<rps.errors.ErrorDetail>;
        /** @internal **/
        showError(validationErrors: Array<rps.data.ValidationError>): Array<rps.errors.ErrorDetail>;
        /** @internal **/
        showError(errorDetails: Array<rps.errors.ErrorDetail>): Array<rps.errors.ErrorDetail>;
        /** @internal **/
        showError(errorDetails: rps.errors.ErrorDetails): Array<rps.errors.ErrorDetail>;
        /** @internal **/
        showError(errorMessages: Array<string>): Array<rps.errors.ErrorDetail>;
        /** @internal **/
        showError(errorMessages: Array<rps.services.IException>): Array<rps.errors.ErrorDetail>;
        /** @internal **/
        showError(code: string, message: string): Array<rps.errors.ErrorDetail>;
        /** @internal **/
        showError(error: Error): Array<rps.errors.ErrorDetail>;
        /** @internal **/
        showError(serviceExceptionError: rps.services.IServiceExceptionError): Array<rps.errors.ErrorDetail>;
    }

    export interface IEntityFactory {
        /** @rpsInternal **/
        configure(): void;
        /** @internal **/
        "get"(pkValue: string | {}, entityDefinition: rps.services.entityFactory.IEntityDefinition<any>, saveOriginalData?: boolean, customQuery?: rps.data.sources.GetEntitySource): Promise<any>;
        /** @internal **/
        "get"(pkValue: string | {}, entityUrl: string, saveOriginalData?: boolean, customQuery?: rps.data.sources.GetEntitySource): Promise<any>;
        /** @internal **/
        getCompanySingleEntity(entityDef: rps.services.entityFactory.IEntityDefinition<any>): Promise<any>;
        entities: rps.services.entityFactory.services;
        /** @internal **/
        createNew(url: string, saveOriginalData?: boolean): Promise<any>;
        /** @internal **/
        createNew(entityDef: rps.services.entityFactory.IEntityDefinition<any>, saveOriginalData?: boolean): Promise<any>;
        /** @internal **/
        findEntityDefinition(url: string): rps.services.entityFactory.IEntityDefinition<any>;
        //Para un tipo de entidad concreto, devuelve el tipo resuelto, teniendo en cuenta extensiones
        /** @rpsInternal **/
        resolveEntityType(entityType: Function): Function;
        //Flag para indicar si está en proceso de hacer get o create (para ignorar control de cambios)
        /** @internal **/
        isWorking: boolean;
        //Flag para indicar si está en proceso de aplicar cambios (para que no salten SETs de propiedad)
        /** @rpsInternal **/
        isApplyingPatch: boolean;
}

    export interface INotificationManager {
        /** @rpsInternal **/
        setKendoNotification(kendoNotification: any);
        /** @rpsInternal **/
        configure(): void;
        show(params: {
            message: string;
            notificationType: NotificationType;
        });
    }

    export enum NotificationType {
        Info = 0, Success = 1, Warning = 2, Error = 3
    }

    /**
    * Servicio para gestionar los procesos con resultado
    */
    export interface IProcessResultManager {
        configure(): void;
        show(params: { result: rps.data.SyncProcessResult }): Promise<any>;
        showAsync(params: { result: rps.data.AsyncProcessResult }): () => void;
    }

    /**
    * Servicio para gestionar el busyIndicator global de la aplicación
    */
    export interface IBusyIndicatorManager {
        isBusy: boolean;
        isBusyChanged: IEventEmitter<boolean>;

        configure(): void;
        setIsBusy(newValue: boolean): void;
    }

    export interface IEntityInstanceQueryResult {
        Result: any;
    }

    export enum UrlType {
        Absolute = 0,
        Relative = 1
    }

    export enum HttpVerb {
        PUT = 0,
        POST = 1
    }

    export interface IApi {
        configure(): void;
        invokeAction(actionName: string, parameters: Object): Promise<any>;
        invokeMethod(url: string, methodName: string, parameters: Object,headers?: {}): Promise<any>;
        query<T>(params: {
            queryName: string;
            pageOptions?: {
                page: number;
                pageSize: number;
            };
            params?: { [id: string]: Object; };
            forPrint?: boolean;
        }): Promise<T>;
        entityInstanceQuery(queryName: string, id: string): Promise<rps.services.IEntityInstanceQueryResult>;
        get<T>(params: { url: string; urlType: UrlType; queryParams?: any; }): Promise<T>;
        getHTML(params: { templateUrl?: string; template?: string; urlType: UrlType }): Promise<string>;
        update<T extends rps.entities.IBaseEntity>(entity: T): Promise<T>;
        patch<T extends rps.entities.IBaseEntity>(entity: T): Promise<T>;
        delete<T extends rps.entities.IBaseEntity>(entity: T | string, entityId?: string): Promise<boolean>;
        setProperty(entity: rps.entities.IBaseEntity, propertyName: string): Promise<rps.entities.IPropertyPatch[]>;        
        setProperty(entity: rps.entities.IBaseEntity, propertyName: string,contractName:string): Promise<rps.entities.IPropertyPatch[]>;        
        uploadImage(params: { url: string; file: File }): Promise<string>;
        removeImage(params: { url: string }): Promise<any>;
        uploadFile(params?: { allowedExtensions?:Array<string> }): Promise<{ ticket: string }>;
        getReport(service: string, reportName: string, params?: {}): Promise<string>;
        exportQuery(params: { queryReference: string, exportFormat: string, params?: {} }): Promise<any>;
        download(params: { url: string, urlType: rps.services.UrlType, params?: {}}): Promise<any>;
    }    

    export interface IClientAPI {
        configure(): void;
        get<T>(params: { url: string, queryParams?: any }): Promise<T>;
        put(params: { url: string, body: any }): Promise<rps.services.API.IResponse>;
        post(params: { url: string, body: any }): Promise<rps.services.API.IResponse>;
        delete(params: { url: string, body: any}): Promise<rps.services.API.IResponse>;
    }

    export interface IApiRef {
        configure(): void;
        get<T>(params: { url: string; }): Promise<T>;
        getFile(fileUrl: string): Promise<string>;
        previewQuery(query: any): Promise<{ queryDefinition: rps.data.QueryReference, querySource: rps.data.sources.QuerySource }>;
        getFavourites(): Promise<any>;
        addFavourite(menuId: string): Promise<boolean>;
        deleteFavourite(menuId: string): Promise<boolean>;
        getMenu(codUser: string, codRole: string): Promise<any[]>;
    }

    export interface IEntityManager {
        /** @rpsInternal **/
        configure(): void;
        /** @internal **/
        searchEntityID(entityName: string, hasAnyEntityView:boolean, dataSource: rps.data.sources.LookupSource): Promise<string>;
        /** @internal **/
        searchEntitiesIds(dataSource: rps.data.sources.LookupSource, hasAnyEntityView: boolean, vmProperty: rps.viewmodels.properties.MultiLookupProperty): Promise<Array<string>>;
        /** @internal **/
        searchItem(dataSource: rps.data.sources.LookupSource): Promise<rps.viewmodels.ModelVM>;
        /** @internal **/
        navigateToEntity(entityName: string, entityID: string, codCompany: string): void;
        /** @internal **/
        getNavigableStates(entityName: string, entityID: string, codCompany: string): Promise<Array<rps.data.NavigableState>>;
    }

    export interface IViewFactory {
        configure(): void;
        getStateView(params: {
            stateName: string,
            isModal?: boolean
        }): Promise<JQuery>;
        getModalStateView(stateName: string): Promise<JQuery>;
        getStateViewCode(stateName: string): Promise<string>;
    }

    export interface IResourceManager {
        configure(): void;
        loadCultureResources(culture: string, cultureHTML5: string, loadMenu: boolean): Promise<boolean>;
        loadLanguageResources(languageId: string, loadMenu: boolean): Promise<boolean>;
        getCurrentCulture(): string;
    }

    export interface IAppSettings {
        configure(): void;
        rpsAPIAddress: string;
        APP_VERSION: string;
    }

    export interface IMessageManager {
        configure(): void;
        show(params: {
            message: string;
            messageButton: MessageButton;
            messageType: MessageType;
            okOptions?: {
                text?: string;
                isPrimary?: boolean;
            },
            yesOptions?: {
                text?: string;
                isPrimary?: boolean;
            },
            noOptions?: {
                text?: string;
                isPrimary?: boolean;
            },
            cancelOptions?: {
                text?: string;
                isPrimary?: boolean;
            }
        }): Promise<MessageResult>;
    }

    export interface IUserInputManager {
        configure(): void;
        setPrimaryTarget(vm: any);
        handleUserInput(e: KeyboardEvent, vm: any);
        getActionForKey(e: KeyboardEvent | JQueryKeyEventObject): rps.services.Action;
    }

    export enum MessageButton {
        Ok = 0, YesNo = 1, YesNoCancel = 2
    }

    export enum MessageType {
        Info = 0, Success = 1, Warning = 2, Error = 3, Question = 4
    }

    export enum MessageResult {
        Ok = 0, Yes = 1, No = 2, Cancel = 3
    }

    export enum Action {
        None,
        Accept,
        Cancel,
        Yes,
        No,
        New,
        Save,
        Delete,
        Next,
        Previous,
        Find,
        Help

    }

    export interface IUIFactory {
        /** @rpsInternal **/
        configure(): void;
        /** @rpsInternal **/
        setWindowContainer(windowContainer: any);

        /** @internal **/
        showWindow(params: {
            viewModel: rps.viewmodels.IWindowVM;
            templateUrl?: string;
            template?: string;            
            preventCloseOnNavigate?: boolean;
        }): Promise<rps.viewmodels.IWindowVM>;

        /** @internal **/
        createComponent<T>(componentType: Function, container: any): Promise<any>;
        /** @internal **/
        createComponentRefFromUrl<T>(url: string, viewModel: any, container: any): Promise<any>;
        /** @internal **/
        createComponentFactoryFromUrl<T>(url: string): Promise<any>;
        /** @internal **/
        createComponentRefFromTemplate<T>(template: string, viewModel: any, container: any): Promise<any>;
        /** @internal **/
        createComponentFactoryFromTemplate<T>(template: string): Promise<any>;
        /** @internal **/
        createComponentRefFromFactory<T>(componentFactory: any, viewModel: any, container: any): Promise<any>;
    }

    export interface IComponentDefinition {
        Name: string;
        UIStates: IStateDefinition[];
    }

    export interface IStateDefinition {
        Name: string;
        URL: string;
        TemplateURL: string;
        ViewModelName: string;
    }

    export interface IViewModelFactory {
        configure(): void;

        /**
         * Crea una instancia de viewModel cogiendo como parámetro la función de creación, e invocando a toda la cadena de métodos necesaria para la inicialización con los parámetros indicados
         */
        createViewModel(viewModelFunction: Function|rps.viewmodels.BaseVM, initParams?: rps.IParams): Promise<rps.viewmodels.BaseVM>;

        createViewModelCollection<MT extends rps.viewmodels.BaseVM, AT extends rps.viewmodels.ObservableArray<MT>>(params: {
            viewModelCollectionFunction: Function;
            initParams: {
                viewModelFunction: Function;
                parentVM: rps.viewmodels.BaseVM;
            };
        }): Promise<AT>;

        createEntityViewModelCollection<VMT extends rps.viewmodels.EntityVM<rps.entities.IBaseEntity>>(params: {
            viewModelCollectionFunction: Function;
            initParams: {
                childCollection: rps.entities.ChildCollection<rps.entities.IBaseEntity>;
                viewModelFunction: Function;
                checkFilters:(viewModel: VMT)=>boolean;
                orderDefinition: Array<{ propertyName: string; direction: number }>;
                parentVM: rps.viewmodels.BaseVM;
            };
        }): Promise<rps.viewmodels.EntityVMCollection<VMT>>;

        /**
         * Dado un nombre de componente, crea y carga la función correspondiente, y devuelve una instancia de ella correctamente inicializada
         * También crea y carga en memoria todas las funciones de viewModel relacionados con el componente en cuestión
         */
        createComponent(componentName: string, initParams?: rps.IParams): Promise<rps.viewmodels.ComponentVM>;

        getComponentFunction(componentName: string): Promise<FunctionConstructor>;

    }

    export interface INavigationOptions {
        openInNewWindow: boolean;
        replaceLocation?: boolean;
    }

    export interface IStateStack {
        add(item: rps.services.IBaseComponent);
        removeLast(): rps.services.IBaseComponent;
        clear();
        current: rps.services.IBaseComponent;
        length: number;
        at(i: number): rps.services.IBaseComponent;
        find(predicate: (value: rps.services.IBaseComponent) => boolean): rps.services.IBaseComponent;
        tryRemove(component: rps.services.IBaseComponent): rps.services.IBaseComponent;
    }

    /**
    * Servicio para tratar los estados de la aplicación, navegación,...
    */
    export interface IStateManager {
        configure();

        navigationStarted: rps.services.IEventEmitter<string>;

        goTo(linkDefinition: rps.data.IUILinkDefinition, params?: {}, options?: INavigationOptions): Promise<any>;
        goToRoute(route: any): Promise<any>;
        goBack():Promise<any>;
        goToParent(): Promise<any>;

        href(stateName: rps.data.IUILinkDefinition, params?: {}, options?: IHrefOptions): string;
        stateHierarchy: StateDescriptor[];
        uiStateHierarchy: StateDescriptor[];
        resetStateHierarchy(): void;
        restoreRedirectPath(): boolean;
        saveRedirectPath(): void;
        getStateParams(): IParams;
        replaceID(newID: string): void;
        currentPath(): string;
        previousUrl: string;
        nextUrl: string;
        forceNewWindowInModal: boolean;

        /** Navega directamente a la url indicada como parámetro */
        navigateToUrl(url: string, useCachedViewModel?:boolean);

        //La empresa en la que se está trabajando (la que aparece en la URL)
        getCurrentCompany(): string;
        setCurrentCompany(value: string);

        setAsModalState(stateName: string);
        navigateModal: (state: string, vm: rps.viewmodels.BaseVM, okCommand: rps.viewmodels.commands.CommandProperty) => Promise<{ result: NavigationResult; data: any }>;
        navigateChild: (link: rps.viewmodels.properties.LinkProperty, navigationArguments: IParams, vm: rps.viewmodels.BaseVM, okCommand: rps.viewmodels.commands.CommandProperty) => Promise<{ result: NavigationResult; data: any }>;

        refreshPageTitle(): void;

        navigateToEntity(entity: string, id: string, openInNewWindow: boolean): Promise<any>;

        getCachedVM(stateName: string): rps.viewmodels.BaseVM;

        /** Convierte un IUILinkDefintion en un valor bindeable a la directiva routerLink de Angular */
        createRouterLink(linkDefinition: rps.data.IUILinkDefinition): { routerLink: Array<any>, queryParams: rps.IParams };
        createRouterLink(linkDefinition: rps.data.IUILinkDefinition, routeParams: {}): { routerLink: Array<any>, queryParams: rps.IParams };

        stateStack: IStateStack;

        notFoundLink: Array<any>;

        goToLogon(replaceUrl?: boolean ): Promise<any>;
        goToDesktop(replaceUrl?: boolean): Promise<any>;
        goToNotFound(replaceUrl?: boolean ): Promise<any>;

        /** Indica si un estado concreto está registrado dentro de la lista de rutas principales (depende de si tiene permisos o no) */
        isStateRegistered(stateName: string): boolean;
    }

    export interface IHistoryState {
        id: string;
        URL: string;
        stateStack: Array<{
            stateName: string,
            vmId: string
        }>;
    }

    export interface IVMCache {
        add(stack: { id: string, URL: string, stateStack: Array<{ stateName: string, vmId: string }> }, deleteItemsAfterLastGet:boolean): void;
        replaceLastEntry(entry:IHistoryState): void;
        getVM(stateName: string): rps.viewmodels.BaseVM;
    }

    export interface IBaseComponent {
        /**
        * Navega de forma relativa a la ruta del componente al estado pasado por parámetro
        * @param relativeRoute Ruta a la que se quiere navegar
        */
        navigate(relativeRoute: Array<any>): Promise<boolean>;
        vm: rps.viewmodels.BaseVM;
        configOptions: rps.viewmodels.IConfigOptions;
        routeParams: {
            [key: string]: string;
        };
        isModalComponent: boolean;
        vmChanged: rps.services.IEventEmitter<any>;

        activatedRoute: any;

        view: { rpsVisible: boolean };
    }

    export interface IHrefOptions {
        absolute?: boolean;
    }

    export interface IStateParams extends IParams {
        stateName: String;
        stateParams: {};
    }

    export interface IUIStateDefinition {
        State: string,
        Arguments: {},
        Parameters: {}
    };

    export interface ICurrencyResolver {
        configure(): void;
        resolve(codCompany: string, entityType: string, entityId: string): Promise<rps.data.CurrencySymbol>;
    }

    export interface IQuantityUnitResolver {
        configure(): void;
        resolve(codCompany: string, entityType: string, entityId: string, isSecondaryUnit: boolean): Promise<rps.data.QuantityUnitAbbreviation>;
    }

    export interface IPriceUnitResolver {
        configure(): void;
        resolve(codCompany: string, entityType: string, entityId: string): Promise<rps.data.PriceUnitAbbreviation>;
    }

    export interface IDurationResolver {
        configure(): void;
        resolve(codCompany: string, timeUnit: number): Promise<rps.data.DurationFactor>;
    }

    export enum NavigationResult {
        OK = 1, Cancel = 2
    }

    export class StateDescriptor {
        constructor(public relatedVM: rps.viewmodels.BaseVM) {
        }
        public routerLink: Array<any>;
        public queryParams: rps.IParams;
    }


}

module rps.async {
    export type IResolveReject<T> = (value?: T | PromiseLike<T>) => void;
}

module rps.services.entities {
    export interface BPMEntities {
    }
    export interface WorkflowEntities {
    }

    export interface GeneralEntities {
    }

    export interface MaintenanceEntities {
    }

    export interface ManufacturingEntities {
    }

    export interface SalesEntities {
    }

    export interface ProjectEntities {
    }

    export interface PurchaseEntities {
    }

    export interface WarehouseEntities {
    }
}

module rps.services.entityFactory {

    export interface IEntityDefinition<T> {
        url: string;
        isNavigable: boolean;
        createNew(saveOriginalData?: boolean): Promise<T>;
        "get"(id: string | {}, saveOriginalData?: boolean, customQuery?: rps.data.sources.GetEntitySource): Promise<T>;
        getCompanySingleEntity(): Promise<T>;
        ensureLoaded(): Promise<IEntityDefinition<T>>;
    }

    export interface services {
        BPM: rps.services.entities.BPMEntities;
        Workflow: rps.services.entities.WorkflowEntities;
        General: rps.services.entities.GeneralEntities;
        Maintenance: rps.services.entities.MaintenanceEntities;
        Manufacturing: rps.services.entities.ManufacturingEntities;
        Project: rps.services.entities.ProjectEntities;
        Purchase: rps.services.entities.PurchaseEntities;
        Sales: rps.services.entities.SalesEntities;
        Warehouse: rps.services.entities.WarehouseEntities;
    }
}