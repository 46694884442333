/// <reference path="parameters.ts" />

module rps.data.sources {

    export class RecursiveHierarchicalQuerySource implements IExecutableQuery, IActivable{
        //Contiene el array con los padres de la entidad que está pendiente de seleccionar
        private _selectedEntityParentsIDs: Array<string>;
        private _parentVM: rps.viewmodels.BaseVM;
        private service: string;
        private queryName: string;                
        private relatedVMFunction: Function;
        public  executionPolicy: rps.queryExecutionPolicy;
        public loaded: boolean = false;    
        private childrenCountPropertyPath: string = "ChildNumber";    
        private childrenPropertyPath: string = "Children";
        private parentPropertyPath: string = "Parent";
        private idParamPath: string = "ID";       
        private firstLevelReaded: boolean = false;
        private loadingSelectedVMParentsIDs: boolean = false;
        private selfState: rps.data.IUILinkDefinition;
        private selfStateParameterName: string;
        private kendoTreeView: kendo.ui.TreeView;
        private putItemParent: (sources: rps.viewmodels.BaseVM, destination: rps.viewmodels.BaseVM) => Promise<boolean>;
        public isIActivable: boolean = true;

        public queryParams: parameters.QueryParams;
        public dataSource: kendo.data.HierarchicalDataSource;
        //Es el EntityViewModel correspondiente al ModelViewModel seleccionado en el treeView
        public selectedEntityVM: {
            IsNew: rps.viewmodels.properties.VMProperty<boolean>;
            getEntityID(): string;
            getParentID(): string;
            setParentID?(parentID: string);
        };    

        //Igor: selfStateName?: rps.data.IUILinkDefinition; (no compilaba)
        constructor(params: {
            service: string;
            queryName: string;            
            relatedVMFunction: Function;
            executionPolicy: rps.queryExecutionPolicy;
            selfStateName?: string;
            selfStateParameterName?: string;
            parentVM: rps.viewmodels.BaseVM
            queryParams?: parameters.QueryParams;            
        }) {
            this._parentVM = params.parentVM;
            this.service = params.service;
            this.queryName = params.queryName;

            this.queryParams = params.queryParams;
            this.relatedVMFunction = (<any>(params.relatedVMFunction)).getResolvedType(params.relatedVMFunction);            
            this.executionPolicy = params.executionPolicy;

            if (params.selfStateName)
                this.selfState = JSON.parse(params.selfStateName.toString());
            this.selfStateParameterName = params.selfStateParameterName;

            this.createDataSource();

            //Si se autoexecuta, hay que añadir watches a las propiedades
            if ((this.executionPolicy == rps.queryExecutionPolicy.Automatic || this.executionPolicy == rps.queryExecutionPolicy.WhenActive) && this.queryParams) {
                this.queryParams.forEach((ip: parameters.QueryParam) => {
                    if (ip.value && ip.value instanceof rps.viewmodels.properties.VMProperty) {
                        (<rps.viewmodels.properties.VMProperty<any>>ip.value).propertyChanged.subscribe((subscriptionArgs: rps.viewmodels.properties.VMPropertyChange) => {
                            if (subscriptionArgs.propertyName == "value") {
                                this.load();
                            }
                        });
                    }
                });
            }
        }

        initialize(): Promise<any> {
            if (this.executionPolicy == rps.queryExecutionPolicy.Automatic)
                this.load();
            return Promise.resolve<any>(this);
        }

        public configure(params: {
            putItemParent?: (sources: rps.viewmodels.BaseVM, destination: rps.viewmodels.BaseVM) => Promise<boolean>
        }) {
            if (rps.object.hasValue(params.putItemParent))
                this.putItemParent = params.putItemParent;
        }

        load() {
            if (!this.loaded)
                this.loadItems.execute();
        }

        public execute = (commandParameters?: any): Promise<any> => {
            return this.loadItems.execute();
        }

        public onActivate(isFirstActivation: boolean): void {
            //Si es la primera vez y la query está marcada como whenActivate, se intenta lanzar
            if (isFirstActivation) {
                if (this.executionPolicy == rps.queryExecutionPolicy.WhenActive)
                    this.execute();
            }
        }

        public loadItems: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<RecursiveHierarchicalQuerySource> => {
                this.loaded = true;
                this.dataSource.read();
                return Promise.resolve<RecursiveHierarchicalQuerySource>(this);
            }
        });

        getParams(): { [id: string]: Object; } {
            var params: { [id: string]: Object; } = {};
            if (this.queryParams) {
                this.queryParams.forEach((queryParam: parameters.QueryParam) => {
                    var value = queryParam.getValue();
                    if (!rps.object.isNullOrUndefined(value)) {
                        if (value instanceof Date)
                            params[queryParam.paramName] = value.toISOString();
                        else if (Array.isArray(value)) {
                            if (value.length > 0) {
                                var newParamValue: string;
                                (<Array<any>>value).forEach((item) => {
                                    if (newParamValue == undefined)
                                        newParamValue = item;
                                    else
                                        newParamValue += "," + item;
                                });
                                params[queryParam.paramName] = newParamValue;
                            }
                        }
                        else
                            params[queryParam.paramName] = value;
                    }
                });
            }
            return params;            
        }

        createDataSource() {
            var dataSourceOptions: kendo.data.HierarchicalDataSourceOptions = {}            
            dataSourceOptions.transport = {
                read: this.read
            };

            dataSourceOptions.schema = {
                model: {
                    id: "nodeID"
                }
            }

            this.dataSource = new kendo.data.HierarchicalDataSource(dataSourceOptions);            
        }

        read = (options: kendo.data.DataSourceTransportReadOptions) => {            
            if (!options.data["nodeID"]) {
                rps.app.api.query<rps.data.PaginatedViewResult>({
                    queryName: this.service + "/" + this.queryName,
                    params: this.getParams()
                }).then((result) => {
                    this.createViewModels(result, null).then((items: Array<rps.viewmodels.ModelVM>) => {
                        options.success(items);                        
                        this.firstLevelReaded = true;
                        this.selectSelectedID();
                    });
                });
            }
            else {
                var item: rps.viewmodels.ModelVM = <any>this.dataSource.get(options.data["nodeID"]);
                rps.app.api.get<rps.data.PaginatedViewResult>({
                    url: item.model[this.childrenPropertyPath],
                    urlType: rps.services.UrlType.Absolute             
                }).then((result) => {
                    this.createViewModels(result, options.data["nodeID"]).then((items: Array<rps.viewmodels.ModelVM>) => {                        
                        options.success(items);
                    });
                });
            }
        }

        createViewModels(result: rps.data.PaginatedViewResult, parentNodeID: string): Promise<Array<rps.viewmodels.ModelVM>> {
            var promises: Array<Promise<rps.viewmodels.ModelVM>> = new Array<Promise<rps.viewmodels.ModelVM>>();
            result.Data.forEach((dataItem) => {
                promises.push(this.createViewModel(dataItem, parentNodeID));
            });
            return Promise.all(promises).then((newItems) => {
                return newItems;
            });
        }

        createViewModel = (model: any, parentNodeID: string): Promise<rps.viewmodels.ModelVM> => {
            return new Promise<rps.viewmodels.ModelVM>((resolve) => {
                var initParams = { model: model };
                if (this._parentVM)
                    initParams["parentVM"] = this._parentVM;
                rps.app.viewModelFactory.createViewModel(this.relatedVMFunction, initParams).then((vm: rps.viewmodels.ModelVM) => {
                    this.fillKendoTreeViewFields(vm,parentNodeID);
                    resolve(vm);
                });
            });
        }

        private fillKendoTreeViewFields(vm: rps.viewmodels.ModelVM, parentID: string) {
            vm["nodeID"] = vm.model["ID"];
            vm["parentNodeID"] = parentID;
            vm["hasChildren"] = (vm.model[this.childrenCountPropertyPath] > 0);
        }

        setKendoTreeView(kendoTreeView: kendo.ui.TreeView) {
            this.kendoTreeView = kendoTreeView;
            this.selectSelectedID();
        }

        /**
         * Busca el ModelViewModel correspondiente al EntityViewModel, expande todos sus padres y lo selecciona
         * @param mainEntityVM
         */
        findAndSelectEntityVM(mainEntityVM: rps.viewmodels.RecursiveHierarchicalMainEntityVM<rps.entities.IMainEntity>):Promise<any> {                                    
            return this.setSelectedEntityVM(mainEntityVM);
        }

        /**
         * Busca el ModelViewModel correspondiente al ID, expande todos sus padres y lo selecciona
         * @param mainEntityVM
         */
        findAndSelectEntityID(entityID: string): Promise<any> {
            if (entityID) {
                return this.getParentID(entityID).then((parentID: string) => {
                    return this.setSelectedEntityVM({
                        IsNew: new rps.viewmodels.properties.VMProperty<boolean>({
                            target: this,
                            initialValue: false
                        }),
                        getEntityID: () => {
                            return entityID;
                        },
                        getParentID: () => {
                            return parentID
                        }
                    });
                }).catch(() => {
                    rps.utils.showIdNotFound().then(() => {
                        return this.setSelectedEntityVM(null);
                    });
                    //rps.app.stateManager.goToNotFound();
                });
            }
            else
                return this.setSelectedEntityVM(null);
        }

        setSelectedEntityVM(newValue: {
            IsNew: rps.viewmodels.properties.VMProperty<boolean>;
            getEntityID(): string;
            getParentID(): string;
            setParentID?(parentID: string);
        }): Promise<any> {
            this.selectedEntityVM = newValue;

            //Si es nuevo, se suma el contador de hijos del padre
            if (this.selectedEntityVM && this.selectedEntityVM.IsNew.value && this.selectedEntityVM.getParentID()) {
                var parent: rps.viewmodels.ModelVM = <any>this.dataSource.get(this.selectedEntityVM.getParentID());
                if (parent) {
                    parent.model[this.childrenCountPropertyPath].value += 1;
                    parent["hasChildren"] = true;
                }
            }

            return this.selectSelectedVMIDFillingParents();
        }

        selectSelectedVMIDFillingParents():Promise<any> {
            if (this.selectedEntityVM && this.selectedEntityVM.getParentID()) {

                this.loadingSelectedVMParentsIDs = true;
                var ids: Array<string> = new Array<string>();

                return this.fillParentIDWithID(ids, this.selectedEntityVM.getParentID()).then(() => {
                    this.loadingSelectedVMParentsIDs = false;
                    this._selectedEntityParentsIDs = ids;
                    return this.selectSelectedID();
                });
            }
            else
                return this.selectSelectedID();
        }

        fillParentIDWithID(ids: Array<string>, parentID: string): Promise<any> {
            var params: { [id: string]: Object; } = this.getParams();
            params[this.idParamPath] = parentID;
            return this.getParentURL(parentID).then((result) => {
                ids.unshift(parentID);
                if (result)
                    return this.fillParentIDWithParentURL(ids, result);
            });
        }        

        getParentID(id:string): Promise<string> {
            return this.getParentURL(id).then((parentURL: string) => {
                if (rps.object.hasValue(parentURL)) {
                    return rps.app.api.get<any>({
                        url: parentURL,
                        urlType: rps.services.UrlType.Absolute
                    }).then((result) => {
                        if (result && result.Data && result.Data.length > 0 && result.Data[0][this.idParamPath])
                            return result.Data[0][this.idParamPath];
                        else
                            return null;
                    });
                }
                else
                    return null;
            });
        }

        getParentURL(id: string): Promise<string> {
            var params: { [id: string]: Object; } = this.getParams();
            params[this.idParamPath] = id;
            return rps.app.api.query<rps.data.PaginatedViewResult>({
                queryName: this.service + "/" + this.queryName,
                params: params
            }).then((result) => {
                if (result && result.Data && result.Data.length > 0 && result.Data[0][this.parentPropertyPath])
                    return result.Data[0][this.parentPropertyPath];
                else
                    return null;
            });
        }

        fillParentIDWithParentURL(ids: Array<string>, parentURL: string): Promise<any> {
            return new Promise<any>((resolve) => {
                rps.app.api.get<any>({
                    url: parentURL,
                    urlType: rps.services.UrlType.Absolute
                }).then((result) => {
                    ids.unshift(result.Data[0][this.idParamPath]);
                    if (result.Data[0][this.parentPropertyPath]) {
                        return this.fillParentIDWithParentURL(ids, result.Data[0][this.parentPropertyPath]).then(() => {
                            resolve(this);
                        });
                    }
                    else {
                        resolve(this);
                    }
                });
            });
        }

        selectSelectedID(): Promise<any> {
            if (this.kendoTreeView && this.firstLevelReaded && !this.loadingSelectedVMParentsIDs) {
                if (this._selectedEntityParentsIDs) {
                    var selectedVMParentsIDs: Array<string> = this._selectedEntityParentsIDs;
                    this._selectedEntityParentsIDs = null;
                    return new Promise((resolve) => {
                        this.kendoTreeView.expandPath(selectedVMParentsIDs, () => {
                            return this.selectSelectedID().then(() => {
                                resolve(this);
                            });
                        });
                    })
                }
                else if (this.selectedEntityVM) {
                    var model: kendo.data.Model = this.dataSource.get(this.selectedEntityVM.getEntityID());
                    if (model) {
                        var node: JQuery = this.kendoTreeView.findByUid(model.uid);
                        this.kendoTreeView.select(node);
                        return Promise.resolve<any>(this);
                    } else if (this.selectedEntityVM.IsNew.value) {
                        var parentNode: JQuery = null;
                        var parentModel: kendo.data.Model = null;
                        if (this.selectedEntityVM.getParentID()) {
                            parentModel = this.dataSource.get(this.selectedEntityVM.getParentID());
                            if (!parentModel)
                                return;
                            parentNode = this.kendoTreeView.findByUid(parentModel.uid);
                        }

                        var newDescriptor: rps.data.Descriptor = new rps.data.Descriptor();
                        newDescriptor.MainDescriptor = rps.app.resources.directives.NEW;
                        var initParams = {
                            model: {
                                ID: this.selectedEntityVM.getEntityID(),
                                descriptor: newDescriptor
                            }
                        };
                        if (this._parentVM)
                            initParams["parentVM"] = this._parentVM;
                        return rps.app.viewModelFactory.createViewModel(this.relatedVMFunction, initParams).then((vm: viewmodels.ModelVM) => {
                            this.fillKendoTreeViewFields(vm, parentModel ? parentModel.uid : null);
                            var node: JQuery = this.kendoTreeView.append(vm, parentNode);
                            this.kendoTreeView.select(node);
                        });
                    }
                    else
                        return this.setSelectedEntityVM(null);
                }
                else {
                    this.kendoTreeView.select(null);
                    return Promise.resolve<any>(this);
                }
            }
            else
                return Promise.resolve<any>(this);
        }

        addChild(modelVM: rps.viewmodels.ModelVM) {
            //RC6
            //Crear los parámetros
            var parameters = {};
            parameters[this.selfStateParameterName] = rps.viewmodels.EntityVM.NEW_ID_VALUE;            
            parameters["_IDParent"] = modelVM["ID"].value;            

            //Añadir el parámetro _IDParent al último estado, para esto se crea un nuevo array
            var newStateName = new rps.data.IUILinkDefinition();
            this.selfState.slice().forEach((part) => {
                newStateName.push(part);
            });
            newStateName[newStateName.length - 1].Arguments["_IDParent"] = null;

            var selectedNode: JQuery;
            if (this.kendoTreeView)
                selectedNode = this.kendoTreeView.select();            
            rps.app.stateManager.goTo(newStateName, parameters).catch(() => {
                this.kendoTreeView.select(selectedNode);
            });            
        }

        public goToModelVM(newValue: rps.viewmodels.ModelVM): Promise<any> {
            if (newValue && this.selfState) {
                var parameters = {};                
                parameters[this.selfStateParameterName] = newValue.model['ID'];                
                return rps.app.stateManager.goTo(this.selfState, parameters);
            }
            else {
                return Promise.resolve<any>(this);
            }
        }

        public refreshNew(newEntityVM: rps.viewmodels.RecursiveHierarchicalMainEntityVM<rps.entities.IMainEntity>): Promise<any> {
            var newModel: kendo.data.Model = this.dataSource.get(newEntityVM.model.getEntityID());
            var newNode: JQuery = this.kendoTreeView.findByUid(newModel.uid);
            var parentNode: JQuery = null;
            if (newEntityVM.getParentID())
                parentNode = this.kendoTreeView.parent(newNode);
            this.kendoTreeView.remove(newNode);
            var params: { [id: string]: Object; } = this.getParams();
            params[this.idParamPath] = newEntityVM[newEntityVM.idPropertyName].value;
            return rps.app.api.query<rps.data.PaginatedViewResult>({
                queryName: this.service + "/" + this.queryName,
                params: params
            }).then((result) => {
                var initParams = { model: result.Data[0] };
                if (this._parentVM)
                    initParams["parentVM"] = this._parentVM;
                return rps.app.viewModelFactory.createViewModel(this.relatedVMFunction, initParams).then((vm:viewmodels.ModelVM) => {
                    this.fillKendoTreeViewFields(vm, newEntityVM.getParentID());
                    var node: JQuery = this.kendoTreeView.append(vm, parentNode);                    
                    this.kendoTreeView.select(node);
                    return this;
                });
            });
        }

        public deleteItem(deletedEntityID: string, deletedEntityParentID:string) {
            var newModel: kendo.data.Model = this.dataSource.get(deletedEntityID);
            var newNode: JQuery = this.kendoTreeView.findByUid(newModel.uid);  
                      
            if (deletedEntityParentID) {
                var parentModel: rps.viewmodels.ModelVM = <any>this.dataSource.get(deletedEntityParentID);
                if (parentModel) {
                    parentModel.model[this.childrenCountPropertyPath].value -= 1;
                    if (parentModel.model[this.childrenCountPropertyPath].value == 0)
                        parentModel["hasChildren"] = false;
                }
            }

            this.kendoTreeView.remove(newNode);
        }

        public cancelNew(newEntityVM: rps.viewmodels.RecursiveHierarchicalMainEntityVM<rps.entities.IMainEntity>) {
            var newModel: kendo.data.Model = this.dataSource.get(newEntityVM.model.getEntityID());
            var newNode: JQuery = this.kendoTreeView.findByUid(newModel.uid);

            if (newEntityVM.getParentID()) {
                var parentModel: rps.viewmodels.ModelVM = <any>this.dataSource.get(newEntityVM.getParentID());
                if (parentModel) {
                    parentModel.model[this.childrenCountPropertyPath].value -= 1;
                    if (parentModel.model[this.childrenCountPropertyPath].value == 0)
                        parentModel["hasChildren"] = false;
                }
            }

            this.kendoTreeView.remove(newNode);
        }

        public allowDrag(sourceVM: rps.viewmodels.ModelVM) {
            if (this.putItemParent || (this.selectedEntityVM &&
                sourceVM["nodeID"] == this.selectedEntityVM.getEntityID() &&
                this.selectedEntityVM.setParentID)) {
                return true;
            }
            else
                return false;
        }

        public dragstart(sourceVM: rps.viewmodels.ModelVM) {
            sourceVM['originalParent'] = this.kendoTreeView.parent(this.kendoTreeView.findByUid(sourceVM.uid));
            let nextNode = this.kendoTreeView.findByUid(sourceVM.uid).next();
            if (nextNode.length > 0)
                sourceVM['originalNextNode'] = nextNode;
        }

        public dragend(sourceVM: rps.viewmodels.ModelVM, destinationVM: rps.viewmodels.ModelVM): Promise<any> {
            if (this.putItemParent){
                var promise: Promise<boolean> = this.putItemParent.call(this._parentVM, sourceVM, destinationVM);
                return promise.then((result) => {
                    if (!result)
                        throw result;
                });
            }
            else if (this._parentVM instanceof rps.viewmodels.RecursiveHierarchicalMaintenanceComponentVM) {
                if (this.selectedEntityVM) {
                    if (this.selectedEntityVM.getEntityID() == sourceVM.model.ID) {
                        this.setActiveChildVMParent(destinationVM);
                        return Promise.resolve<any>(this);
                    }
                    else {
                        return this.goToModelVM(<any>sourceVM).then(() => {
                            this.setActiveChildVMParent(destinationVM);
                            return destinationVM;
                        });
                    }
                }
                else {
                    return this.goToModelVM(<any>sourceVM).then(() => {
                        this.setActiveChildVMParent(destinationVM);
                        return destinationVM;
                    });
                }
            }
            else {
                return Promise.reject<any>(this);
            }
        }

        private setActiveChildVMParent(destinationVM: rps.viewmodels.ModelVM) {
            if (destinationVM)
                this.selectedEntityVM.setParentID(destinationVM.model.ID);
            else
                this.selectedEntityVM.setParentID(null);
        }

        public undoDragend(entityID: string) {
            var kendoModel: kendo.data.Model = this.dataSource.get(entityID);
            if (kendoModel['originalParent']) {
                if (kendoModel['originalNextNode'])
                    this.kendoTreeView.insertBefore(this.kendoTreeView.findByUid(kendoModel.uid), kendoModel['originalNextNode']);
                else {
                    if (kendoModel['originalParent'].length > 0)
                        this.kendoTreeView.append(this.kendoTreeView.findByUid(kendoModel.uid), kendoModel['originalParent']);
                    else
                        this.kendoTreeView.append(this.kendoTreeView.findByUid(kendoModel.uid));
                }
                delete kendoModel['originalParent'];
                delete kendoModel['originalNextNode'];
            }
        }
    }
}