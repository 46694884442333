/// <reference path="..\viewmodels\observable.ts" />

module rps.errors {

    export interface INotifyDataErrorInfo {
        /** @internal */
        __hasErrors: boolean;
        getErrors(propertyName?: string): ErrorDetail[];
    }

    export class ErrorDetail extends rps.viewmodels.ObservableObject {
        constructor(code: string, msg: string)
        constructor(code: string, msg: string, property: string)
        constructor(code: string, msg: string, property?:string) {
            super();

            this.code = code;
            this.description = msg;
            this.property = property;
        }

        property: string;
        code: string;
        description: string;
    }

    export class ErrorDetails extends rps.viewmodels.ObservableArray<ErrorDetail> implements IDestroyable{
        public isIDestroyable: boolean = true;

        get hasErrors() {
            if (this.length > 0)
                return true;
            else
                return false;
        }

        public errorsChanged: rps.services.IEventEmitter<{ newErrors?: Array<ErrorDetail>, oldErrors?: Array<ErrorDetail> }> = rps.app.eventManager.createEmitter<{ newErrors?: Array<ErrorDetail>, oldErrors?: Array<ErrorDetail> }>();

        public addError(errDetail: ErrorDetail): Array<ErrorDetail>;
        public addError(validationErrors: Array<rps.data.ValidationError>): Array<ErrorDetail>;
        public addError(errorDetails: Array<ErrorDetail>): Array<ErrorDetail>;
        public addError(errorDetails: rps.errors.ErrorDetails): Array<ErrorDetail>;        
        public addError(errorMessages: Array<string>): Array<ErrorDetail>;
        public addError(errorMessages: Array<rps.services.IException>): Array<ErrorDetail>;
        public addError(code: string, message: string): Array<ErrorDetail>;
        public addError(error: Error): Array<ErrorDetail>;
        public addError(serviceExceptionError: rps.services.IServiceExceptionError): Array<ErrorDetail>;        
        public addError(param: any, additionalMessage?: any): Array<ErrorDetail> {
            var newErrors = createErrorDetails(param, additionalMessage);

            if (newErrors.length > 0) {
                newErrors.forEach((newError: ErrorDetail) => {
                    this.push(newError);
                });
                this.errorsChanged.emit({ newErrors: newErrors });
            }

            return newErrors;
        }

        public removeErrors(oldErrors: Array<ErrorDetail>) {
            if (oldErrors && oldErrors.length) {
                oldErrors.forEach((oldError) => {
                    this.remove(oldError);
                });
                this.errorsChanged.emit({oldErrors: oldErrors});
            }            
        }

        public clear(): Array<ErrorDetail> {
            if (this.length) {
                var result: Array<ErrorDetail> = new Array<ErrorDetail>();
                this.forEach((error: ErrorDetail) => {
                    result.push(error);
                });
                this.splice(0, this.length);
                this.errorsChanged.emit({ oldErrors: result });
                return result;
            } else {
                return new Array<ErrorDetail>();
            }
        }

        public onDestroy() {
            if (this.errorsChanged)
                this.errorsChanged.onDestroy();
        }
    }

    /** @internal */
    export function createErrorDetails(errDetail: ErrorDetail): Array<ErrorDetail>;
    /** @internal */
    export function createErrorDetails(validationErrors: Array<rps.data.ValidationError>): Array<ErrorDetail>;
    /** @internal */
    export function createErrorDetails(errorDetails: Array<ErrorDetail>): Array<ErrorDetail>;
    /** @internal */
    export function createErrorDetails(errorDetails: rps.errors.ErrorDetails): Array<ErrorDetail>;        
    /** @internal */
    export function createErrorDetails(errorMessages: Array<string>): Array<ErrorDetail>;
    /** @internal */
    export function createErrorDetails(errorMessages: Array<rps.services.IException>): Array<ErrorDetail>;
    /** @internal */
    export function createErrorDetails(code: string, message: string): Array<ErrorDetail>;
    /** @internal */
    export function createErrorDetails(error: Error): Array<ErrorDetail>;
    /** @internal */
    export function createErrorDetails(serviceExceptionError: rps.services.IServiceExceptionError): Array<ErrorDetail>; 
    /** @internal */
    export function createErrorDetails(param: any, additionalMessage?: any) {
        var result: Array<ErrorDetail> = new Array<ErrorDetail>();
        //createErrorDetails(code: string, message: string)
        if (rps.object.isString(param))
            result.push(new ErrorDetail(param, additionalMessage));
        //createErrorDetails(errDetail: ErrorDetail)
        else if (param instanceof ErrorDetail)
            result.push(param);
        //createErrorDetails(serverErrors: Array<rps.data.ValidationError>)
        //createErrorDetails(serverErrors: Array<ErrorDetail>)
        //createErrorDetails(serverErrors: Array<string>)
        //createErrorDetails(errorMessages: Array<rps.services.IException>)
        else if (rps.object.isArray(param)) {
            param.forEach((m) => {
                if (m instanceof ErrorDetail)
                    result.push(m);
                else if (rps.data.isValidationError(m))
                    result.push(new ErrorDetail(m.ErrorCode, m.ErrorDescription, m.Property));
                else if (rps.object.isString(m))
                    result.push(new ErrorDetail("", m));
                else if (rps.services.isIException(m))
                    result.push(new ErrorDetail(m.Code, m.Description));
            });
        }
        //createErrorDetails(serverErrors: rps.errors.ErrorDetails)
        else if (param instanceof rps.errors.ErrorDetails) {
            param.forEach((m: ErrorDetail) => {
                result.push(m);
            });
        }
        //createErrorDetails(error: Error)
        else if (param instanceof Error) {
            if (param["rejection"]) {
                try {
                    var errors = rps.errors.createErrorDetails(param["rejection"])
                    if (errors.length > 0) {
                        errors.forEach((error) => {
                            result.push(error);
                        });
                    }
                    else
                        result.push(new ErrorDetail("", (<Error>param).message));
                } catch (e) {
                    result.push(new ErrorDetail("", (<Error>param).message));
                }
            }
            else
                result.push(new ErrorDetail("", (<Error>param).message));
        }
        //createErrorDetails(error: rps.services.IServiceExceptionError)
        else if (isServiceException(param)) {
            let messageInDetails = false;
            if (param.Details) {
                param.Details.forEach((detail) => {
                    result.push(new ErrorDetail(detail.Code, detail.Message));
                    if (detail.Message === param.Message) {
                        messageInDetails = true;
                    }
                });
            }
            // Si el Message no está ya presente en la lista, insertarlo como primer detalle
            if (!messageInDetails) {
                result.splice(0, 0, new ErrorDetail("", param.Message));
            }
        }
        else
            throw param;

        return result;
    }

    function isServiceException(error: any): error is rps.services.IServiceExceptionError {
        return error.Message !== undefined;
    }

    /**
     * Función que intenta convertir un objeto de error genérico (obtenido en un catch de cualquier excepción o llamada asíncrona) en un string. 
     * @param err Puede ser un error o un array de errores
     */
    /** @internal */
    export function getErrorString(err: any): string {
        try {
            var errors = rps.errors.createErrorDetails(err)
            return errors.map(e => {
                let errText = e.description;
                if (e.code) {
                    errText = errText + ' (' + e.code + ')';
                }
                return errText;
            }).join("\r\n");
        } catch (e) {
            return "";
        }
    }
}