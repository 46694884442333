/// <reference path="../utils/errors.ts" />
/// <reference path="../utils/utils.ts" />
/// <reference path="../viewmodels/baseVM.ts" />
/// <reference path="../viewmodels/modelVM.ts" />
/// <reference path="../services/interfaces.ts" />
/// <reference path="../data/filters.ts" />
/// <reference path="../viewmodels/adapters/fileUploadAdapter.ts" />

module rps.app.viewmodels {
    /** @internal */
    export class reportFiltersVM extends rps.viewmodels.BaseVM implements rps.viewmodels.IWindowVM {
        public isOpenChanged = rps.app.eventManager.createEmitter<boolean>();
        public defaultCloseCommand = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (commandParameters: any): Promise<any> => {
                return this.cancelCommand.execute();
            }
        });
        private _isOpen: boolean = false;
        public get isOpen(): boolean {
            return this._isOpen;
        }
        public set isOpen(newValue: boolean) {
            if (this._isOpen != newValue) {
                this._isOpen = newValue;
                this.isOpenChanged.emit(newValue);
            }
        }
        public filtersManager: rps.data.filters.FiltersManager;
        public showReport: boolean = false;
        private codReport: string;
        public reportTitle: string = "";
        public actions: any;
        constructor(params: { codReport: string}) {
            super();
            this.codReport = params.codReport;
            this.filtersManager = new ReportFiltersManager(params.codReport);
            this.acceptCommand = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: this.accept,
                canExecute: this.canAccept
            });
            this.cancelCommand = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: this.cancel
            });
            this.showCommand = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: this.renderReportUrl
            });
            this.showExternalCommand = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: this.showReportNewWindow
            });
            this.actions = [{
                    target: this,
                    name: 'close',
                    method: this.cancel
                }];
            let docURL: string = string.format("reports/{0}", params.codReport);
            rps.app.apiRef.get({ url: docURL }).then((result: { CodReport: string, Description: string, WaitForUserFilters: boolean }) => {
                this.reportTitle = result.Description;
                if (!result.WaitForUserFilters) {
                    this.renderReportUrl();
                }
            });
        }

        public reportUrl: string = null;

        public renderReportUrl(): Promise<any> {
            let params = "CodReport=" + this.codReport;
            let filter = null;
            if (this.filtersManager.filters.hasValue()) {
                filter = this.filtersManager.getFilterParameters();
            }
            if (!string.isNullOrEmpty(filter))
                params += "&$filter=" + filter;
            this.reportUrl = rps.app.appSettings.rpsAPIAddress + "api/Security/query/UserReport?" + params;
            return Promise.resolve<any>(this);
        }

        public showReportNewWindow(): Promise<any> {
            let params = "CodReport=" + this.codReport;
            let filter = null;
            if (this.filtersManager.filters.hasValue()) {
                filter = this.filtersManager.getFilterParameters();
            }
            if (!string.isNullOrEmpty(filter))
                params += "&$filter=" + filter;
            let url = rps.app.appSettings.rpsAPIAddress + "api/Security/query/UserReport?" + params;
            window.open(url, "_blank");
            return Promise.resolve<any>(this);
        }

        public acceptCommand: rps.viewmodels.commands.CommandProperty;
        public cancelCommand: rps.viewmodels.commands.CommandProperty;
        public showCommand: rps.viewmodels.commands.CommandProperty;
        public showExternalCommand: rps.viewmodels.commands.CommandProperty;

        accept(): Promise<any> {
            this.isOpen = false;
            this.showReport = true;
            return Promise.resolve<any>(this);
        }

        canAccept(): rps.viewmodels.commands.CanExecuteResult {
            return rps.viewmodels.commands.CanExecuteResult.allow();
        }

        cancel(): Promise<any> {
            this.showReport = false;
            this.isOpen = false;
            return Promise.resolve<any>(this);
        }
    }

    class ReportFiltersManager extends rps.data.filters.FiltersManager {
        constructor(private codReport:string) {
            super({entityName: ""})
        }

        fillSavedFilters(): Promise<any> {
            return Promise.resolve(null);
        }

        ensureAllowedFiltersLoaded(): Promise<any> {
            if (!this.allowedFilters) {
                //Se pueden obtener los filtros específicos de la query o de la entidad, ya que todas las queries de la misma entidad, tienen los mismos filtros
                var docURL: string = string.format("reports/{0}/filters", this.codReport);
                return rps.app.apiRef.get({
                    url: docURL
                }).then((queryDoc: Array<rps.data.sources.IQueryFilterDocumentation>) => {
                    this.allowedFilters = rps.data.filters.FilterDefinition.createFilterDefinition({ QueryDocumentation: queryDoc });
                });
            }
            else {
                return Promise.resolve<any>(this);
            }
        }
    }
}