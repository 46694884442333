module rps.services.API {
    /**
     * Acceptable response types to be associated with a {@link Response}, based on
     * [ResponseType](https://fetch.spec.whatwg.org/#responsetype) from the Fetch spec.
     */
    export declare enum ResponseType {
        Basic = 0,
        Cors = 1,
        Default = 2,
        Error = 3,
        Opaque = 4,
    }

    export declare class Headers {
        /** @internal */
        _headersMap: Map<string, string[]>;
        constructor(headers?: Headers | {
            [key: string]: any;
        });
        /**
         * Returns a new Headers instance from the given DOMString of Response Headers
         */
        static fromResponseHeaderString(headersString: string): Headers;
        /**
         * Appends a header to existing list of header values for a given header name.
         */
        append(name: string, value: string): void;
        /**
         * Deletes all header values for the given name.
         */
        delete(name: string): void;
        forEach(fn: (values: string[], name: string, headers: Map<string, string[]>) => void): void;
        /**
         * Returns first header that matches given name.
         */
        get(header: string): string;
        /**
         * Check for existence of header by given name.
         */
        has(header: string): boolean;
        /**
         * Provides names of set headers
         */
        keys(): string[];
        /**
         * Sets or overrides header value for given name.
         */
        set(header: string, value: string | string[]): void;
        /**
         * Returns values of all headers.
         */
        values(): string[][];
        /**
         * Returns string of all headers.
         */
        toJSON(): {
            [key: string]: any;
        };
        /**
         * Returns list of header values for a given name.
         */
        getAll(header: string): string[];
        /**
         * This method is not implemented.
         */
        entries(): void;
    }

    /**
     * Interface for options to construct a Response, based on
     * [ResponseInit](https://fetch.spec.whatwg.org/#responseinit) from the Fetch spec.
     */
    export declare type ResponseOptionsArgs = {
        body?: string | Object | FormData;
        status?: number;
        statusText?: string;
        headers?: Headers;
        type?: ResponseType;
        url?: string;
    };

    /**
     * Creates a response options object to be optionally provided when instantiating a
     * {@link Response}.
     *
     * This class is based on the `ResponseInit` description in the [Fetch
     * Spec](https://fetch.spec.whatwg.org/#responseinit).
     *
     * All values are null by default. Typical defaults can be found in the
     * {@link BaseResponseOptions} class, which sub-classes `ResponseOptions`.
     *
     * This class may be used in tests to build {@link Response Responses} for
     * mock responses (see {@link MockBackend}).
     *
     * ### Example ([live demo](http://plnkr.co/edit/P9Jkk8e8cz6NVzbcxEsD?p=preview))
     *
     * ```typescript
     * import {ResponseOptions, Response} from '@angular/http';
     *
     * var options = new ResponseOptions({
     *   body: '{"name":"Jeff"}'
     * });
     * var res = new Response(options);
     *
     * console.log('res.json():', res.json()); // Object {name: "Jeff"}
     * ```
     */
    export declare class ResponseOptions {
        /**
         * String or Object representing the body of the {@link Response}.
         */
        body: string | Object;
        /**
         * Http {@link http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html status code}
         * associated with the response.
         */
        status: number;
        /**
         * Response {@link Headers headers}
         */
        headers: Headers;
        /**
         * @internal
         */
        statusText: string;
        /**
         * @internal
         */
        type: ResponseType;
        url: string;
        constructor({body, status, headers, statusText, type, url}?: ResponseOptionsArgs);
        /**
         * Creates a copy of the `ResponseOptions` instance, using the optional input as values to
         * override
         * existing values. This method will not change the values of the instance on which it is being
         * called.
         *
         * This may be useful when sharing a base `ResponseOptions` object inside tests,
         * where certain properties may change from test to test.
         *
         * ### Example ([live demo](http://plnkr.co/edit/1lXquqFfgduTFBWjNoRE?p=preview))
         *
         * ```typescript
         * import {ResponseOptions, Response} from '@angular/http';
         *
         * var options = new ResponseOptions({
         *   body: {name: 'Jeff'}
         * });
         * var res = new Response(options.merge({
         *   url: 'https://google.com'
         * }));
         * console.log('options.url:', options.url); // null
         * console.log('res.json():', res.json()); // Object {name: "Jeff"}
         * console.log('res.url:', res.url); // https://google.com
         * ```
         */
        merge(options?: ResponseOptionsArgs): ResponseOptions;
    }

    /**
     * Creates `Response` instances from provided values.
     *
     * Though this object isn't
     * usually instantiated by end-users, it is the primary object interacted with when it comes time to
     * add data to a view.
     *
     * ### Example
     *
     * ```
     * http.request('my-friends.txt').subscribe(response => this.friends = response.text());
     * ```
     *
     * The Response's interface is inspired by the Response constructor defined in the [Fetch
     * Spec](https://fetch.spec.whatwg.org/#response-class), but is considered a static value whose body
     * can be accessed many times. There are other differences in the implementation, but this is the
     * most significant.
     */
    export interface IResponse {
        /**
         * One of "basic", "cors", "default", "error, or "opaque".
         *
         * Defaults to "default".
         */
        type: ResponseType;
        /**
         * True if the response's status is within 200-299
         */
        ok: boolean;
        /**
         * URL of response.
         *
         * Defaults to empty string.
         */
        url: string;
        /**
         * Status code returned by server.
         *
         * Defaults to 200.
         */
        status: number;
        /**
         * Text representing the corresponding reason phrase to the `status`, as defined in [ietf rfc 2616
         * section 6.1.1](https://tools.ietf.org/html/rfc2616#section-6.1.1)
         *
         * Defaults to "OK"
         */
        statusText: string;
        /**
         * Non-standard property
         *
         * Denotes how many of the response body's bytes have been loaded, for example if the response is
         * the result of a progress event.
         */
        bytesLoaded: number;
        /**
         * Non-standard property
         *
         * Denotes how many bytes are expected in the final response body.
         */
        totalBytes: number;
        /**
         * Headers object based on the `Headers` class in the [Fetch
         * Spec](https://fetch.spec.whatwg.org/#headers-class).
         */
        headers: Headers;
        constructor(responseOptions: ResponseOptions);
        /**
         * Not yet implemented
         */
        blob(): any;
        /**
         * Attempts to return body as parsed `JSON` object, or raises an exception.
         */
        json(): any;
        /**
         * Returns the body as a string, presuming `toString()` can be called on the response body.
         */
        text(): string;
        /**
         * Not yet implemented
         */
        arrayBuffer(): any;
    }

    export function deserialize(data: string | {}): any {
        var deserializedData: any = "";

        if (data) {
            if (rps.object.isString(data)) {
                if (!rps.string.isNullOrEmpty(<string>data)) {
                    try {
                        deserializedData = JSON.parse(<string>data, function (key, value) {
                            //Ignorar propiedades especiales que devuelve la API que no tienen sentido en HTML5 (caso de DI, DeletedItems)
                            if (key == "DI" || key == "MP" || key == "HMR")
                                return undefined;

                            if (typeof value === 'string') {
                                var re = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/
                                if (value.match(re)) {
                                    if (value === "0001-01-01T00:00:00") {
                                        return null;
                                    }
                                    var parts = value.match(/\d+/g);
                                    var millisecs: number = 0;
                                    if (parts.length > 6) {
                                        //Rellenar lo qeu viene con zeros hasta 3 (023 -> 023; 23 -> 230; 2 -> 200)
                                        var ms = parts[6];
                                        while (ms.length < 3)
                                            ms += "0";
                                        millisecs = parseInt(ms);
                                    }
                                    return new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]), parseInt(parts[3]), parseInt(parts[4]), parseInt(parts[5]), millisecs);
                                }
                            }
                            return value;
                        });
                    } catch (e) {
                        //TODO: Quitar en la versión final
                        alert("Error al deserializar");
                    }
                }
            }
            else
                deserializedData = data;
        }

        deserializedData = rps.data.retrocycle(deserializedData);

        return deserializedData;
    }

    export function serialize(data: any, ignoreImages: boolean = false, ignoreParentReferences: boolean = false): string {
        //Antes de serializar, se crea un objeto nuevo sustituyendo objetos referenciados por $id y $ref
        //Además, se eliminan los objetos que empiecen por __ y las fechas se formatean
        var decycledCopy = rps.data.decycle(data, ignoreImages, ignoreParentReferences);
        return JSON.stringify(decycledCopy);
    }
    
    export function treatErrorStatusCode(res: IResponse | XMLHttpRequest, resolveFunction: rps.async.IResolveReject<any>, rejectFunction: rps.async.IResolveReject<any>) {
        var errorDataString: string;
        if (res instanceof XMLHttpRequest)
            errorDataString = (<XMLHttpRequest>res).responseText;
        else
            errorDataString = (<IResponse>res).text(); 
        var status: number = res.status;
        var errorData: any;
        if (errorDataString) {
            try {
                errorData = JSON.parse(errorDataString);
            }
            catch (e) {
                errorData = errorDataString;
            }
        }
        else {
            var url: string = "";
            if (res instanceof XMLHttpRequest) {
                if (res["responseURL"])
                    url = res["responseURL"];
            }
            else
                url = res.url;
            errorData = new rps.errors.ErrorDetail(status.toString(), `${url} ${status.toString()} (${res.statusText})`);
        }

        
        //Si el error es <400, se supone que será código correcto ->         
        if (status < 400)
            resolveFunction(res);
        else if (status >= 400 && status < 500) {
            if (status === 401) {
                rps.app.session.logged = false;
                rps.app.stateManager.goToLogon();
            }
            //Se trata expresamente el 409, ya que indica que el registro ha sido modificado en el servidor
            else if (status == 409)
                rejectFunction(409);
            else if (status == 403) {
                rejectFunction(new rps.errors.ErrorDetail("HANDLED_FORBIDDEN_ERROR", rps.app.resources.errors.ERR_FORBIDDEN));
            }
            else
                rejectFunction(errorData);
        } else if (status >= 500) {        
            rejectFunction(errorData);
        }
    }    

    declare function unescape(uriComponent: string): string;

    export function decode64(base64EncodedString: string): string {
            var b64array = "ABCDEFGHIJKLMNOP" +
            "QRSTUVWXYZabcdef" +
            "ghijklmnopqrstuv" +
            "wxyz0123456789+/" +
            "=";
            var input = base64EncodedString;
            var output = "";
            var chr1, chr2, chr3;
            var enc1, enc2, enc3, enc4;
            var i = 0;
            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
            do {
                enc1 = b64array.indexOf(input.charAt(i++));
                enc2 = b64array.indexOf(input.charAt(i++));
                enc3 = b64array.indexOf(input.charAt(i++));
                enc4 = b64array.indexOf(input.charAt(i++));

                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;

                output = output + String.fromCharCode(chr1);

                if(enc3 != 64) {
                    output = output + String.fromCharCode(chr2);
                }
            if (enc4 != 64) {
                    output = output + String.fromCharCode(chr3);
                }

            chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";

            } while (i < input.length);

        return unescape(output);
    }
}