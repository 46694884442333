/// <reference path="parameters.ts" />

module rps.data.sources {

    export class EntitySource implements IExecutableQuery, ISelectable, rps.viewmodels.rules.IRuleTrigger{                                
        public IRuleTrigger: boolean = true;
        public ISelectable: boolean = true;

        private _parentVM: rps.viewmodels.BaseVM | undefined;
        private service: string;
        private queryName: string;
        private params: { [id: string]: Object; }        
        private relatedVMFunction: Function;

        public propertyChanged = rps.app.eventManager.createEmitter<rps.viewmodels.properties.VMPropertyChange>();
        public filtersManager: rps.data.filters.FiltersManager;
        public rules: rps.viewmodels.rules.Rule[] = [];

        private removedItems: Array<rps.viewmodels.ModelVM>;
        private _items: Array<rps.viewmodels.ModelVM>;
        get items(): Array<rps.viewmodels.ModelVM> {        
            return this._items;
        }
        set items(newValue: Array<rps.viewmodels.ModelVM>) {
            if (this._items != newValue) {
                this._items = newValue;
                var newDataSource: rps.viewmodels.ObservableArray<rps.viewmodels.ModelVM> = new rps.viewmodels.ObservableArray<rps.viewmodels.ModelVM>();
                if (this._items)
                    this._items.forEach((item) => {newDataSource.push(item);});
                this.dataSource.data(newDataSource);

                //Lanzar las when del target (las que no tienen Trigger)
                if (this._parentVM && (<any>this._parentVM).rules && (<any>this._parentVM).rules.length > 0) {
                    Enumerable.From<rps.viewmodels.rules.Rule>((<any>this._parentVM).rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                        ForEach((rule) => {
                            rule.execute(this);
                        });
                }
            }
        }

        public dataSource: kendo.data.DataSource = new kendo.data.DataSource({
            page: 1
        });

        public hasRemovedItemsItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.removedItems) && this.removedItems.length > 0)
                return true;
            else
                return false;
        }
        public hasRemovedItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.removedItems) && this.removedItems.length > 0)
                return true;
            else
                return false;
        }
        public getRemovedItems(): Array<any> {
            if (this.hasRemovedItems())
                return this.removedItems;
            else
                return new Array<any>();
        }
        public hasItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.items) && this.items.length > 0)
                return true;
            else
                return false;
        }
        public getItems(): Array<any> {
            if (this.hasItems())
                return this.items;
            else
                return new Array<any>();
        }

        private _selectedItems: Array<any>;
        public get selectedItems(): Array<any> {
            return this._selectedItems;
        }
        public set selectedItems(newValue: Array<any>) {
            if (this._selectedItems != newValue) {
                this._selectedItems = newValue;             

                this.propertyChanged.emit({ propertyName: "selectedItems", newValue: newValue });

                //Lanzar las when del target (las que no tienen Trigger)
                if (this._parentVM && (<any>this._parentVM).rules && (<any>this._parentVM).rules.length > 0) {
                    Enumerable.From<rps.viewmodels.rules.Rule>((<any>this._parentVM).rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                        ForEach((rule) => {
                            rule.execute(this);
                        });
                }
            }
        }

        public hasSelectedItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.selectedItems) && this.selectedItems.length > 0)
                return true;
            else
                return false;
        }
        public getSelectedItems(): Array<any> {
            if (this.hasSelectedItems())
                return this.selectedItems;
            else
                return new Array<any>();
        }
        public setSelectedItems(newSelection: Array<any>) {
            this.selectedItems = newSelection;
        }

        private _loaded: boolean=false;
        public get loaded(): boolean {
            return this._loaded;
        }
        public set loaded(newValue: boolean) {
            if (this._loaded != newValue) {
                this._loaded = newValue;
                this.propertyChanged.emit({ propertyName: "loaded", newValue: newValue });
            }
        }

        public queryParams: parameters.QueryParams | undefined;

        get parentVM(): rps.viewmodels.BaseVM | undefined {
            return this._parentVM;
        }

        constructor(params: {
            service: string;
            queryName: string;       
            relatedVMFunction: Function;            
            parentVM?:rps.viewmodels.BaseVM
            queryParams?: parameters.QueryParams;
            entityName?: string;
        }) {
            if (rps.object.hasValue(params.entityName)) {
                this.filtersManager = new rps.data.filters.FiltersManager({
                    entityName: params.entityName
                });
            }
            //Si se ha creado el manejador de filtros, nos enganchamos al cambio de filtros para ejecutar la consulta
            if (this.filtersManager) {
                this.filtersManager.filtersChanged.subscribe(() => {
                    this.loadItems.execute();
                });
            }

            this._parentVM = params.parentVM;
            this.service = params.service;
            this.queryName = params.queryName;
            
            this.queryParams = params.queryParams;
            this.relatedVMFunction = (<any>(params.relatedVMFunction)).getResolvedType(params.relatedVMFunction);
                        
            this.createItems();
        }

        initialize(): Promise<any> {
            this.loaded = true;
            this.createItems();            
            return Promise.resolve<any>(this);
        }

        public executionPolicy: rps.queryExecutionPolicy = rps.queryExecutionPolicy.Automatic;

        public execute = (): Promise<any> => {            
            return this.loadItems.execute();
        }

        public loadItems: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                if (!this.beforeExecuteQuery) {
                    return this._loadItems();
                }
                else {
                    return this.beforeExecuteQuery.call(this._parentVM).then((successCallback: { cancel?: boolean }) => {
                        if (!successCallback.cancel)
                            return this._loadItems();
                    });
                }
            },
            canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
                if (!this.canCreateParams()) {
                    var reasons: Array<string> = new Array<string>();
                    reasons.push(rps.app.resources.directives.FILL_REQUIRED_PARAMETERS);
                    return rps.viewmodels.commands.CanExecuteResult.deny(reasons);
                }
                else if (this.canExecuteQuery)
                    return this.canExecuteQuery.call(this._parentVM);
                else
                    return rps.viewmodels.commands.CanExecuteResult.allow();
            }
        });

        private _loadItems():Promise<any> {
            if (!this.canExecuteSave().result)
                return this.load();
            else {
                return rps.app.messageManager.show({
                    message: rps.app.resources.messages.MSG_ASK_SAVE_CHANGES_RELOAD,
                    messageButton: rps.services.MessageButton.YesNoCancel,
                    messageType: rps.services.MessageType.Question,
                    yesOptions: {
                        text: rps.app.resources.messages.MSG_SAVE_CHANGES,
                        isPrimary: true
                    },
                    noOptions: {
                        text: rps.app.resources.messages.MSG_DISCARD_CHANGES
                    }
                }).then((result) => {
                    if (result === rps.services.MessageResult.Yes) {
                        return this.executeSave().then((result) => {
                            if (result)
                                return this.load();
                        });
                    }
                    else if (result === rps.services.MessageResult.No) {
                        rps.app.errorManager.clear();
                        return this.load();
                    }
                });
            }
        }

        public canExecuteQuery?: () => rps.viewmodels.commands.CanExecuteResult;
        private beforeExecuteQuery?: () => Promise<{ cancel?: boolean }>;
        public customCardViewItemUrl?: string;

        public configure(params: {
            canExecuteQuery?: () => rps.viewmodels.commands.CanExecuteResult,
            beforeExecuteQuery?: () => Promise<{ cancel?: boolean }>,
            customCardViewItemUrl?: string
        }) {
            if (rps.object.hasValue(params.canExecuteQuery))
                this.canExecuteQuery = params.canExecuteQuery;

            if (rps.object.hasValue(params.beforeExecuteQuery))
                this.beforeExecuteQuery = params.beforeExecuteQuery;

            if (rps.object.hasValue(params.customCardViewItemUrl))
                this.customCardViewItemUrl = params.customCardViewItemUrl;
        }

        private load():Promise<any> {
            if (this.canCreateParams()) {
                this.loaded = true;
                this.createParams();
                return this.readItems();
            }
            else if(this.loaded) {
                this.loaded = true;
                           
                this.createItems();                
                return Promise.resolve<any>(this);
            }

            return Promise.resolve<any>(this);
        }

        private canCreateParams(): boolean {
            var canCreate: boolean = true;
            if (this.queryParams) {
                this.queryParams.forEach((ip: parameters.QueryParam) => {
                    //Mirar si todos los parámetros requeridos tienen valor
                    if (ip.isRequired && !rps.object.hasValue(ip.getValue()))
                        canCreate = false;
                });
            }
            return canCreate;
        }

        createParams() {
            this.params = this.getParams();            
        }

        getParams(): { [id: string]: Object; } {
            var params: { [id: string]: Object; } = {};

            //Si se ha creado el manejador de filtros, miramos a ver si contiene filtros
            if (this.filtersManager) {
                if (this.filtersManager.search.hasValue())
                    params["$search"] = this.filtersManager.search.value;
                if (this.filtersManager.filters.hasValue()) {
                    var filtersParam: string = "";
                    params["$filter"] = this.filtersManager.getFilterParameters();
                }
            }

            if (this.queryParams) {
                this.queryParams.forEach((queryParam: parameters.QueryParam) => {
                    var value = queryParam.getValue();
                    if (!rps.object.isNullOrUndefined(value)) {
                        if (value instanceof Date)
                            params[queryParam.paramName] = value.toISOString();
                        else if (Array.isArray(value)) {
                            if (value.length > 0) {
                                var newParamValue: string;
                                (<Array<any>>value).forEach((item) => {
                                    if (newParamValue == undefined)
                                        newParamValue = item;
                                    else
                                        newParamValue += "," + item;
                                });
                                params[queryParam.paramName] = newParamValue;
                            }
                        }
                        else
                            params[queryParam.paramName] = value;
                    }
                });
            }

            return params;
        }

        createItems() {
            this.items = new Array<rps.viewmodels.ModelVM>();
            this.removedItems = new Array<rps.viewmodels.ModelVM>();
        }

        readItems():Promise <any> {
            this.selectedItems = undefined;
            return rps.app.api.query<Array<any>>({
                queryName: this.service + "/" + this.queryName,
                params: this.params
            }).then((result) => {
                return this.createViewModels(result).then((viewModels) => {
                    this.items = viewModels
                    this.removedItems = new Array<rps.viewmodels.ModelVM>();
                    this.processRules(rps.viewmodels.rules.Triggers.OnExecuteQuery);
                });
            }).catch((error) => {
                throw rps.app.resources.errors.ERR_EXECUTING_QUERY.format(error.toString());
            });
        }

        createViewModels(result: Array<any>): Promise<Array<rps.viewmodels.ModelVM>> {
            var promises: Array<Promise<rps.viewmodels.ModelVM>> = new Array<Promise<rps.viewmodels.ModelVM>>();
            for (var i = 0; i < result.length; i += 1)
                promises.push(this.createViewModel(result[i]));

            return Promise.all(promises);                
        }

        createViewModel(model: any): Promise<rps.viewmodels.ModelVM> {
            return new Promise<rps.viewmodels.ModelVM>((resolve) => {
                var initParams = {
                    model: model
                };
                if (this._parentVM)
                    initParams["parentVM"] = this._parentVM;
                rps.app.viewModelFactory.createViewModel(this.relatedVMFunction, initParams).then((vm: rps.viewmodels.ModelVM) => {
                    resolve(vm);
                });
            });
        }

        add(): Promise<rps.viewmodels.MultipleMainEntityVM<rps.entities.BaseEntity>> {
            var initParams = {
                model: rps.viewmodels.EntityVM.NEW_ID_VALUE
            };
            if (this._parentVM)
                initParams["parentVM"] = this._parentVM;
            return rps.app.viewModelFactory.createViewModel(this.relatedVMFunction, initParams).then((vm: rps.viewmodels.ModelVM) => {
                this.items.push(vm);
                this.dataSource.data().push(vm);
                return vm;
            });
        }

        remove(viewModel: rps.viewmodels.ModelVM) {
            this.removedItems.push(viewModel);
            this.items.splice(this.items.indexOf(viewModel), 1);
            this.dataSource.data().remove(viewModel);
        }

        canExecuteSave(): rps.viewmodels.commands.CanExecuteResult {
            if (!Enumerable.From<rps.viewmodels.MainEntityVM<rps.entities.BaseEntity>>(this.getItems()).Any(item => item.hasPendingChanges()) &&
                !Enumerable.From<rps.viewmodels.MainEntityVM<rps.entities.BaseEntity>>(this.getRemovedItems()).Any())
                return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.messages.MSG_NO_PENDING_CHANGES]);
            else
                return rps.viewmodels.commands.CanExecuteResult.allow();
        }

        executeSave(): Promise<boolean> {
            rps.app.errorManager.clear();
            var promises: Array<Promise<any>> = new Array<Promise<any>>();

            Enumerable.From<rps.viewmodels.MainEntityVM<rps.entities.BaseEntity>>(this.getRemovedItems()).forEach((item: rps.viewmodels.MainEntityVM<rps.entities.BaseEntity>) => {                
                promises.push(item.deleteModel().then(() => {
                    this.removedItems.splice(this.removedItems.indexOf(item), 1);
                }).catch((err) => {
                    rps.app.errorManager.add(err);
                    throw err;
                }));
            });

            Enumerable.From<rps.viewmodels.MainEntityVM<rps.entities.BaseEntity>>(this.getItems()).Where(item => item.hasPendingChanges()).forEach((item: rps.viewmodels.MainEntityVM<rps.entities.BaseEntity>) => {
                promises.push(item.savePendingChanges().catch((err) => {
                    rps.app.errorManager.add(err);
                    throw err;
                }));
            });

            return Promise.all(promises).then(() => {
                return true;
            }).catch(() => {
                return false;
            });
        }

        public resolveStateViewModel(stateParams: rps.services.IStateParams): Promise<rps.viewmodels.MainEntityVM<rps.entities.BaseEntity>> {
            if (stateParams.stateName == this.relatedVMFunction["relatedState"]) {
                var result: rps.viewmodels.MainEntityVM<rps.entities.BaseEntity> = Enumerable.From<rps.viewmodels.MainEntityVM<rps.entities.BaseEntity>>(this.getItems()).FirstOrDefault(null, item => item.model.getEntityID() == stateParams.stateParams[(<any>this.relatedVMFunction).idPropertyName]);
                if (result)
                    return Promise.resolve<rps.viewmodels.MainEntityVM<rps.entities.BaseEntity>>(result);
                else {
                    if (this._parentVM)
                        stateParams["parentVM"] = this._parentVM;
                    return rps.app.viewModelFactory.createViewModel(this.relatedVMFunction, stateParams).then((vm: rps.viewmodels.ModelVM) => {
                        this.items.push(vm);
                        this.dataSource.data().push(vm);
                        return vm;                        
                    });                    
                }
            }
            else
                return Promise.reject<rps.viewmodels.MainEntityVM<rps.entities.BaseEntity>>("incorrect related state");
        }

        protected processRules(trigger?: rps.viewmodels.rules.Triggers): Promise<rps.viewmodels.rules.RuleExecutionResult> {
            var rulesToExecute: Array<rps.viewmodels.rules.Rule> = [];
            if (this.rules && this.rules.length > 0) {
                this.rules.forEach((rule) => {
                    if (trigger && rule.Trigger == trigger)
                        rulesToExecute.push(rule);
                });
            }

            return rulesToExecute.reduce((prevPromise, rule) => {
                return prevPromise.then((result) => {
                    if (result.value === rps.viewmodels.rules.RuleExecutionValue.Continue)
                        return rule.execute().catch((err) => {
                            //Si casca la ejecución de una regla, añadir el error y cancelar el resto de reglas
                            rps.app.errorManager.add(
                                new rps.errors.ErrorDetail("RULE_ERROR", rps.app.resources.errors.ERR_ERROR_EXECUTING_RULE.format(rule.ID, rps.errors.getErrorString(err))));

                            return Promise.resolve({ value: rps.viewmodels.rules.RuleExecutionValue.Cancel });
                        });
                    else
                        return Promise.resolve({ value: rps.viewmodels.rules.RuleExecutionValue.Cancel });
                })
            }, Promise.resolve({ value: rps.viewmodels.rules.RuleExecutionValue.Continue }));

        }
    }
}