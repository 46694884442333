/// <reference path="../baseVM.ts" />


//rpsAdapter

module rps.viewmodels.adapters {

    export enum ganttViewTypes {
        projectView = 0,
        resourceView = 1,
        treelistView = 2,
    }

    export enum ganttRelationType {
        //Kendo Type: 0 - Finish - Finish, 1 - Finish - Start, 2 - Start - Finish, 3 - Start - Start.
        endToEnd = 0,
        endToStart = 1,
        startToEnd = 2,
        startToStart = 3
    }

    export enum ganttResourceType {
        employee = 0,
        machine = 1,
        tool = 2,
        machineGroup = 3,
        qualify=4   
    }
    export interface enumTaskExpanded {
        id: string;
        expanded: boolean
    }

    export abstract class GanttTask extends kendo.data.GanttTask {

        public secondaryTitle: string;
        public durationText: string;
        public color: number;
        public milestone: boolean;

        public $Assignments: Array<GanttResource>;
        public $Predecessors: Array<GanttDependency>;
        public $Successors: Array<GanttDependency>;

        private adapter: rps.viewmodels.adapters.GanttAdapterBase;

        constructor(adapter: rps.viewmodels.adapters.GanttAdapterBase) {
            super();
            this.adapter = adapter;

            this.$Assignments = new Array<GanttResource>();
            this.$Predecessors = new Array<GanttDependency>();
            this.$Successors = new Array<GanttDependency>();            

            this.milestone = false;

            this.secondaryTitle = "";
            this.durationText = "";
        }

        public setProperty(propertyName: string, value: any) {
            this.set(propertyName, value);
        }

        /**
         * Llamado cuando se actualiza alguna propiedad de la task desde el interface, mediante interacción del usuario (view -> model)
           Se debería sobreescribir en la clase heredada para modificar los valores correspondientes del modelo de datos original
         * @param propertyName
         * @param value
         */
        public setModelProperty(propertyName: string, value: any) {
        }
    }

    export class GanttDependency {
        constructor(task: GanttTask, ganttRelationType)
        constructor(taskId: string, ganttRelationType)
        constructor(public taskOrTaskId: GanttTask | string, public ganttRelationType) {
        }
    }

    export abstract class GanttResource {// extends rps.viewmodels.ObservableObject {

        public id: string;
        public description: string;
        public image: string;
        public type: ganttResourceType;        
        
    }

    //export abstract class GanttTaskAssigment {

    //}

    export abstract class GanttAdapterBase extends rps.viewmodels.BaseVM {

        protected vm: rps.viewmodels.BaseVM;

        public refreshData(): void { }

        initialize(parameters: rps.viewmodels.adapters.InitParams): Promise<GanttAdapterBase> {
            this.vm = parameters.vm;
            return super.initialize(parameters).then(() => { return this.setAdapterOptions() });
        }

        protected setAdapterOptions(): Promise<GanttAdapterBase> {
            return Promise.resolve(this);
        }

        public onTaskEdit(task: GanttTask) { }
        public onTaskAdd(position: number, startDate: Date, idParent: string): Promise<rps.viewmodels.adapters.GanttTask> {
            return Promise.resolve(null);
        }
        public onTaskUpdate(task: GanttTask, startDate?:Date, endDate?: Date, idParent?:string): Promise<boolean> {
            return Promise.resolve(true);
        }
      
        public onTaskDeleteSelected(tasks:Array<GanttTask>): Promise<boolean> {
            return Promise.resolve(true);
        }

        public onResourceNavigate(resourceId: string, resourceType?: ganttResourceType): boolean {
            //Si retorna false, navega por defecto a el recurso que está ya en el gantt
            return false;
        }

     
        public viewType: rps.viewmodels.properties.EnumProperty = new rps.viewmodels.properties.EnumProperty({
            enumItems: [{
                value: 0,
                description: rps.app.resources.directives.PROJECT_VIEW
            }, {
                    value: 1,
                    description: rps.app.resources.directives.RESOURCE_VIEW
                }, {
                    value: 2,
                    description: rps.app.resources.directives.TREELIST_VIEW
                }],
            target: this,
            initialValue: rps.viewmodels.adapters.ganttViewTypes.projectView
        });

        public tasks: Array<rps.viewmodels.adapters.GanttTask>;
        public resources: Array<rps.viewmodels.adapters.GanttResource>;
        public startDate: Date;
        public endDate: Date;
    }


    export abstract class GanttAdapter<T extends rps.viewmodels.BaseVM> extends GanttAdapterBase {

        protected vm: T;

        configureRules() {
            super.configureRules();

            this.addRule("onViewTypeChanged").onPropertyChanged(this.viewType).then().executeCode(() => this.trigger("dataChanged"));

        }

        private _tasks: Array<rps.viewmodels.adapters.GanttTask>;
        public get tasks(): Array<rps.viewmodels.adapters.GanttTask> {
            return this._tasks;
        }
        public set tasks(newValue: Array<rps.viewmodels.adapters.GanttTask>) {
            this._tasks = newValue;
            if (this._tasks.length > 0 && (!this._startDate || !this.endDate)) {
                var startDate = new Date(2050, 0, 1);
                var endDate = new Date(1900, 0, 1);
                for (var i = 0; i < this._tasks.length; i++) {
                    if (startDate > this._tasks[i].start) {
                        startDate = this._tasks[i].start;
                    }
                    if (endDate < this._tasks[i].end)
                        endDate = this._tasks[i].end;
                }
                if (!this._startDate)
                    this.startDate = startDate;
                if (!this._endDate)
                    this.endDate = endDate;
            }
            
        }

        private _resources: Array<rps.viewmodels.adapters.GanttResource>;
        public get resources(): Array<rps.viewmodels.adapters.GanttResource> {
            return this._resources;
        }
        public set resources(newValue: Array<rps.viewmodels.adapters.GanttResource>) {
            this._resources = newValue;
        }

        private _startDate: Date;
        public get startDate(): Date {
            return this._startDate;
        }
        public set startDate(newValue: Date) {
            this._startDate = newValue;
        }

        private _endDate: Date;
        public get endDate(): Date {
            return this._endDate;
        }
        public set endDate(newValue: Date) {
            this._endDate = newValue;
        }

        public refreshData() {
            this.getData().then((data) => {
                this.tasks = data.tasks;
                this.resources = data.resources;
                this.trigger("dataChanged");
            });
        }

        public abstract getData(): Promise<{
            tasks: rps.viewmodels.adapters.GanttTask[],
            resources: rps.viewmodels.adapters.GanttResource[]
            
        }>;

        /**
         * Llamado cuando desde el gantt se lanza un comando para editar una tarea (típicamente un doble-click sobre la tarea). Aquí se implementaría cualquier
        navegación a formulario o vista de edición de datos de la tarea
         * @param task
         */
        public onTaskEdit(task: GanttTask) {
        }

        /**
         * Llamado cuando desde el gantt se modifica el valor de una propiedad de la tarea. Devuelve una promesa con el resultado de si se acepta o no el cambio
         * @param task La tarea que se ha actualizado desde el gantt
         * @param property La propiedad que ha cambiado
         * @param value El nuevo valor que tiene la propiedad
         */
        public onTaskUpdate(task: GanttTask, startDate?: Date, endDate?: Date, idParent?: string): Promise<boolean> {
            return Promise.resolve(true); 
        }
                
        /**
         * Llamado cuando desde el gantt  se añade una tarea. Devuelve una promesa con el resultado, y si es resuelta y devuelve un task, se añade ese task al origne de datos del gantt
         * @param position Posición en la que se va a añadir
         * @param startDate Fecha en la que se añade la tarea
         * @param idParent ID del padre, si es que se añade en un grupo de tareas
         */
        public onTaskAdd(position: number, startDate: Date, idParent: string): Promise<rps.viewmodels.adapters.GanttTask> {
            return Promise.resolve(null);
        }
        public onTaskDeleteSelected(tasks: Array<GanttTask>): Promise<boolean> {
            return Promise.resolve(true);
        }

    }
}