module rps.ui.itemsControl {

    export enum EditableColumnTypes {
        textBox = 0,
        checkBox = 1,
        datePicker = 2,
        lookup = 3,
        decimalEditor = 4,
        integerEditor = 5,
        imageEditor = 6,
        longTextBox = 7,
        enumEditor = 8,
        dataComboBox = 9,
        entityLink = 10,
        percentageEditor = 11,
        amountEditor = 12,
        priceEditor = 13,
        quantityEditor = 14,
        formattedNumber = 15,
        durationEditor = 16,
        address = 17,
        addressValue = 18,
        emailEditor = 19,
        urlEditor = 20,
        colorEditor = 21,
        button = 22,
        timePicker = 23,
        icon = 24,
        documents = 25
    }

    export enum ServerColumnTypes {
        textBox = 0,
        checkBox = 1,
        datePicker = 2,
        lookup = 3,
        decimalEditor = 4,
        integerEditor = 5,
        imageEditor = 6,
        formattedNumber = 7,
        address = 8,
        uri = 9,
        email = 10,
        icon = 11,
        timePicker = 12,
        documents = 13
    }

    export class ColumnDefinition {
        public title: string;        
        public field: string;
        public columns: number;
        public isEditable: boolean = true;

        public titleBinding: string;
        public visibilityBinding: string;

        constructor(params: {
            title?: string;
            field: string;
            columns: number;
            isEditable?: boolean;
            titleBinding?: string;
            visibilityBinding?: string;
        }) {
            this.title = params.title;
            this.field = params.field;
            this.columns = params.columns;
            if (!rps.object.isNullOrUndefined(params.isEditable))
                this.isEditable = params.isEditable;

            this.titleBinding = params.titleBinding;
            this.visibilityBinding = params.visibilityBinding;
        }
    }

    export class ServerColumnDefinition extends ColumnDefinition {
        public template: ServerColumnTypes;
        public options: IServerColumnOptions;

        constructor(params: {
            template: ServerColumnTypes;
            title?: string;
            field: string;
            columns: number;
            isEditable?: boolean;
            options?: any;
            titleBinding?: string;
            visibilityBinding?: string;
        }) {
            super(params);
            this.template = params.template;
            if (!rps.object.isNullOrUndefined(params.options))
                this.options = params.options;
        }
    }

    export function isServerColumnDefinition(value: any): value is ServerColumnDefinition {
        return value && !rps.object.isNullOrUndefined(value["template"]);
    } 

    export interface IServerColumnOptions {
    }

    export class EditableColumnDefinition extends ColumnDefinition {
        public type: EditableColumnTypes;
        public options: IEditableColumnOptions;

        constructor(params: {
            type: EditableColumnTypes;
            title?: string;
            field: string;
            columns: number;
            isEditable?: boolean;
            options?: any;
            titleBinding?: string;
            visibilityBinding?: string;
        }) {
            super(params);
            this.type = params.type;
            if (!rps.object.isNullOrUndefined(params.options))
                this.options = params.options;
        }
    }

    export function isEditableColumnDefinition(value: any): value is EditableColumnDefinition {
        return value && !rps.object.isNullOrUndefined(value["type"]);
    } 

    export interface IEditableColumnOptions {
    }

    export class EditableLookupOptions implements IEditableColumnOptions {
        public dataSource: string;

        constructor(params: { dataSource: string; }) {
            this.dataSource = params.dataSource;
        }
    }

    export class EditableDataComboBoxOptions implements IEditableColumnOptions {
        public dataSource: string;
        public valuePath: string;
        public editable: boolean;

        constructor(params: {
            dataSource: string,
            valuePath: string,
            editable?: boolean
        }) {
            this.dataSource = params.dataSource;
            this.valuePath = params.valuePath;
            if (params.editable)
                this.editable = true;
        }
    }

    export class EditableButtonOptions implements IEditableColumnOptions {
        public label: string;
        public template: string;
        public icon: string;
        public type: string;

        constructor(params: { label?: string; icon?: string; type?: string; }) {
            if (!rps.object.isNullOrUndefined(params.label))
                this.label = params.label;
            if (!rps.object.isNullOrUndefined(params.icon))
                this.icon = params.icon;
            if (!rps.object.isNullOrUndefined(params.type))
                this.type = params.type;
        }
    }

    //HACK: En algunas ocasiones, se llama a las funciones estáticas de las grids, después de a ver eliminado la instancia, por lo que se crea este proxy para evitarlo
    export function loadBaseColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadBaseColumn(dataItem, valuePath,divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadTimePickerColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadTimePickerColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadDataComboBoxColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadDataComboBoxColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadEnumEditorColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadEnumEditorColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadDecimalEditorColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadDecimalEditorColumn(dataItem, valuePath, divID);
        return "<div class=\"rps-number-editor-input\" id=\"" + divID + "\"></div>";
    }

    export function loadPercentageEditorColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadPercentageEditorColumn(dataItem, valuePath, divID);
        return "<div class=\"rps-number-editor-input\" id=\"" + divID + "\"></div>";
    }

    export function loadIntegerEditorColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadIntegerEditorColumn(dataItem, valuePath, divID);
        return "<div class=\"rps-number-editor-input\" id=\"" + divID + "\"></div>";
    }

    export function loadTextBoxColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadTextBoxColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadDatePickerColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadDatePickerColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadIconColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadIconColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadDocumentsColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadDocumentsColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadDatePickerServerColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadDatePickerServerColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadImageEditorServerColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadImageEditorServerColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadLookupServerColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadLookupServerColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadFormattedNumberServerColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadFormattedNumberServerColumn(dataItem, valuePath, divID);
        return "<div class=\"rps-number-editor-input\" id=\"" + divID + "\"></div>";
    }

    export function loadAddressServerColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadAddressServerColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadCheckBoxServerColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadCheckBoxServerColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadTimePickerServerColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadTimePickerServerColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadIconServerColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadIconServerColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadDocumentsServerColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadDocumentsServerColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadLookupColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadLookupColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadCheckBoxColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadCheckBoxColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadAmountEditorColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadAmountEditorColumn(dataItem, valuePath, divID);
        return "<div class=\"rps-number-editor-input\" id=\"" + divID + "\"></div>";
    }

    export function loadPriceEditorColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadPriceEditorColumn(dataItem, valuePath, divID);
        return "<div class=\"rps-number-editor-input\" id=\"" + divID + "\"></div>";
    }

    export function loadQuantityEditorColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadQuantityEditorColumn(dataItem, valuePath, divID);
        return "<div class=\"rps-number-editor-input\" id=\"" + divID + "\"></div>";
    }

    export function loadAddressColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadAddressColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadAddressValueColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadAddressValueColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadDurationEditorColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadDurationEditorColumn(dataItem, valuePath, divID);
        return "<div class=\"rps-number-editor-input\" id=\"" + divID + "\"></div>";
    }

    export function loadImageEditorColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadImageEditorColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadButtonColumn(rpsGridID: string, dataItem: any, valuePath: string, buttonTemplate: string, buttonLabel: string, buttonIcon: string, type: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadButtonColumn(dataItem, valuePath, divID, buttonTemplate, buttonLabel, buttonIcon, type);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadColorEditorColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadColorEditorColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadEntityLinkColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadEntityLinkColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadFormattedNumberColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadFormattedNumberColumn(dataItem, valuePath, divID);
        return "<div class=\"rps-number-editor-input\" id=\"" + divID + "\"></div>";
    }

    export function loadUrlColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadUrlColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }

    export function loadMailtoColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadMailtoColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }
    export function loadLongTextBoxLittleColumn(rpsGridID: string, dataItem: any, valuePath: string) {
        var divID = rps.guid.newGuid();
        if (rps.ui.itemsControl[rpsGridID])
            rps.ui.itemsControl[rpsGridID].loadLongTextBoxLittleColumn(dataItem, valuePath, divID);
        return "<div id=\"" + divID + "\"></div>";
    }
}