module rps.entities {
    export interface IPropertyPatch {
        Path: string;
        Operation: PatchOperationType;
        OldValue: any;
        NewValue: any;
    }

    export enum PatchOperationType {
        Replace,
        Delete,
        Add
    }
} 