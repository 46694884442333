/// <reference path="../baseVM.ts" />

module rps.viewmodels.adapters {
    export class PivotGridAdapter {
        private _fields: Array<PivotGridAdapterField>;
        get fields(): Array<PivotGridAdapterField> {
            return this._fields;
        }

        private _columns: Array<PivotGridAdapterField>;
        get columns(): Array<PivotGridAdapterField> {
            return this._columns;
        }

        private _rows: Array<PivotGridAdapterField>;
        get rows(): Array<PivotGridAdapterField> {
            return this._rows;
        }

        private _measures: Array<PivotGridAdapterField>;
        get measures(): Array<PivotGridAdapterField> {
            return this._measures;
        }

        private _data: Array<any>;
        get data(): Array<any> {
            return this._data;
        }

        constructor(params: {
            fields: Array<PivotGridAdapterField>,
            columns: Array<PivotGridAdapterField>,
            rows: Array<PivotGridAdapterField>,
            measures: Array<PivotGridAdapterField>,
            data:Array<any>
        }) {
            this._fields = params.fields;
            this._columns = params.columns;
            this._rows = params.rows;
            this._measures = params.measures;
            this._data = params.data;
        }
    }

    export interface PivotGridAdapterField {
        field: string;
        dataType: PivotGridAdapterFieldDataTypes;
        title: string;
        type: PivotGridAdapterFieldTypes;
    }

    export enum PivotGridAdapterFieldDataTypes {
        string = 0,
        number = 1
    }

    export enum PivotGridAdapterFieldTypes {
        dimension = 0,
        measure = 1
    }
}