/// <reference path="../baseVM.ts" />

module rps.viewmodels.adapters {
    export class SchedulerAdapterBase extends BaseVM {
        allowCreate: boolean = false;
        allowMoveItem: boolean =false;
        allowEditItem: boolean=false;
        allowRemoveItem: boolean=false;
        querySource: rps.data.sources.ChronologicalQuerySource;

        initialize(parameters: InitParams): Promise<SchedulerAdapterBase> {            
            return super.initialize(parameters);
        }

        createItem(startDate: Date, endDate: Date): void {
        }

        moveItem(relatedVM: rps.viewmodels.ModelVM, startDate: Date, endDate: Date): Promise<any> {
            return Promise.reject<any>(this);
        }

        editItem(relatedVM: rps.viewmodels.ModelVM): void {            
        }
	
        removeItem(relatedVM: rps.viewmodels.ModelVM): Promise<any> {
            return Promise.reject<any>(this);
        }        
    }

    export abstract class SchedulerAdapter<T extends rps.viewmodels.BaseVM> extends SchedulerAdapterBase{
        protected vm: T;

        initialize(parameters: InitParams): Promise<SchedulerAdapter<T>> {
            return super.initialize(parameters).then(() => {
                this.vm = <any>parameters.vm;
                this.querySource = this.getQuerySource();
                return this;
            });
        }

        abstract getQuerySource(): rps.data.sources.ChronologicalQuerySource;
    }
}