module rps.app.resources {
    export class validation {
        public static ERR_UTILS_VALIDATE_INCORRECT_FORMAT: string = 'The value entered in the {0} field is not valid for the invariant culture format.';
        public static ERR_UTILS_VALIDATE_DATATYPE: string = 'The values entered in the {0} field does not match the datatype of label.';
        public static ERR_UTILS_VALIDATE_LOWER: string = 'Lower limit greater than upper limit.';
        public static ERR_UTILS_VALIDATE_DEFAULT: string = 'Default value is outside of determined limits.';
        public static ERR_UTILS_VALIDATE_VALUE_OBLIGATORYNOTEXIST: string = 'Obligatory to set the variable data.';
        public static ERR_UTILS_VALIDATE_VALUE_TIPOVALUES: string = 'The values entered in the limits and / or in default values do not match with the datatype of label.';
        public static ERR_UTILS_VALIDATE_VALUE_OUT_LIMITS: string = '{0}: Variable data has value assigned that is not within the permitted limits specified in its label definition ({1} - {2}).';
        public static ERR_UTILS_OPERAND_NOT_CORRECT: string = 'The values of the parameters used in the function are not correct.';        
    }

    export class errors {
        public static ERR_EXECUTING_QUERY: string = 'Error executing query: {0}';
        public static ERR_UPLOADING_FILE: string = 'Error uploading file';
        public static ERR_ENTITY_FACTORY: string = 'Entity factory error: {0}';
        public static ERR_VALUE_REQUIRED: string = 'Value is required for property: {0}';
        public static ERR_MAX_LENGTH: string = 'Please enter no more than {0} characters on the property: {1}';
        public static ERR_MAX_VALUE: string = 'Please enter a number less than {0} on the property: {1}';
        public static ERR_RULE_CONDITIONS_DEFINED: string = 'Conditions block has already been defined';
        public static ERR_RULE_THEN_DEFINED: string = 'Then block has already been defined';
        public static ERR_RULE_ELSE_DEFINED: string = 'Else block has already been defined';
        public static ERR_ERROR_EXECUTING_RULE: string = 'An error occurred executing rule {0}: {1}';
        public static ERR_ERROR_EXECUTING_API_ACTION: string = 'An error occurred executing an API Action';
        public static ERR_FILTER_NOT_COMPLETE: string = 'The filter is not complete';
        public static ERR_CANNOT_MERGE: string = 'An unresolvable conflict was found while saving data; please, refresh you local data to continue';
        public static ERR_NOT_FOUND: string = '404. That\'s an error.';
        public static ERR_NOT_FOUND_DESCRIPTION: string = 'The requested URL was not found on this server. That\'s all we know.';
        public static ERR_OTHER_COMPANY_DATA: string = 'You are trying to view data from another company; please, log in that company to continue.';
        public static ERR_FORBIDDEN: string = 'You don\'t have permissions to execute this action';
        public static ERR_REGISTRY_DOES_NOT_EXIST: string = 'The requested registry ID does not exist.';
    }

    export class messages {
        public static MSG_ASK_SAVE_CHANGES: string = 'You have unsaved data; do you want to save before exiting?';
        public static MSG_ASK_SAVE_CHANGES_RELOAD: string = 'You have unsaved data; do you want to save before reload?';
        public static MSG_SAVE_CHANGES: string = 'Yes, save';
        public static MSG_DISCARD_CHANGES: string = 'No, discard changes';
        public static MSG_ENTITY_NOT_REQUIRED_CRITERIA: string = 'The selected entity doesn\'t fulfill the required criteria'
        public static MSG_ANY_ENTITY_NOT_REQUIRED_CRITERIA: string = 'Some entities don\'t fulfill the required criteria'
        public static MSG_ENTITY_NOT_SAVED: string = 'The entity must be saved';
        public static MSG_ASK_DELETE_ITEM: string = "Are you sure you want to delete this item?";
        public static MSG_NO_PENDING_CHANGES: string = "No pending changes";
        public static MSG_UNLOADED_SOURCE: string = "Unloaded data source";        
        public static MSG_ITEM_SAVED_TO_UPDATE_IMAGE: string = "The item must be saved to update the image";
        public static MSG_NO_NAVIGABLE_ITEM: string = "You can not navigate to any screen with this element";                
        public static MSG_ASK_CANCEL_EDITING_FILTER: string = "You are already editing a filter; do you want to cancel editing?";
        public static MSG_EDITING_FILTER: string = "You are editing a filter";
        public static MSG_NO_FILTERS: string = "You don\'t have any filter";
        public static MSG_FILTERS_NAME_REQUIRED: string = "The filters group name is required";
        public static MSG_OVERWRITE_FILTES_GROUP: string = "\"{0}\" filters group already exists; Do you want to overwrite it?";
        public static MSG_UNHANDLER_EXCEPTION: string = "Error";
    }

    export class directives {
        public static OK: string = 'Ok';
        public static YES: string = 'Yes';
        public static NO: string = 'No';
        public static UPLOAD: string = 'Upload...';
        public static EXECUTE: string = 'Execute';
        public static REFRESH: string = 'Refresh';
        public static DELETE: string = 'Delete';
        public static DETACH: string = 'Detach document';
        public static EDIT: string = 'Edit';
        public static TARGET_CATEGORY: string = 'Target category';
        public static TARGET_TYPE: string = 'Document type';
        public static DOCUMENT_TO_ATTACH: string = 'Document to attach';
        public static ACCEPT: string = 'Accept';
        public static CANCEL: string = 'Cancel';
        public static CLOSE: string = 'Close';
        public static VALUE: string = 'Value';
        public static ENTITY: string = 'Entity';
        public static PROPERTY: string = 'Property';
        public static FILTER_HEADER: string = 'Where';
        public static ADD: string = 'Add';
        public static APPLY: string = 'Apply';
        public static COMPARISON_OPERATOR: string = 'Comparison operator';
        public static QUERY: string = 'Query';
        public static INCORRECT_FILTERS: string = "Incorrect Filters";
        public static FILE_UPLOADED: string = "File uploaded";
        public static ENTITY_SAVED: string = "Entity saved";
        public static ENTITY_DELETED: string = "Entity deleted";
        public static NEW: string = "New";
        public static N_ATTACHMENTS: string = "{0} attachments";
        public static NEXT: string = "Next";
        public static PREVIOUS: string = "Previous";
        public static SAVE: string = "Save";
        public static FILTER: string = "Filter";
        public static FILTERS: string = "Filters";
        public static RESULT: string = "Result";
        public static SELECT: string = "Select";        
        public static SHOW_ALL: string = "Show all";        
        public static SELECT_ALL: string = "Select all";
        public static EXCEL_EXPORT: string = "Export to Excel";
        public static UNSELECT_ALL: string = "Unselect all";
        public static NO_ITEMS_TO_SELECT: string = "There are no items to select";
        public static ALL_ITEMS_SELECTED: string = "All items are selected";
        public static NO_ITEMS_TO_UNSELECT: string = "There are no items to unselect";        
        public static ALL_ITEMS_UNSELECTED: string = "All items are unselected";
        public static FILL_REQUIRED_PARAMETERS: string = "Fill required parameters";        
        public static BACK: string = "Back";
        public static NO_ITEMS_TO_DISPLAY: string = "No items to display";
        public static ITEMS_COUNT: string = "{0} of {1} items";
        public static UNLOADED_QUERY: string = "Run a query";
        public static SELECT_AN_ITEM: string = "Select an item";  
        public static IMAGE_EDITOR: string = "Image editor";
        public static NAVIGATE_TO_COMPONENT: string = "Navigate";
        public static CHANGE_IMAGE: string = "Change image";
        public static REMOVE_IMAGE: string = "Remove image";
        public static GRID_VIEW: string = "Grid view";
        public static CARD_VIEW: string = "Card view";
        public static ADD_FILTER: string = "Add filter";
        public static LOAD_FILTERS: string = "Load filters";
        public static SAVE_FILTERS: string = "Save filters";
        public static CLAER_FILTERS: string = "Clear filters";
        public static PROJECT_VIEW: string = "Project";
        public static RESOURCE_VIEW: string = "Resource";
        public static TREELIST_VIEW: string = "Tree List";        
        public static FILTERS_NAME: string = "Filters group name";
        public static FILTERS_SAVED: string = "Filters group saved";
        public static VIEW_DEFINITION_MY_VIEWS: string = "My views";        
        public static EDIT_VIEW: string = "Edit view";    
        public static VIEWS: string = "Views";
        public static CURRENT_QUERY: string = "Current query";
        public static CLOSE_DETAIL: string = "Close detail";
        public static SHOW_DETAIL_WINDOW: string = "Show in a popup window";
        public static SHOW_DETAIL_FORM: string = "Show in the form";
        public static SHOW_DETAIL_ROW_GRID: string = "Show details";
        public static DELETE_ROW_GRID: string = "Delete";
        public static NAVIGATE_ROW_GRID: string = "Navigate";
        public static ICON_EDITABLE_GRID: string = "Editable";
        public static BUTTON_NAVIGABLE_TOOLTIP: string = "Navigate";
        public static SEARCH_ENTITY_WINDOW: string = "Search Item";
        public static SEARCH_ENTITIES_WINDOW: string = "Search Items";
        public static SEARCH_ITEM_WINDOW: string = "Search item";
        public static NAVIGATE_ENTITY_WINDOW: string = "Navigate to item";
        public static FILE_UPLOADER_WINDOW: string = "Upload file";
        public static QUICK_LAUNCH_PLACE_HOLDER: string = "Quick Search";
        public static MSG_DELETE_DOCUMENT: string = "Delete selected document?";
        public static MSG_WARN_RELATED_ENTITIES: string = "The document is attached to other {0} entities. ";
        public static NEW_NOTIFICATION: string = "New notification";
        public static CLEAR_ALL_NOTIFICATIONS = "Clear all";
        public static EXPAND = "Expand";
        public static COLLAPSE = "Collapse";
        public static SEARCH = "Search";
        public static SHORTCUT_LIST_WINDOW: string = "Shortcut list";
        public static TODAY: string = "Today";
        public static FIRST_DAY_OF_MONTH: string = "First day of month";
        public static LAST_DAY_OF_MONTH: string = "Last day of month";
        public static NAVIGATION: string = "Navigation";
        public static DATE_SPECIFIC: string = "Dates";
        public static GENERAL: string = "General";
        public static PRINT: string = "Print";
        public static ATTACHMENTS: string = "Attachments";
        public static DOCUMENT_MANAGER_EXPLORER: string = "Document manager explorer";        
    }

    export class gantt {
        public static PROJECT_VIEW: string = "Project";
        public static RESOURCE_VIEW: string = "Resource";
        public static TREELIST_VIEW: string = "Tree list";
        public static VIEW_DAY: string = "Day";
        public static VIEW_WEEK: string = "Week";
        public static VIEW_MONTH: string = "Month";
        public static VIEW_YEAR: string = "Year";
        public static FIT_ALL: string = "Fit All";
        public static FIT_SELECTION: string = "Fit Selection";
        public static TITLE: string = "Title";
        public static START_TIME: string = "Start Time";
        public static END_TIME: string = "End Time";
        public static TT_TASK_START: string = "Start";
        public static TT_TASK_END: string = "End";
        public static TT_TASK_EMPLOYEE: string = "R. Employee";
        public static TT_TASK_MACHINE: string = "R. Machine";
        public static TT_TASK_TOOL: string = "R. Tool";
        public static TASK_DELETE_CONFIRM: string = "Are you sure you want to delete this task?";
        public static DEPENDENCY_DELETE_CONFIRM: string = "Are you sure you want to delete this dependency?";
    }

    export class comparisonOperators {
        public static EQ: string = 'equals';
        public static EQS: string = 'equals session parameter';
        public static NE: string = 'not equal to';
        public static GT: string = 'greater than';
        public static LT: string = 'less than';
        public static GE: string = 'greater than or equal to';
        public static LE: string = 'less than or equal to';
        public static LK: string = 'contains';
        public static SW: string = 'starts with';
        public static EW: string = 'ends with';
        public static ISNULL: string = 'doesn´t contain value';
        public static ISNOTNULL: string = 'contains value';
        public static ISTRUE: string = 'is true';
        public static ISFALSE: string = 'is false';
        public static IN: string = 'equals';
        public static NI: string = 'not equal to';
        public static TODAY: string = 'today';
        public static YESTERDAY: string = 'yesterday';
        public static TOMORROW: string = 'tomorrow';
        public static THIS_WEEK: string = 'this week';
        public static LAST_WEEK: string = 'last week';
        public static NEXT_WEEK: string = 'next week';
        public static THIS_MONTH: string = 'this month';
        public static LAST_MONTH: string = 'last month';
        public static NEXT_MONTH: string = 'next month';
        public static THIS_YEAR: string = 'this year';
        public static LAST_YEAR: string = 'last year';
        public static NEXT_YEAR: string = 'next year';
    }

    export class commonResources {
        public static ADDRESS: string = 'Address';
        public static BASIC_DATA: string = 'Basic data';
        public static COMMENTS: string = 'Comments';
        public static CONFIGURATION: string = 'Configuration';
        public static DATE_FROM: string = 'Date from';
        public static DATE_TO: string = 'Date to';
        public static DETAILS: string = 'Details';
        public static ENTITYSAVE: string = 'Save the changes';
        public static FILTERS: string = 'Filters';
        public static GENERAL_DATA: string = 'General data';
        public static MSG_REQUIREDFIELDS: string = 'The required fields must be filled';
        public static MSG_SAVEBEFORE: string = 'The pending changes must be saved before doing this action.';
        public static NEW: string = 'New';
        public static QUERY: string = 'Query';
        public static AND: string = "and";
        public static MSG_SELECTED_RECORDS: string = 'Select at least one record';
        public static MSG_READ_ONLY: string = "It is read-only";
    }

    export class services {
        public static MSG_PROCESS_COMPLETED_SUCCESSFULLY: string = 'Process completed successfully';
        public static MSG_PROCESS_COMPLETED_WITH_ERRORS: string = 'Process completed with errors';
        public static PROCESS_ERROR: string = 'Error';
        public static PROCESS_ERRORS: string = 'Errors';
        public static PROCESS_SUCCESSFULL: string = 'Ok';
        public static SHOW_DETAIL: string = 'Show detail';
        public static HIDE_DETAIL: string = 'Hide detail';        
        public static EXPORT: string = 'Export';
    }
}

module rps.app.resources.components {
    export class logon {
        public static USER: string = 'User';
        public static PASSWORD: string = 'Password';
        public static REMEMBER_ME: string = 'Keep me logged in';
        public static LOGON: string = 'Logon';
        public static LOGOUT: string = 'Logout';
    }

    export class settings {
        public static CHANGES_APPLIED: string = 'Changes applied';
        public static ERROR_CHANGING_DATA: string = 'Error changing data';
        public static PASSWORD_CHANGED: string = 'Password changed';
        public static ERROR_CHANGING_PASSWORD: string = 'Could not change password';
        public static NOT_LOGGED: string = 'Not logged in';
        public static SETTINGS: string = 'Settings';
    }

    export class menu {
        public static CUSTOMERS: string = 'Customers';
        public static ARTICLES: string = 'Articles';
        public static SUPPLIERS: string = 'Suppliers';
        public static FAVOURITES: string = 'Favourites';
        public static ADD_TO_FAVOURITES: string = 'Add to favourites';
        public static REMOVE_FROM_FAVOURITES: string = 'Remove from favourites';
    }
}