/// <reference path="..\viewmodels\observable.ts" />
/// <reference path="..\services\api.partial.ts" />

module rps.data.filters {

    /**
     * VM que maneja los filtros avanzados de las queries
     */
    /** @ignore */
    export class FiltersManager implements IDestroyable {
        public isIDestroyable: boolean = true;

        private editingFilterBooleanChanging: boolean = false;

        public savedFilters: Array<rps.data.FilterDefinition> = new Array<rps.data.FilterDefinition>();
        public entityName: string;
        public allowedFilters: Array<filters.FilterDefinition>;
        public search: rps.viewmodels.properties.VMProperty<string>;
        public filters: rps.viewmodels.properties.CollectionProperty<filters.Filter>;        
        public filtersChanged: rps.services.IEventEmitter<FiltersManager> = rps.app.eventManager.createEmitter<FiltersManager>();
        public addNewFilterCommand: rps.viewmodels.commands.CommandProperty;
        public addNewFilterBoolean: rps.viewmodels.properties.VMProperty<boolean>;
        public editingFilter: filters.Filter;
        public validateEditingFilterCommand: rps.viewmodels.commands.CommandProperty;        
        public cancelEditingFilterCommand: rps.viewmodels.commands.CommandProperty;
        public saveFiltersCommand: rps.viewmodels.commands.CommandProperty;        
        public clearFiltersCommand: rps.viewmodels.commands.CommandProperty;        
        public allowLoadFilters: boolean = false;
        public savedFiltersChanged = rps.app.eventManager.createEmitter<Array<rps.data.FilterDefinition>>();

        constructor(params: {
            entityName: string;   
        }) {
            this.entityName = params.entityName;

            this.search = new rps.viewmodels.properties.VMProperty<string>({ target: this, initialValue: "" });
            this.filters = new rps.viewmodels.properties.CollectionProperty<filters.Filter>({ target: this, initialValue: new rps.viewmodels.ObservableArray<filters.Filter>() });
            this.addNewFilterCommand = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: this.addNewFilter
            });
            //Se mantiene una propiedad boleana, para poder añadir en la UI, un toggleButton con el bool a true, mientras se está editando.
            this.addNewFilterBoolean = new rps.viewmodels.properties.VMProperty<boolean>({
                target: this,
                initialValue: false
            });
            this.addNewFilterBoolean.propertyChanged.subscribe((value: viewmodels.properties.VMPropertyChange) => {
                if (!this.editingFilterBooleanChanging) {
                    if (value.propertyName == "value") {
                        //Si pasa de true a false, se intenta añadir un nuevo filtro
                        //Si no, intenta validar el que ya se está editando
                        if (value.newValue) {
                            this.addNewFilterCommand.execute().catch(() => {
                                this.editingFilterBooleanChanging = true;
                                this.addNewFilterBoolean.value = false;
                                this.editingFilterBooleanChanging = false;
                            });
                        }
                        else {
                            setTimeout(() => {
                                this.editingFilterBooleanChanging = true;
                                this.addNewFilterBoolean.value = true;
                                this.editingFilterBooleanChanging = false;
                            });
                        }
                    }
                }
            });
            this.validateEditingFilterCommand = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: this.validateEditingFilter,
                canExecute: this.canValidateEditingFilter
            });
            this.cancelEditingFilterCommand = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: this.cancelEditingFilter
            });

            this.saveFiltersCommand = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: this.saveFilters,
                canExecute: this.canSaveFilters
            });

            this.clearFiltersCommand = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: this.clearFilters,
                canExecute: this.canClearFilters
            });

            this.fillSavedFilters();
        }

        onDestroy() {
            this.filtersChanged.onDestroy();
            this.savedFiltersChanged.onDestroy();
            if (this.search)
                this.search.onDestroy();
            if (this.addNewFilterBoolean)
                this.addNewFilterBoolean.onDestroy();
        }

        fillSavedFilters(): Promise<any>{
            //Se consultan los grupos de filtros guardados para este tipo de entidad
            return rps.app.clientAPI.get({
                url: string.format("{0}/userfilters", this.entityName)
            }).then((result: Array<rps.data.FilterDefinition>) => {
                //Se guarda el resultado en una variable local y si hay algún elemento, se habilita el botón que carga los grupos de filtros
                if (result) {
                    result.forEach((ri) => {
                        this.savedFilters.push(ri);
                    });
                }
                if (this.savedFilters.length > 0)
                    this.allowLoadFilters = true;
                this.savedFiltersChanged.emit(this.savedFilters);
            });
        }

        addSavedFilter(filterDefinition:rps.data.FilterDefinition) {
            this.savedFilters.push(filterDefinition);
            if (this.savedFilters.length > 0)
                this.allowLoadFilters = true;
            this.savedFiltersChanged.emit(this.savedFilters);
        }

        replaceSavedFilter(filterDefinition: rps.data.FilterDefinition) {
            var oldFilterDefinition: rps.data.FilterDefinition = Enumerable.From<rps.data.FilterDefinition>(this.savedFilters).FirstOrDefault(null, sf => sf.Name == filterDefinition.Name);
            if (oldFilterDefinition) {
                this.savedFilters[this.savedFilters.indexOf(oldFilterDefinition)] = filterDefinition;
                this.savedFiltersChanged.emit(this.savedFilters);
            }
            else
                this.addSavedFilter(filterDefinition);
        }

        removeSavedFilter(filterName: string) {            
            //Se crea un nuevo grupo de filtros, solo con el nombre, para que no viaje todo el objeto para borrarse
            var newFilterDefinition = new rps.data.FilterDefinition();
            newFilterDefinition.Name = filterName;
            //El servicio, requiere un string con el JSon de la definición, no el objeto.
            var filterDefinitionString: string = JSON.stringify(newFilterDefinition).toString();;

            return rps.app.clientAPI.delete({
                url: string.format("{0}/userfilters", this.entityName),
                body: filterDefinitionString,
            }).then(() => {
                //Se busca el grupo de filtros precargado
                var filterDefinition: rps.data.FilterDefinition = Enumerable.From<rps.data.FilterDefinition>(this.savedFilters).First((sf) => sf.Name == filterName);
                //Eliminar el grupo de filtros de la lista precargada
                this.savedFilters.splice(this.savedFilters.indexOf(filterDefinition), 1);
                if (this.savedFilters.length == 0)
                    this.allowLoadFilters = false;
                this.savedFiltersChanged.emit(this.savedFilters);
            });
        }

        loadSavedFilter(filterName: string): Promise<any> {
            var filterDefinition: rps.data.FilterDefinition = Enumerable.From<rps.data.FilterDefinition>(this.savedFilters).First((sf) => sf.Name == filterName);
            return this.setFilterDefinition(filterDefinition);
        }

        ensureAllowedFiltersLoaded(): Promise<any> {
            if (!this.allowedFilters) {
                //Se pueden obtener los filtros específicos de la query o de la entidad, ya que todas las queries de la misma entidad, tienen los mismos filtros
                var docURL: string = string.format("/{0}/entities/{1}/filters", this.entityName.split("/")[0], this.entityName.split("/")[1]);
                return rps.app.apiRef.get({
                    url: docURL
                }).then((queryDoc: Array<sources.IQueryFilterDocumentation>) => {
                    this.allowedFilters = filters.FilterDefinition.createFilterDefinition({ QueryDocumentation: queryDoc });
                });
            }
            else {
                return Promise.resolve<any>(this);
            }
        }

        public deleteFilter(filter: filters.Filter): Promise<any> {
            return this.canEditFilter().then((canEditFilter: boolean) => {
                if (canEditFilter) {
                    this.filters.value.splice(this.filters.value.indexOf(filter), 1);
                    this.filtersChanged.emit(this);
                }
            });
        }

        public editFilter(filter: filters.Filter): Promise<any> {
            return this.canEditFilter().then((canEditFilter: boolean) => {
                if (canEditFilter)
                    this.editingFilter = filter.clone();
                else {
                    //Si no se puede crear/editar un filtro, lanza una excepción, para revertir el toggleButton
                    throw "Other filter is being edited";
                }
            });                        
        }

        addNewFilter(): Promise<any> {
            return this.canEditFilter().then((canEditFilter) => {
                if (canEditFilter) {
                    return this.ensureAllowedFiltersLoaded().then(() => {
                        this.editingFilter = new filters.Filter({
                            filtersManager: this,
                            relatedItems: this.allowedFilters
                        });
                    });
                }
                else {
                    //Si no se puede crear/editar un filtro, lanza una excepción, para revertir el toggleButton
                    throw "Other filter is being edited";
                }
            });
        }

        /**
         * Devuelve una promesa boleana, indicando, si se puede pasar a la edición de un filtro o no
         */
        canEditFilter(): Promise<boolean> {
            if (rps.object.hasValue(this.editingFilter))
                return rps.app.messageManager.show({
                    message: rps.app.resources.messages.MSG_ASK_CANCEL_EDITING_FILTER,
                    messageButton: rps.services.MessageButton.YesNo,
                    messageType: rps.services.MessageType.Question,
                    yesOptions: { isPrimary: true }
                }).then((result: rps.services.MessageResult) => {
                    if (result == rps.services.MessageResult.Yes)
                        return this.cancelEditingFilterCommand.execute().then(() => {
                            return true;
                        });
                    else
                        return false;
                });
            else
                return Promise.resolve<boolean>(true);
        }

        validateEditingFilter(): Promise<any> {
            if (!this.editingFilter.idFilterDefinition.hasValue() || !this.editingFilter.comparisonOperator.getSelectedItem() || !this.editingFilter.value.hasValue()) {
                return rps.app.messageManager.show({
                    message: rps.app.resources.directives.INCORRECT_FILTERS,
                    messageButton: rps.services.MessageButton.Ok,
                    messageType: rps.services.MessageType.Error
                });
            }
            else {
                //Si contiene un valor en el originalFilter, significa que es una copia de este, por lo que es una edición de un filtro ya existente
                //En este caso se reemplaza el elemento
                if (this.editingFilter.originalFilter) {
                    this.filters.value[this.filters.value.indexOf(this.editingFilter.originalFilter)] = this.editingFilter;
                    this.editingFilter.originalFilter = null;
                }
                else {
                    this.filters.value.push(this.editingFilter);
                    this.editingFilterBooleanChanging = true;
                    this.addNewFilterBoolean.value = false;
                    this.editingFilterBooleanChanging = false;
                }
                this.editingFilter = null;
                this.filtersChanged.emit(this);
                return Promise.resolve(this);
            }
        }

        canValidateEditingFilter(): rps.viewmodels.commands.CanExecuteResult {
            if (!this.editingFilter ||
                !this.editingFilter.idFilterDefinition ||
                !this.editingFilter.idFilterDefinition.hasValue() ||
                !this.editingFilter.comparisonOperator ||
                !this.editingFilter.comparisonOperator.getSelectedItem() ||
                !this.editingFilter.value.hasValue())
                return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.errors.ERR_FILTER_NOT_COMPLETE]);
            else
                return rps.viewmodels.commands.CanExecuteResult.allow();
        }

        saveFilters(): Promise<any> {
            return rps.app.uiFactory.showWindow({
                template: `<rps-window [rpsOpen]='vm.isOpen'             rpsHorizontalSize="6">     <rps-rows-group>         <rps-row>             <rps-rows-group>                 <rps-row>                     <rps-combo-box rpsColumns="3"                                    rpsLabel="{{$root.resources.directives.FILTERS_NAME}}"                                    [rpsModel]="vm.name"                                    rpsEditable="true">                     </rps-combo-box>                 </rps-row>             </rps-rows-group>         </rps-row>         <rps-row>             <rps-button rpsColumns="3"                         rpsLabel="{{$root.resources.directives.ACCEPT}}"                         [rpsModel]="vm.acceptCommand"                         rpsType="PRIMARY"></rps-button>             <rps-button rpsColumns="3"                          rpsLabel="{{$root.resources.directives.CANCEL}}"                          [rpsModel]="vm.cancelCommand">             </rps-button>         </rps-row>     </rps-rows-group> </rps-window>`,
                viewModel: new SaveFilters(this)
            });
        }

        canSaveFilters(): rps.viewmodels.commands.CanExecuteResult {
            if (this.filters.value.length > 0 && !rps.object.hasValue(this.editingFilter))
                return rps.viewmodels.commands.CanExecuteResult.allow();
            else if (this.filters.value.length <= 0)
                return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.messages.MSG_NO_FILTERS]);
            else
                return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.messages.MSG_EDITING_FILTER]);
        }

        clearFilters(): Promise<any> {
            this.filters.cleanValue();
            this.filtersChanged.emit(this); 
            return Promise.resolve<any>(this);  
        }

        canClearFilters(): rps.viewmodels.commands.CanExecuteResult {
            if (this.hasFilters())
                return rps.viewmodels.commands.CanExecuteResult.allow();
            else (this.filters.value.length <= 0)
                return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.messages.MSG_NO_FILTERS]);
        }

        hasFilters(): boolean {
            if (this.filters && this.filters.value && this.filters.value.length > 0)
                return true;

            return false;
        }

        cancelEditingFilter(): Promise<any> {
            //Si contiene un valor en el originalFilter, significa que es una copia de este, por lo que es una edición de un filtro ya existente
            //En este caso se pone en modo no edición este filtro
            if (this.editingFilter.originalFilter) {                
                this.editingFilter.originalFilter.editingFilterBooleanChanging = true;
                this.editingFilter.originalFilter.editFilterBoolean.value = false;
                this.editingFilter.originalFilter.editingFilterBooleanChanging = false;
            }
            else {
                this.editingFilterBooleanChanging = true;
                this.addNewFilterBoolean.value = false;
                this.editingFilterBooleanChanging = false;
            }
            this.editingFilter = null;
            return Promise.resolve(this);
        }

        getFilterDefinition(name:string): rps.data.FilterDefinition {
            //Generar el objeto que requiere el servicio para guardar los filtros
            var filterDefinition: rps.data.FilterDefinition = new rps.data.FilterDefinition();
            filterDefinition.Name = name;

            //Se añade un grupo con and, ya que de momento, no se contempla la posibilidad de agrupar filtros
            var filterExpression: rps.data.FilterExpression = new rps.data.FilterExpression();
            filterExpression.OperatorType = rps.data.ConditionalOperatorTypes.And;
            filterExpression.Clauses = new Array<rps.data.IFilterClause>();

            //Nos recorremos los filtros para añadir el primer nivel de filtros
            this.filters.value.forEach((filter: filters.Filter) => {
                var filterClause: rps.data.FilterClause = new rps.data.FilterClause();
                filterClause.FilterSpec = filter.idFilterDefinition.value;
                filterClause.Operator = filter.comparisonOperator.value;
                filterClause.Value = filter.value.getParameterValue();

                filterExpression.Clauses.push(filterClause);
            });

            filterDefinition.FilterExpression = filterExpression;
            return filterDefinition;
        }

        setFilterDefinition(filterDefinition: rps.data.FilterDefinition): Promise<any> {
            return this.ensureAllowedFiltersLoaded().then(() => {
                //Comprobar que es un filtro soportado por la UI actual (Que sea un grupo simple unidos con AND)
                if (filterDefinition.FilterExpression != null &&
                    filterDefinition.FilterExpression.OperatorType == rps.data.ConditionalOperatorTypes.And &&
                    !Enumerable.From<rps.data.FilterExpression>(filterDefinition.FilterExpression.Clauses).Any(fc => rps.object.hasValue(fc.OperatorType))) {
                    //Se comprueba que ya exista algún filtro o que haya alguno nuevo
                    if (Enumerable.From<rps.data.FilterExpression>(filterDefinition.FilterExpression.Clauses).Any() || Enumerable.From<filters.Filter>(this.filters.value).Any()) {
                        //Eliminamos los ya existentes
                        this.filters.cleanValue();
                        //Nos recorremos cada una de las clausulas para aplicarlas
                        filterDefinition.FilterExpression.Clauses.forEach((filterClause: rps.data.FilterClause) => {
                            //Crear un nuevo filtro de UI
                            var filter: filters.Filter = new filters.Filter({
                                filtersManager: this,
                                relatedItems: this.allowedFilters
                            });
                            //Establecer todas las propiedades que hacen que el filtro sea aplicable
                            filter.idFilterDefinition.value = filterClause.FilterSpec;
                            filter.comparisonOperator.value = filterClause.Operator;
                            filter.value.setParameterValue(filterClause.Value);

                            //Añadirlo a la lista de filtros
                            this.filters.value.push(filter);
                        });

                        //Emitir el cambio, para que se enteren todos aquellos que lo requieran
                        this.filtersChanged.emit(this);
                    }
                }
                else
                    throw "Not implemented";
            });
        }

        getFilterParameters(): string {
            var filtersParam: string = "";
            this.filters.value.asEnumerable().ForEach((item) => {
                if (this.filters.value.indexOf(item) !== 0)
                    filtersParam += " and ";
                var comparisonOperator: rps.data.filters.ComparisonOperator = item.comparisonOperator.getSelectedItem();
                if (comparisonOperator) {
                    if (comparisonOperator.isRequired)
                        filtersParam += item.idFilterDefinition.value + " " + comparisonOperator.queryComparisonOperator + " " + item.value.getParameterValue();
                    else
                        filtersParam += item.idFilterDefinition.value + " " + comparisonOperator.queryComparisonOperator;
                }
            });
            return filtersParam;
        }
    }

    /**
     * VM de cada uno de los filtros aplicables
     */
    /** @ignore */
    export class FilterDefinition {
        public entityPropertyDocumentation: rps.data.sources.IQueryFilterDocumentation;
        public idFilterDefinition: string;
        public entity: string;
        public description: string;
        public fullDescription: string;

        constructor(params: {
            QueryFilterDocumentation: rps.data.sources.IQueryFilterDocumentation;
        }) {
            this.entityPropertyDocumentation = params.QueryFilterDocumentation;
            this.idFilterDefinition = params.QueryFilterDocumentation.Name;
            this.entity = params.QueryFilterDocumentation.Entity;
            this.description = params.QueryFilterDocumentation.Label;
            var rightArrow = '\u2192';
            if (params.QueryFilterDocumentation.Relation && params.QueryFilterDocumentation.Relation != "detail")
                this.fullDescription = rps.string.format("{0} {1} {2}", this.entity, rightArrow, this.description);
            else
                this.fullDescription = this.description;
        }

        /** @internal */
        getAllowedComparisonOperators(): Array<ComparisonOperator> {
            var allowedComparisonOperators: Array<ComparisonOperator> = new Array<ComparisonOperator>()
            allowedComparisonOperators = new Array<ComparisonOperator>();
            switch (this.entityPropertyDocumentation.Type) {
                case "string":
                    if (!this.entityPropertyDocumentation.ValueList) {
                        allowedComparisonOperators.push(new ComparisonOperator(true, "eq", "eq", rps.app.resources.comparisonOperators.EQ));
                        allowedComparisonOperators.push(new ComparisonOperator(true, "ne", "ne", rps.app.resources.comparisonOperators.NE));
                        allowedComparisonOperators.push(new ComparisonOperator(true, "lt", "lt", rps.app.resources.comparisonOperators.LT));
                        allowedComparisonOperators.push(new ComparisonOperator(true, "le", "le", rps.app.resources.comparisonOperators.LE));
                        allowedComparisonOperators.push(new ComparisonOperator(true, "gt", "gt", rps.app.resources.comparisonOperators.GT));
                        allowedComparisonOperators.push(new ComparisonOperator(true, "ge", "ge", rps.app.resources.comparisonOperators.GE));
                        allowedComparisonOperators.push(new ComparisonOperator(true, "lk", "lk", rps.app.resources.comparisonOperators.LK));
                        allowedComparisonOperators.push(new ComparisonOperator(true, "sw", "sw", rps.app.resources.comparisonOperators.SW));
                        allowedComparisonOperators.push(new ComparisonOperator(true, "ew", "ew", rps.app.resources.comparisonOperators.EW));
                        allowedComparisonOperators.push(new ComparisonOperator(false, "isNull", "eq null", rps.app.resources.comparisonOperators.ISNULL));
                        allowedComparisonOperators.push(new ComparisonOperator(false, "isNotNull", "ne null", rps.app.resources.comparisonOperators.ISNOTNULL));
                    }
                    else {
                        allowedComparisonOperators.push(new ComparisonOperator(true, "in", "in", rps.app.resources.comparisonOperators.IN));
                        allowedComparisonOperators.push(new ComparisonOperator(true, "ni", "ni", rps.app.resources.comparisonOperators.NI));
                    }
                    break;
                case "decimal":
                case "integer":
                case "int32":
                    allowedComparisonOperators.push(new ComparisonOperator(true, "eq", "eq", rps.app.resources.comparisonOperators.EQ));
                    allowedComparisonOperators.push(new ComparisonOperator(true, "ne", "ne", rps.app.resources.comparisonOperators.NE));
                    allowedComparisonOperators.push(new ComparisonOperator(true, "lt", "lt", rps.app.resources.comparisonOperators.LT));
                    allowedComparisonOperators.push(new ComparisonOperator(true, "le", "le", rps.app.resources.comparisonOperators.LE));
                    allowedComparisonOperators.push(new ComparisonOperator(true, "gt", "gt", rps.app.resources.comparisonOperators.GT));
                    allowedComparisonOperators.push(new ComparisonOperator(true, "ge", "ge", rps.app.resources.comparisonOperators.GE));  
                    break;
                case "boolean":
                    allowedComparisonOperators.push(new ComparisonOperator(true, "eq", "eq", rps.app.resources.comparisonOperators.EQ));
                    break;
                case "enum":
                    allowedComparisonOperators.push(new ComparisonOperator(true, "in", "in", rps.app.resources.comparisonOperators.IN));
                    allowedComparisonOperators.push(new ComparisonOperator(true, "ni", "ni", rps.app.resources.comparisonOperators.NI));
                    break;
                case "datetime":
                    allowedComparisonOperators.push(new ComparisonOperator(false, "tomorrow", "dt tomorrow", rps.app.resources.comparisonOperators.TOMORROW));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "today", "dt today", rps.app.resources.comparisonOperators.TODAY));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "yesterday", "dt yesterday", rps.app.resources.comparisonOperators.YESTERDAY));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "nextweek", "dt nextweek", rps.app.resources.comparisonOperators.NEXT_WEEK));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "thisweek", "dt thisweek", rps.app.resources.comparisonOperators.THIS_WEEK));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "lastweek", "dt lastweek", rps.app.resources.comparisonOperators.LAST_WEEK));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "nextmonth", "dt nextmonth", rps.app.resources.comparisonOperators.NEXT_MONTH));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "thismonth", "dt thismonth", rps.app.resources.comparisonOperators.THIS_MONTH));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "lastmonth", "dt lastmonth", rps.app.resources.comparisonOperators.LAST_MONTH));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "nextyear", "dt nextyear", rps.app.resources.comparisonOperators.NEXT_YEAR));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "thisyear", "dt thisyear", rps.app.resources.comparisonOperators.THIS_YEAR));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "lastyear", "dt lastyear", rps.app.resources.comparisonOperators.LAST_YEAR));
                    allowedComparisonOperators.push(new ComparisonOperator(true, "eq", "eq", rps.app.resources.comparisonOperators.EQ));
                    allowedComparisonOperators.push(new ComparisonOperator(true, "ne", "ne", rps.app.resources.comparisonOperators.NE));
                    allowedComparisonOperators.push(new ComparisonOperator(true, "lt", "lt", rps.app.resources.comparisonOperators.LT));
                    allowedComparisonOperators.push(new ComparisonOperator(true, "le", "le", rps.app.resources.comparisonOperators.LE));
                    allowedComparisonOperators.push(new ComparisonOperator(true, "gt", "gt", rps.app.resources.comparisonOperators.GT));                    
                    allowedComparisonOperators.push(new ComparisonOperator(true, "ge", "ge", rps.app.resources.comparisonOperators.GE));                    
                    allowedComparisonOperators.push(new ComparisonOperator(false, "isNull", "eq", rps.app.resources.comparisonOperators.ISNULL));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "isNotNull", "ne", rps.app.resources.comparisonOperators.ISNOTNULL));                                        
                    break;
                case "foreignkey":
                    allowedComparisonOperators.push(new ComparisonOperator(true, "in", "in", rps.app.resources.comparisonOperators.IN));
                    allowedComparisonOperators.push(new ComparisonOperator(true, "ni", "ni", rps.app.resources.comparisonOperators.NI));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "isNull", "eq null", rps.app.resources.comparisonOperators.ISNULL));
                    allowedComparisonOperators.push(new ComparisonOperator(false, "isNotNull", "ne null", rps.app.resources.comparisonOperators.ISNOTNULL));
                    break;
                default:
                    break;
            }
            return allowedComparisonOperators;
        }

        getDefaultComparisonOperatorValue(): string {            
            switch (this.entityPropertyDocumentation.Type) {
                case "string":
                    if (!this.entityPropertyDocumentation.ValueList)
                        return "lk";                
                    else
                        return "in";
                case "decimal":
                case "integer":
                case "int32":
                    return "eq";
                case "boolean":
                    return "eq";
                case "enum":
                    return "in";
                case "datetime":
                    return "today";
                case "foreignkey":
                    return "in";
                default:
                    return "";
            }
        }

        static createFilterDefinition(params: { QueryDocumentation: Array<sources.IQueryFilterDocumentation>; }): Array<FilterDefinition> {
            var newAllowedFilters: Array<FilterDefinition> = new Array<FilterDefinition>();
            params.QueryDocumentation.forEach((queryFilterDoc) => {
                switch (queryFilterDoc.Type) {
                    case "string":
                    case "decimal":
                    case "integer":
                    case "int32":
                    case "boolean":
                    case "enum":
                    case "datetime":
                    case 'foreignkey':
                        newAllowedFilters.push(new FilterDefinition({ QueryFilterDocumentation: queryFilterDoc }));
                        break;
                    default:
                        console.log("QueryFilter type '" + queryFilterDoc.Type + "' is not implemented");
                        break;
                }
            });
            return newAllowedFilters;
        }
    }    
    
    /** @ignore */
    export class Filter extends rps.viewmodels.ObservableObject {
        private filtersManager: FiltersManager;

        public editingFilterBooleanChanging: boolean = false;
        public originalFilter: Filter;        
        public relatedItems: Array<FilterDefinition>;        

        idFilterDefinition: rps.viewmodels.properties.ComboProperty;
        private idFilterDefinitionChanged() {
            var filterDefinition: FilterDefinition = this.idFilterDefinition.getSelectedItem();
            this.setFilterDefinition(filterDefinition);
        }
        private setFilterDefinition = (newValue: FilterDefinition) => {
            if (newValue) {
                this.comparisonOperator = new rps.viewmodels.properties.ComboProperty({
                    target: this,
                    initialValue: "",
                    relatedItems: newValue.getAllowedComparisonOperators(),
                    valuePath: "value",
                    displayPath: "description",
                    preventAlphabeticalOrder: true
                });
                this.comparisonOperator.propertyChanged.subscribe((value: rps.viewmodels.properties.VMPropertyChange) => {
                    if (value.propertyName == "value")
                        this.comparisonOperatorValueChanged();
                });                
                
                this.value.setEntityPropertyDocumentation(newValue.entityPropertyDocumentation);                
                this.comparisonOperator.value = newValue.getDefaultComparisonOperatorValue();
            }
            else {
                this.value.setEntityPropertyDocumentation(null);
                this.comparisonOperator = new rps.viewmodels.properties.ComboProperty({
                    target: this,
                    initialValue: "",
                    relatedItems: [],
                    valuePath: "value",
                    displayPath: "description"
                });
                this.comparisonOperator.isLocked = true;
            }
        }

        comparisonOperator: rps.viewmodels.properties.ComboProperty;
        comparisonOperatorValueChanged(){
            this.value.comparisonOperator = this.comparisonOperator.getSelectedItem();
        }

        value: variableEditor;

        deleteFilter: rps.viewmodels.commands.CommandProperty;

        editFilter: rps.viewmodels.commands.CommandProperty;        
        editFilterBoolean: rps.viewmodels.properties.VMProperty<boolean>;

        constructor(params: {
            filtersManager: FiltersManager;
            relatedItems: Array<FilterDefinition>;            
        }) {
            super();
            this.filtersManager = params.filtersManager;
            this.relatedItems = params.relatedItems;

            this.deleteFilter = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: (): Promise<any> => {
                    return this.filtersManager.deleteFilter(this);
                }
            });
            this.editFilter = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: (): Promise<any> => {
                    return this.filtersManager.editFilter(this);
                }
            });
            //Se mantiene una propiedad boleana, para poder añadir en la UI, un toggleButton con el bool a true, mientras se está editando.
            this.editFilterBoolean = new rps.viewmodels.properties.VMProperty<boolean>({
                target: this,
                initialValue: false
            });
            this.editFilterBoolean.propertyChanged.subscribe((value: viewmodels.properties.VMPropertyChange) => {
                if (!this.editingFilterBooleanChanging) {
                    if (value.propertyName == "value") {
                        //Si pasa de true a false, se intenta añadir un nuevo filtro
                        //Si no, intenta validar el que ya se está editando
                        if (value.newValue) {
                            this.editFilter.execute().catch(() => {
                                this.editingFilterBooleanChanging = true;
                                this.editFilterBoolean.value = false;
                                this.editingFilterBooleanChanging = false;
                            });
                        }
                        else {
                            setTimeout(() => {
                                this.editingFilterBooleanChanging = true;
                                this.editFilterBoolean.value = true;
                                this.editingFilterBooleanChanging = false;
                            });
                        }
                    }
                }
            });

            var entities: Array<{ value: string, description: string, items: Array<FilterDefinition> }> = new Array<{ value: string, description: string, items: Array<FilterDefinition> }>();
            Enumerable.From<FilterDefinition>(this.relatedItems).GroupBy(ri => ri.entity).forEach((entity) => {
                entities.push({
                    value: entity.Key(),
                    description: entity.Key(),
                    items: entity
                });
            });

            this.idFilterDefinition = new rps.viewmodels.properties.ComboProperty({
                target: this,
                initialValue: "",
                relatedItems: this.relatedItems,
                valuePath: "idFilterDefinition",
                displayPath: "fullDescription"
            });
            this.idFilterDefinition.propertyChanged.subscribe((value: rps.viewmodels.properties.VMPropertyChange) => {
                if (value.propertyName == "value")
                    this.idFilterDefinitionChanged();
            });            

            this.value = new variableEditor();

            this.setFilterDefinition(null);
        }

        public description(): string {
            try {
                var property: string = this.idFilterDefinition.description;
                var comparisonOperator: string = this.comparisonOperator.description;
                return property + " " + comparisonOperator + " " + this.value.getValueText() + "";
            }
            catch (ex) {
                return "Pending";
            }
        }

        /**
         * Método, que devuelve un clon de la instancia actual
         */
        public clone(): Filter {
            //Crear la nueva instancia                        
            var clone: Filter = new Filter({
                filtersManager: this.filtersManager,
                relatedItems: this.relatedItems
            });

            //Copiar el valor de todas las propiedades
            clone.originalFilter = this;
            clone.idFilterDefinition.value = this.idFilterDefinition.value;
            clone.comparisonOperator.value = this.comparisonOperator.value;
            clone.value = this.value.clone();

            return clone;
        }
    }

    /** @ignore */
    export class ComparisonOperator {
        public isRequired: boolean;
        public value: string;
        public queryComparisonOperator: string;
        public description: string;

        constructor(isRequired: boolean, value: string, queryComparisonOperator: string, description: string) {
            this.isRequired = isRequired;
            this.value = value;
            this.queryComparisonOperator = queryComparisonOperator;
            this.description = description;
        }
    }

    /** @ignore */
    export class variableEditor {
        public valueChanged: rps.services.IEventEmitter<any> = rps.app.eventManager.createEmitter(false);
        public resolvedPropertyType: string = "Empty";

        public valueList: Array<sources.IValueListItem>;
        public fkEntity: string;
        private _propertyType: string;
        public get propertyType(): string {
            return this._propertyType;
        }
        public set propertyType(newValue: string) {
            if (this._propertyType != newValue) {
                this._propertyType = newValue;
                this.refreshVMProperties();
            }
        }

        setEntityPropertyDocumentation(queryFilterDocumentation: rps.data.sources.IQueryFilterDocumentation) {
            //Se limpia el comparisonOperator, ya que este es un combo en cascada y así se evita el que el refreshVMProperties, genere editores innecesariamente
            this.comparisonOperator = null;
            if (queryFilterDocumentation) {                
                this.valueList = queryFilterDocumentation.ValueList;
                this.fkEntity = queryFilterDocumentation.FKEntity;
                this.propertyType = queryFilterDocumentation.Type;
            }
            else {
                this.valueList = null;
                this.propertyType = null;
            }
        }

        clearVMProperties() {
            this.valueString = null;
            this.valueMultiComboBox = null;
            this.valueMultiComboBox = null;
            this.valueDecimal = null;
            this.valueInteger = null;
            this.valueBoolean = null;
            this.valueMultiEnum = null;
            this.valueDatetime = null;
            this.valueMultiLookup = null;
            this.valueMultiLookupSource = null;
        }

        refreshVMProperties() {

            //En función del comparador, necesitamos la sección para establecer valores o no
            if (this._comparisonOperator)
                this.isRequired = this._comparisonOperator.isRequired;
            else
                this.isRequired = false;

            //Limpiar todas las propiedades, luego se establece la correcta
            this.clearVMProperties();            

            //Si requiere editor, se establece el tipo de editor y se rellenan las vmProperties que necesita
            if (this.isRequired) {
                switch (this.propertyType) {
                    case "string":
                        if (!this.valueList) {
                            this.resolvedPropertyType = this.propertyType;
                            this.valueString = new rps.viewmodels.properties.VMProperty({ target: this, initialValue: "" });
                            this.valueString.propertyChanged.subscribe((pc) => { if (pc.propertyName == "value") this.valueChanged.emit(this.valueString.value); });
                        }
                        else {
                            this.resolvedPropertyType = this.propertyType;
                            var valueComboBoxItems: Array<{ value: string, description: string }>;
                            valueComboBoxItems = new Array<{ value: string, description: string }>();
                            this.valueList.forEach((ValueListItem) => {
                                valueComboBoxItems.push({ value: ValueListItem.Value, description: ValueListItem.Value });
                            });
                            this.valueMultiComboBox = new rps.viewmodels.properties.MultiComboBoxProperty({
                                target: this,
                                comboBoxItems: valueComboBoxItems,
                                initialValue: []
                            });
                            break;
                        }
                        break;
                    case "decimal":
                        this.resolvedPropertyType = this.propertyType;
                        this.valueDecimal = new rps.viewmodels.properties.VMProperty({ target: this, initialValue: 0 });
                        this.valueDecimal.propertyChanged.subscribe((pc) => { if (pc.propertyName == "value") this.valueChanged.emit(this.valueDecimal.value); });
                        break;
                    case "integer":
                    case "int32":
                        this.resolvedPropertyType = this.propertyType;
                        this.valueInteger = new rps.viewmodels.properties.VMProperty({ target: this, initialValue: 0 });
                        this.valueInteger.propertyChanged.subscribe((pc) => { if (pc.propertyName == "value") this.valueChanged.emit(this.valueInteger.value); });
                        break;
                    case "boolean":
                        this.resolvedPropertyType = this.propertyType;
                        this.valueBoolean = new rps.viewmodels.properties.VMProperty({ target: this, initialValue: false });
                        this.valueBoolean.propertyChanged.subscribe((pc) => { if (pc.propertyName == "value") this.valueChanged.emit(this.valueBoolean.value); });
                        break;
                    case "enum":
                        this.resolvedPropertyType = this.propertyType;
                        var valueEnumItems: Array<{ value: number, description: string }>;
                        valueEnumItems = new Array<{ value: number, description: string }>();
                        this.valueList.forEach((ValueListItem) => {
                            valueEnumItems.push({ value: ValueListItem.Key, description: ValueListItem.Value });
                        });
                        this.valueMultiEnum = new rps.viewmodels.properties.MultiEnumProperty({
                            target: this,
                            enumItems: valueEnumItems,
                            initialValue: []
                        });
                        break;
                    case "datetime":
                        this.resolvedPropertyType = this.propertyType;
                        this.valueDatetime = new rps.viewmodels.properties.VMProperty({ target: this, initialValue: null });
                        this.valueDatetime.propertyChanged.subscribe((pc) => { if (pc.propertyName == "value") this.valueChanged.emit(this.valueDatetime.value); });
                        break;
                    case "foreignkey":
                        this.resolvedPropertyType = this.propertyType; 

                        //Rellenar una lista de parámetros, con el parámetro de la entidad, para crear el origen genérico de entidades
                        var queryParams: parameters.QueryParams = new parameters.QueryParams();
                        queryParams.push(new parameters.QueryParam("EntityTypeId", this.fkEntity));                        
                        this.valueMultiLookupSource = new rps.data.sources.LookupSource({
                            queryName: "security/GenericLookupSource",
                            queryParams: queryParams
                        });
                        //Cear la propiedad para el MultiLookup
                        this.valueMultiLookup = new rps.viewmodels.properties.MultiLookupProperty({
                            entityName: this.fkEntity,
                            target: this
                        });
                        break;
                    default:
                        this.resolvedPropertyType = "Empty";
                        break;
                }
            }
            else {
                this.resolvedPropertyType = "Empty";
            }
        }

        private _comparisonOperator: ComparisonOperator;
        get comparisonOperator(): ComparisonOperator {
            return this._comparisonOperator;
        }
        set comparisonOperator(newValue: ComparisonOperator) {
            if (this._comparisonOperator != newValue) {
                this._comparisonOperator = newValue
                this.refreshVMProperties();
            }
        }
        public isRequired: boolean = false;        
        public valueString: rps.viewmodels.properties.VMProperty<string>;
        public valueMultiComboBox: rps.viewmodels.properties.MultiComboBoxProperty;
        public valueDecimal: rps.viewmodels.properties.VMProperty<number>;
        public valueInteger: rps.viewmodels.properties.VMProperty<number>;
        public valueBoolean: rps.viewmodels.properties.VMProperty<boolean>;
        public valueMultiEnum: rps.viewmodels.properties.MultiEnumProperty;
        public valueDatetime: rps.viewmodels.properties.VMProperty<Date>;
        public valueMultiLookup: rps.viewmodels.properties.MultiLookupProperty;
        public valueMultiLookupSource: rps.data.sources.LookupSource;        
        public hasValue(): boolean {
            if (this.isRequired) {
                switch (this.propertyType) {
                    case "string":
                        if (!this.valueList)
                            return !rps.object.isNullOrUndefined(this.valueString.value);
                        else
                            return !rps.object.isNullOrUndefined(this.valueMultiComboBox.value) && this.valueMultiComboBox.value.length > 0;
                    case "decimal":
                        return !rps.object.isNullOrUndefined(this.valueDecimal.value);
                    case "integer":
                    case "int32":
                        return !rps.object.isNullOrUndefined(this.valueInteger.value);
                    case "boolean":
                        return !rps.object.isNullOrUndefined(this.valueBoolean.value);
                    case "enum":
                        return !rps.object.isNullOrUndefined(this.valueMultiEnum.value) && this.valueMultiEnum.value.length > 0;
                    case "datetime":
                        return !rps.object.isNullOrUndefined(this.valueDatetime.value);
                    case "foreignkey":
                        return !rps.object.isNullOrUndefined(this.valueMultiLookup.value) && this.valueMultiLookup.value.length > 0;
                    default:
                        return false;
                }
            }
            else {
                return true;
            }
        }

        /**
         * Crea un string, con la sintaxis que requiere la queryString, en función del tipo de dato
         */
        public getParameterValue(): string {
            if (this.isRequired) {
                switch (this.propertyType) {
                    case "string":
                        if (!this.valueList)
                            return "'" + this.valueString.value + "'"
                        else {
                            var comboBoxValue: string = "{";
                            this.valueMultiComboBox.value.forEach((val) => {
                                if (comboBoxValue == "{")
                                    comboBoxValue = comboBoxValue + "'" + val.toString() + "'";
                                else
                                    comboBoxValue = comboBoxValue + "," + "'" + val.toString() + "'";
                            });
                            comboBoxValue = comboBoxValue + "}"
                            return comboBoxValue;
                        }
                    case "decimal":
                        return rps.number.toString(this.valueDecimal.value);
                    case "integer":
                    case "int32":
                        return rps.number.toString(this.valueInteger.value);
                    case "boolean":
                        if (this.valueBoolean.value)
                            return "true";
                        else
                            return "false";
                    case "enum":
                        var enumValue: string = "{";
                        this.valueMultiEnum.value.forEach((val) => {
                            if (enumValue == "{")
                                enumValue = enumValue + val.toString();
                            else
                                enumValue = enumValue + "," + val.toString();
                        });
                        enumValue = enumValue + "}"
                        return enumValue;
                    case "datetime":
                        return this.valueDatetime.value.toISOString();
                    case "foreignkey":
                        var foreignkeyValue: string = "{";
                        this.valueMultiLookup.value.forEach((val) => {
                            if (foreignkeyValue == "{")
                                foreignkeyValue = foreignkeyValue + "'" + val + "'";
                            else
                                foreignkeyValue = foreignkeyValue + "," + "'" + val + "'";
                        });
                        foreignkeyValue = foreignkeyValue + "}"
                        return foreignkeyValue;
                    default:
                        return "";
                }
            }
            else {
                return "null";
            }
        }

        /**
         * Establece el valor a partir de la sintaxis que requiere la queryString, en función del tipo de dato
         */
        public setParameterValue(value: string) {
            if (this.isRequired) {
                switch (this.propertyType) {
                    case "string":
                        if (!this.valueList)
                            this.valueString.value = value.substring(1, value.length - 1);
                        else {
                            var newComboBoxValue: Array<string> = new Array<string>();
                            var comboBoxValue: string = value.substring(1, value.length - 1);
                            comboBoxValue.split(",").forEach((v) => {
                                newComboBoxValue.push(v.substring(1, v.length - 1));
                            });
                            this.valueMultiComboBox.value = newComboBoxValue;
                            break;
                        }
                        break;
                    case "decimal":
                        this.valueDecimal.value = rps.number.toNumber(value);
                        break;
                    case "integer":
                    case "int32":
                        this.valueInteger.value = rps.number.toNumber(value);
                        break;
                    case "boolean":
                        if (value == "true")
                            this.valueBoolean.value = true;
                        else
                            this.valueBoolean.value = false;
                        break;
                    case "enum":
                        var newEnumValue: Array<number> = new Array<number>();
                        var enumValue: string = value.substring(1, value.length - 1);
                        enumValue.split(",").forEach((v) => {
                            newEnumValue.push(rps.number.toNumber(v));
                        });
                        this.valueMultiEnum.value = newEnumValue;
                        break;
                    case "datetime":
                        this.valueDatetime.value= rps.date.fromISOString(value);                        
                        break;
                    case "foreignkey":
                        var newForeignkeyValue: Array<string> = new Array<string>();
                        var foreignkeyValue: string = value.substring(1, value.length - 1);
                        foreignkeyValue.split(",").forEach((v) => {
                            newForeignkeyValue.push(v.substring(1, v.length - 1));
                        });
                        this.valueMultiLookup.value = newForeignkeyValue;
                        break;
                }
            }
        }

        public getValueText(): string {
            if (this.isRequired) {
                switch (this.propertyType) {
                    case "string":
                        if (!this.valueList)
                            return "'" + this.valueString.value + "'";
                        else
                            return this.valueMultiComboBox.text;
                    case "decimal":
                        return this.valueDecimal.value.toString();
                    case "integer":
                    case "int32":
                        return this.valueInteger.value.toString();
                    case "boolean":
                        if (this.valueBoolean.value) {
                            return "true";
                        }
                        else {
                            return "false";
                        }
                    case "enum":
                        return this.valueMultiEnum.text;
                    case "datetime":
                        return this.valueDatetime.value.toShortDate();
                    case "foreignkey":
                        return this.valueMultiLookup.text;
                    default:
                        return "";
                }
            }
            else {
                return "";
            }
        }

        public setValue(newValue: any) {
            switch (this.propertyType) {
                case "string":
                    if (!this.valueList)
                        this.valueString.value = newValue;
                    else
                        this.valueMultiComboBox.value = newValue;
                    break;
                case "decimal":
                    this.valueDecimal.value = newValue;
                    break;
                case "integer":
                case "int32":
                    this.valueInteger.value = newValue;
                    break;
                case "boolean":
                    this.valueBoolean.value = newValue;
                    break;
                case "enum":
                    this.valueMultiEnum.value = newValue;
                    break;
                case "datetime":
                    this.valueDatetime.value = newValue;
                    break;
                case "foreignkey":
                    this.valueMultiLookup.value = newValue;
                    break;
                default:
                    break;
            }
        }
        constructor() {
            this.propertyType = "";
        }

        /**
         * Método, que devuelve un clon de la instancia actual
         */
        public clone(): variableEditor {
            //Crear la nueva instancia
            var clone: variableEditor = new variableEditor();

            //Copiar el tipo de propiedad
            clone.valueList = this.valueList;
            clone.fkEntity = this.fkEntity;
            clone.propertyType = this.propertyType;
            clone.comparisonOperator = this.comparisonOperator;

            //Copiar el valor de todas las propiedades
            if (this.valueString)
                clone.valueString.value = this.valueString.value;
            if (this.valueMultiComboBox)
                clone.valueMultiComboBox.value = this.valueMultiComboBox.value;
            if (this.valueDecimal)
                clone.valueDecimal.value = this.valueDecimal.value;
            if (this.valueInteger)
                clone.valueInteger.value = this.valueInteger.value;
            if (this.valueBoolean)
                clone.valueBoolean.value = this.valueBoolean.value;
            if (this.valueMultiEnum)
                clone.valueMultiEnum.value = this.valueMultiEnum.value;
            if (this.valueDatetime)
                clone.valueDatetime.value = this.valueDatetime.value;
            if (this.valueMultiLookup)
                clone.valueMultiLookup.value = this.valueMultiLookup.value;

            return clone;
        }
    }

    /**
     * VM de la ventana emergente que guarda los datos
     */
    /** @ignore */
    export class SaveFilters implements rps.viewmodels.IWindowVM {        
        private filtersManager: FiltersManager;

        public isOpenChanged = rps.app.eventManager.createEmitter<boolean>();
        public defaultCloseCommand = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (commandParameters: any): Promise<any> => {
                return this.cancelCommand.execute();
            }
        });
        private _isOpen: boolean = true;
        public get isOpen(): boolean {
            return this._isOpen;
        }
        public set isOpen(newValue: boolean) {
            if (this._isOpen != newValue) {
                this._isOpen = newValue;
                this.isOpenChanged.emit(newValue);
            }
        }
        public name: rps.viewmodels.properties.ComboProperty
        public acceptCommand: rps.viewmodels.commands.CommandProperty;
        public cancelCommand: rps.viewmodels.commands.CommandProperty;

        constructor(filtersManager: FiltersManager) {
            this.filtersManager = filtersManager;

            this.name = new rps.viewmodels.properties.ComboProperty({
                relatedItems: this.filtersManager.savedFilters,
                target: this,
                valuePath: "Name",
                displayPath: "Name",
                initialValue: ""
            });
            this.acceptCommand = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: this.accept,
                canExecute: this.canAccept
            });
            this.cancelCommand = new rps.viewmodels.commands.CommandProperty({
                target: this,
                command: this.cancel
            });
        }

        accept(): Promise<any> {
            var filterDefinition: rps.data.FilterDefinition = this.filtersManager.getFilterDefinition(this.name.value);            

            //El servicio, requiere un string con el JSon de la definición, no el objeto.
            var filterDefinitionString: string = JSON.stringify(filterDefinition).toString();;

            return rps.app.clientAPI.post({
                url: string.format("{0}/userfilters", this.filtersManager.entityName),
                body: filterDefinitionString                
            }).then((postResponse: services.API.IResponse) => {                
                //Añadir la definición de los filtros de la lista precargada
                this.filtersManager.addSavedFilter(filterDefinition);
                this.isOpen = false;
            }).catch((errors) => {
                if (errors == 409) {
                    //Si el error devuelve un 409, significa que el filtro ya existe, por lo que se le pregunta al usuario a ver si quieres sobrescribirlo
                    return rps.app.messageManager.show({
                        message: rps.string.format(rps.app.resources.messages.MSG_OVERWRITE_FILTES_GROUP, this.name.value),
                        messageButton: services.MessageButton.YesNo,
                        messageType: services.MessageType.Question,
                        yesOptions: { isPrimary: true }
                    }).then((promiseValue: services.MessageResult) => {
                        if (promiseValue == services.MessageResult.Yes) {
                            //Modificar los filtros con un put
                            return rps.app.clientAPI.put({
                                url: string.format("{0}/userfilters", this.filtersManager.entityName),
                                body: filterDefinitionString
                            }).then((putResponse: services.API.IResponse) => {
                                //Reemplazar la definición de los filtros de la lista precargada
                                this.filtersManager.replaceSavedFilter(filterDefinition);
                                this.isOpen = false;
                                app.notificationManager.show({
                                    message: rps.app.resources.directives.FILTERS_SAVED,
                                    notificationType: services.NotificationType.Success
                                });
                            });
                        }
                    });
                }
                else
                    throw errors;
            });
        }

        canAccept(): rps.viewmodels.commands.CanExecuteResult {
            if (this.name.hasValue())
                return rps.viewmodels.commands.CanExecuteResult.allow();
            else
                return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.messages.MSG_FILTERS_NAME_REQUIRED]);
        }

        cancel(): Promise<any> {
            this.isOpen = false;
            return Promise.resolve<any>(this);
        }
    }
} 