/// <reference path="parameters.ts" />
/// <reference path="filters.ts" />

module rps.data.sources {

    export class ChartSource implements IExecutableQuery ,IActivable{
        private service: string;
        private queryName: string;
        private params: { [id: string]: Object; }        
        private queryParams: parameters.QueryParams;

        public isIActivable: boolean = true;
      

        public propertyChanged: rps.services.IEventEmitter<rps.viewmodels.properties.VMPropertyChange> = rps.app.eventManager.createEmitter<rps.viewmodels.properties.VMPropertyChange>();

        private _loaded: boolean=false;
        public get loaded(): boolean {
            return this._loaded;
        }
        public set loaded(newValue: boolean) {
            if (this._loaded != newValue) {
                this._loaded = newValue;
                this.propertyChanged.emit({ propertyName: "loaded", newValue: newValue });
            }
        }        

        private _chartData: rps.data.ChartData;
        public get chartData(): rps.data.ChartData {
            return this._chartData;
        }
        public set chartData(newValue: rps.data.ChartData) {
            if (this._chartData != newValue) {
                this._chartData = newValue;
                this.propertyChanged.emit({ propertyName: "chartData", newValue: newValue });
            }
        }    

        constructor(params: {
            service: string;
            queryName: string;            
            queryParams?: parameters.QueryParams;
            executionPolicy: rps.queryExecutionPolicy;
        }) {
            this.service = params.service;
            this.queryName = params.queryName;
            this.executionPolicy = params.executionPolicy;

            this.queryParams = params.queryParams;
        }

        initialize(): Promise<any> {
            if (this.executionPolicy == rps.queryExecutionPolicy.Automatic || this.executionPolicy == rps.queryExecutionPolicy.WhenActive) {
                var promise: Promise<any> = this.load();
                //Si se autoexecuta, hay que añadir watches a las propiedades
                if (this.queryParams) {
                    this.queryParams.forEach((ip: parameters.QueryParam) => {
                        if (ip.value && ip.value instanceof rps.viewmodels.properties.VMProperty) {
                            (<rps.viewmodels.properties.VMProperty<any>>ip.value).propertyChanged.subscribe((subscriptionArgs: rps.viewmodels.properties.VMPropertyChange) => {
                                if (subscriptionArgs.propertyName == "value") {
                                    this.load();
                                }
                            });
                        }
                    });
                }
                return promise;
            }
            else
                return Promise.resolve<any>(this);
        }

        onActivate(isFirstActivation: boolean): void {
            //Si es la primera vez y la query está marcada como whenActivate, se intenta lanzar
            if (isFirstActivation) {
                if (this.executionPolicy == rps.queryExecutionPolicy.WhenActive)
                    this.execute();
            }
            else {
                if (this.loaded)
                    this.load();
            }
        }

        public executionPolicy: rps.queryExecutionPolicy;

        public execute = (): Promise<any> => {
            return this.loadItems.execute();
        }

        public loadItems: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {                
                return this.load();
            },
            canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
                if (!this.canCreateParams()) {
                    var reasons: Array<string> = new Array<string>();
                    reasons.push(rps.app.resources.directives.FILL_REQUIRED_PARAMETERS);
                    return rps.viewmodels.commands.CanExecuteResult.deny(reasons);
                }
                else
                    return rps.viewmodels.commands.CanExecuteResult.allow();
            }
        });

        private load(): Promise<any> {
            if (this.canCreateParams()) {                
                this.createParams();                
                return this.read().then((result:rps.data.ChartData) => {                    
                    this.chartData = result;
                    this.loaded = true;
                    return this;
                });
            }
            else if (this.loaded) {                                
                this.chartData = null;
                this.loaded = false;
                return Promise.resolve<any>(this);
            }

            return Promise.resolve<any>(this);
        }            


        private canCreateParams(): boolean {
            var canCreate: boolean = true;
            if (this.queryParams) {
                this.queryParams.forEach((ip: parameters.QueryParam) => {
                    //Mirar si todos los parámetros requeridos tienen valor
                    if (ip.isRequired && !rps.object.hasValue(ip.getValue()))
                        canCreate = false;
                });
            }
            return canCreate;
        }

        createParams() {
            this.params = {};

            if (this.queryParams) {
                this.queryParams.forEach((queryParam: parameters.QueryParam) => {
                    var value = queryParam.getValue();
                    if (!rps.object.isNullOrUndefined(value)) {
                        if (value instanceof Date)
                            this.params[queryParam.paramName] = value.toISOString();
                        else if (Array.isArray(value)) {
                            if (value.length > 0)
                            {
                                var newParamValue: string;
                                (<Array<any>>value).forEach((item) => {
                                    if (newParamValue == undefined)
                                        newParamValue = item;
                                    else
                                        newParamValue += "," + item;
                                });
                                this.params[queryParam.paramName] = newParamValue;
                            }
                        }
                        else
                            this.params[queryParam.paramName] = value;
                    }                    
                });
            }
        }

        protected read(): Promise<rps.data.ChartData> {
            //Si pasan una url, se ejecuta la query contra esta; si no, se ejecuta la query con el nombre configurado
            return rps.app.api.query<rps.data.ChartData>({
                queryName: this.queryReference,                
                params: this.params
            }).then((result) => {
                return result;
            });
        }

        /**
        Sobreescribir para indicar una URL alternativa para el querySource. Por defecto, se crea con service/queryName
        */
        protected get queryReference() : string {
            return this.service + "/" + this.queryName
        }
    }
}