/// <reference path="parameters.ts" />
/// <reference path="filters.ts" />

module rps.data.sources {

    export interface IQueryFilterDocumentation {
        Entity: string;
        Name: string;
        Label: string;
        Type: string;
        Relation?: string;
        ValueList?: Array<IValueListItem>;
        FKEntity?: string;
    }

    export interface IValueListItem {
        Key: number;
        Value: string;
    }

    export interface getColumnsArgs {
        newItems: Array<any>
    }

    export class QuerySource implements ISelectable,IItemContainer, IActivable, IDestroyable, IExecutableQuery, rps.viewmodels.rules.IRuleTrigger, IColumnsHandler {        
        public IItemContainer = true;
        public IRuleTrigger: boolean = true;
        public IColumnsHandler: boolean = true;        
        public ISelectable: boolean = true;        
        public isIActivable: boolean = true;
        public isIDestroyable: boolean = true;

        private _parentVM: rps.viewmodels.BaseVM;
        private service: string;
        private queryName: string;
        private params: { [id: string]: Object; }
        private relatedVMFunction: Function;
        private saveState: boolean | undefined;
        private configuredPagination: { disablePagination: boolean, pageSize: number };
        private canExecuteQuery: (() => rps.viewmodels.commands.CanExecuteResult) | undefined;
        private beforeExecuteQuery:( () => Promise<{ cancel?: boolean }>) | undefined;
        private afterExecuteQuery: ((serverResult: rps.data.PaginatedViewResult, querySourceResult: Array<rps.viewmodels.ModelVM>) => Promise<any>) | undefined;
        private isSearchable: boolean = true;

        public customCardViewItemUrl: string | undefined;

        public propertyChanged: rps.services.IEventEmitter<rps.viewmodels.properties.VMPropertyChange> = rps.app.eventManager.createEmitter<rps.viewmodels.properties.VMPropertyChange>();        
        public filtersManager: rps.data.filters.FiltersManager;


        public variableColumns: Array<rps.ui.itemsControl.ColumnDefinition>;

        public rules: rps.viewmodels.rules.Rule[] = [];

        public _items: { data: Array<any>; count: number };
        get items(): { data: Array<any>; count: number } {
            return this._items;
        }
        set items(newValue: { data: Array<any>; count: number }) {
            if (this._items != newValue) {
                this._items = newValue;

                //Lanzar las when del target (las que no tienen Trigger)
                if (this._parentVM && (<any>this._parentVM).rules && (<any>this._parentVM).rules.length > 0) {
                    Enumerable.From<rps.viewmodels.rules.Rule>((<any>this._parentVM).rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                        ForEach((rule) => {
                            rule.execute(this);
                        });
                }
            }
        }

        public hasItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.items) && this.items.count > 0)
                return true;
            else
                return false;
        }
        public getItems(): Array<any> {
            if (this.hasItems())
                return this.items.data;
            else
                return new Array<any>();
        }
        public setItems(items: Array<any>) {
            this.items = {
                data: items,count:items.length
            }
            this.propertyChanged.emit({ propertyName: "items", newValue: items });

            if (this.dataSource)
                this.dataSource.data(items);

        }

        private _selectedItems: Array<any>;
        public get selectedItems(): Array<any> {
            return this._selectedItems;
        }
        public set selectedItems(newValue: Array<any>) {
            if (this._selectedItems != newValue) {
                this._selectedItems = newValue;

                this.propertyChanged.emit({ propertyName: "selectedItems", newValue: newValue });

                //Lanzar las when del target (las que no tienen Trigger)
                if (this._parentVM && (<any>this._parentVM).rules && (<any>this._parentVM).rules.length > 0) {
                    Enumerable.From<rps.viewmodels.rules.Rule>((<any>this._parentVM).rules).Where(rule => rps.object.isUndefined(rule.Trigger)).
                        ForEach((rule) => {
                            rule.execute(this);
                        });
                }
            }
        }

        public hasSelectedItems(): boolean {
            if (!rps.object.isNullOrUndefined(this.selectedItems) && this.selectedItems.length > 0)
                return true;
            else
                return false;
        }
        public getSelectedItems(): Array<any> {
            if (this.hasSelectedItems())
                return this.selectedItems;
            else
                return new Array<any>();
        }

        public setSelectedItems(newSelection: Array<any>) {
            this.selectedItems = newSelection;
        }

        public Clear(): Promise<any> {
            return this.loadPage(0);
        }

        private _loaded: boolean=false;
        public get loaded(): boolean {
            return this._loaded;
        }
        public set loaded(newValue: boolean) {
            if (this._loaded != newValue) {
                this._loaded = newValue;
                this.propertyChanged.emit({ propertyName: "loaded", newValue: newValue });
            }
        }

        private _isReading: boolean = false;
        public get isReading(): boolean {
            return this._isReading;
        }
        public set isReading(newValue: boolean) {
            if (this._isReading != newValue) {
                this._isReading = newValue;
                this.propertyChanged.emit({ propertyName: "isReading", newValue: newValue });
            }
        }
        
        public queryParams: parameters.QueryParams;

        private defaultDisablePagination: boolean = false;
        private _disablePagination: boolean = false;
        public get disablePagination(): boolean {
            return this._disablePagination;
        }
        public set disablePagination(newValue: boolean) {
            if (this._disablePagination != newValue) {
                this._disablePagination = newValue;
                this.propertyChanged.emit({ propertyName: "disablePagination", newValue: newValue });
            }
        }

        public pageSize: number;

        public dataSource: kendo.data.DataSource;

        public getColumns: (args: getColumnsArgs) => Promise<Array<rps.ui.itemsControl.ColumnDefinition>>;

        get parentVM(): rps.viewmodels.BaseVM | any {
            return this._parentVM;
        }

        constructor(params: {
            service: string;
            queryName: string;
            relatedVMFunction: Function;
            executionPolicy: rps.queryExecutionPolicy;
            parentVM?:rps.viewmodels.BaseVM
            disablePagination?: boolean;
            pageSize?: number;
            queryParams?: parameters.QueryParams;
            saveState?: boolean;
            filtersManager?: rps.data.filters.FiltersManager;
            isSearchable?: boolean;
            entityName?: string;            
        }) {
            //Se establece el manejador de filtros, si nos viene dado del padre o tenemos la entidad relacionada
            if (rps.object.hasValue(params.filtersManager))
                this.filtersManager = params.filtersManager;
            else if (rps.object.hasValue(params.entityName))
                this.filtersManager = new rps.data.filters.FiltersManager({
                    entityName: params.entityName
                });
            //Si se ha creado el manejador de filtros, nos enganchamos al cambio de filtros para ejecutar la consulta
            if (this.filtersManager) {
                this.filtersManager.filtersChanged.subscribe(() => {
                    this.loadItems.execute();
                });
            }
            if (params.isSearchable == false)
                this.isSearchable = false;

            this._parentVM = params.parentVM;
            this.service = params.service;
            this.queryName = params.queryName;
            if (params.disablePagination) {
                this.defaultDisablePagination = params.disablePagination;
                this.disablePagination = params.disablePagination;
                this.pageSize = 0;
            }
            else if (params.pageSize)
                this.pageSize = params.pageSize;
            else
                this.pageSize = 24;
            this.saveState = params.saveState;
            this.executionPolicy = params.executionPolicy;

            this.queryParams = params.queryParams;
            this.relatedVMFunction = (<any>(params.relatedVMFunction)).getResolvedType(params.relatedVMFunction);

            this.createDataSource();
        }

        initialize(): Promise<any> {
            if (this.executionPolicy == rps.queryExecutionPolicy.Automatic || this.executionPolicy == rps.queryExecutionPolicy.WhenActive) {
                var promise: Promise<any> = this.loadPage(1);
                //Si se autoexecuta, hay que añadir watches a las propiedades
                if (this.queryParams) {
                    this.queryParams.forEach((ip: parameters.QueryParam) => {
                        if (ip.value && ip.value instanceof rps.viewmodels.properties.VMProperty) {
                            (<rps.viewmodels.properties.VMProperty<any>>ip.value).propertyChanged.subscribe((subscriptionArgs: rps.viewmodels.properties.VMPropertyChange) => {
                                if (subscriptionArgs.propertyName == "value") {
                                    this.loadPage(1);
                                }
                            });
                        }
                    });
                }
                return promise;
            }
            else
                return Promise.resolve<any>(this);
        }

        onActivate(isFirstActivation: boolean): void {
            //Si es la primera vez y la query está marcada como whenActivate, se intenta lanzar
            if (isFirstActivation) {
                if (this.executionPolicy == rps.queryExecutionPolicy.WhenActive)
                    this.execute();
            }
            else {
                //Si no es la primera vez que se activa la pantalla y la query está cargada, se refresca
                if (this.loaded && this.dataSource.page() > 0 && !this.defaultDisablePagination)
                    this.loadPage(this.dataSource.page());
            }
        }

        onDestroy(): void {
            this.propertyChanged.onDestroy();
            if (this._items) {
                this._items.data.forEach((item) => {
                    if (item.onDestroy)
                        item.onDestroy();
                });
                this._items.data = null;
                this._items = null;
            } 
            if (this.dataSource)
                this.dataSource = null;
        }

        remove(viewModel: rps.viewmodels.ModelVM): Promise<any> {
            if (Enumerable.From<rps.viewmodels.ModelVM>(this.getItems()).Any(item => item == viewModel)) {
                return new Promise((resolve) => {
                    this.items.data.splice(this.items.data.indexOf(viewModel), 1);
                    //HACK: Se eliminan todos los items y se vuelven a crear, todo excepto el eliminado, ya que si no, la grid, no actúa de la forma esperada…
                    var length: number = this.dataSource.data().length;
                    for (var i = 0; i < length; i++) {
                        this.dataSource.remove(this.dataSource.at(0));
                    }
                    this.items.data.forEach((dataItem) => {
                        this.dataSource.add(dataItem);
                    });
                    resolve(this);
                });
            }
            else {
                return Promise.reject("Not found");
            }
        }

        public executionPolicy: rps.queryExecutionPolicy;

        public execute = (commandParameters?: any): Promise<any> => {
            var canExecuteResult = this.loadItemsCanExecute(commandParameters);
            if (canExecuteResult.result)
                return this.loadItemsExecute(commandParameters);
            else
                throw canExecuteResult.denyReasons;
        }

        public loadItems: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: this.loadItemsExecute,
            canExecute: this.loadItemsCanExecute
        });

        private loadItemsExecute(commandParameters?: any): Promise<any>{
            if (this.configuredPagination) {
                this.disablePagination = this.configuredPagination.disablePagination;
                this.pageSize = this.configuredPagination.pageSize;
                this.configuredPagination = null;
                this.dataSource.pageSize(this.pageSize);
            }
            return this.loadPage(1, commandParameters);
        }

        private loadItemsCanExecute(commandParameters?: any): rps.viewmodels.commands.CanExecuteResult {
            if (!this.canCreateParams(commandParameters)) {
                var reasons: Array<string> = new Array<string>();
                reasons.push(rps.app.resources.directives.FILL_REQUIRED_PARAMETERS);
                return rps.viewmodels.commands.CanExecuteResult.deny(reasons);
            }
            else if (this.canExecuteQuery)
                return this.canExecuteQuery.call(this._parentVM);
            else
                return rps.viewmodels.commands.CanExecuteResult.allow();
        }

        public printItems: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: this.executePrint,
            canExecute: this.printItemsCanExecute
        });

        private printItemsCanExecute(commandParameters?: any): rps.viewmodels.commands.CanExecuteResult {
            if (!this.canCreateParams(commandParameters)) {
                var reasons: Array<string> = new Array<string>();
                reasons.push(rps.app.resources.directives.FILL_REQUIRED_PARAMETERS);
                return rps.viewmodels.commands.CanExecuteResult.deny(reasons);
            }
            else if (this.canExecuteQuery)
                return this.canExecuteQuery.call(this._parentVM);
            else
                return rps.viewmodels.commands.CanExecuteResult.allow();
        }

        public configure(params: {
            canExecuteQuery?: () => rps.viewmodels.commands.CanExecuteResult,
            beforeExecuteQuery?: () => Promise<{ cancel?: boolean }>,
            afterExecuteQuery?: (serverResult: rps.data.PaginatedViewResult, querySourceResult: Array<rps.viewmodels.ModelVM>) => Promise<any>,
            customCardViewItemUrl?:string
        }) {
            if (rps.object.hasValue(params.canExecuteQuery))
                this.canExecuteQuery = params.canExecuteQuery;

            if (rps.object.hasValue(params.beforeExecuteQuery))
                this.beforeExecuteQuery = params.beforeExecuteQuery;

            if (rps.object.hasValue(params.afterExecuteQuery))
                this.afterExecuteQuery = params.afterExecuteQuery;

            if (rps.object.hasValue(params.customCardViewItemUrl))
                this.customCardViewItemUrl = params.customCardViewItemUrl;
        }

        public removePagination: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<QuerySource> => {
                this.configuredPagination = {
                    disablePagination: this.disablePagination,
                    pageSize: this.pageSize
                };
                this.disablePagination = true;                
                this.pageSize = 0;              
                this.dataSource.pageSize(0);
                return Promise.resolve<any>(this);
            },
            canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
                if (this.disablePagination)
                    return rps.viewmodels.commands.CanExecuteResult.deny(null);
                else if (!this.loaded)
                    return rps.viewmodels.commands.CanExecuteResult.deny(null);
                else if (!this.items || this.items.count <= this.pageSize)
                    return rps.viewmodels.commands.CanExecuteResult.deny(null);
                else
                    return rps.viewmodels.commands.CanExecuteResult.allow();
            }
        });

        private loadPage(page: number, commandParameters?: any):Promise<any> {
            if (page > 0 && this.canCreateParams(commandParameters)) {
                if (!this.beforeExecuteQuery) {
                    this.loaded = true;
                    this.createParams(commandParameters);
                    return new Promise((resolve, reject) => {
                        this.dataSource.query({
                            page: page,
                            pageSize: this.pageSize
                        }).done(() => {
                            resolve(this);
                        }).fail((error) => {
                            reject(error);
                        });
                    });
                }
                else {
                    return this.beforeExecuteQuery.call(this._parentVM).then((successCallback: { cancel?: boolean }) => {
                        if (!successCallback.cancel) {
                            this.loaded = true;
                            this.createParams();
                            return new Promise((resolve, reject) => {
                                this.dataSource.query({
                                    page: page,
                                    pageSize: this.pageSize
                                }).done(() => {
                                    resolve(this);
                                }).fail((error) => {
                                    reject(error);
                                });
                            });
                        }
                    });
                }
            }
            else if (this.loaded) {
                this.loaded = false;

                var items: Array<any> = new Array<any>();
                items["total"] = 0;
                this.items = {
                    data: items,
                    count: items["total"]
                };
                this.dataSource.data(items);
                return Promise.resolve<any>(this);
            }

            return Promise.resolve<any>(this);
        }

        public canCreateParams(commandParameters?: any): boolean {
            var canCreate: boolean = true;
            if (this.queryParams) {
                this.queryParams.forEach((ip: parameters.QueryParam) => {
                    //Mirar si todos los parámetros requeridos tienen valor
                    if (ip.isRequired) {
                        if (!rps.object.hasValue(ip.getValue())
                            && (rps.object.isNullOrUndefined(commandParameters) || !rps.object.hasValue(commandParameters[ip.paramName]))) {

                            canCreate = false;
                        }
                    }
                });
            }
            return canCreate;
        }     

        createParams(commandParameters?: any) {
            this.params = this.getParams(commandParameters);
        }

        getParams(commandParameters?: any): { [id: string]: Object; } {
            var params: { [id: string]: Object; } = {};

            //Si se ha creado el manejador de filtros, miramos a ver si contiene filtros
            if (this.filtersManager) {
                if (this.isSearchable && this.filtersManager.search.hasValue())
                    params["$search"] = this.filtersManager.search.value;
                if (this.filtersManager.filters.hasValue()) {
                    var filtersParam: string = "";
                    params["$filter"] = this.filtersManager.getFilterParameters();
                }
            }

            if (this.queryParams) {
                this.queryParams.forEach((queryParam: parameters.QueryParam) => {
                    //Mirar si está entre los pasados o coge el bindeado
                    var value: any;
                    if (commandParameters && rps.object.hasValue(commandParameters[queryParam.paramName])) {
                        if (commandParameters[queryParam.paramName] instanceof rps.viewmodels.properties.VMProperty)
                            value = (<rps.viewmodels.properties.VMProperty<any>>commandParameters[queryParam.paramName]).getServerParamValue();
                        else if (rps.object.isFunction(commandParameters[queryParam.paramName]))
                            value = commandParameters[queryParam.paramName].call();
                        else
                            value = commandParameters[queryParam.paramName];
                    }
                    else
                        value = queryParam.getValue();

                    if (!rps.object.isNullOrUndefined(value)) {
                        if (value instanceof Date)
                            params[queryParam.paramName] = value.toISOString();
                        else if (Array.isArray(value)) {
                            if (value.length > 0) {
                                var newParamValue: string;
                                (<Array<any>>value).forEach((item) => {
                                    if (newParamValue == undefined)
                                        newParamValue = item;
                                    else
                                        newParamValue += "," + item;
                                });
                                params[queryParam.paramName] = newParamValue;
                            }
                        }
                        else
                            params[queryParam.paramName] = value;
                    }
                });
            }

            return params;
        }

        createDataSource() {
            var dataSourceOptions: kendo.data.DataSourceOptions = {}
            dataSourceOptions.serverFiltering = true;
            dataSourceOptions.serverPaging = true;
            dataSourceOptions.pageSize = this.pageSize;
            dataSourceOptions.transport= {
                read: this.read
            };
            dataSourceOptions.schema= {
                total: this.total,
                
            };

            this.dataSource = new kendo.data.DataSource(dataSourceOptions);
        }

        read = (options: kendo.data.DataSourceTransportReadOptions) => {
            this.doRead(options);
        }

        protected doRead(options: kendo.data.DataSourceTransportReadOptions) {
            this.isReading = true;
            this.selectedItems = undefined;

            var pageOptions: {
                page: number;
                pageSize: number;
            };
            if (!this.disablePagination) {
                pageOptions = {
                    pageSize: this.pageSize,
                    page: this.dataSource.page()
                }
            }

            //Si pasan una url, se ejecuta la query contra esta; si no, se ejecuta la query con el nombre configurado
            rps.app.api.query<rps.data.PaginatedViewResult>({ queryName: this.queryReference, pageOptions: pageOptions, params: this.params }).then((result) => {
                this.createViewModels(result).then((items: Array<rps.viewmodels.ModelVM>) => {
                    items["total"] = result.Count;
                    this.items = {
                        data: items,
                        count: items["total"]
                    };
                    this.createVariableColumns(items).then(() => {
                        this.isReading = false;
                        options.success(items);
                        this.processRules(rps.viewmodels.rules.Triggers.OnExecuteQuery);
                    });
                });
            }).catch((error) => {
                this.isReading = false;
                options.error(error);
            });
        }

        /**
        Sobreescribir para indicar una URL alternativa para el querySource. Por defecto, se crea con service/queryName
        */
        protected get queryReference() : string {
            return this.service + "/" + this.queryName
        }

        createVariableColumns = (newItems: Array<any>): Promise<any> => {
            if (this.getColumns) {                
                return this.getColumns({ newItems: newItems }).then((getColumnsResult) => {                    
                    this.variableColumns = getColumnsResult;
                });
            }
            else
                return Promise.resolve<any>({});
        }

        total = (items: Array<any>): number => {
            if (items && items["total"])
                return items["total"];
            else
                return 0;
        }

        createViewModels(result: rps.data.PaginatedViewResult): Promise<Array<rps.viewmodels.ModelVM>>{
            var promises: Array<Promise<rps.viewmodels.ModelVM>> = new Array<Promise<rps.viewmodels.ModelVM>>();
            result.Data.forEach((dataItem) => {
                promises.push(this.createViewModel(dataItem));
            });
            return Promise.all(promises).then((newItems) => {
                if (this.afterExecuteQuery)
                    return (<Promise<any>>this.afterExecuteQuery.call(this._parentVM,result, newItems)).then(() => {
                        return newItems;
                    })
                else
                    return newItems;
            });
        }

        createViewModel(model: any): Promise<rps.viewmodels.ModelVM>{            
            var initParams = { model: model };
            if (this._parentVM)
                initParams["parentVM"] = this._parentVM;
            return rps.app.viewModelFactory.createViewModel(this.relatedVMFunction, initParams);
        }        

        protected processRules(trigger?: rps.viewmodels.rules.Triggers): Promise<rps.viewmodels.rules.RuleExecutionResult> {
            var rulesToExecute: Array<rps.viewmodels.rules.Rule> = [];
            if (this.rules && this.rules.length > 0) {
                this.rules.forEach((rule) => {
                    if (trigger && rule.Trigger == trigger)
                        rulesToExecute.push(rule);
                });
            }

            return rulesToExecute.reduce((prevPromise, rule) => {
                return prevPromise.then((result) => {
                    if (result.value === rps.viewmodels.rules.RuleExecutionValue.Continue)
                        return rule.execute().catch((err) => {
                            //Si casca la ejecución de una regla, añadir el error y cancelar el resto de reglas
                            rps.app.errorManager.add(
                                new rps.errors.ErrorDetail("RULE_ERROR", rps.app.resources.errors.ERR_ERROR_EXECUTING_RULE.format(rule.ID, rps.errors.getErrorString(err))));

                            return Promise.resolve({ value: rps.viewmodels.rules.RuleExecutionValue.Cancel });
                        });
                    else
                        return Promise.resolve({ value: rps.viewmodels.rules.RuleExecutionValue.Cancel });
                })
            }, Promise.resolve<rps.viewmodels.rules.RuleExecutionResult>({ value: rps.viewmodels.rules.RuleExecutionValue.Continue }));

        }

        public export: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: this.executeExport,
            canExecute: this.loadItemsCanExecute
        });

        protected executeExport(commandParameters: { format: string }): Promise<any> {
            this.createParams();
            return rps.app.api.exportQuery({
                queryReference: this.queryReference,
                exportFormat: commandParameters.format,
                params: this.params
            });
        }

        public executePrint(): Promise<boolean> {
            this.createParams();
            return rps.app.api.query<boolean>(
                {
                    queryName: this.queryReference,
                    params: this.params,
                    forPrint: true
                });
        }
    }

    export class QuerySourceItemVM extends rps.viewmodels.ModelVM {

        public ID: rps.viewmodels.properties.VMProperty<string>;

        configureVMProperties() {
            super.configureVMProperties();

            this.ID = new rps.viewmodels.properties.VMProperty<string>({ target: this, valuePropertyPath: "model.ID" });
        }
    }

    export class AlternativeQuerySource extends QuerySource {
        private queryUrl: string;

        constructor(params: {
            queryUrl: string,
            relatedVMFunction: Function;
            executionPolicy: rps.queryExecutionPolicy;
            parentVM?: rps.viewmodels.BaseVM;
            disablePagination?: boolean;
            pageSize?: number;
            queryParams?: parameters.QueryParams;
            saveState?: boolean
        }) {
            super({
                service: "",
                queryName: "",
                relatedVMFunction: params.relatedVMFunction,
                executionPolicy: params.executionPolicy,
                parentVM: params.parentVM,
                disablePagination: params.disablePagination,
                pageSize: params.pageSize,
                queryParams: params.queryParams,
                saveState: params.saveState
            });

            this.queryUrl = params.queryUrl;
        }

        protected get queryReference(): string {
            return this.queryUrl;
        }
    }
}